<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'BOOKING', subMenuCode: 'CURRENT_BOOKING', pageCode: 'ALL_BOOKING', subPageCode: 'VIEW' }">
@if(isReady){
    <div class="grid details-div">
        <div class="flex-grow-1 flex flex-row flex-wrap">
            @if(attributeValues){
            <app-booking-header [data]="attributeValues" [bookingId]="bookingId" [passenger]="relatedData" [passengerCount]="relatedData?.booking_pass?.length" [driver]="driverRelatedData" [vehicle]="vehicleRelatedData"> </app-booking-header>
            }
    
        </div>
        @if(attributeValues){
            <div class="flex flex-row-reverse flex-wrap booking-header-button">
                @if(isAdminHasCancelBookingAccessOnBasisOfBookingStatus){
                    <button pButton label="{{'bookings.cancel_journey'| translate }}" class="mr-2 cancel-journey" (click)="onCancelJourney()" ><i class="{{miIcons.BASIC_TRAY}} inline-block mr-2 wh-20 mr-2"></i></button>
                }
                
                <!-- <app-action-buttons [rowData]="attributeValues"  [bookingStatus]="bookingStatus" class="mr-2"></app-action-buttons> -->
                <div class="status-btn cursor-pointer font-semibold mr-2" (click)="showStatusDialog($event,attributeValues)" [ngStyle]="bookingStyle">
                    {{bookingStatusDisplayStr?.label || BOOKING_STATUS_LABEL[attributeValues?.booking_status] || "-"}}
                    <i class="{{miIcons.CHEVRON_BIG_DOWN}} inline-block relative mr-2 text-xs  wh-16 ml-1 chevron-big-down mr-1"></i>
                 </div>        
                    
            </div>
        }
    </div>
    
    <p-tabView [(activeIndex)]="activeIndex" [scrollable]="true">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i  class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-16 relative cursor-pointer mr-1"></i>
                <label translate>bookings.details</label>
            </ng-template>
            @if (bookingAttributeData) {
            <app-booking-info
                [attributeValues]="attributeValues"
                [attributeLabels]="attributeLabels"
                [bookingAttributeData]="bookingAttributeData"
                [bookingDetails]="bookingDetails"
                [driverAttributeData]="driverAttributeData"
                [bookingStatus]="bookingStatus"
                [bookingId]="bookingId"
                [passengerRelatedData]="passengerRelatedData"
                [vehicleRelatedData]="vehicleRelatedData"
                [driverRelatedData]="driverRelatedData"></app-booking-info>
            }
    
        </p-tabPanel>
    
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i  class="{{miIcons.BOOKING_ACTIVITY}} inline-block wh-16 relative cursor-pointer mr-1"></i>
                <label translate>bookings.activity</label>
            </ng-template>
            <app-booking-activity> 
                
            </app-booking-activity>

        </p-tabPanel>
    
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i  class="{{miIcons.MAP_2}} inline-block wh-16 relative cursor-pointer"></i>
                <label translate>bookings.map</label>
            </ng-template>

            <app-dispatch-map [geoLocationsData]="geoLocationsData"></app-dispatch-map>

            
        </p-tabPanel>
    </p-tabView>


    
    @if(statusData){
        <app-booking-status [visible]="isStatusDialogShow" [bookingStatus]="bookingStatus" [bookingDetails]="bookingDetails" [statusData]="statusData"  (onClose)="onHideStatusDialog()" (updateStatus)="onUpdatedStatus()" (statusUpdatedChanges)="onStatusUpdated()" (openDialog)="onOpenDialog($event)"></app-booking-status>
    }
}
</div>

<p-dialog [modal]="true" [(visible)]="visible" [style]="{ width: '35vw' }" [draggable]="false">
    <ng-template pTemplate="header">
        <div class="flex flex-column gap-2">
          <span class="text-base font-bold" translate>bookings.cancel_journey</span>
        </div>
    </ng-template>
    <div class="grid">
        <div class="col-6 pb-4">
            <label class="pl-0 booking-dialog font-semibold" translate>bookings.lbl_booking_id</label>
        </div>
        <div class="col-6 pb-3">
            <label class="booking-dialog font-semibold" translate>bookings.lbl_status</label>
        </div>
        <div class="col-6 pl-2 flex justify-content-start align-items-center">
            <p-checkbox [(ngModel)]="isMainBooking" [binary]="true" inputId="binary"></p-checkbox> 
            <label for="binary" class="checkbox-id cursor-pointer">{{attributeValues?.booking_code}}<span class="font-light ml-1" translate>bookings.lbl_main_booking</span></label>
        </div>
        <div class="col-6 mb-3 mt-3 assigned-btn" [ngStyle]="bookingStyle">
            <label [ngClass]="cs.getLabelClass(attributeValues?.booking_status)" class="cancel-journey-btn cursor-pointer font-semibold mr-2">{{BOOKING_STATUS_LABEL[attributeValues?.booking_status]}}</label>

        </div>
        @if(attributeValues?.passenger_booking_ids){
            @for(bookingPassengerCode of passengerIdData; track bookingPassengerCode; let i = $index){
                    <div class="col-6 pl-2 flex justify-content-start align-items-center mb-3 luggageLabel">
                        <p-checkbox [(ngModel)]="isPassengerSubBooking" [label]="bookingPassengerCode.bookingCode"  [value]="bookingPassengerCode?.entityId" name="passenger"></p-checkbox>
                  
                    </div>
                
           
                <div class="col-6 mb-4 mt-3 assigned-btn" [ngStyle]="bookingPassengerCode?.statusStyle">
                    <label  class="cancel-journey-btn cursor-pointer font-semibold">{{bookingPassengerCode?.statusLabel}}</label>
                </div>
            }
        }
        
        @if(attributeValues?.luggage_booking_ids){
            @for(bookingLuggageCode of luggageIdData; track bookingLuggageCode; let i = $index){
                <div class="col-6 pl-2 flex justify-content-start align-items-center mb-3 luggageLabel">
                    <p-checkbox [(ngModel)]="isLuggageSubBooking" name="luggage" [label]="bookingLuggageCode.bookingCode" [value]="bookingLuggageCode?.entityId" ></p-checkbox>
                </div>

                <div class="col-6 mb-4 mt-3 assigned-btn" [ngStyle]="bookingLuggageCode?.statusStyle">
                    <label class="cancel-journey-btn cursor-pointer font-semibold">{{bookingLuggageCode?.statusLabel}}</label>
                </div>
            }     
        }
    </div>
    
    
    @if(onTheWayBookings?.length > 0){
    <div class="flex pb-3 mr-3 note-journey">
        <label translate><span class="font-bold" translate>bookings.lbl_note</span><span translate>bookings.lbl_cancel_text_part_1</span><span class="font-bold" translate>bookings.lbl_cancel_text_part_2</span><span translate>bookings.lbl_cancel_text_part_3</span></label>
    </div>
    }
    
    @for(booking of onTheWayBookings; track booking; let i = $index){
        <div class="grid">
            <div class="col-6 pl-2 flex justify-coonLuggageCheckboxClickntent-start align-items-center mb-3">
                <p-checkbox [(ngModel)]="isLuggageSubBooking" name="luggage" [label]="booking.bookingCode" [value]="booking?.entityId" disabled ></p-checkbox>
            </div>

            <div class="col-6 mb-4 mt-3 assigned-btn" [ngStyle]="booking?.statusStyle">
                <label class="cancel-journey-btn cursor-pointer font-semibold">{{booking?.statusLabel}}</label>
            </div>
        </div>
    }
    

    <ng-template pTemplate="footer">
        <div class="flex justify-content-end pt-4 px-3 pb-3 footer-btn">
            <button pButton class="bg-white mr-2 text-base font-semibold keep-btn" (click)="keepJourney()" translate>bookings.lbl_keep_journeys</button>
            <button pButton class="text-base font-semibold journey-btn" (click)="showConfirmDialog()" [disabled]="isCancelJourneyDisabled()" translate>bookings.cancel_journey</button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog [modal]="true" [(visible)]="confirmVisible" [style]="{ width: '35vw'}" [draggable]="false" styleClass="custom-dialog">
    <ng-template pTemplate="header">
      <div class="flex flex-column gap-2">
        <span class="text-base font-bold" translate>bookings.lbl_cancel_booking_text</span>
      </div>
    </ng-template>
    <div class="grid">
      <div class="col-12">
        <label class="text-base" translate>bookings.lbl_cancel_booking_body_text</label>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="flex justify-content-end footer-btn">
        <button pButton class="bg-white text-base font-semibold keep-btn" (click)="onGoBack()" translate>bookings.lbl_go_back</button>
        <button pButton class="text-base font-semibold journey-btn" (click)="cancelBooking(selectedEntityId || bookingId)" translate>bookings.lbl_cancel_journey_button</button>
      </div>
    </ng-template>
</p-dialog>