export const environment = {
  production: true,
  basePath: 'https://mitransportationv3-demoapi.mi2.in/',
  idleTimeEnable: true,
  magneticHolder: 'Web@V3~2024',
  tenantCode: 't001',
  mapId: '4504f8b37365c3d0',
  googleMapKey: 'AIzaSyCSs1NL8mAOyv2Y5m4_slV2_gcuTRw12RA',
  supportedCountry: ['IN', 'UK', 'US', 'AU'],
};
