@if(isReady){
  <form [formGroup]="miFormGroup"  [class.disabled-form]="isAssignNowClicked">
    @if(isAssignNowClicked){
      <div class="form-overlay"></div>
    }
    <div class="booking-form-container p-0">
  
      @for (group of groups; track groups.fields;let i = $index) {
        @if(skipGroup.includes(group.code)){
  
        }@else {
  
          @if(group.code ==='booking_for' ){
            <div [class.disabled-booking-for-section]="isEdit" class="remaining-form-sections">
              @if(isEdit){
                <div class="form-overlay"></div>
              }
              <div class="booking-for p-3 pb-2 bg-white border-round mb-3">
                  <div class="p-fluid p-formgrid grid">
                    <div class="field col-12 md:col-12 mb-0">
                      <div class="booking-for-label font-bold mb-3 " translate>bookings.booking_for <span class="required">&nbsp;*</span></div>
                      <div class="flex">
                        <div class="flex-grow-1 flex mr-2 relative align-items-center booking-for-input">
                          <p-autoComplete class="w-full mi-auto-complete" [placeholder]="'bookings.search_passenger_placeholder'|translate"
                            [(ngModel)]="currentSelectedPassenger" [suggestions]="suggestions" (completeMethod)="search($event)" (onSelect)="onSelectPassenger($event, 'isOwner')"
                            optionLabel="name" [multiple]="false" [showClear]="false" [disabled]="isPassengerSelected" [ngModelOptions]="{standalone : true}">
                            <ng-template let-passenger pTemplate="item">
                              <div class="flex align-items-center gap-2">
                                <div class="passenger-popup bold-name">
                                  @if (passenger.passenger_profile_image_url && passenger.passenger_profile_image_url?.length > 0) {
                                    <img [src]="passenger.passenger_profile_image_url[0] || defaultLoaderImg" class="vertical-align-middle border-round-sm list-img mr-1" />
                                  } @else {
                                    <i class="mi-lg mi-default-user wh-20 m-2 mr-2 vertical-align-middle border-round-sm"></i>
                                  }
                                  <span class="ellipsis mr-2">
                                    {{passenger.name}}
                                  </span>
                                  <span class="unique ellipsis mr-2">
                                    ({{passenger.unique_id}}) ({{passenger.passenger_type}})
                                  </span>
                                </div>
                      
                              </div>
                            </ng-template>
                          </p-autoComplete>
                          <i class="pi pi-search absolute search-passenger-icon"></i>
                          <i class="{{miIcons.BOOKING_EDIT_PLUS_FILL_CIRCLE}} create-new-passenger absolute inline-block wh-28 cursor-pointer" (click)="createNewPassenger($event)"
                             [pTooltip]="'bookings.create_new_passenger' | translate" tooltipPosition="top" tooltipStyleClass="tooltip-container"></i>
                        </div>
                      </div>
                      <p class="mt-2 font-14 booking-note" translate><span class="font-14 font-semibold">Note:</span> bookings.booking_for_note</p>
                      <div class="requested-by flex justify-content-between align-items-center mr-2 ">
                        <div class="flex w-7 relative align-items-center requested-by-input">
                          @if (requestedByChecked) {
                            <p-autoComplete class="w-full mi-auto-complete" [placeholder]="'bookings.search_requested_passenger_placeholder'|translate"
                              [(ngModel)]="currentRequestedPassenger" [suggestions]="suggestions" (completeMethod)="search($event)" (onSelect)="onSelectRequestPassenger($event)"
                              optionLabel="name" [disabled]="isRequestedPassengerSelected" [ngModelOptions]="{standalone : true}">
                              <ng-template let-passenger pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                  <div class="passenger-popup bold-name">
                                    @if (passenger.passenger_profile_image_url && passenger.passenger_profile_image_url?.length > 0) {
                                      <img [src]="passenger?.passenger_profile_image_url[0] || defaultLoaderImg" class="vertical-align-middle border-round-sm list-img mr-1" />
                                    } @else {
                                      <i class="mi-lg mi-default-user wh-20 m-2 mr-2 vertical-align-middle border-round-sm"></i>
                                    }
                                    <span class="ellipsis mr-2">{{passenger.name}}</span>
                                    <span class="unique ellipsis mr-2">({{passenger.unique_id}}) ({{passenger.passenger_type}})</span>
                                  </div>
                                </div>
                              </ng-template>
                            </p-autoComplete>
                            <i class="pi pi-search absolute search-passenger-icon"></i>
                          }
                        </div>
                        <div class="flex w-5 align-items-center justify-content-end requested-by my-2">
                          <span class="requestedby-label font-semibold mr-2" translate>bookings.booking_requested_by</span>
                          <p-inputSwitch class="flex align-items-center" [(ngModel)]="requestedByChecked" [disabled]="selectedPassenger.length === 0" (onChange)="onRequestedBySwitchChange($event)" [ngModelOptions]="{standalone : true}"/>
                        </div>
                      </div>
                      @if (selectedPassenger.length > 0) {
                        <hr class="hr-line-booking-for mr-2">
                        <div class="selected-booking-for flex align-items-center mr-2">
                          <span class="mr-4 font-16 font-semibold booking-for-heading" translate>bookings.booking_select_for</span>
            
                            <div class="flex align-items-center justify-content-between flex-grow-1 cursor-pointer selected-passenger">
                              <div class="flex align-items-center">
                                <img [src]="selectedPassenger[0]?.passenger_profile_image_url?.length > 0 ? selectedPassenger[0]?.passenger_profile_image_url[0] : defaultLoaderImg" class="vertical-align-middle border-round-sm mr-2 selected-img" />
                                <span class="ellipsis font-14 font-bold selected-name">
                                  {{selectedPassenger[0].name}}
                                </span>
                                <span class="unique ellipsis mr-2 font-14 text-600 selected-id">
                                  | {{selectedPassenger[0].unique_id}} <span class="text-white text-sm ml-2 border-round passenger-type">{{selectedPassenger[0].passenger_type}}</span>
                                </span>
                              </div>
                              <div class="flex">
                                <i class="{{miIcons.OFF_CLOSE}} inline-block  wh-22" (click)="removePassenger(selectedPassenger[0])"></i>
                              </div>
                            </div>
                        </div>
                      }
                      @if (requestedPassenger.length > 0) {
                        <div class="selected-booking-for flex align-items-center mr-2 mt-2">
                          <span class="mr-2 font-16 requested-by-heading" translate>bookings.booking_show_requested_by</span>
                          @for (requestPass of requestedPassenger; track requestPass) {
            
                            <div class="flex align-items-center justify-content-between flex-grow-1 cursor-pointer selected-passenger">
                              <div>
                                <img [src]="requestPass.passenger_profile_image_url?.length > 0 ? requestPass.passenger_profile_image_url[0] : defaultLoaderImg" class="vertical-align-middle border-round-sm mr-1 selected-img" />
                                <span class="ellipsis font-14 font-semibold selected-name">
                                  {{requestPass.name}}
                                </span>
                                <span class="unique ellipsis mr-2 font-14 text-600 selected-id">
                                  | {{requestPass.unique_id}} <span class="text-white text-sm ml-2 border-round passenger-type">{{requestPass.passenger_type}}</span>
                                </span>
                              </div>
                              <div class="flex">
                                <i class="{{miIcons.OFF_CLOSE}} inline-block  wh-22" (click)="removePassenger(requestPass, true)"></i>
                              </div>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  </div>
              </div>
            </div>
          }@else{
            <div [class.disabled-section]="!selectedPassenger?.length && transportationTypeView !== 'Vehicle Transfer'" class="remaining-form-sections">
              @if(!selectedPassenger?.length && transportationTypeView !== 'Vehicle Transfer'){
                <div class="form-overlay"></div>
              }
            @if('journey_preference' === group.code){
              <div class="mt-3"></div>
              <div class="text-xl font-bold pt-3 px-3 bg-white others">Others </div>
            }
            @if('instruction' === group.code && transportationTypeView === 'Vehicle Transfer'){
              <div class="mt-3"></div>
              <div class="text-xl font-bold pt-3 px-3 bg-white others">Others </div>
            }
            
          <div class="col-12 md:col-12 p-0">
            @if(excludedCodesFromAccordion.includes(group.code)){
  
            <div class="p-fluid" [id]="i" [ngClass]="group.code">
  
              <div class="p-fluid p-formgrid m-0 p-0">
                
                <div class=" formgrid w-full p-3 mb-3 mt-3 card" [ngClass]="{'grid ml-0': group.code !== 'vehicle_dispatch_preference'}">
                  @if (!excludeGroupCodeLabel.includes(group.code)) {
                    <label class="w-full mt-0 text-xl font-bold">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>
                  }
  
                  @for (field of group.fields; track field.attributeCode;let j = $index) {
                    @if(field.inputCode !== 'spacer'){
                      @if (!excludeFields.includes(field.attributeCode)) {
                       <div class="mb-0 p-0 field col-12 md:col-{{field.uiColumns}} " [ngClass]="{'align-content-start': group.code === vehiclePreference, 'align-content-center': group.code !== vehiclePreference}">
                        @if(!isRoundTrip && field.attributeCode === "return_time_and_date" || (!isHourlyTrip && field.attributeCode
                        === "journey_time")){
      
      
                        } @else{
                          <div>
                            @if(!excludeLabels.includes(field.attributeCode) ){
                            <label class="flex font-semibold mb-2" [ngClass]="{'ml-2': field.attributeCode === 'pickup_time'}">{{cs.getLabelValue(moduleName
                                +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
                                @if (field.validation?.required) {
                                <span class="required">&nbsp;*</span>
                                }
                                @if (cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.helpTips',attributeLabels,0)) {
                                <app-mi-tooltip
                                  [messageData]="cs.getLabelValue(moduleName
                                                                    +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
                                </app-mi-tooltip>
                                }
                              </label>
            
                            }
      
                          </div>
                          <div class=" justify-content-left align-items-center pb-2" [ngClass]="{'flex pt-25': trip_code === field.attributeCode, 'mt-3': field.attributeCode === 'drivers_to_transfer'}" >
                            <div [ngClass]="{
                                'disabled-body-types': (field.attributeCode === 'vehicle_body_type_preference' && isVehicleBodyTypeDisableOnwardDriverAssignedStatuses && isEdit) || 
                                  (field.attributeCode === 'vehicle_body_type_preference' && isVehicleBodyTypeAndLocationDisableOnwardOnTheWayStatuses && isEdit),
                                'disabled-pickup-date-time': ((field.attributeCode === 'pickup_time' || field.attributeCode === 'pickup_date') && isRequestSentFlag)
                              }" class="remaining-form-sections" [attr.disabled-body-type-time]="getTranslatedMessage(field.attributeCode)">
                              @if((field.attributeCode === 'vehicle_body_type_preference' && isVehicleBodyTypeDisableOnwardDriverAssignedStatuses && isEdit) || 
                                (field.attributeCode === 'vehicle_body_type_preference' && isVehicleBodyTypeAndLocationDisableOnwardOnTheWayStatuses && isEdit) ||
                                ((field.attributeCode === 'pickup_time' || field.attributeCode === 'pickup_date') && isRequestSentFlag)) {
                                <div class="form-overlay"></div>
                              }
                              <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [showImg]=""
                                [moduleName]="moduleName"></app-mi-fields>
                            </div>
                          </div>
                          <app-mi-error [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"></app-mi-error>
                        }
                        </div> 
                          
                      } @else {
                      
                      @if(field.attributeCode === 'booking_pickup_location_geolocation'){}
      
                      @if(field.attributeCode === 'booking_stops_location_geolocation'){
                        <div class="field col-12 align-content-center md:col-12 p-0 m-0">
                          <div class="mb-2 location-heading font-semibold" translate>bookings.select_pick_up_and_drop_locations <span class="required">&nbsp;*</span></div>
                          <div class="col-12 align-content-end md:col-12 py-0">
                            <app-stop-locations #stopLocationsComponent class="col-12"
                                [field]="field"
                                [attributeLabels]="attributeLabels"
                                [moduleName]="moduleName"
                                [miFormGroup]="miFormGroup"
                                [attributeValues]="attributeValues"
                                [edit]="isEdit"
                                [passengerId]="passengerId"
                                (stopRemoved)="handleStopRemoval($event)"
                                [editStopLocations]="stopLocations"
                                [transportationTypeView]="transportationTypeView"
                                [bookingCode]="bookingCode"
                                [isRequestSentFlag]="isRequestSentFlag"
                                [isVehicleBodyTypeAndLocationDisableOnwardOnTheWayStatuses]="isVehicleBodyTypeAndLocationDisableOnwardOnTheWayStatuses">
                              </app-stop-locations>
                          </div>
                        </div>
                      }
      
                      @if(field.attributeCode === 'booking_dropoff_location_geolocation'){}
      
                      <app-mi-error [field]="field" [attributeLabels]="attributeLabels"
                        [moduleName]="moduleName"></app-mi-error>
      
                      }
    
                    }
  
                  }
  
                  @if (group.code === 'vehicle_dispatch_preference') {

                    <div class="card auto-manual-card mb-2">
                      <div class="flex justify-content-between align-items-center auto-manual-dispatch">
                          <div class="flex align-items-center input-switch-container">

                            <div class="auto-dispatch-switch mr-1">
                              <div class="toggle-button-cover">
                                <div class="button-cover">
                                  <div class="button b2" id="button-13">
                                    <input type="checkbox" class="checkbox" [(ngModel)]="autoToManual"  (change)="onSwitchChange($event)" [ngModelOptions]="{standalone : true}"/>
                                    <div class="knobs">
                                      <span></span>
                                    </div>
                                    <div class="layer"></div>
                                  </div>
                                </div>
                              </div>
                            </div>

                              <!-- <p-inputSwitch [(ngModel)]="autoToManual" (onChange)="onSwitchChange($event)" class="ml-0"></p-inputSwitch>
                              <span class="switch-text " [ngClass]="{'auto': !autoToManual,'manual':autoToManual}">{{ autoToManual ? 'Manual': 'Auto' }}</span> -->
                              @if(!autoToManual){    
                                  <div class="flex flex-column mb-2">
                                      <span class="font-bold ml-3 lbl-head" translate>bookings.lbl_auto_dispatch</span>
                                      <span class="font-normal ml-3 mt-0 lbl-msg" translate>bookings.lbl_assign_vehicles_automatically</span>
                                  </div>       
                              }@else{
                                  <div class="flex flex-column mb-2">
                                      <span class="font-bold ml-3 lbl-head" translate>bookings.lbl_manual_dispatch</span>
                                      <span class="font-normal ml-3 mt-1 lbl-msg" translate>bookings.lbl_assign_vehicles_manually</span>
                                  </div>   
                              }
                          </div>
                          <div class="flex">
                              @if(!autoToManual){
                                  <i class="{{miIcons.BOOKING_AUTO_DISPATCH}} inline-block wh-22 relative auto-dispatch-icon"></i>
                              }@else {
                                  <i class="{{miIcons.BOOKING_MANUAL_DISPATCH}} inline-block wh-30 relative"></i>
                              }
                          </div>
                      </div>    
                    </div>
                  
                    @if(isManualDispatch && group.code === 'vehicle_preference'){
                      <div class="field col-12 align-content-end md:col-6 "></div>
                      <div class="field col-12 align-content-end md:col-{{manualDispatch.uiColumns}} horizontal">
                        <app-mi-fields [field]="manualDispatch" [attributeLabels]="attributeLabels" [showImg]=""
                          [moduleName]="moduleName"></app-mi-fields>
                      </div>
                    }
    
                    @if(isVisibleSelectVehicle && group.code === 'vehicle_preference'){
                      <div class="field col-12 align-content-end md:col-6 "></div>
                      <div class="field col-12 align-content-end md:col-6 horizontal">
      
                        <div translate="" class="mt-3 font-bold"> bookings.select_vehicle</div>
                        <p-dropdown
                          [options]="selectVehicle.presetValues"
                          optionLabel="labelKey" optionValue="labelValue"
                          [formControlName]="selectVehicle.attributeCode"
                          [placeholder]=" 'bookings.any_vehicle' | translate"
                          (onClick)="showAssignVehicle()" />
                      </div>
                    }
                  }
  
  
                </div>
              </div>
            </div>
            }@else {
              <!-- <hr class="hr-line"> -->
              @if(!excludeToggle.includes(group.code)){
                <p-accordion class="w-full" [ngClass]="group.code" >
                  <p-accordionTab expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up" iconPos="right">
                    <ng-template pTemplate="header">
                      <div class="flex  justify-content-start align-items-center">
                        <div class="flex w-full align-items-center justify-content-left mr-4">
                          <i class="{{getIcon(group.code)}} wh-26 inline-block cursor-pointer icon-color mr-2"  [ngClass]="{'active': group.codeActive}" ></i>
                          <label class="font-semibold">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label', attributeLabels, group.code)}}</label>
                          <span class="absolute font-normal" style="right: 4rem;">Select</span>
                        </div>
  
                        @for (field of group.fields; track field.attributeCode; let j = $index) {
                          @if(formValue?.[field.attributeCode]){
                            @if(field?.attributeCode == ride_preference_code){
                              @for (item of formValue?.[field.attributeCode]; track $index) {
                                <div class="flex justify-content-left selected-items mr-2">
                                  <div class="flex align-items-left justify-content-left mr-1 active-label">
                                    {{item}}  
                                  </div>
                                  <div class="flex align-items-center justify-content-center highlight-label">
                                    <app-attribute-label [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"></app-attribute-label>
                                  </div>
                                </div>
                              }
                            }@else{
                              @if (!excludeAccordionHeaderFormValue.includes(field.attributeCode)) {
                                <div class="flex justify-content-left selected-items mr-2">                       
                                  <div class="flex align-items-left justify-content-left mr-1 active-label">
                                    {{formValue?.[field.attributeCode]}}  
                                  </div>
                                  <div class="flex align-items-center justify-content-center highlight-label">
                                    @if (group.code !== 'journey_preference') {
                                      <app-attribute-label [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"></app-attribute-label>
                                    }
                                  </div>
                                </div>
                              }
                            }
                          }
                        }
                        
                      </div>
                    </ng-template>
                    @if(!excludeToggle.includes(group.code)){
                      <div class="booking-content p-fluid p-0 m-0" [id]="i" [ngClass]="{' ml-3 mr-2': !horizontalForm.includes(group.code)}">
                        <div class="p-fluid p-formgrid grid mt-0">
                          <div class="w-full formgrid grid">
                            @for (field of group.fields; track field.attributeCode;let j = $index) {
            
                            @if(includeSpacer.includes(group.code)){
                              <div class="field col-12 align-content-end md:col-6">
                              </div>
                            }
            
                            <div class=" col-auto field col-12 align-content-end md:col-{{field.uiColumns}} align-items-center" 
                              [ngClass]="{'flex horizontal justify-content-between': horizontalForm.includes(group.code), 'flex': field.attributeCode === 'display_to_driver' || 
                                field.attributeCode === 'display_to_passenger' || group.code === 'child_seat'}">
                              @if(field.inputCode !== 'spacer'){
                                <div [ngClass]="{'flex align-items-center note-display': field.attributeCode === 'display_to_driver' || field.attributeCode === 'display_to_passenger'}">
                                  @if (field.attributeCode === 'display_to_driver' || field.attributeCode === 'display_to_passenger') {
                                    <i class="{{miIcons.INFO_CIRCLE}} inline-block wh-14 vertical-align-middle mr-2"></i>
                                  }
                                  @if (group.code !== 'journey_preference' && field.attributeCode !== 'instruction_note') {
                                    <app-attribute-label [field]="field" [attributeLabels]="attributeLabels"
                                      [moduleName]="moduleName"></app-attribute-label>
                                  }    
                                <div class="help-tips-text"> {{field.inputCode === 'fileImg' ? '' : cs.getLabelValue(moduleName +'.fields.'+ field.attributeCode + '.example',attributeLabels, '') }}</div>
                              </div>
    
                              <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [showImg]=""
                                [moduleName]="moduleName" [ngClass]="{'form-reverse': group.code === 'journey_preference'}"></app-mi-fields>
                        
                              }
                            </div>
                            }
            
                          </div>
                        </div>
                      </div>
                    }
                  </p-accordionTab>
                </p-accordion>
              }@else{
                  <div class="flex w-full justify-content-start mi-panel" [ngClass]="{'pb-0': group.code === 'total_passenger' || group.code === 'pet'}">
                    <div class="flex align-items-center justify-content-left gap-2">
                      <i class="{{getIcon(group.code)}} wh-26 inline-block cursor-pointer icon-color mr-2"  [ngClass]="{'active': group.codeActive}"></i>
                      <!-- <span class="font-bold text-nowrap">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label', attributeLabels, group.code)}}</span> -->
                    </div>
                  
                    @if (excludeToggle.includes(group.code)) {
                      <div class="flex w-full justify-content-between align-items-center">
                        @for (field of group.fields; track field.attributeCode; let j = $index) {
                          <div class="">
                            <!-- <app-attribute-label [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"></app-attribute-label> -->
                            <label class="font-semibold text-nowrap">{{cs.getLabelValue(moduleName +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            <app-mi-error [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"></app-mi-error>
                          </div>
                          <div class="flex align-items-center justify-content-center">
                            <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [showImg]="" [moduleName]="moduleName"></app-mi-fields>
                          </div>
                        }
                      </div>
                    }
                  </div>
                  @if (group.code === 'total_passenger') {
                    <div class="co-pass-info bg-white px-4 pb-4 pt-0">
                      <div class="flex align-items-center mb-0">
                        <i class="{{miIcons.INFO_CIRCLE}} inline-block wh-14 vertical-align-middle mr-2"></i>
                        <span class="mr-2 co-pass-text">Co-passenger(s) Info</span>
                        <p-inputSwitch class="" [(ngModel)]="coPassengerInfo" [disabled]="selectedPassenger.length === 0" (onChange)="coPassengerInfoChange($event)" [ngModelOptions]="{standalone : true}" />
                      </div>

                      <div [class.disabled-booking-for-section]="isEdit" class="remaining-form-sections">
                        @if(isEdit){
                          <div class="form-overlay"></div>
                        }
                        @if (coPassengerInfo) {
                          <div class="card co-pass-detail bg-white p-2 pr-2 mt-2 mb-2">
                            <div class="pr-5">
                              @if(selectedPassenger.length > 0) {
                                <div class="selected-booking-for flex align-items-center card p-0 mt-0">
                                  <div class="flex align-items-center justify-content-between flex-grow-1 cursor-pointer selected-passenger">
                                    <div>
                                      <img [src]="selectedPassenger[0]?.passenger_profile_image_url?.length > 0 ?  selectedPassenger[0]?.passenger_profile_image_url[0] : defaultLoaderImg" class="vertical-align-middle border-round-sm mr-1 selected-img" />
                                      <span class="ellipsis font-14 font-bold selected-name">
                                        {{selectedPassenger[0].name}}
                                      </span>
                                      <span class="unique ellipsis mr-2 font-14 text-600 selected-id">
                                        | {{selectedPassenger[0].unique_id}} <span class="text-white text-sm ml-2 border-round passenger-type">{{selectedPassenger[0].passenger_type}}</span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                            @if (numberOfPassengerCount > 1) {
                              <div class="w-full">
                                @for (i of passengerIndices; track i) {
                                  <div class="flex align-items-center mb-0 mt-2">
                                    <div class="w-full pr-2">
                                      <ng-container>
                                        <div class="flex relative align-items-center">
                                          @if (selectedPassenger[i]) {
                                            <div class="selected-booking-for flex align-items-center card w-full p-0 mb-0 mt-0">
                                              <div class="flex align-items-center justify-content-between flex-grow-1 cursor-pointer selected-passenger">
                                                <div>
                                                  <img [src]="selectedPassenger[i]?.passenger_profile_image_url?.length > 0 ? selectedPassenger[i]?.passenger_profile_image_url[0] : defaultLoaderImg" class="vertical-align-middle border-round-sm mr-1 selected-img" />
                                                  <span class="ellipsis font-14 font-bold selected-name">{{selectedPassenger[i].name}}</span>
                                                  <span class="unique ellipsis mr-2 font-14 text-600 selected-id">
                                                    | {{selectedPassenger[i].unique_id}} <span class="text-white text-sm ml-2 border-round passenger-type">{{selectedPassenger[i].passenger_type}}</span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          } @else {
                                            <p-autoComplete class="w-full mi-auto-complete" 
                                               [placeholder]="'Enter Passenger ' + (i + 1)"
                                               [(ngModel)]="passengerSearch[i]" 
                                               [suggestions]="suggestions" 
                                               (completeMethod)="search($event)" 
                                               (onSelect)="onSelectPassenger($event, isCoPass)"
                                               >
                                              <ng-template let-suggestion pTemplate="item">
                                                <div class="flex align-items-center gap-2">
                                                  <div class="passenger-popup bold-name">
                                                    <img [src]="suggestion?.passenger_profile_image_url?.length > 0 ? suggestion?.passenger_profile_image_url[0] : defaultLoaderImg" class="vertical-align-middle border-round-sm list-img mr-1" />
                                                    <span class="ellipsis mr-2">{{suggestion.name}}</span>
                                                    <span class="unique ellipsis mr-2">({{suggestion.unique_id}}) ({{suggestion.passenger_type}})</span>
                                                  </div>
                                                </div>
                                              </ng-template>
                                            </p-autoComplete>
                                          }
                                        </div>
                                      </ng-container>
                                    </div>
                                    <div class="flex">
                                      <i class="{{miIcons.OFF_CLOSE}} inline-block wh-20 cursor-pointer" (click)="removePassenger(selectedPassenger[i])"></i>
                                    </div>
                                  </div>
                                }
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  }
                  @if (group.code === 'pet'){
                    <div class="co-pass-info bg-white pl-4 pb-1 pt-0">
                      <div class="flex align-items-center mb-0">
                        <i class="{{miIcons.INFO_CIRCLE}} inline-block wh-14 vertical-align-middle mr-2"></i>
                        <span class="mr-2 text-sm" translate>Pet(s) info</span>
                        <p-inputSwitch class="" [(ngModel)]="petInfo" (onChange)="petInfoChange($event)" [disabled]="petCount === 0" [ngModelOptions]="{standalone : true}"/>
                      </div>
                      @if(petInfo && petCount > 0) {
                        <div class="card bg-white p-2 mt-2 mb-2 pet-info">
                          @for (petFields of petAccomadationArray; track petFields; let z = $index) {
                            <div class="flex justify-content-between align-items-center mb-2">
                              <div class="grid w-full mr-2">
                                @for (field of petFields; track field.attributeCode; let k = $index) {
                                  <!-- <div class="col-12 md:col-{{field.uiColumns}}"> -->
                                  <div [ngClass]="field.attributeCode === 'species' ? 'col-4' : 'col-4'">
                                    @if (field.attributeCode === 'species') {
                                      <span class="font-semibold text-nowrap">Pet {{ z + 1 }} -</span>
                                    }
                                    <label class="font-semibold text-nowrap">
                                      {{ cs.getLabelValue('booking_pet_info.fields.' + field.attributeCode + '.label', attributeLabels, field.attributeCode) }}
                                    </label>
                                    <app-mi-error [field]="field" [attributeLabels]="attributeLabels" [moduleName]="'booking_pet_info'"></app-mi-error>
                                    <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [showImg]="false" [moduleName]="'booking_pet_info'"></app-mi-fields>
                                  </div>
                                }
                              </div>
                              <div class="flex mt-4">
                                <i class="{{miIcons.OFF_CLOSE}} inline-block wh-18 cursor-pointer" (click)="removePetInfo(z)"></i>
                              </div>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  }
              }
              <hr class="hr-line">
  
            
            }
          </div>
        </div>
          }
        }
  
      }
    </div>
  </form>
  @if(isAssignNowClicked){
    <div class="card assign-section bg-white border-none">
      <div class="text-lg font-bold mb-3">Assign Vehicle</div>
      @if(autoToManual && isAssignNowClicked) {
        <div class="flex passenger-detials">
          <app-manual-dispatch-details [miFormGroup]="miFormGroup"></app-manual-dispatch-details>
        </div>
      }
      <app-manual-dispatch [assignConfiguration]="assignAdditionalConfiguration"  (additionalRequired)="additionalRequired($event)"
      (switchChanged)="onSwitchChanged($event)"   [luggageBookingIds]="luggageBookingIds"
      [passengerBookingIds]="passengerBookingIds" [mainBookingId]="mainBookingId"></app-manual-dispatch>
    </div>
  }
  <div class="col-12 save-action-div flex justify-content-start align-items-center mt-3">
    <p-button [id]="saveBtnId" class="inline-flex height-44" (click)="submitData($event)"
      [label]="submitBtnText"></p-button>
      @if (autoToManual) {
        <i class="{{miIcons.INFO_CIRCLE}} inline-block wh-12 vertical-align-middle assign-info mr-2 ml-2"></i>
        <span class="text-sm">Click "Create Booking" Button to Assign Vehicle</span>
      }
  </div>
  
  @if(transportationTypeView === 'Vehicle Transfer'){
    <app-assign-vehicle-from-bookings 
    (onToggleOverLay)="onToggleOverLay($event)"
      [visibleAssignVehicle]="isVisibleAssignVehicle"
      (onSelectVehicle)="onSelectedVehicle($event)"
      [vehicleBodyType]="vehicleBodyType"
      [miFormGroup]="miFormGroup">
    </app-assign-vehicle-from-bookings>
  }
  
}