import { JsonPipe, NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NoResultFoundComponent } from 'app/modules/shared/components/no-result-found/no-result-found.component';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppIcons } from '../../../../shared/app.icons';
import { AppSettings } from '../../../../shared/app.settings';
import { QuickFilterFormComponent } from '../../../../shared/components/quick-filter-form/quick-filter-form.component';
import { Country } from '../../../../shared/models/country';
import { Language } from '../../../../shared/models/language';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../../shared/services/config.service';
import { EntityService } from '../../../../shared/services/entity.service';
import type { EntityList } from '../../../../vehicles/models/entity.models';
import type { ListResponse, Values } from '../../../../vehicles/models/listResponse.models';
import { BookingService } from 'app/modules/bookings/services/booking.service';
import {  SidebarModule } from 'primeng/sidebar';
import { EventService } from 'app/modules/events/services/event.service';
import { MiImageContainerComponent } from 'app/modules/shared/ui-sharable/mi-image-container/mi-image-container.component';
import { ImageModule } from 'primeng/image';
import { CheckboxModule } from 'primeng/checkbox';
import { FilterBoxComponent } from 'app/modules/shared/components/filter-box/filter-box.component';
import { BadgeModule } from 'primeng/badge';
import { forkJoin } from "rxjs";
@Component({
  selector: 'app-assign-vehicle-from-bookings',
  standalone: true,
  imports: [NgClass, JsonPipe, ProgressSpinnerModule, DialogModule, TranslateModule, QuickFilterFormComponent, InputTextModule, RadioButtonModule, ButtonModule, ReactiveFormsModule, FormsModule, NoResultFoundComponent,SidebarModule,
     MiImageContainerComponent, ImageModule, CheckboxModule, FilterBoxComponent, BadgeModule],
  templateUrl: './assign-vehicle-from-bookings.component.html',
  styleUrl: './assign-vehicle-from-bookings.component.scss'
})
export class AssignVehicleFromBookingsComponent implements OnInit {
  miIcons = AppIcons;
  @Input() visibleAssignVehicle;
  @Input() vehicleBodyType: string;
  @Input() miFormGroup: FormGroup;
  @Input() position: string;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Output() onSelectVehicle: EventEmitter<string[]> = new EventEmitter<string[]>();
  private searchSubject: Subject<string> = new Subject();
  attributeLabels = {};
  smartSearchTimer: any;
  moduleName: string;
  selectedFilterCount: number;
  driverData: any;
  advanceFilterValue: any[] = [];
  quickFilterValue: any[] = [];
  vehicleBodyTypes: any[] = [];
  parkingAndQueueList: any[] = [];
  parkingLocationList: any[] = [];
  queueLocationList: any[] = [];
  dutyStatusList: any[] = [
    {
      labelKey: 'On Duty',
      labelValue: 'ON'
    },
    {
      labelKey: 'Off Duty',
      labelValue: 'OFF'
    }
  ];
  shiftStatusList: any[] = [
    {
      labelKey: 'On Shift For Pickup',
      labelValue: 'ON'
    },
    {
      labelKey: 'Off Shift For Pickup',
      labelValue: 'OFF'
    }
  ];
  categoryAttributeIdInLocationFilter: any;
  locationFilters: any;
  userId: any = '';
  imgFileId: any;
  selectedModify: Set<string> = new Set<string>();
  vehicleList: any = [];
  vehicleListTemp: any = [];
  country: Country;
  language: Language;
  listColumns: Values;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: '',
    actionStatus: AppSettings.VEHICLE_TAB_LIST.ASSIGNED
  };
  hideTable: boolean = false;

  bodyTypeAttribute: any;
  vehicleStatus: any;
  vehicleGroup: any;
  filterList;
  show: boolean = false;
  filterAttributeLabels = {};
  searchValue: string;
  loading: boolean = true;
  requestPayload = {
    forTenantCode: this.configService.getForTenantCode(),
    eventCode: this.eventService.getSelectedEventcode(),
    pickUpLocationType: "",
    pickUpGeoLocation: "",
    dropOffGeoLocation: "",
    pickUpDate: 1,
    pickUpTime: 0,
    searchStr: "",
    dutyStatus: "All",
    shiftStatus: "On",
    vehicleBodyTypes: [],
    parkingIds: [],
    queueIds: [],
    driverGroupIds: [],
    filters: []
  }

  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;

  constructor(private vehicleService: EntityService, private cs: CommonBindingDataService, private messageService: MessageService,
    private configService: ConfigService, private bookingService: BookingService, private eventService: EventService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.getFilterViewForLocation();
    this.bookingService.visibleAssignVehicle$.subscribe((visible) => {
      this.visibleAssignVehicle = visible;
      if (visible) {
        this.searchEntity();
      }
    });
    this.moduleName = AppSettings.ENTITY_CODE.VEHICLE;
    this.entityData.forTenantCode = this.configService.getForTenantCode()
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.getVehicleBodyTypes();
    this.position = 'right';
  }

  ngAfterViewInit(): void {
    const pickup = '1001_booking_stops_location_geolocation';
    const dropOff = '1002_booking_stops_location_geolocation';
    if (this.miFormGroup) {
      this.miFormGroup.controls[pickup]?.valueChanges.subscribe(value => {
        if (value) {
          this.requestPayload.pickUpLocationType = value?.select_location_type;
          this.requestPayload.pickUpGeoLocation = value?.select_location_geolocation;
        }
      });
      this.miFormGroup.controls[dropOff]?.valueChanges.subscribe(value => {
        if (value) {
          this.requestPayload.dropOffGeoLocation = value?.select_location_geolocation;
        }
      });
      this.miFormGroup.controls['pickup_date']?.valueChanges.subscribe(value => {
        const pickupDate = new Date(value);
        pickupDate.setHours(0, 0, 0, 0);

        this.requestPayload.pickUpDate = pickupDate.getTime();
      });
      this.miFormGroup.controls['pickup_time']?.valueChanges.subscribe(value => {
        if (value instanceof Date) {
          const hours = value.getHours();
          const minutes = value.getMinutes();
  
          const pickupDate = this.miFormGroup.controls['pickup_date']?.value;
          if (pickupDate) {
            const combinedDateTime = new Date(pickupDate);
            combinedDateTime.setHours(hours, minutes, 0, 0);
  
            this.requestPayload.pickUpTime = combinedDateTime.getTime();
          }
        }
      });
    }
    this.searchEntity();
  }

  getFilterViewForLocation() {
    this.vehicleService.getAttributeDefinition(AppSettings.ENTITY_CODE.LOCATION, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
       this.locationFilters = filterResponse;
       const fields = this.locationFilters?.tabs[0]?.groups[0]?.fields;
       const categoryField = fields ? fields.find(ele => ele.attributeCode === AppSettings.LOCATION_FILTER_ATTRIBUTES.LOCATION_CATEGORY) : [];
       this.categoryAttributeIdInLocationFilter = categoryField ? categoryField.attributeId : null;
      }
    })
  }

  showFilterDialog() {
    this.show = true;
    this.cd.detectChanges();
  }

  getVehicleBodyTypes() {
    const requestBody = {
      limit: 600,
      offset: 0,
      searchStr: '',
      defaultSortColumn: 'updatedAt',
      defaultSortType: 'desc',
      forTenantCode: this.configService.getForTenantCode(),
      attributeCode: 'body_type',
    }
    this.vehicleService.searchAttributeSettings(AppSettings.ENTITY_CODE.VEHICLE, requestBody).subscribe({
      next: (result: any) => {
        this.vehicleBodyTypes = result.data;
        const labels = this.getFilterLabelsForVehicleTransferDialog();
        this.filterAttributeLabels =  this.cs.getAttributeLabels(labels);
        this.filterList = this.setFilterListForVehicleTransferDialog();
        this.getDataForFilter();
      },
      error: (error) => {}
    });
  }

  onHide() {
    this.visibleAssignVehicle = false;
    this.onToggleOverLay.emit(this.visibleAssignVehicle);
    this.bookingService.setVisibleAssignVehicle(false);
  }

  searchEntity() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    if (!country || !country[0]?.countryId) {
      console.error('Country information not found in local storage.');
      return;
    }
    this.vehicleList = [];
    this.loading = true;
    this.vehicleService.searchEntityForVehicleTransfer(this.requestPayload).subscribe(res => {
      this.loading = false;
      if (Array.isArray(res)) {
        this.vehicleList = _.flatMap(res, (driverData) => {
          const driver = driverData?.relatedData?.find(item => item.entityCode === 'driver');
          const vehicle = driverData?.relatedData?.find(item => item.entityCode === 'vehicle');
          const driverGroup = driverData?.relatedData?.find(item => item.entityCode === 'driver_group');
          const location = driverData?.relatedData?.find(item => item.entityCode === 'location');
          const onShift = driverData?.onShift;
          const driverCheckInTime = driverData?.driverCheckInTime;

          if (driver && vehicle) {
            return [{
              driverId: driver.id,
              driverName: `${driver.values.first_name} ${driver.values.last_name}`,
              driverUniqueId: driver.values.unique_id,
              driverStatus: driver.values.duty_status?.toUpperCase(),
              driverProfileImage: driver.values.driver_profile_image_url,
              vehicleId: vehicle.id,
              vehicleName: vehicle.values.name_code,
              vehicleRegNumber: vehicle.values.reg_number,
              vehicleType: vehicle.values.vehicle_type,
              vehicleBodyType: vehicle.values.body_type,
              vehicleYear: vehicle.values.year_of_manufacturing,
              vehicleManufacturer: vehicle.values.manufacturer,
              vehiclePassengerCapacity: vehicle.values.passenger_capacity,
              driverGroupName: driverGroup?.values?.group_name || '',
              driverGroupColor: driverGroup?.values?.group_color || '',
              locationAddress: location?.values?.location_address,
              locationCategory: location?.values?.location_category,
              onShift: onShift,
              driverCheckInTime: this.formatTime(driverCheckInTime)
            }];
          }
          return [];
        });
      } else {
        console.error('API response is not an array:', res);
      }
    });
  }

  formatTime(timestamp: number): string {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  }

  onFilterValueChange(filterFlag, event) {
    filterFlag ? (this.advanceFilterValue = event) : (this.quickFilterValue = event);
    const combineFilter = { ...this.advanceFilterValue, ...this.quickFilterValue };
    this.selectedFilterCount = 0;

    for (const [key, value] of Object.entries(combineFilter)) {
      if (value) {
        this.selectedFilterCount++;

        switch (key) {
          case 'driver_duty_status':
            if (value.includes('ON') && value.includes('OFF')) {
              this.requestPayload.dutyStatus = 'All';
            } else {
              this.requestPayload.dutyStatus = value[0].charAt(0).toUpperCase() + value[0].slice(1).toLowerCase();
            }
            break;
          case 'driver_shift_status':
            if (value.includes('ON') && value.includes('OFF')) {
              this.requestPayload.shiftStatus = 'All';
            } else {
              this.requestPayload.shiftStatus = value[0].charAt(0).toUpperCase() + value[0].slice(1).toLowerCase();
            }
            break;
          case 'vehicle_body_type':
            this.requestPayload.vehicleBodyTypes = _.flattenDeep(value);
            break;
          case 'queue_location':
            this.requestPayload.queueIds = _.flattenDeep(value);
            break;
          case 'parking_location':
            this.requestPayload.parkingIds = _.flattenDeep(value);
            break;
          case 'driver_group_id':
            this.requestPayload.driverGroupIds = _.flattenDeep(value);
            break;
          default:
            break;
        }
      }
    }
    this.searchEntity();
  }

  getDataForFilter() {
    const requestBodyForParkingLocation = {
      limit: 2000,
      offset: 0,
      searchStr: '',
      filters: [{
        attributeId: this.categoryAttributeIdInLocationFilter,
        attributeValue: [AppSettings.ATTRIBUTE_VALUES_FOR_LOCATION_CATEGORY.PARKING, AppSettings.ATTRIBUTE_VALUES_FOR_LOCATION_CATEGORY.QUEUE]
      }],
      forTenantCode: this.configService.getForTenantCode(),
      deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
      countryCode: this.country[0].countryCode
    }

    forkJoin({
      parkingAndQueueLocationList:  this.vehicleService.searchEntity(AppSettings.ENTITY_CODE.LOCATION, requestBodyForParkingLocation)
    }).subscribe((result: any) => {
      this.parkingAndQueueList = result.parkingAndQueueLocationList.data;
      this.filterParkingAndQueueLocation();
    })  
  }

  filterParkingAndQueueLocation() {
    this.parkingAndQueueList.forEach((element, index) => {
        if (element.values.location_category === AppSettings.ATTRIBUTE_VALUES_FOR_LOCATION_CATEGORY.PARKING) {
          this.parkingLocationList.push({
            labelKey: element.values.location_display_name_for_booker,
            labelValue: element.id
          })
        } else {
          this.queueLocationList.push({
           labelKey: element.values.location_display_name_for_booker,
            labelValue: element.id
          })
        }
        if (index === this.parkingAndQueueList.length - 1) {
          this.filterList = this.setFilterListForVehicleTransferDialog();
        }
    });
  }

  assignVehicleData() {
    console.log('selectedModify:',this.selectedModify);
    this.onSelectVehicle.emit(Array.from(this.selectedModify));
    this.visibleAssignVehicle = false;
  }

  onCheckboxChange(event: any, driverId: string) {
    if (event.checked) {
      this.selectedModify.add(driverId);
    } else {
      this.selectedModify.delete(driverId);
    }
    this.cd.detectChanges();
  }

  cancelVehicleData() {
    this.selectedModify.clear();
    this.visibleAssignVehicle = false;
  }

  isAllSelected(): boolean {
    return this.vehicleList.every((vehicle: any) => this.selectedModify.has(vehicle.driverId));
  }

  toggleSelectAll(event: any) {
    if (event.checked) {
      this.vehicleList.forEach(vehicle => this.selectedModify.add(vehicle.driverId));
    } else {
      this.selectedModify.clear();
    }
    this.cd.detectChanges();
  }

  clearSearch() {
    this.searchValue = "";
    this.requestPayload.searchStr = '';
    this.searchEntity();
  }

  onSearch(event) {
    const value = event.target.value;
    if (event.target.value.length >= 3) {
      this.requestPayload.searchStr = value;
      this.searchEntity();
    } else if (event.target.value.length === 0) {
      this.requestPayload.searchStr = value
      this.searchEntity();
    }
  }

  setFilterListForVehicleTransferDialog() {
    return [
      {
        attributeCode: 'driver_duty_status',
        attributeIndex: 0,
        attributeValue: null,
        presetValues: this.dutyStatusList,
        uiColumns: 6,
        inputCode: 'select'
      },
      {
        attributeCode: 'driver_shift_status',
        attributeIndex: 0,
        attributeValue: null,
        presetValues: this.shiftStatusList,
        uiColumns: 6,
        inputCode: 'select'
      },
      {
        attributeCode: 'driver_group_id',
        attributeIndex: 0,
        attributeValue: null,
        uiColumns: 6,
        inputCode: 'inputAutocomplete'
      },
      {
        attributeCode: 'vehicle_body_type',
        attributeIndex: 2,
        attributeValue: null,
        presetValues: this.vehicleBodyTypes,
        uiColumns: 6,
        inputCode: 'select',
      },
      {
        attributeCode: 'queue_location',
        attributeIndex: 2,
        attributeValue: null,
        presetValues: this.queueLocationList,
        uiColumns: 6,
        inputCode: 'select',
      },
      {
        attributeCode: 'parking_location',
        attributeIndex: 2,
        attributeValue: null,
        presetValues: this.parkingLocationList,
        uiColumns: 6,
        inputCode: 'select',
      }
    ];
  }

  getFilterLabelsForVehicleTransferDialog() {
    return{
        attributeLabels: [
          {
            labelKey: 'vehicle.fields.driver_duty_status.label',
            labelValue: 'Duty Status'
          },
          {
            labelKey: 'vehicle.fields.driver_shift_status.label',
            labelValue: 'Shift Status'
          },
          {
            labelKey: 'vehicle.fields.driver_group_id.label',
            labelValue: 'Group'
          },
          {
            labelKey: 'vehicle.fields.vehicle_body_type.label',
            labelValue: 'Vehicle Body Type'
          },
          {
            labelKey: 'vehicle.fields.queue_location.label',
            labelValue: 'Queue Location'
          },
          {
            labelKey: 'vehicle.fields.parking_location.label',
            labelValue: 'Parking Location'
          }
        ]
    }
  }

}

