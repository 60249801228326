import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { forkJoin } from 'rxjs';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { ModuleHeaderComponent } from '../../../shared/components/module-header/module-header.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityCount, EntityCountsResponse, EntityList } from '../../../vehicles/models/entity.models';
import { ListResponse, Values } from '../../../vehicles/models/listResponse.models';
import { EntityService } from '../../../shared/services/entity.service';
import { FilterResponseModal } from '../../models/filterResponse.models';
import { ButtonModule } from 'primeng/button';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { PermissionAccessService } from 'app/modules/shared/services/permission-access.service';

@Component({
  selector: 'app-manage-group-list',
  standalone: true,
  imports: [BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, MITableComponent, AsyncPipe, ButtonModule, AccessProviderDirective,],
  templateUrl: './manage-group-list.component.html',
  styleUrl: './manage-group-list.component.scss'
})
export class ManageGroupListComponent implements OnInit {
  miIcons = AppIcons;
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLElement>;
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  @Input() moduleName: string;
  filterList;
  btnLabel: string;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.confService.getLoggedInTenantCode(),
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  deleteGroupMsg: string;
  deleteGroupHeaderMsg: string;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  deleteLabel: string = this.cs.getLabel('manage_group.delete');
  editLabel: string = this.cs.getLabel('manage_group.edit');
  filterFieldsArray: any[];
  attributeData: any;
  data: any;
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  currentRow: any;
  visible: boolean = false;
  totalRecords: number = 0;
  attributeIdOfGroupStatus: string;
  bulkUpdateLabel: string = this.cs.getLabel("manage_group.update_group");
  bulkDeleteLabel: string = this.cs.getLabel("lbl_delete");
  bulkUpdateHeader: string = this.cs.getLabel("manage_group.bulk_update_header");
  groupEntityName: string = this.cs.getLabel("manage_group.lbl_group");
  reviewHeader: string = this.cs.getLabel("manage_group.review_header");
  updateBtnLbl: string = this.cs.getLabel("manage_group.updateBtnLbl");

  constructor(private manageGroupService: EntityService, private confService: ConfigService,
    private cs: CommonBindingDataService, private confirmationService: ConfirmationService, private cd: ChangeDetectorRef,
    private router: Router, private messageService: MessageService, private actRoute: ActivatedRoute,
    private permissionAccessService: PermissionAccessService) { }

  ngOnInit() {
    this.setRoutePath();
    this.setRowItems();
    this.bulkUpdateItems();
    this.setModuleHeaderSecondaryBtnMenuItems();
    this.setLabels();
    this.getAttributeData();
    this.getData();
  }

  public setRoutePath() {
    this.routePath = [
      { label: this.cs.getLabel('lbl_groups'), routerLink: 'manage-groups/list' },
    ];
  }

  public setRowItems() {
    const isEdit = this.permissionAccessService.hasAccess('SHIFT_MANAGEMENT', 'MANAGE_GROUPS', 'ALL', 'EDIT');
    const isDelete = this.permissionAccessService.hasAccess('SHIFT_MANAGEMENT', 'MANAGE_GROUPS', 'ALL', 'DELETE');
    this.rowItems = [
      ...(isEdit ? [{
        label: this.editLabel,
        icon: AppIcons.EDIT_2 + " wh-16",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.update();
        }
      }] : []),
      ...(isDelete ? [{
        label: this.deleteLabel,
        icon: AppIcons.BASIC_DELETE + " wh-16",
        iconClass: "bulk-update-icon",
        command: () => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.ROW);
        },
      }] : []),
    ];
  }

  private bulkUpdateItems() {
    const isBulkAction = this.permissionAccessService.hasAccess('SHIFT_MANAGEMENT', 'MANAGE_GROUPS', 'ALL', 'BULK_ACTION');
    const isBulkUpdate = this.permissionAccessService.hasAccess('SHIFT_MANAGEMENT', 'MANAGE_GROUPS', 'ALL', 'UPDATE_STATUS');
    this.bulkSelectionsItems = [
      ...(isBulkUpdate ? [{
        label: this.bulkUpdateLabel,
        icon: AppIcons.EDIT_2 + " wh-16",
        iconClass: "bulk-update-icon",
        styleClass: "bulk-update-list",
        command: () => {
          this.tableRef.showBulkRowDialog();
        },
      }] : []),
      ...(isBulkAction ? [{
        label: this.bulkDeleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-16",
        iconClass: "bulk-update-icon",
        styleClass: "bulk-update-list",
        command: (e) => {
          const event = _.cloneDeep(e);
          this.tableRef.delete(AppSettings.DELETE_TYPE.BULK);
        },
      }] : []),
    ];
  }

  public setModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.cs.getLabel('manage_group.import_group'),
        icon: AppIcons.UPLOAD + " wh-16",
        command: () => {
          const el: HTMLElement = this.uploader.nativeElement;
          el.click();
        }
      },
      {
        label: this.cs.getLabel('vehicle.download_important_template'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.manageGroupService.downloadImportTemplate(AppSettings.ENTITY_CODE.DRIVER_GROUP).subscribe(
            (result: any) => {
              const a = document.createElement('a');
              a.href = URL.createObjectURL(result);
              a.download = 'Import Template.xlsx';
              a.click();
            },
            err => {
              console.log(err);
            });
        }
      },
      {
        label: this.cs.getLabel('vehicle.download_important_guide'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.manageGroupService.downloadImportGuide(AppSettings.ENTITY_CODE.DRIVER_GROUP).subscribe(result => {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(result);
            a.download = 'Import Guide.xlsx';
            a.click();
          },
            err => {
              console.log(err);
            });
        }
      },
      {
        separator: true
      },
      {
        label: this.cs.getLabel('driver.export_xlsx_csv'),
        icon: AppIcons.EXPORT + " wh-16",
      },
    ];
  }

  public setLabels() {
    this.btnLabel = this.cs.getLabel('manage_group.lbl_new_groups');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.deleteGroupMsg = this.cs.getLabel('manage_group.message.confirm_delete_msg');
    this.deleteGroupHeaderMsg = this.cs.getLabel('manage_group.message.confirm_delete_header');
  }

  public onChange(event) {
    for (const file of event.target.files) {
      if (file) {
        if (file.size > AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('err_file_size_exceed_msg') });
        } else {
          const formData = new FormData();
          formData.append("file", file);
          formData.append('forTenantCode', this.confService.getForTenantCode());
          formData.append('entityCode', AppSettings.ENTITY_CODE.DRIVER_GROUP);
          formData.append('viewCode', AppSettings.VIEW_CODE.EXCEL_IMPORT_VIEW);
          this.cs.importEntity(formData, AppSettings.ENTITY_CODE.DRIVER_GROUP).subscribe(res => {
            this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_file_uploaded_successfully') });
          })
        }
      }
    }

  }

  getAttributeData() {
    this.manageGroupService.getAttributeDefinition(AppSettings.ENTITY_CODE.DRIVER_GROUP, AppSettings.VIEW_CODE.BULK_UPDATE_VIEW).subscribe(res => {
      if (res) {
        this.setBulkView(res);
      }
    });
  }

  setBulkView(res) {
    this.attributeData = res;
    this.isLoading = false;
    this.filterFieldsArray = res?.tabs[0]?.groups[0]?.fields;
    this.filterFieldsArray.forEach(element => {
      switch (element.attributeCode) {
        case AppSettings.MANAGE_GROUP_BULK_STATUS_CASE.GROUP_STATUS:
          element.attributeValue = AppSettings.MANAGE_GROUP_BULK_STATUS.STATUS;
          element.isDisable = false;
          break;
      }
    });

  }

  setCurrentRowData(event) {
    this.currentRow = event;
    this.rowItems[0].disabled = this.currentRow.deleted;
    this.rowItems[1].disabled = this.currentRow.deleted;
    this.cd.detectChanges();
  }

  update() {
    this.onUpdateGroups(this.currentRow.id);
  }

  delete(event: Event, rowData: any, deleteType: string) {
    this.confirmationService.confirm({
      header: this.deleteGroupHeaderMsg,
      message: this.deleteGroupMsg,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        if (deleteType === 'row') {
          this.onGroupDelete(this.currentRow.id);
        } else {
          this.onBulkDataDeleteEvent(
            this.currentRow.map((ele) => {
              return ele.id;
            })
          );
        }
      },
      reject: () => {
      }
    });
  }

  getTableView() {
    this.manageGroupService.getAttributeDefinition(AppSettings.ENTITY_CODE.DRIVER_GROUP, AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW).subscribe(res => {
      if (res) {
        this.setGroupTableView(res);
      }
    });
  }

  setGroupTableView(res) {
    this.isLoading = false;
    this.data = res;
    this.attributeIdOfGroupStatus = res?.tabs[0]?.groups[0]?.fields?.find(element => element.attributeCode === 'group_status')?.attributeId;
    this.attributeLabels = this.cs.getAttributeLabels(this.data);
    this.allColumns = this.cs.getColumns(this.data);
    this.filterAvailableColumns = _.clone(this.allColumns);
    this.allColumns?.forEach((key, index) => {
      this.allColumns[index] = key;
      this.allColumns[index].field = key.attributeCode;
      this.allColumns[index].header = this.cs.getLabelValue(
        "driver_group" + ".fields." + key.attributeCode + ".label",
        this.attributeLabels,
        key.attributeCode
      );
    });
    this.filterAvailableColumns = _.clone(this.allColumns);
    this.searchEntity();
  }

  getFilterView() {
    this.manageGroupService.getAttributeDefinition(AppSettings.ENTITY_CODE.DRIVER_GROUP, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe((filterResponse: FilterResponseModal) => {
      if (filterResponse) {
        this.setFilterView(filterResponse);
      }
    })
  }

  setFilterView(filterResponse) {
    this.filterAttributeLabels = this.cs.getAttributeLabels(filterResponse);
    this.filterList = this.cs.getColumns(filterResponse);
    this.filterList = _.sortBy(this.filterList, 'attributeIndex');
  }

  getEntityCount() {
    const entityCountPayload = <EntityCount>{
      forTenantCode: this.confService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.DRIVER_GROUP,
    };

    this.manageGroupService.getEntityCount(AppSettings.ENTITY_CODE.DRIVER_GROUP, entityCountPayload)
      .subscribe((res: EntityCountsResponse) => {
        this.entityCount = res;
      });
  }

  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
      getEntityCount: this.getEntityCount(),
    })
  }


  searchEntity() {
    this.entityData.countryCode = this.country[0].countryCode;
    this.manageGroupService.searchEntity(AppSettings.ENTITY_CODE.DRIVER_GROUP, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = [];
      this.tableData = this.cs.getTableData(res);
    })
  }

  onAddGroups() {
    this.router.navigate(['app/manage-groups/add-group',]);
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  onGroupDelete(event) {
    const requestBody = {
      forTenantCode: this.confService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.DRIVER_GROUP,
      entityIds: [
        event
      ]
    };
    this.manageGroupService.deleteEntityActionStatus(requestBody).subscribe(response => {
      this.cs.onDisplayMessage(this.data, 'driver_group', AppSettings.MSG_ACTION.DELETE, 'success');
      setTimeout(() => {
        this.searchEntity();
      }, 1000);
    })
  }

  onUpdateGroups(groupId) {
    this.router.navigate(['app/manage-groups/edit/' + groupId,]);
  }

  onRowSelect(event) {
    if (event.type === 'row') {
      this.router.navigate(['app/manage-groups/' + event.data.id,]);
    }
  }


  onFilterValueChange(event) {
    console.log(event);
    const vData = event;
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(vData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.entityData.filters.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.entityData.filters = _.filter(_.uniq(this.entityData.filters, function(item, key, a) {
      if (item.attributeValue.length > 0) {
        return item.attributeCode;

      }
    }), function(element) {
      if (element.attributeValue.length > 0) {
        return true;
      }
      return false;
    });
    this.entityData.offset = event.first;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
    this.searchEntity();
  }


  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.confService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.DRIVER_GROUP,
      countryCode: this.country[0].countryCode,
      entityIds: event
    };
    this.manageGroupService.deleteEntityActionStatus(requestBody).subscribe((response) => {
      this.cs.onDisplayMessage(this.data, 'driver_group', AppSettings.MSG_ACTION.BULK_DELETE, 'success');
      setTimeout(() => {
        this.searchEntity();
      }, 1000);
    });
  }

  onBulkDataUpdateEvent(event) {
    if (event) {
      this.searchEntity();
    }
  }

  tabViewChange(event) {
    switch (event.index) {
      case 0:
        this.entityData.actionStatus = '';
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
          this.entityData.filters = [];
        this.searchEntity();
        break;
      default:
        break;
    }
  }
}
