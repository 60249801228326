import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppSettings {
  public static BASE_URL = environment.basePath;
  public static TENANT_CODE = environment.tenantCode;
  public static LOGGED_IN_TENANT_CODE = 'logged_in_tenant';
  public static DEFAULT_COUNTRY_CODE = 41;
  public static PROFILE_DATA = 'profileData';
  public static FOR_TENANT_AND_COUNTRY_DETAILS = 'for_tenant_and_country_details';
  public static SET_UNIT = 'imperialUnit';
  public static COUNTRY = 'country';
  public static LANGUAGE = 'language';
  public static HMAC_ENC_SIGNATURE = 'HMAC-SHA256 Signature=';
  public static HEADER_WEB = 'WEB';
  public static MAGNETIC_HOLDER = environment.magneticHolder;
  public static IS_IDLE_TIME = environment.idleTimeEnable;
  public static BODY_TYPE_XL = 'XL';
  public static BODY_TYPE_L = 'L';
  public static BODY_TYPE_ESi = 'ESi';
  public static BODY_TYPE_XLE = 'XLE';
  public static GOOGLE_API_KEY = 'AIzaSyDDE5tOJG6bh7Vzbxv60TVJoykNIZdCEe8';
  public static LEAVE_DATE_FORMAT = 'EEEE dd MMMM';
  public static DEFAULT_MOBILE_NUMBER_FORMAT = '9999999999';
  public static MIN_MOBILE_LENGTH = 8;
  public static MAX_MOBILE_LENGTH = 10;
  public static MAX_LENGTH_FOR_FORM_VALIDATION = 50;
  public static MAX_LENGTH_FOR_ABOUT_COMPANY_FORM = 256;
  public static DEFAULT_LOADER_IMAGE = ' ../../../../../assets/images/mi-theme/light/basic/default_user.svg';
  public static DEFAULT_EVENT_IMAGE = '../../../../../assets/images/mi-theme/light/calendar/calendar_event.svg';
  public static DEFAULT_PROFILE_IMAGE = '../../../../../assets/images/loader.gif';
  public static REGEX_FOR_REMOVE_MILLISECONDS_FROM_TIME_STRING = /\.\d+Z/;
  public static SET_HOURS_START_TIME = '0, 0, 0, 0';
  public static SET_HOURS_END_TIME = '23, 59, 59, 999';
  public static ACTIVE_DRIVER_STATUS = 'Active';
  public static BUTTON_PREFIX = "0_"
  public static TENANT_ID = 'tenantId';
  public static ONLY_ALPHA_P_KEY_FILTER = 'alpha';
  public static VEHICLE_STATUS = {
    active: 'Active',
    inShop: 'InShop',
    outOfService: ''
  }
  public static USER_PREFERENCE = {
    COLUMN: "GRID_COLUMN_SEQUENCE",
    FILTER: "TABLE_FILTER"
  }

  public static GOOGLE_GEOCODE = {
    PREMISE: 'premise',
    STREET_ADDRESS: 'street_address',
    ESTABLISHMENT: "establishment"
  }


  public static BOOKING_TAB_LIST = {
    ALL: 'ALL',
    CRITICAL: 'CRITICAL',
    WATCHLIST: 'WATCHLIST'
  };

  public static BOOKING_TAB_LIST_IN_DISPATCH_VIEW = {
    ALL: 'ALL',
    NEW: 'NEW',
    ASSIGNED: 'ASSIGNED',
    COMPLETED: 'COMPLETED'
  };

  public static VEHICLE_TAB_LIST = {
    ASSIGNED: 'ASSIGNED',
    UNASSIGNED: 'UNASSIGNED',
    DELETE: 'DELETED'
  };

  public static EVENT_TAB_lIST = {
    ALL: 'All',
    DELETE: 'DELETED'
  }

  public static DRIVER_TAB_lIST = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    DELETE: 'DELETED'
  }

  public static CONCERN_TAB_lIST = {
    ALL: 'ALL',
    INCIDENTS: 'INCIDENTS',
    COMPLAINTS: 'COMPLAINTS'
  }

  public static PASSENGER_STATUS = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
  };

  public static DRIVER_STATUS = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
  };

  public static LOCATION_STATUS = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
  };

  public static LOCATION_ATTRIBUTES = {
    LOCATION_STATUS: 'location_status',
    LOCATION_ADDRESS: 'location_address',
    LOCATION_GEOLOCATION: 'location_geolocation',
    ALLOW_BOOKING_START_TIME: 'allow_booking_start_time',
    ALLOW_BOOKING_END_TIME: 'allow_booking_end_time',
    LOCATION_PLACE_ID: 'location_place_id'
  }
  public static LOCATION_FILTER_ATTRIBUTES = {
    LOCATION_CATEGORY: 'location_category',
    LOCATION_ENTITY_ID: 'location_entity_id',
    CHECKIN_LOCATION_ID: 'checkin_location_id',
  }

  public static ATTRIBUTE_VALUES_FOR_LOCATION_CATEGORY = {
    PARKING: 'Parking',
    QUEUE: 'Queue'
  }
  
  public static DRIVER_ATTRIBUTES = {
    DRIVER_ADDRESS: 'driver_address',
    DRIVER_GEOLOCATION: 'driver_geo_location',
    DRIVER_ENTITY_ID: 'driver_entity_id',
    NEXT_SHIFT_LOCATION_TYPE: 'next_shift_location_type',
    DUTY_STATUS: 'duty_status',
  }

  public static DRIVER_NEXT_SHIFT_ATTRIBUTES = {
    HOME_LOCATION: 'Home Location',
    TRANSPORT_HUB_LOCATION: 'Transport Hub Location',
    OTHER: 'Other'
  }

  public static PASSENGER_ATTRIBUTES = {
    PASSENGER_ADDRESS: 'select_location_address',
    PASSENGER_GEOLOCATION: 'select_location_geolocation'
  }

  public static PASSENGER_PRIVILEGE_ATTRIBUTES = {
    PASSENGER_PRIVILEGE_ADDRESS: 'select_location_address',
    PASSENGER_PRIVILEGE_GEOLOCATION: 'select_location_geolocation'
  }

  public static COMPARISON_OPERATOR = {
    GTE: 'GTE',
    BETWEEN: 'BETWEEN'
  }

  public static BOOKING_ATTRIBUTES = {
    BOOKING_PICKUP_ADDRESS: 'booking_pickup_location_address',
    BOOKING_PICKUP_GEOLOCATION: 'booking_pickup_location_geolocation',
    BOOKING_DROPOFF_ADDRESS: 'booking_dropoff_location_address',
    BOOKING_DROPOFF_GEOLOCATION: 'booking_dropoff_location_geolocation',
    BOOKING_STOP_ADDRESS: 'booking_stops_location_address',
    BOOKING_STOP_GEOLOCATION: 'booking_stops_location_geolocation'
  }

  public static BOOKING_VIEWS = {
    CAR_JOURNEY: 'CAR_BOOKING_VIEW',
    AIRPORT: 'AIRPORT_BOOKING_VIEW',
    VEHICLE_TRANSFER: 'VEHICLE_TRANSFER_BOOKING_VIEW'
  }

  public static USERS_STATUS = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
  };

  public static ROLES_STATUS = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
  };

  public static DELETE_TYPE = {
    ROW: 'row',
    BULK: 'bulk'
  };

  public static EVENT_STATUS = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
  };

  public static DRIVER_TYPE = {
    DRIVERS: 'drivers',
    GROUPS: 'groups'
  };

  public static ACTION_STATUS = {
    DELETED: 'DELETED'
  };

  public static LOCATION_MAP_STYLE_DEFAULT = [];

  public static LOCATION_MAP_STYLE_DARK = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ];

  public static TABS = {
    PRIVILEGE_SETTINGS: 'privilege_settings',
  }

  public static COLOR_PICKER_DEFAULT_COLOR = "#e00d23";
  public static DATE_FORMAT_DD_MMM_YYYY = 'dd MM yy';
  public static DATE_FORMAT_DD_MMM_YYYY_FOR_TS_FILE = 'dd MMM yyyy';
  public static DATE_FORMAT_DD_MM_YY = 'dd/mm/yy';
  public static HOUR_FORMAT = '12';
  public static IMAGE_UPLOAD_TYPES = 'image/jpeg,image/jpg,image/png';
  public static PDF_UPLOAD = 'application/pdf';
  public static MAX_NUMBER_OF_ADDRESS = 3;
  public static HOUR_MINUTE_FORMAT = 'hh:mm a';
  public static HOUR_MINUTE = 'H:mm';
  public static dd_MMM_yyyy_hh_mm_FORMAT = 'dd MMM yyyy | hh:mm a';
  public static dd_MMM_yyyy_hh_mm_FORMAT2 = 'dd MMM yyyy hh:mm a';
  public static hh_mm_dd_MMM_yyyy_FORMAT = 'hh:mm, dd MMM yyyy';
  public static DD_MM_YYYY = 'DD-MM-YYYY';
  public static YYYY_MM_DD = 'YYYY-MM-DD';
  public static DATE_FORMAT_DD_MM_YYYY_hh_mm_a = 'dd/MM/yyyy h:mm a';
  public static DATE_FORMAT_MMM_D_YYYY: 'MMM dd, yyyy';
  public static hh_m_FORMAT = 'hh:mm,';
  public static dd_MMM_yyyy_FORMAT = 'dd MMM yyyy';
  public static STRING_SPLIT_BY_SPACE = ' ';
  public static STRING_SPLIT_BY_COLON = ':';
  public static AUTO_DISPATCH = 'Auto Dispatch';
  public static DISPATCH_PREFERENCE_ATTRIBUTE_CODE = 'dispatch_preference';
  public static VIEW_CODE = {
    DEFAULT_TABLE_VIEW: "DEFAULT_TABLE_VIEW",
    DEFAULT_TABLE_VEHICLE_ASSIGNMENT_VIEW: 'ASSIGNMENT_HISTORY_VEHICLE_ASSIGN_VIEW',
    DEFAULT_TABLE_DRIVER_ASSIGNMENT_VIEW: 'ASSIGNMENT_HISTORY_DRIVER_ASSIGN_VIEW',
    DEFAULT_FILTER_VIEW: "DEFAULT_FILTER_VIEW",
    ADD_EDIT_VIEW: "ADD_EDIT_VIEW",
    DETAIL_VIEW: "DETAIL_VIEW",    
    EVENT_ADD_EDIT_VIEW: "EVENT_ADD_EDIT_VIEW",
    BULK_UPDATE_VIEW: 'BULK_UPDATE_VIEW',
    EXCEL_IMPORT_VIEW: 'EXCEL_IMPORT_VIEW',
    ADVANCED_FILTER_VIEW: 'ADVANCED_FILTER_VIEW',
    DRIVER_ASSIGN_DUTY_STATUS_VIEW: 'DRIVER_ASSIGN_DUTY_STATUS_VIEW',
    DRIVER_DETAILS_ON_BOOKING_VIEW: 'DRIVER_DETAILS_ON_BOOKING_VIEW',
    VEHICLE_DETAILS_ON_BOOKING_VIEW: 'VEHICLE_DETAILS_ON_BOOKING_VIEW',
    DRIVER_DETAILS_ON_DISPATCH_VIEW: 'DRIVER_DETAILS_ON_DISPATCH_VIEW',
    GENERAL_SETTINGS_VIEW: 'GENERAL_SETTINGS__VIEW',
    TIME_SETTINGS_VIEW: 'TIME_SETTINGS_VIEW',
    SECURITY_SETTINGS_VIEW: 'SECURITY_SETTINGS_VIEW',
    EXPORT_ACCOUNT_DATA_SETTINGS: 'EXPORT_ACCOUNT_DATA_SETTINGS_VIEW',
    BOOKING_HISTORY_VIEW: 'BOOKING_HISTORY_VIEW',
    VARIANT_AUTOCOMPLETE_VIEW: 'VARIANT_AUTOCOMPLETE_VIEW',
    LABELS_AUTOCOMPLETE_VIEW: 'LABELS_AUTOCOMPLETE_VIEW',
    AUTOCOMPLETE_VIEW: 'AUTOCOMPLETE_VIEW',
    SHIFT_CALENDAR_DRIVER_LIST_VIEW: 'SHIFT_CALENDAR_DRIVER_LIST_VIEW',
    DRIVER_LIST_FOR_GROUP_VIEW: 'DRIVER_LIST_FOR_GROUP_VIEW',
    DRIVER_BOOKING_SCHEDULE_ON_DISPATCH_VIEW: 'DRIVER_BOOKING_SCHEDULE_ON_DISPATCH_VIEW',
    DRIVER_ADDRESS_DETAIL_VIEW: 'DRIVER_ADDRESS_DETAIL_VIEW',
    LOCATION_WITH_GEOCODE_VIEW: 'LOCATION_WITH_GEOCODE_VIEW',
    BOOKING_DETAILS_ON_CONCERN_VIEW :"BOOKING_DETAILS_ON_CONCERN_VIEW",
    DRIVER_NEXT_BOOKING_ON_DISPATCH_VIEW: 'DRIVER_NEXT_BOOKING_ON_DISPATCH_VIEW',
    BOOKING_DETAILS_ON_DISPATCH_VIEW: 'BOOKING_DETAILS_ON_DISPATCH_VIEW',
    BOOKING_LIST_ON_DISPATCH_VIEW: 'BOOKING_LIST_ON_DISPATCH_VIEW',
    JOURNEY_HISTORY_IN_DRIVER_VIEW: 'JOURNEY_HISTORY_IN_DRIVER_VIEW',
    JOURNEY_HISTORY_IN_PASSENGER_VIEW: 'JOURNEY_HISTORY_IN_PASSENGER_VIEW',
    JOURNEY_HISTORY_IN_VEHICLE_VIEW: 'JOURNEY_HISTORY_IN_VEHICLE_VIEW',
    JOURNEY_HISTORY_FILTER_VIEW: 'JOURNEY_HISTORY_FILTER_VIEW',
  }

  public static ROLE_CODE = {
    ADMIN_USER: "ADMIN_USER",
    DRIVER: "DRIVER",
    PASSENGER: "PASSENGER"
  }
  public static ENTITY_CODE = {
    VEHICLE: 'vehicle',
    PASSENGER: 'passenger',
    EVENTS: 'event',
    DRIVER: 'driver',
    BOOKING: 'booking',
    BOOKING_HISTORY: 'booking_history',
    LOCATION: 'location',
    INCIDENTS_AND_COMPLAINT: 'incidents_and_complaint',
    PASSENGER_ADDRESS: 'passenger_address',
    PASSENGER_BOOKING_TIME_RESTRICTION: 'passenger_booking_time_restriction',
    DRIVER_DOCUMENT: 'driver_document',
    DRIVER_GROUP: 'driver_group',
    VEHICLE_DOCUMENT: 'vehicle_document',
    VEHICLE_ASSIGNMENT_HISTORY: 'vehicle_assignment_history',
    USER_DETAIL: 'user_detail',
    DRIVER_DRIVING_RECORD: 'driver_driving_record',
    DRIVER_DRIVING_RECORD_SECTION: 'driving_record_section',
    OTHER_DETAILS: 'other_details',
    SEGMENT: 'segment',
    BROADCAST_MESSAGE: 'broadcast_message',
    BOOKING_PASS: 'booking_pass',
    PICKUP_AUTOCOMPLETE_VIEW: 'PICKUP_AUTOCOMPLETE_VIEW',
    DROPOFF_AUTOCOMPLETE_VIEW: 'DROPOFF_AUTOCOMPLETE_VIEW',
    LEAVES: 'leaves',
    QUEUE: 'queue',
    TAGS: 'tags',
    PASS_PRIVILEGE_CONF: 'pass_privilege_conf',
    USER_APP_LOGIN_SETTING: 'user_app_login_setting',
    SHIFT: 'shift',
    GENERAL_SETTING_ADDRESS: 't_gen_sett_address',
    BOOKING_ACTIVITY: 'booking_activity',
    DRIVER_NEXT_SHIFT: 'driver_next_shift',
    INCIDENT_COMPLAINT_HISTORY: 'incident_complaint_history',
    LEAVE_MANAGEMENT_REQUEST: 'leave_management_request',
    LEAVE_MANAGEMENT_HISTORY: 'leave_management_history',
    MANAGE_SHIFT: 'manage-shift',
    CONFIG_SETTING: 't_conf_sett',
    ADD_QUEUE: 'add_queue',
    APP_CONFIG: 'app_config'
  }

  public static KEYS_TO_STORE_DATA_IN_SERVICE = {
    BOOKING_DETAILS: 'BOOKING_DETAILS'
  }

  public ENTITY_CODE_FOR_REMOVE_CHECKBOX = [
    AppSettings.ENTITY_CODE.DRIVER_GROUP,
    AppSettings.ENTITY_CODE.SEGMENT,
    AppSettings.ENTITY_CODE.BROADCAST_MESSAGE,
    AppSettings.ENTITY_CODE.LEAVES,
    AppSettings.ENTITY_CODE.LEAVE_MANAGEMENT_REQUEST,
    AppSettings.ENTITY_CODE.LEAVE_MANAGEMENT_HISTORY,
    AppSettings.ENTITY_CODE.QUEUE,
    AppSettings.ENTITY_TYPE.ORGANIZATION,
    AppSettings.ENTITY_CODE.MANAGE_SHIFT,
    AppSettings.ENTITY_CODE.VEHICLE_ASSIGNMENT_HISTORY,
    AppSettings.ENTITY_CODE.EVENTS,
    AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT,
    AppSettings.ENTITY_CODE.BOOKING,
    AppSettings.ENTITY_CODE.DRIVER_DOCUMENT,
    AppSettings.VIEW_CODE.JOURNEY_HISTORY_IN_DRIVER_VIEW,
    AppSettings.VIEW_CODE.JOURNEY_HISTORY_IN_PASSENGER_VIEW,
    AppSettings.VIEW_CODE.JOURNEY_HISTORY_IN_VEHICLE_VIEW,
    AppSettings.ENTITY_CODE.ADD_QUEUE,
  ]

  public static GROUP_CODE = {
    DRIVER_DRIVING_RECORD: 'driver_driving_record',
    BOOKING_TIME_RESTRICTION: 'booking_time_restriction',
    STAY_HOME_ADDRESS: 'stay_home_address',
    DRIVER_DOCUMENT: 'driver_document',
    DOCUMENTS: 'documents',
    DIMENSIONS: 'dimensions',
    ASSIGN_LOCATION: 'assign_location',
    VEHICLE_DOCUMENT: 'vehicle_document',
    ACCOUNT_OWNER: 'account_owner',
    GENERAL: 'general',
    TIME_SETTINGS: 'time_settings',
    PASSWORD_REQUIREMENTS: 'password_requirements',
    EXPORT_ACCOUNT_DATA: 'export_account_data',
    DOWNLOAD_ACCOUNT_DATA: 'download_account_data',
    SESSION_TIMEOUT: 'session_timeout',
    ADDRESS: 'address',
    SETTING: 'setting',
    DOCUMENT_SECTION: 'document_section',
    DEFAULT_VALUES: 'default_values',
    LOCATION_DETAILS: 'location_details'
  }

  public static ORGANIZATION_TAB_LIST = {
    GENERAL_SETTINGS: 'general_settings',
    SECURITY: 'security'
  }

  public static ORGANIZATION_ATTRIBUTE_CODE = {
    ACCOUNT_NAME: 'account_name',
    TENANT_ID: 'tenant_id',
    SPACE: 'space',
    MOBILE_NUMBER: 'mobile_number'
  }

  public static PASSENGER_ATTRIBUTE_CODE = {
    SELECT_LOCATION_GEOLOCATION: 'select_location_geolocation'
  }

  public static TIME_SETTING_ATTRIBUTE_CODE = {
    MAX_VALID_TIME_FOR_BOOKING_APP_USER: 'max_valid_time_for_booking_app_user',
    MAX_VALID_TIME_FOR_BOOKING_PORTAL_USER: 'max_valid_time_for_booking_portal_user'
  }

  public static DATE_ATTRIBUTE_IDS = {
    PASSENGER_DATE_OF_BIRTH: 'date_of_birth',
    APP_ACCESS_DATE: 'app_access_date',
    TRANSPORTATION_START_DATE: 'transportation_start_date',
    TRANSPORTATION_END_DATE: 'transportation_end_date',
    VOID_DATE_TIME: 'void_date_time',
    INCIDENTS_DATE_TIME: 'incident_complaint_date',
    DRIVER_DATE_OF_BIRTH: 'date_of_birth',
    DRIVER_DRIVING_RECORD_DATE: 'date',
    START_TIME: 'start_time',
    END_TIME: 'end_time',
    DRIVER_DOCUMENT_ISSUED_DATE: 'issued_date',
    DRIVER_DOCUMENT_EXPIRY_DATE: 'expiry_date',
    VEHICLE_DOCUMENT_ISSUED_DATE: 'issued_date',
    VEHICLE_DOCUMENT_EXPIRY_DATE: 'expiry_date',
    VEHICLE_DETAIL_YEAR_OF_MFG: 'year_of_manufacturing',
    EVENT_TRANSPORTATION_START_DATE: '11',
    BROADCAST_SELECT_TIME: 'select_time',
    DEPARTURE_TIME_AND_DATE: 'departure_time_and_date',
    RETURN_TIME_AND_DATE: 'return_time_and_date',
    PICKUP_DATE: 'pickup_date',
    PICKUP_TIME: 'pickup_time',
    EVENT_START_DATE: 'event_start_date',
    DATE: 'date',
    DOCUMENT_EXPIRY_DATE: 'expiry_date',
    APP_ACCESS_START_DATE: 'app_access_start_date',
    APP_ACCESS_END_DATE: 'app_access_end_date',
    CREATED_AT: 'created_at',
    INCIDENT_COMPLAINT_DATE: 'incident_complaint_date',
  }

  public static DEFAULT_ADDRESS_LABELS = ['Home', 'Hotel'];
  public static DEFAULT_ADDRESS_LABELS_FOR_GENERAL_SETTINGS = ['Main Office', '2nd Office'];

  public static DEFAULT_ADDRESS_LIST = [
    {
      labelKey: 'Home',
      labelValue: "Home",
      entityId: null,
      index: 0
    },
    {
      labelKey: 'Hotel',
      labelValue: 'Hotel',
      entityId: null,
      index: 1
    }];

  public static DEFAULT_ADDRESS_LIST_FOR_ORGANIZATION = [
    {
      labelKey: 'Main Office',
      labelValue: "Main Office",
      entityId: null,
      index: 0
    },
    {
      labelKey: '2nd Office',
      labelValue: '2nd Office',
      entityId: null,
      index: 1
    }
  ];

  public static INDUSTRY_LIST = [
    { label: 'Technology', value: 'Technology' },
    { label: 'Healthcare and Pharmaceuticals', value: 'Healthcare and Pharmaceuticals' },
    { label: 'Finance and Insurance', value: 'Finance and Insurance' },
    { label: 'Manufacturing', value: 'Manufacturing' },
    { label: 'Retail and E-commerce', value: 'Retail and E-commerce' },
    { label: 'Energy', value: 'Energy' },
    { label: 'Agriculture and Food Production', value: 'Agriculture and Food Production' },
    { label: 'Construction and Real Estate', value: 'Construction and Real Estate' },
    { label: 'Transportation and Logistics', value: 'Transportation and Logistics' },
    { label: 'Telecommunications', value: 'Telecommunications' },
    { label: 'Education and Training', value: 'Education and Training' },
    { label: 'Entertainment and Media', value: 'Entertainment and Media' },
    { label: 'Hospitality and Tourism', value: 'Hospitality and Tourism' },
    { label: 'Professional Services', value: 'Professional Services' },
    { label: 'Aerospace and Defense', value: 'Aerospace and Defense' },
    { label: 'Biotechnology', value: 'Biotechnology' },
    { label: 'Automotive', value: 'Automotive' },
    { label: 'Mining and Natural Resources', value: 'Mining and Natural Resources' },
  ]

  public static ADDRESS_FIELDS = {
    SELECT_LOCATION: 'select_location',
    ADDRESS_LINE_ONE: 'address_line_1',
    ADDRESS_LINE_TWO: 'address_line_2',
    SELECT_LOCATION_TYPE: 'select_location_type',
    NEARBY_LANDMARK: 'nearby_landmark',
    POSTAL_CODE: 'postal_code',
    STATE: 'state',
    COUNTRY: 'country',
    INSTRUCTIONS: 'instructions',
    SELECT_LOCATION_ADDRESS: 'select_location_address'
  }

  public static LIST_TYPE = {
    DRIVER: 'DR',
    GROUP: 'GR',
    VEHICLE: 'VH'
  }

  public static BOOKING_ATTRIBUTE_CODE = {
    OWNER_WATCHLIST: 'owner_watchlist',
    PICK_UP_DATE: 'pickup_date',
    ADDITIONAL_PASSENGER_VEHICLE: 'additional_passenger_vehicle',
    PET_COUNT: 'pet_count',
    BOOKING_ENTITY_ID: 'booking_entity_id'
  }

  public static YES_NO_VALUES = {
    YES: 'Yes',
    NO: 'No'
  }

  public static LIST_TYPE_OPTIONS = [
    { name: 'DRIVERS', value: 'DR' },
    { name: 'GROUPS', value: 'GR' },
    { name: 'VEHICLES', value: 'VH' },
  ];

  public static CALENDAR_FILTER_TYPE = {
    DAY: 'day',
    WEEK: 'week',
  }

  public static DAY_HOURS = [['12a', '1a', '2a', '3a'], ['4a', '5a', '6a', '7a'], ['8a', '9a', '10a', '11a'], ['12p', '1p', '2p', '3p'], ['4p', '5p', '6p', '7p'], ['8p', '9p', '10p', '11p'], ['']];
  public static DAY_HOURS_24_FORMAT = [['0', '1', '2', '3'], ['4', '5', '6', '7'], ['8', '9', '10', '11'], ['12', '13', '14', '15'], ['16', '17', '18', '19'], ['20', '21', '22', '23'], ['']];
  public static WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  public static MONTH_LABELS = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  public static INCIDENTS_SPACER_IDS = ["space", "space_2"];
  public static LOCATION_SPACER_CODES = ["space", "space_2", "space_3"];
  public static MAP_COUNTRY_COMPONENT_RESTRICTIONS: ['IN', 'UK', 'US', 'AU'];

  public static SPACERS_ATTRIBUTE_CODES_FOR_PASSENGER_DETAILS = ['space', 'space_2'];
  public static SPACERS_ATTRIBUTE_CODES_FOR_STAY_HOME_ADDRESS = ['space', 'space_2', 'space_3'];
  public static SPACERS_ATTRIBUTE_CODES_FOR_DRIVER_DETAILS = ['space', 'space_2'];
  public static SPACERS_ATTRIBUTE_CODES_FOR_LOCATION = ['space', 'space_2', 'space_3'];

  public static INSTRUCTION_ATTRIBUTE_ID: '11';
  public static TIME_RESTRICTION_ATTRIBUTE_ID: '2';
  public static SELECT_LOCATION_TYPE_ATTRIBUTE_ID: '1';
  public static DEFAULT_INSTRUCTION: '   ';
  public static PREFIX_FOR_ONE_TO_MANY_RELATED_FIELDS: '_';

  public static FIELDS = {
    STAY_HOME_ADDRESS_SECTION: 'stay_home_address_section',
    BOOKING_TIME_RESTRICTION: 'booking_time_restriction_section',
    SELECT_LOCATION_TYPE: 'select_location_type',
    DRIVER_DOCUMENT: 'driver_document',
    DRIVER_DOCUMENT_URL: 'upload_document_url',
    DRIVER_DRIVING_RECORD: 'driver_driving_record',
    DRIVER_DRIVING_RECORD_SECTION: 'driving_record_section',
    VEHICLE_DOCUMENT: 'vehicle_document',
    VEHICLE_DOCUMENT_URL: 'vehicle_document_url',
    TIME_RESTRICTION: 'time_restriction',
    INSTRUCTIONS: 'instructions',
    AIRPORT_TRANSPORT: 'airport_transport',
    TRANSPORTATION_END_DATE: 'transportation_end_date',
    DEPARTURE_JOURNEY: 'departure_journey',
    NUMBER_OF_BOOKING_PER_DAY: 'number_of_bookings_per_day',
    BOOKING_CANCELLATION_IN_STATUS: 'booking_cancellation_in_status',
    OTP_VERIFICATION_FOR_BOOKING_END: 'otp_verification_for_booking_end',
    ADDRESS_SECTION: 'address_section',
    SECTION_LABEL: 'sectionLabel'
  }


  public static IDLE_TIME = 18;
  public static IDLE_TIME_ON_CONFIRM_MSG = 120000;
  public static INTERVAL = 'interval';
  public static HEADER_CONTENT_TYPE = 'application/json';
  public static ACCEPT_TYPE_FOR_FILE_UPLOAD = '*/*';
  public static HEADER_CONTENT_TYPE_MULTIPART = 'application/json';
  public static HEADER_AUTHORIZATION = 'Authorization';
  public static PAGINATION_ONE_ROW_PER_PAGE_LIMIT = 1;
  public static PAGINATION_ROWS_PER_PAGE_LIMIT = 10;
  public static ENTITY_DATA_PER_PAGE_LIMIT = 20;
  public static PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS = 500;
  public static PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_DRIVER_GROUP = 50;
  public static PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_CUSTOM_FIELDS = 100;
  public static HEADER_TIMEZONE = 'Timezone';
  public static HEADER_TIMEZONE_VALUE = 'multipart/form-data';
  public static HEADER_ACCEPT_LANGUAGE = 'en_IN';
  public static HEADER_COUNTRY_CODE = 'IN'
  public static HEADER_AUTHORIZATION_VALUE = '';
  public static USER: any = null;
  public static TOKEN_KEY = 'Token';
  public static USER_DETAILS = 'userDetails';
  public static FILE_UPLOAD_ID = '';
  public static FILE_UPLOAD_NAME = '';
  public static PROFILE_UPLOAD_ID = '';
  public static PROFILE_UPLOAD_NAME = '';
  public static FAQ: any = null;
  public static ACCESS_LIST = 'ACCESS_LIST';
  public static ACCESS_MENU = 'ACCESS_MENU';
  public static TIME_SETTINGS = 'TIME_SETTINGS';
  public static MIN_DISTANCE = 0.1;
  public static MAX_DISTANCE = 1;
  public static UPLOAD_FILE_URL = environment.basePath + '/secure/file';
  public static GET_FILE_URL = environment.basePath + '/file';
  public static GET_FILE_THUMB_URL = environment.basePath + '/file/thumbnail_';
  public static EXPORT_URL = environment.basePath + '/';
  public static PRINT_URL = environment.basePath + '/';
  public static DEFAULT_PROFILE_IMAGE_URL = '/assets/images/default_profile.png';
  public static CLEAR_NOTIFICATION_INTERVAL: any;
  public static NOTIFICATION_INTERVAL: 600;
  public static IGNORE_LINK = [];
  public static SUPPORTED_COUNTRY = environment.supportedCountry;
  public static NAME_PATTERN = new RegExp(['^[A-Za-z\\s]*$'].join(''));
  public static ROLE_PATTERN = new RegExp(['^[A-Za-z\\d\-_\\s/\\\\\]*$'].join(''));
  public static REGEX_TO_SEPARATE_HOURS_MINUTES_FROM_TIME_STRING = /(\d+)\:(\d+) (\w+)/;
  public static REGEX_TO_GET_HOURS = /am/i;
  public static PHONE_PATTERN: any = '^[0-9][0-9]*$';
  public static NUMBER_PATTERN = /^\+?[0-9\-]+$/;
  public static NAME_SPACE_PATTERN = /^[^\s][aA-zZ-\\s]$/ //^\S*\s*\S*$/g;
  public static NAME_SPACE_PATTERN2 = /^[a-zA-Z\s\-\_a-zA-Z]+$/;
  public static ALPHA_NUMERIC_NAME = /^[a-zA-Z\s\-\_a-zA-Z0-9]+$/;
  public static SHORT_CODE = /^[a-zA-Z0-9]+$/;
  public static AMOUNT_PATTERN = new RegExp(['^[0-9\\d\.]*$'].join(''));
  public static PHONE_CODE = /([0-9]{4,})|[1-9]/;
  public static EMAIL_PATTERN = new RegExp(['[a-z|A-Z|0-9]+[@]+[a-z|A-Z|0-9]+[.]+[a-z|A-Z|0-9]+'].join(''));
  public static WEBSITE_PATTERN = new RegExp('^(https?:\\/\\/)?www\\.[a-zA-Z0-9-]+\\.(com|co|in)(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?$');
  public static PASSWORD_PATTERN = new RegExp(['^(?=.*[a-z])', '(?=.*[A-Z])', '(?=.*\\d)', '(?=.*[!@#$%^&*()_+\\-=[\\]{};:"\\,.<>/?|`~])', '.{88888,}$'].join(''));
  public static ZIPCODE_PATTERN = new RegExp(['^[0-9]'].join(''));
  public static DIGIT_PATTERN = new RegExp(['^[\\d]*$'].join(''));
  public static RADIUS_DIGIT_PATTERN = /^[0-9|.|0-9]+$/;
  public static NUMBER_NOT_ZERO = new RegExp(['^[1-9][0-9]*$'].join(''));
  public static FEE_PATTERN = new RegExp(['^\\d+(\.\\d{1,2})?$'].join(''));
  public static ALPHA_NUMERIC =  '^[a-zA-Z0-9\\s]*$';
  public static VALUE_ONE_TO_HUNDRED = new RegExp(['^[1-9][0-9]?$|^100$'].join(''));
  public static NON_ZERO_VALUES = new RegExp(['^[1-9][0-9]*$'].join(''));
  public static BLOCK_CHARS = new RegExp(['^[^<>*!]+$'].join(''));
  public static HOTEL_PROFILE_UPLOAD_ID = '';
  public static HOTEL_PROFILE_UPLOAD_NAME = '';
  public static PERSON_NAME_PATTERN = '^[a-zA-Z][a-zA-Z\\s\-\_]+$';
  public static COMPANY_AND_PERSON_NAME_PATTERN = '^[a-zA-Z0-9][a-zA-Z0-9\\s\-\_]+$';
  public static FAX_PATTERN = /^\+?[0-9\-]+$/;
  public static TIME_ZONE_FIRST_STRING = /[(]/gi;
  public static TIME_ZONE_SECOND_STRING = /[)]/gi;
  public static KEY_FILTER_FOR_BARCODE = 'num';
  public static SINGLE_DIGIT_HIGHEST_NUMBER = 9;
  public static PHONE_MASK_PATTERN = /(\d{3})(\d{3})(\d{4})/;
  public static RESET_PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=])[^\s]{8,}$/;
  public static DAYS_COLLECTION = [
    { label: 'Sun', value: 'SUNDAY' },
    { label: 'Mon', value: 'MONDAY' },
    { label: 'Tue', value: 'TUESDAY' },
    { label: 'Wed', value: 'WEDNESDAY' },
    { label: 'Thu', value: 'THURSDAY' },
    { label: 'Fri', value: 'FRIDAY' },
    { label: 'Sat', value: 'SATURDAY' }
  ];

  public static LOGGED_IN_ROLE = '';
  public static roles = [
    {
      roleCode: 'System Admin',
      redirect: '/admin-dashboard'
    }

  ];

  public static DATE_FORMATTER = 'MMM DD, YYYY hh:mm A';
  public static DATE_FORMATTER_MONTH_TIME = 'MMM d, HH:mm';

  public static COOKIE_EXPIRY = (1 * 24 * 60 * 60 * 1000);

  public static FILE_UPLOAD_MAX_SIZE_IN_BYTE = 10000000;

  public static SESSION_KEY_NAME = 'X-SESSION-KEY';
  public MESSAGE_COLOR_STATUS = {
    active: 'Active',
    away: 'Away',
    onCall: 'On Call',
    offline: 'Offline'
  };

  public static ENTITY_TYPE = {
    VEHICLE: 'vehicle',
    DRIVER: 'driver',
    EVENTS: 'event',
    LOCATION: 'location',
    PASSENGER: 'passenger',
    INCIDENTS_AND_COMPLAINT: 'incidents_and_complaint',
    DRIVER_GROUP: 'driver_group',
    DRIVER_DRIVING_RECORD: 'driver_driving_record',
    SHIFT_TEMPLATE: 'shift_template',
    GENERAL_SETTINGS: 't_gen_sett',
    GENERAL_SETTING_ADDRESS: 't_gen_sett_address',
    TIME_SETTINGS: 't_time_sett',
    SECURITY_SETTINGS: 't_security_sett',
    EXPORT_ACCOUNT_DATA_SETTINGS: 't_expt_sett',
    QUEUE: 'queue',
    ORGANIZATION: 'organization',
    LABEL: 'label',
    MANAGE_SHIFT: 'manage-shift',
    SHIFT_LIST: 'shift-list',
    BOOKING_ACTIVITY: 'booking_activity',
    DRIVER_NEXT_SHIFT: 'driver_next_shift',
  };

  
  public static ATTRIBUTE_CODE_FOR_CONFIG_SETTINGS = {
    PASSENGER_EDIT_BOOKING_ALLOWED_STATUS: 'passenger_edit_booking_allowed_status',
    ADMIN_EDIT_BOOKING_ALLOWED_STATUS: 'admin_edit_booking_allowed_status',
    PASSENGER_CANCEL_BOOKING_ALLOWED_STATUS: 'passenger_cancel_booking_allowed_status',
    ADMIN_CANCEL_BOOKING_ALLOWED_STATUS: 'admin_cancel_booking_allowed_status',
  };

  public static ATTRIBUTE_CODE_FOR_SETTINGS = {
    TRANSPORTATION_TYPE: 'transportation_type',
    BAGS: 'bags',
    JOURNEY_PREFERENCE: 'journey_preference',
    VEHICLE_TYPE_PREFERENCE: 'vehicle_type_preference',
    VEHICLE_DISPATCH_PREFERENCE: 'vehicle_dispatch_preference',
    EVENT_STATUS: 'event_status',
    EVENT_STATE: 'event_state',
    TRANSPORTATION_MODE: 'transportation_mode',
    PASSENGER_TYPE: 'passenger_type',
    PASSENGER_STATUS: 'passenger_status',
    VEHICLE_TYPE: 'vehicle_type',
    BODY_TYPE: 'body_type',
    VEHICLE_STATUS: 'vehicle_status',
    FEATURES: 'features',
    ACCOUNT_STATUS: 'account_status',
    DUTY_STATUS: 'duty_status',
    OFF_DUTY_REASON: 'off_duty_reason',
    LOCATION_CATEGORY: 'location_category',
    LOCATION_STATUS: 'location_status',
    DISPATCH_ZONE: 'dispatch_zone',
    CATEGORY: 'category',
    RAISED_BY_TYPE: 'raised_by_type',
    INCIDENTS_AND_COMPLAINT_STATUS: 'incident_complaint_status',
    COLOR: 'group_color',
    STATUS: 'status',
    MESSAGE_TYPE: 'message_type',
    SELECT_TIME: 'select_time',
    RECORD: 'record',
    LEAVE_TYPE: 'leave_type',
    REASON_FOR_BREAK: 'reason_for_break',
  };

  public static ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS = {
    SELECT_LOCATION_GEOLOCATION: 'select_location_geolocation',
    SELECT_LOCATION_ADDRESS: 'select_location_address',
    DEFAULT_ADDRESS: 'default_address',
    LOCATION_ENTITY_ID: 'location_entity_id',
    LOCATION_PLACE_ID: 'location_place_id',
    STATE: 'state',
    COUNTRY: 'country',
    POSTAL_CODE: 'postal_code',
    PINCODE: 'pincode'
  };

  public COMMUNICATION_DROPDOWN = [
    { label: 'Individual', value: 'individual' },
    { label: 'Group', value: 'group' },
  ];

  public static REPLACE_WITH_DROPDOWN = [
    { label: 'Replace With', value: 'replaceWith', disabled: false },
    { label: 'Remove all values', value: 'removeAllValues', disabled: false },
  ];

  public static MENU_ITEMS = [
    { label: 'Vehicles', value: 'vehicles' },
    { label: 'Passengers', value: 'passengers' }
  ];

  public static magneticHolder() {
    return AppSettings.MAGNETIC_HOLDER;
  }

  public static HEADER_UPLOAD_CONTENT_TYPE = 'multipart/form-data';
  public static DOCUMENTS_TYPE = {
    PROFILE: 'profile',
    DOCUMENT: 'document',
    REPORT: 'report',
    KML: 'kml'
  }

  public static MODULES = [
    { key: 'dashboard', label: 'Dashboard', value: '', showInAccess: false },
    { key: 'vehicle', label: 'Vehicle Management', value: '', showInAccess: false },
    { key: 'booking', label: 'Bookings', value: '', showInAccess: false },
    { key: '', label: 'Dispatch', value: '', showInAccess: false },
    { key: 'passenger', label: 'Passenger Management', value: '', showInAccess: false },
    { key: 'driver', label: 'Driver Management', value: '', showInAccess: false },
    { key: 'event', label: 'Event Management', value: '', showInAccess: false },
    { key: 'location', label: 'Manage Locations', value: '', showInAccess: false },
    { key: '', label: 'Manage Zones', value: '', showInAccess: false },
    { key: 'queue', label: 'Queue Management', value: '', showInAccess: false },
    { key: 'incidents_and_complaint', label: 'Incidents and Complaints', value: '', showInAccess: false },
    { key: '', label: 'Shift Management', value: '', showInAccess: false },
    { key: '', label: 'Communication', value: '', showInAccess: false },
    { key: '', label: 'User Management', value: '', showInAccess: false },
  ];

  public static MODULE_NAME = [
    { key: 'vehicle', label: 'Vehicle', value: '', showInAccess: false },
    { key: 'passenger', label: 'Passenger', value: '', showInAccess: false },
    { key: 'driver', label: 'Driver', value: '', showInAccess: false },
    { key: 'location', label: 'Manage Locations', value: '', showInAccess: false },
    { key: 'incidents_and_complaint', label: 'Incidents and Complaints', value: '', showInAccess: false },
  ];

  public static DATE_YEAR_PREVIOUS_YEAR_LIMIT = 25;
  public static DRIVER_PROFILE_IMAGE_ATTRIBUTE_CODE = 'driver_profile_image';
  public static DRIVER_PROFILE_IMAGE_URL_ATTRIBUTE_CODE = 'driver_profile_image_url';
  public static DRIVER_TYPE_ATTRIBUTE_CODE = 'DriverType';
  public static PASSENGER_PRIVILEGE_SETTINGS_SELECTED_LOCATIONS_ATTRIBUTE_CODE = 'selected_locations';
  public static PASSENGER_PRIVILEGE_SETTINGS_SHOW_LIST_OF_PREDEFINED_LOCATIONS_ATTRIBUTE_CODE = 'show_list_of_predefined_location';

  public static DATE_OF_BIRTH_INPUT_CODE = 'dateOfBirth';
  public static SERVICE_AREA_RADIUS = 'service_area_radius';
  public static TRANSPORTATION_RESTRICTION_GROUP = 'transportation_restrictions';
  public static RELATED_SETTINGS = 'related_settings';
  public static DATE_INPUT_CODE = 'date';

  public static PASSENGER_PROFILE_IMAGE_ATTRIBUTE_CODE = 'passenger_profile_image';
  public static PASSENGER_PROFILE_IMAGE_URL_ATTRIBUTE_CODE = 'passenger_profile_image_url';
  public static MOBILE_NUMBER_ATTRIBUTE_CODE = 'mobile_number';
  public static EMERGENCY_CONTACT_ATTRIBUTE_CODE = 'emergency_contact_number';
  public static NATIONALITY_ATTRIBUTE_CODE = 'nationality';
  public static PASSENGER_DATE_OF_BIRTH_ATTRIBUTE_CODE = 'date_of_birth';
  public static ATTRIBUTE_ID_FOR_IMAGE_UPLOAD_IN_ORGANIZATION = 5;

  public static DRIVER_DATE_OF_BIRTH_ATTRIBUTE_CODE = 'date_of_birth';
  public static DATE_OF_BIRTH_FORMAT = 'dd MMM yyyy';

  public static SHIFT_TYPE = {
    MANUALLY: 'manually',
    SHIFT_TEMPLATE: 'shiftTemplate'
  };

  public static SHIFT_PUBLISH_UNPUBLISH = {
    PUBLISH: 'published',
    UNPUBLISH: 'unpublished'
  };

  public static SHIFT_STATUS = {
    PUBLISH: 'published',
    UNPUBLISH: 'unpublished'
  };


  public static BREAK_REASONS = [
    { label: 'Meal Break', value: 'Meal Break' },
    { label: 'Rest Break', value: 'Rest Break' },
  ];

  public static SHIFT_TYPE_OPTIONS = [
    { key: 'shiftTemplate', name: 'Shift Template' },
    { key: 'manually', name: 'Add Manually' }
  ];

  public static ASSIGNMENT_TYPE_OPTIONS = [
    { key: 'drivers', name: 'Individual Driver' },
    { key: 'groups', name: 'Drivers Group' }
  ];

  public static EVENT_ATTRIBUTES = {
    STATE: {
      ONGOING: 'ongoing',
      COMPLETED: 'completed',
      UPCOMING: 'upcoming',
    },
    DATE_ATTRIBUTE_CODES: ['event_start_date', 'event_end_date', 'stop_booking_date_passenger_app', 'stop_booking_date_admin_app'],
    STATUS_ATTRIBUTE_ID: '17',
    SPACERS_ATTRIBUTE_IDS: ['6'],
    IMAGE_ATTRIBUTE_CODE: 'event_image',
    START_DATE_ATTRIBUTE_CODE: 'event_start_date',
    END_DATE_ATTRIBUTE_CODE: 'event_end_date',
    STOP_BOOKING_DATE_PASSENGER_APP_ATTRIBUTE_CODE: 'stop_booking_date_passenger_app',
    STOP_BOOKING_DATE_ADMIN_APP_ATTRIBUTE_CODE: 'stop_booking_date_admin_app',
    CAR_SERVICE_AUTO_DISPATCH_ATTRIBUTE_CODE: 'car_service_auto_dispatch',
    SHUTTLE_BOOKING_ATTRIBUTE_CODE: 'shuttle_bookings',
    EVENT_STATUS: 'event_status',
    EVENT_ADDRESS: 'event_address',
    EVENT_GEOLOCATION: 'event_geolocation'
  };


  public PRINT_LABEL_RADIO_OPTIONS = [
    { label: 'Small', value: 'small' },
    { label: 'Large', value: 'large' },
  ];

  public static DRIVER_ASSIGNMENT_TYPE = {
    INDIVIDUAL_DRIVER: 'drivers',
    DRIVER_GROUP: 'groups',
  };


  public static TRANSPORTATION_MODE = {
    CAR: 'Car Journey',
    SHUTTLE: "Shuttle",
    BUS: "Bus",
    AIRPORT: "Airport",
    VEHICLE_TRANSFER: 'Vehicle Transfer'
  }

  public static TRANSPORTATION_TYPE = {
    CITY_RIDE: 'City Ride',
    OUTSTATION: 'Outstation',
    AIRPORT: 'Airport',
    DEDICATED: 'Dedicated'
  }

  public static INCIDENTS_STATUS_CLASS = {
    OPEN: 'status-open',
    UNDER_REVIEW: 'status-under-review',
    IN_PROGRESS: 'status-in-progress',
    RESOLVED: 'status-resolved',
    DEFAULT: 'status-default'
  };

  public static INCIDENT_DATE_TIME_FORMAT = 'dd MMM yyyy hh:mm a';

  public static BOOKING = {
    TAB_LIST: {
      ALL: 'ALL',
      CRITICAL: 'CRITICAL',
      WATCHLIST: 'WATCHLIST'
    },
    ATTRIBUTE_IDS: {
      STATUS: 30
    },
    STATUS: {
      CANCELLED: 'Cancelled',
      EXPIRED: 'Expired',
      COMPLETED: 'Completed',
      DRIVER_ARRIVED: 'Driver Arrived',
      ON_TRIP: 'On Trip',
      DRIVER_ON_THE_WAY: 'Driver On The Way',
      RECONFIRMED: 'Reconfirmed',
      ACKNOWLEDGED: 'Acknowledged',
      DRIVER_ASSIGNED: 'Driver Assigned',
      ADMIN_CONFIRMED: 'Admin Confirmed',
      NEW_REQUEST: 'New Request',
      PASSENGER_CHECKIN: 'Passenger CheckIn',
    },
    STATUS_BY_ID: {
      '12': 'Cancelled',
      '11': 'Expired',
      '10': 'Completed',
      '9': 'On Trip',
      '8': 'Passenger CheckIn',
      '7': 'Driver Arrived',
      '6': 'Driver On The Way',
      '5': 'Reconfirmed',
      '4': 'Acknowledged',
      '3': 'Driver Assigned',
      '2': 'Admin Confirmed',
      '1': 'New Request'
    },
    SHOW_ASSIGN: ["1", "2"],
    SHOW_REASSIGN: ["3", "4"],
    DATE_ATTRIBUTE_CODES: ['departure_time_and_date', 'return_time_and_date', 'arrival_or_departure_time','pickup_date','pickup_time']
  };
  public static COUNTRY_CODE_WITH_COUNTRY_NAME = [
    { name: 'Australia', code: 'AU', countryCode: '+1' },
    { name: 'Brazil', code: 'BR', countryCode: '+2' },
    { name: 'China', code: 'CN', countryCode: '+3' },
    { name: 'Egypt', code: 'EG', countryCode: '+4' },
    { name: 'France', code: 'FR', countryCode: '+5' },
    { name: 'Germany', code: 'DE', countryCode: '+6' },
    { name: 'India', code: 'IN', countryCode: '+91' },
    { name: 'Japan', code: 'JP', countryCode: '+7' },
    { name: 'Spain', code: 'ES', countryCode: '+8' },
    { name: 'United States', code: 'US', countryCode: '+9' }
  ];

  public static COUNTRY_NAME_COUNTRY_CODE = [
    { name: 'Australia', code: 'AU', countryCode: '+1' },
    { name: 'Brazil', code: 'BR', countryCode: '+2' },
    { name: 'China', code: 'CN', countryCode: '+3' },
    { name: 'Egypt', code: 'EG', countryCode: '+4' },
    { name: 'France', code: 'FR', countryCode: '+5' },
    { name: 'Germany', code: 'DE', countryCode: '+6' },
    { name: 'India', code: 'IN', countryCode: '+91' },
    { name: 'Japan', code: 'JP', countryCode: '+7' },
    { name: 'Spain', code: 'ES', countryCode: '+8' },
    { name: 'USA', code: 'US', countryCode: '+9' },
    { name: 'Singapore', code: 'SG', countryCode: '+65' },
    { name: 'United Kingdom', code: 'GB', countryCode: '+44' },
  ];


  public static BOOKING_STATUS = {
    CANCELLED: 'Cancelled',
    EXPIRED: 'Expired',
    COMPLETED: 'Completed',
    DRIVER_ARRIVED: 'Driver Arrived',
    ON_TRIP: 'On Trip',
    DRIVER_ON_THE_WAY: 'Driver On The Way',
    RECONFIRMED: 'Reconfirmed',
    ACKNOWLEDGED: 'Acknowledged',
    DRIVER_ASSIGNED: 'Driver Assigned',
    ADMIN_CONFIRMED: 'Admin Confirmed',
    NEW_REQUEST: 'New Request'
  }

  public static BOOKING_ATTRIBUTE_IDS = {
    STATUS: 30
  }
  public static DRIVER_GROUP_NAME_ATTRIBUTE_CODE = 'group_name';
  public static DRIVER = {
    DUTY_STATUS: {
      ON_DUTY: 'On',
      OFF_DUTY: 'Off'
    },
    RELEASE: {
      LOCATION: 'LOCATION',
      DRIVER_GROUP: 'DRIVER_GROUP',
      ACTIVE_VEHICLE: 'ACTIVE_VEHICLE',
      DEFAULT_VEHICLE: 'DEFAULT_VEHICLE'
    },
    STATUS_HEADER: {
      CHECKIN_LOCATION: 'Checkin Location',
      DRIVER_GROUP: 'Driver Group',
      OFF_DUTY_REASONS: 'Off Duty Reasons',
    }
  };


  public static MANAGE_GROUP_BULK_STATUS = {
    STATUS: 'Status'
  }

  public static MANAGE_GROUP_BULK_STATUS_CASE = {
    GROUP_STATUS: 'group_status'
  }

  public static DRIVER_TAB_CODE = {
    DETAILS: 'details',
    OTHER_DETAILS: 'other_details',
    DOCUMENTS: 'documents'
  }

  public static DRIVER_ATTRIBUTE_CODES = {
    DRIVER_STATUS: 'driver_status',
    EMAIL: 'email',
    GENDER: 'gender',
    MOBILE_NUMBER: 'mobile_number'
  }


  public static MSG_ACTION = {
    ADD: 'add',
    UPDATE: 'update',
    DELETE: 'delete',
    BULK_UPDATE: 'bulkUpdate',
    BULK_DELETE: 'bulkDelete',
    UPDATE_STATUS: 'updateStatus',
    PRINT_LABEL: 'printLabel',
    ACTIVE: 'active',
    INACTIVE: 'inactive'
  }

  public static BROADCAST_GROUP = {
    DELIVERY_METHODS: {
      PUSH_NOTIFICATION: 'Push Notification',
      EMAIL: 'Email',
      SMS: 'SMS'
    },

    SELECT_TIME_OPTIONS: {
      SEND_NOW: 'Send Now',
      PICK_DATE_AND_TIME: 'Pick Date & Time',
    }
  }

  public static LEAVES_GROUP = {
    OVERVIEW_LIST: [
      { label: 'This Week' },
      { label: 'Last Week' },
      { label: 'This Month' },
      { label: 'Last Month' },
      { label: 'This Year' },
      { label: 'Last Year' },
      { label: 'Custom' },
    ],

    BULK_STATUS: [
      { label: 'Pending' },
      { label: 'Approved' },
      { label: 'Rejected' },
    ],
  }
  public static DISPATCH_DRIVER_LIST_TAB_NAMES = {
    TOTAL: 'Total',
    AVAILABLE: 'Available',
    BUSY: 'Busy',
    IDLE: 'Idle'
  }


  public static BOOKING_STATUS_IDS = {
    NEW_REQUEST: '1',
    ADMIN_CONFIRMED: '2',
    DRIVER_ASSIGNED: '3',
    ACKNOWLEDGED: '4',
    RECONFIRMED: '5',
    DRIVER_ON_THE_WAY: '6',
    DRIVER_ARRIVED: '7',
    ON_TRIP: '8',
    COMPLETED: '9',
    EXPIRED: '10',
    CANCELLED: '11',
  }

  public static BOOKING_STATUS_CODES = {
    NEW_REQUEST: 'NEW_REQUEST',
    ADMIN_CONFIRMED: 'ADMIN_CONFIRMED',
    DRIVER_ASSIGNED: 'DRIVER_ASSIGNED',
    ACKNOWLEDGED: 'ACKNOWLEDGED',
    RECONFIRMED: 'RECONFIRMED',
    DRIVER_ON_THE_WAY: 'DRIVER_ON_THE_WAY',
    DRIVER_ARRIVED: 'DRIVER_ARRIVED',
    PASSENGER_CHECKIN: 'PASSENGER_CHECKIN',
    ON_TRIP: 'ON_TRIP',
    COMPLETED: 'COMPLETED',
    EXPIRED: 'EXPIRED',
    CANCELLED: 'CANCELLED',
  }

  public static BOOKING_STATUS_CODES_WHICH_DENOTES_DRIVER_IS_NOT_ASSIGNED_TO_BOOKING = [
    'NEW_REQUEST',
    'ADMIN_CONFIRMED'
  ]

  public static DISPATCH_DRIVER_LIST_FILTER_ATTRIBUTES = {
    BOOKING_STATUS: 'booking_status',
    DRIVER_ENTITY_ID: 'driver_entity_id',
    PICK_UP_DATE: 'pickup_date'
  }

  public static DELETED_TYPE = {
    BOTH: "BOTH",
    ONLY_DELETED: "ONLY_DELETED",
    ONLY_NON_DELETED: "ONLY_NON_DELETED"
  }

  public static SETTINGS_TABLE_HEADERS = {
    NAME: "Name",
    USAGE: "Usage",
    VEHICLE_TYPE: "Vehicle Type",
    VEHICLE_USAGE: "Vehicle Usage",
    COLOR_NAME: 'Color Name',
    PRIVILEGE_SETTINGS: 'Privilege Settings',
    PASSENGER: 'Passenger',
    REASON_FOR_OFF_DUTY: 'Reason For Off Duty',
  }

  public static CUSTOM_FIELD_VALIDATION =
    [
      { label: 'Alphabets Allowed', value: 'alphabetsAllowed' },
      { label: 'Numbers Allowed', value: 'numbersAllowed' },
      { label: 'Special Characters Allowed', value: 'specialCharactersAllowed' },
    ]

  public static LEAVE_REQUEST_OPTIONS = {
    PENDING: 'Pending',
    APPROVE: 'Approved',
    REJECT: 'Rejected'
  };

  public static DRIVER_APP_LOGIN_ATTRIBUTE_CODE = {
    SELECT_LOGIN_OPTION: 'select_login_option',
    ROLE_CODE: 'role_code',
    ROLE_CODE_DRIVER: 'Driver'
  };

  public static EVENT_STATE = {
    ONGOING: 'Ongoing',
    COMPLETED: 'Completed',
    UPCOMING: 'Upcoming',
  }

  public static ATTRIBUTE_CODE = {
    BOOKING_STATUS: 'booking_status',
    UPLOAD_DOCUMENT: 'upload_document',
  };

  public static OUTPUT_KEY = {
    NEW_REQUEST: 'newRequest',
    CRITICAL_WATCHLIST_DATA: 'criticalWatchListData',
    TOTAL_VEHICLE_DATA: 'totalVehicleData',
    VEHICLE_BY_BODY_TYPE: 'vehicleByBodyType',
    LIVE_JOURNEY_TYPE_DATA: 'liveJourneyTypeData',
    PASSENGER_BY_TYPE: 'passengerByType',
    VEHICLE_AVAILABILITY: 'vehiclesAvailabilities',
    CANCELLED_JOURNEY: 'cancelledJourney',
    BOOKING_JOURNEY_STATUS: 'bookingJourneyStatus',
    UPCOMING_JOURNEY: 'upcomingJourney',
    LIVE_STATUS: 'liveStatus',
  }

  public static META_OUTPUT_KEY = {
    SET_LANGUAGE: 'setLanguage',
    SET_COUNTRY: 'setCountry',
    MENU_LIST: 'menuList',
    MENUS: 'menus',
    SET_EVENT: 'setEvent',
    GENERAL_SETTINGS: 'generalSettings',
    ADVANCED_FILTER: 'advacneFilter',
    BULK_UPDATE: 'bulkUpdate',
    DEFAULT_TABLE_VIEW: 'defaultTableView',
    COUNT: 'count'
  }

  public static CUSTOM_FIELD_ALLOW_ACTION = {
    DEFAULT_FILTER_VIEW: 'DEFAULT_FILTER_VIEW',
    ADVANCED_FILTER_VIEW: 'ADVANCED_FILTER_VIEW',
    DEFAULT_TABLE_VIEW: 'DEFAULT_TABLE_VIEW'
  }

  public static CUSTOM_FIELD_VALIDATION_VALUES = {
    ALPHABETS: '^[a-zA-Z\\s]*$',
    NUMBERS: '^[0-9]+$',
    SPECIAL_CHARACTERS: `^[!@#$%^&*()_+\-=\[\]{}|;:',.<>?/~"\\]*$`,
    ALPHA_NUMERIC: '^[a-zA-Z0-9\\s]*$',
    ALPHA_SPECIAL: `^[a-zA-Z!@#$%^&*()_+\\-='\";:,.?/\\\\ ]*$`,
    NUMBER_SPECIAL: `^[0-9!@#$%^&*()_+\\-='\";:,.?/\\\\ ]*$`,
    ALPHA_NUMBER_SPECIAL: '^[a-zA-Z0-9!@#$%^&*()_+\\-=\[\]{}|;:",.<>?/~"\\\\ ]*$'
  }

  public static DAYS_OF_WEEK_ATTRIBUTE_ID = "days_of_the_week_available"

  public static STATIC_CONTENT = {
    TERMS_AND_CONDITION: 'TERM_AND_CONDITIONS',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
    CONTACT_SUPPORT: 'CONTACT_SUPPORT'
  }

  public static CUSTOM_FIELD_INPUT_TYPE_MAPPING = {
    select: 'STRING',
    inputString: 'STRING',
    inputInt: 'INTEGER',
    textArea: 'STRING',
    radioButton: 'STRING',
    checkBox: 'STRING',
    selectWithOther: 'STRING[]',
    checkBoxWithAddNew: 'STRING[]',
    incrementInt: 'INTEGER',
  }

  public static CUSTOM_FIELD_DATA_TYPES_ADVANCE_FILTER = ['Text', 'Integer', 'Check Box', 'Counter'];
  public static CUSTOM_FIELD_DATA_TYPES_SEARCH_FILTER = ['Integer', 'Counter'];
  public static CUSTOM_FIELD_CONTENT_OTHER_OPTION = ['Text', 'Integer', 'Counter'];
  public static PRIVILEGE_SETTINGS_RADIUS_IN_MILES_ATTRIBUTE_CODE = 'radius_in_miles';
}
