import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { AppSettings } from '../../../shared/app.settings';
import { BasicTableComponent } from '../../../shared/components/basic-table/basic-table.component';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { AttributeData, entityResponse } from '../../../vehicles/models/attribute.models';
import { EntityService } from '../../../shared/services/entity.service';

import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { forkJoin, from, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ConfigService } from '../../../shared/services/config.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { EntityList } from 'app/modules/vehicles/models/entity.models';
import { ListResponse } from 'app/modules/vehicles/models/listResponse.models';
import { EventService } from 'app/modules/events/services/event.service';

@Component({
  selector: 'app-add-passengers',
  templateUrl: './add-passengers.component.html',
  styleUrls: ['./add-passengers.component.scss'],
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, BasicTableComponent, ButtonModule, TranslateModule, AccessProviderDirective]
})
export class AddPassengersComponent implements OnInit {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  activeIndex: number = 0;
  data: AttributeData;
  passengerAttributeData: any;
  routePath: MenuItem[] = [];
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  passengerAttributeLength: number | undefined;
  nextBtnLabel: string | undefined;
  previousBtnLabel: string | undefined;
  attributeValues: any[] = [];
  attributeValuesPrivilege;
  privilegeSettingsTab: any = AppSettings.TABS.PRIVILEGE_SETTINGS;
  passengerAddressEntityCode: any;
  passengerEntityId: any;
  addressEntityIdList: any[] = [];
  passengerData: any;
  privilegeData: any;
  passengerEntityData: any;
  prefixForOneToManyRelatedFields: any = '_';
  savePassengerLabel: any;
  edit: boolean = false;
  passengerDetails: any;
  addressPresetValues = [];
  defaultLocationList = AppSettings.DEFAULT_ADDRESS_LIST;
  attributeIdForStayHomeAddress: any;
  disableSaveBtn: boolean = false;
  selectLocationAddressAttribute: any;
  selectLocationGeoLocationAttribute: any;
  defaultAddressAttributeId: any;
  relationshipDataForAddress: any[] = [];
  saveUpdateBtnLabel: string;
  showAddress: boolean = false;
  passengerType;
  passengerTypeId;
  privilegeId;
  isData: boolean = true;
  isPrivilegeEdit: boolean = false;
  addressArray: any[] = [];
  createEntityObsArray: any[] = [];
  saveAttributeObsArray: any[] = [];
  isFirstTabSaved: boolean = false;
  isEdit: boolean;
  unique : any[] = [];
  private valueChangesSubscription: Subscription[] = [];
  locationEntityIdAttribute: any;
  locationPlaceIdAttribute: any;
  passengerAddressFromEvent;
  entityPassengerData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: '',
    tableViewCode: AppSettings.VIEW_CODE.ADD_EDIT_VIEW,
  }

  constructor(public passengerService: EntityService,
    private router: Router,
    public cs: CommonBindingDataService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private configService: ConfigService,
    private eventService: EventService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.passengerEntityId = this.route.snapshot.paramMap.get('id');
    this.activeIndex = Number(this.route.snapshot.paramMap.get('index'));
    this.setLabels();
    this.setRoutePath();
    this.setAppSettings();
    this.loadAttributeData();
    this.cs.passengerType$.subscribe(type => {
      this.passengerType = type;
      if (this.passengerType && this.passengerTypeId) {
        this.searchEntity(this.passengerType, this.passengerTypeId);
      }
    });
  }

  searchEntity(passengerType, passengerTypeId) {
    this.entityPassengerData.countryCode = this.country[0].countryCode;
    this.entityPassengerData.filters = [{ attributeId: passengerTypeId, attributeValue: passengerType }];
    this.passengerService.searchEntity(AppSettings.ENTITY_CODE.PASS_PRIVILEGE_CONF, this.entityPassengerData).subscribe((res: ListResponse) => {
      if (res) {
        this.privilegeId = res.data[0].id;
        this.loadPrivilegeData();
      }
    });
  }

  private loadPrivilegeData() {
    if (this.privilegeId) {
      this.passengerService.getEntity(this.privilegeId, AppSettings.ENTITY_CODE.PASS_PRIVILEGE_CONF, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((res) => {
        if (res) {
          const data = res;
          this.attributeValuesPrivilege = this.cs.getOrgAttributeValues(data) as any;
          const radiusInMiles = this.cs.convertMetersToMiles(this.attributeValuesPrivilege.radius_in_miles);
          this.attributeValuesPrivilege.radius_in_miles = radiusInMiles;
          this.setPrivilegeAddress();
          this.isData = false;
          this.cd.detectChanges();
          this.isData = true;
          this.parsePrivilegeAttributeValues();
        }
      });
    } else {
      // this.getAttributes();
    }
  }

  private setLabels() {
    this.nextBtnLabel = this.cs.getLabel('lbl_next');
    this.previousBtnLabel = this.cs.getLabel('lbl_cancel');
    this.savePassengerLabel = this.passengerEntityId ? this.cs.getLabel('label_update') : this.cs.getLabel('label_save');
    this.saveUpdateBtnLabel = this.passengerEntityId ? this.cs.getLabel('label_update') : this.cs.getLabel('label_save');
    const href = this.router.url;
    this.isEdit = href.includes('edit');
  }

  private setRoutePath() {
    this.routePath = [
      {
        label: this.passengerEntityId ? this.cs.getLabel('passengers.edit_passengers_lbl') : this.cs.getLabel('passengers.add_passengers_lbl'),
        routerLink: '/app/passengers/list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px', 'color': '#495057' }
      },
      {
        label: this.activeIndex === 0 ? this.cs.getLabel('passengers.lbl_details') : this.cs.getLabel('passengers.lbl_privilege_settings'),
        routerLink: '../add',
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  private setAppSettings() {
    this.moduleName = AppSettings.ENTITY_TYPE.PASSENGER;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  private loadAttributeData() {
    forkJoin([
      this.passengerEntityId ? this.passengerService.getEntity(this.passengerEntityId, AppSettings.ENTITY_CODE.PASSENGER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW) : of(null)
    ]).pipe(
      tap(([entityRes]) => {
        if (entityRes) {
          const data = entityRes;
          this.passengerDetails = entityRes;
          this.attributeValues = this.cs.getOrgAttributeValues(data);
          const key = 'passenger_type';
          this.passengerType = this.attributeValues[key];
          const radiusInMilesValue = this.attributeValues['radius_in_miles'];
          const radiusInMiles = this.cs.convertMetersToMiles(radiusInMilesValue);
          this.attributeValues['radius_in_miles'] = radiusInMiles;
          this.parseAttributeValues();
          this.parseAttributeValueForDateOfBirth();
          this.getStayHomeAddress();
          this.setSeededPlayerTrue();
          this.setPrivilegeAddress();
          this.showAddress = true;
        } else {
          this.showAddress = true;
        }
        this.getAttributes(this.passengerType);
      })
    ).subscribe();
  }

  setPrivilegeAddress() {
    const addressAndLatLng = this.attributeValues[AppSettings.PASSENGER_PRIVILEGE_ATTRIBUTES.PASSENGER_PRIVILEGE_GEOLOCATION];
    const latLngArray = addressAndLatLng ? addressAndLatLng?.split(',') : 0;
    const geoLocationValue = {
      address: this.attributeValues[AppSettings.PASSENGER_PRIVILEGE_ATTRIBUTES.PASSENGER_PRIVILEGE_ADDRESS],
      lat: parseFloat(latLngArray[0]),
      lng: parseFloat(latLngArray[1])
    };
    this.attributeValues[AppSettings.PASSENGER_PRIVILEGE_ATTRIBUTES.PASSENGER_PRIVILEGE_ADDRESS] = geoLocationValue;
    this.attributeValues[AppSettings.PASSENGER_PRIVILEGE_ATTRIBUTES.PASSENGER_PRIVILEGE_GEOLOCATION] = geoLocationValue.address;
  }

  setSeededPlayerTrue() {
    this.attributeValues['seeded_player'] = this.attributeValues['seeded_player'] === 'true' ? true : false;
  }

  getAttributes(passengerType) {
    this.passengerService.getAttributeDefinition(AppSettings.ENTITY_TYPE.PASSENGER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.setPassengerView(res, passengerType);
      }
    });
  }

  setPassengerView(res, passengerType) {
    this.data = _.cloneDeep(res);
    this.passengerAddressEntityCode = this.data.relations.find(ele => ele.otherEntityCode === AppSettings.ENTITY_CODE.PASSENGER_ADDRESS).otherEntityCode;
    this.attributeLabels = this.cs.getAttributeLabels(this.data);
    this.passengerAttributeData = this.cs.getOrganizedAttribute(this.data);
    this.passengerAttributeLength = this.passengerAttributeData.tabs.length;
    this.passengerTypeId = this.cs.getAttributeId('passenger_type', this.data);
    this.getUniqueIdentificationByTenant();
    if (this.passengerEntityId) {
      this.attributeValuesPrivilege = this.attributeValues;
    } else {
      if (passengerType && this.passengerTypeId) {
        this.searchEntity(this.passengerType, this.passengerTypeId);
      }
    }
    this.setClassForTabPrivilegeSettings();
    this.attributeIdForStayHomeAddress = this.getAttributeIdForGroupCode(AppSettings.GROUP_CODE.STAY_HOME_ADDRESS);
    this.getLocationAddressAttribute();
    this.removeLocationAttributes();
  }

  getUniqueIdentificationByTenant() {
    this.passengerService.getUniqueIdentificationByTenant().subscribe((res: any) => {
      this.unique = [...new Set(res)];
      const matchedAttributeCodes = this.cs.getUniqueAttributeCodes(this.unique);
      matchedAttributeCodes.forEach(code => {
        const formControl = this.miFormComponent.formGroupFields[code];
        if (formControl) {
          const valueChangesSubscription = formControl.valueChanges.pipe(debounceTime(2000), distinctUntilChanged()).subscribe(value => {
            if (value) {
              this.getEntityDetailsByUniqueId(code, value);
            }
          });
          this.valueChangesSubscription.push(valueChangesSubscription);
        } else {
          console.warn(`FormControl for ${code} not found.`);
        }
      });
    });
  }

  getEntityDetailsByUniqueId(field, value) {
    this.attributeValues = [];
    const payload = {
      userRole: AppSettings.ENTITY_CODE.PASSENGER,
      field: '',
      value: value,
      eventCode: this.eventService.getSelectedEventcode(),
      forTenantCode: this.configService.getForTenantCode()
    }
    if (field.includes('unique_id')) {
      payload.field = 'uniqueId';
    }
    if (field.includes('email')) {
      payload.field = 'email';
    }
    if (field.includes('mobile_number')) {
      payload.field = 'contactNumber';
    }
    this.passengerService.getEntityDetailsByUniqueId(payload, AppSettings.ENTITY_CODE.PASSENGER).subscribe((res: any) => {
      if(res) {
        if (this.valueChangesSubscription) {
          this.valueChangesSubscription.forEach(subscription => subscription?.unsubscribe());
        }
        this.attributeValues = res[0].values;
        this.passengerAddressFromEvent = res[0].relatedData.filter((data) => data.entityCode === "passenger_address").map((data) => data.values);
        this.getStayHomeAddressFromOtherEvent();
        this.parseAttributeValues();
        for (const key in this.attributeValues) {
          if (Object.prototype.hasOwnProperty.call(this.attributeValues, key)) {
            const formControl = this.miFormComponent.formGroupFields[key];
            if (formControl) {
              formControl.setValue(this.attributeValues[key]);
            }
          }
        }
      }
    })
  }

  getLocationAddressAttribute() {
    this.passengerAttributeData.tabs.forEach(tab => {
      tab.groups.forEach(group => {
        if (group.code === AppSettings.GROUP_CODE.STAY_HOME_ADDRESS) {
          this.selectLocationAddressAttribute = group.fields.find(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS);
          this.selectLocationGeoLocationAttribute = group.fields.find(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_GEOLOCATION);
          this.locationEntityIdAttribute = group.fields.find(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.LOCATION_ENTITY_ID);
          this.locationPlaceIdAttribute = group.fields.find(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.LOCATION_PLACE_ID);
          this.defaultAddressAttributeId = group.fields.find(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.DEFAULT_ADDRESS)?.attributeId;
        }
      });
    });
    const stayHomeAddressGroup = this.passengerAttributeData.tabs[0].groups.find(ele => ele.code === AppSettings.GROUP_CODE.STAY_HOME_ADDRESS);
    const index = stayHomeAddressGroup.fields.findIndex(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS);
    stayHomeAddressGroup.fields.splice(index, 1);
  }

  removeLocationAttributes() {
    this.passengerAttributeData.tabs.forEach(tab => {
      tab.groups.forEach(group => {
        if (group.code === AppSettings.GROUP_CODE.STAY_HOME_ADDRESS) {
          const locationEntityIdIndex = group.fields.findIndex(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.LOCATION_ENTITY_ID);
          if (locationEntityIdIndex !== -1) {
            group.fields.splice(locationEntityIdIndex, 1);
          }

          const locationPlaceIdIndex = group.fields.findIndex(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.LOCATION_PLACE_ID);
          if (locationPlaceIdIndex !== -1) {
            group.fields.splice(locationPlaceIdIndex, 1);
          }
        }
      });
    });
  }

  private parsePrivilegeAttributeValues() {
    const dateAttributes = [
      AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_START_DATE, AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_END_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_START_DATE, AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_END_DATE
    ];
    dateAttributes.forEach(attr => {
      this.attributeValuesPrivilege[attr] = this.attributeValuesPrivilege[attr] ? new Date(this.attributeValuesPrivilege[attr]) : null;
    });
  }

  private parseAttributeValues() {
    const dateAttributes = [AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_START_DATE,
    AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_END_DATE,
    AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_START_DATE,
    AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_END_DATE];
    dateAttributes.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
    });

    const addressIdArray = this.passengerDetails?.attributeCodeValueDtoList?.find(ele => ele.attributeCode === AppSettings.FIELDS.STAY_HOME_ADDRESS_SECTION);
    this.addressEntityIdList = (addressIdArray && addressIdArray.attributeValue.length > 0) ? addressIdArray.attributeValue : [];
  }

  private parseAttributeValueForDateOfBirth() {
    const dateAttributes = [AppSettings.DATE_ATTRIBUTE_IDS.PASSENGER_DATE_OF_BIRTH];
    dateAttributes.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
    });
  }

  getStayHomeAddressFromOtherEvent() {
    if (this.passengerAddressFromEvent.length > 0) {
      this.defaultLocationList = [];
      this.passengerAddressFromEvent.forEach((data, index) => {
        this.defaultLocationList.push({ labelKey: data.select_location_type, labelValue: data.select_location_type, entityId: null, index: index });
        for (const [key, value] of Object.entries(data)) {
          this.attributeValues[index + '_' + key] = value ? value : null;
        }
        if (index === this.passengerAddressFromEvent.length - 1) {
          this.showAddress = false;
          this.showAddress = true;
        }
      });
    }
  }

  getStayHomeAddress() {
    if (this.passengerDetails.relatedData.length > 0) {
      this.defaultLocationList = [];
      let passengerAddress = this.passengerDetails.relatedData.filter(ele => ele.entityCode ===  AppSettings.ENTITY_CODE.PASSENGER_ADDRESS);
      passengerAddress.forEach((data, index) => {
        if (data.entityCode === AppSettings.ENTITY_CODE.PASSENGER_ADDRESS) {
          const field = data.attributeCodeValueDtoList.find(element => element.attributeCode === AppSettings.FIELDS.SELECT_LOCATION_TYPE);
          const label = field ? field.attributeValue : '';
          this.defaultLocationList.push({ labelKey: label, labelValue: label, entityId: data.entityId, index: index });
          this.setAttributeValuesForStayHomeAddressEditMode(data, index);
        }
        if (index === passengerAddress.length - 1) {
          this.setDefaultAddressIfNotPresent();
        }
      });
    }
  }

  setDefaultAddressIfNotPresent() {
    let defaultAddressLabels = AppSettings.DEFAULT_ADDRESS_LABELS;
    defaultAddressLabels.forEach(element => {
      let defaultAddress = this.defaultLocationList.find(ele => ele.labelKey === element);
      if (!defaultAddress) {
        this.defaultLocationList.push({ labelKey: element, labelValue: element, entityId: null, index: this.defaultLocationList.length });
      }
    });
  }

  setAttributeValuesForStayHomeAddressEditMode(data, addressCount) {
    for (const labelsObj of data.attributeCodeValueDtoList) {
      this.attributeValues[addressCount + '_' + labelsObj.attributeCode] = labelsObj.attributeValue ? labelsObj.attributeValue : null;
    }
  }

  setClassForTabPrivilegeSettings() {
    if (!this.passengerAttributeData || !this.passengerAttributeData.tabs) {
      return;
    }
    const privilegeTab = this.passengerAttributeData.tabs.find(tab => tab.tabCode === 'privilege_settings');
    if (!privilegeTab || !privilegeTab.groups) {
      return;
    }
    privilegeTab.groups.forEach(group => {
      group.activeStatus = (group.code === 'app_access') ? 'active' : 'inactive';
      group.activeLabel = (group.code === 'app_access') ? 'active' : 'inactive';
    });
  }

  scrollToGroup(event, groupCode) {
    if (event === 1) {
      document.getElementById('scrollable').scrollTo(0, 150);
    } else if (event === 0) {
      document.getElementById('scrollable').scrollTo(0, 0);
    }
    document.getElementById(event).scrollIntoView();
    this.setActiveClassForGroupPrivilegeSettings(groupCode);
  }

  setActiveClassForGroupPrivilegeSettings(groupCode) {
    const privilegeTab = this.passengerAttributeData.tabs.find(tab => tab.tabCode === 'privilege_settings');
    if (!privilegeTab || !privilegeTab.groups) {
      return;
    }

    privilegeTab.groups.forEach(group => {
      group.activeStatus = (group.code === groupCode) ? 'active' : 'inactive';
      group.activeLabel = (group.code === groupCode) ? 'active' : 'inactive';
    });
  }

  onSavePrivilegeSettings(event) {
    this.disableSaveBtn = true;
    setTimeout(() => {
      this.disableSaveBtn = false;
    }, 500);

    if (this.passengerEntityId) {
      this.privilegeData = {
          forTenantCode: this.configService.getForTenantCode(),
          entityCode: this.data.entityCode,
          countryCode: this.country[0].countryCode,
          languageCode: this.language[0].langCode,
          data: []
      };

      const dateIds = [
          AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_START_DATE,
          AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_END_DATE,
          AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_START_DATE,
          AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_END_DATE
      ];

      for (const [key, newValue] of Object.entries(event)) {
        const oldValue = this.attributeValuesPrivilege[key];
        if (newValue !== oldValue) {
          let attributeValue = <any>newValue;
          if (dateIds.includes(key)) {
              attributeValue = new Date(<string>newValue).getTime();
          }
          if (key === AppSettings.PRIVILEGE_SETTINGS_RADIUS_IN_MILES_ATTRIBUTE_CODE) {
              attributeValue = this.cs.convertMilesToMeters(attributeValue);
          }
          this.setAttributeValuesForPrivilegeSetting(key, attributeValue);
        }
      }
      this.privilegeData.data = this.cs.mapAttributeIds(this.privilegeData.data, this.passengerAttributeData.tabs);
      if(this.privilegeData.data && this.privilegeData.data.length === 0) {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', 
          detail: this.passengerEntityId ? this.cs.getLabel('passengers.privilege_data_updated_successfully') : this.cs.getLabel('passengers.privilege_data_saved_successfully') });
        this.router.navigate(['app/passengers']);
      }
      else {
        this.savePrivilegeDataToDb();
      }
    } else {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('passengers.please_fill_mandatory_data_in_details_tab') });
        this.activeIndex = 0;
        this.nextBtnLabel = this.isLastIndex() ? this.saveUpdateBtnLabel : this.cs.getLabel('lbl_next');
    }
  }

  setAttributeValuesForPrivilegeSetting(key, attributeValue) {
    if (attributeValue) {
      this.privilegeData.data.push({
        attributeCode: key,
        attributeValue
      });
    }
  }

  savePrivilegeDataToDb() {
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: this.moduleName
    }
    if (this.passengerEntityId) {
      this.saveAttributeData(entitiesData.entityCode)
    } else {
      this.createEntityAndUpdateAttributeData(entitiesData);
    }
  }

  saveAttributeData(entityCode) {
    this.passengerService.saveAttributeData(entityCode, this.passengerEntityId, this.privilegeData).subscribe((res: any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('passengers.privilege_data_updated_successfully') });
      setTimeout(() => {
        this.router.navigate(['app/passengers']);
      }, 300);
    });
  }

  createEntityAndUpdateAttributeData(entitiesData) {
    this.passengerService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.passengerEntityId = res.entityId;
      this.passengerService.saveAttributeData(entitiesData.entityCode, this.passengerEntityId, this.privilegeData).subscribe((res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('passengers.privilege_data_saved_successfully') });
        setTimeout(() => {
          this.router.navigate(['app/passengers']);
        }, 300);
      });
    });
  }

  onSavePassenger(event) {
    this.addressArray = [];
    this.disableSaveBtn = true;

    if (!this.country || !this.language) {
      console.error('Data, country, or language not initialized.');
      return;
    }

    this.passengerData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.moduleName,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: []
    };
    const passengerFormData = event;
    for (let k = 0; k < this.defaultLocationList.length; k++) {
      passengerFormData[`${k}_${AppSettings.ADDRESS_FIELDS.SELECT_LOCATION_TYPE}`] = this.defaultLocationList[k].labelKey
    }
    const dateIds = [AppSettings.DATE_ATTRIBUTE_IDS.PASSENGER_DATE_OF_BIRTH];
    for (const [key, value] of Object.entries(passengerFormData)) {
      let attributeValue = value;
      if (dateIds.includes(key)) {
        attributeValue = new Date(<string>value).getTime();
      }
      const arr = key.split('_');
      if (parseInt(arr[0]) >= 0) {
        this.setAttributeValuesForStayHomeAddress(key, attributeValue);
      } else {
        this.setPassengerData(key, attributeValue);
      }
    }

    const tempAddressArray = [];
    this.addressArray.forEach((address, index) => {
      this.addressArray[index] = this.cs.mapAttributeIdsForRelatedData(address, this.passengerAttributeData.tabs);
      address.forEach(element => {
        if (element.attributeId === this.selectLocationAddressAttribute?.attributeId && element.attributeValue) {
          tempAddressArray.push(this.addressArray[index]);
        }
      });
    });

    this.addressArray = tempAddressArray;
    this.passengerData.data = this.cs.mapAttributeIds(this.passengerData.data, this.passengerAttributeData.tabs);
    this.setPassengerEntityData(this.moduleName);
    if (this.addressArray.length > 0) {
      this.saveStayHomeAddress();
    } else {
      this.savePassengerToDb(AppSettings.ENTITY_CODE.PASSENGER);
    }
   
    this.isFirstTabSaved = true;
  }

  setAttributeValuesForStayHomeAddress(key, attributeValue) {
    const arr = key.split('_');
    if (typeof this.addressArray[arr[0]] === 'undefined') {
      this.addressArray[arr[0]] = [];
    }

    const keyId = key.substring(key.indexOf('_') + 1);
    if (!AppSettings.SPACERS_ATTRIBUTE_CODES_FOR_STAY_HOME_ADDRESS.includes(keyId)) {
      if (keyId === AppSettings.PASSENGER_ATTRIBUTE_CODE.SELECT_LOCATION_GEOLOCATION) {
        this.setStayHomeAddress(keyId, attributeValue?.geoLocation, arr[0]);
        this.addressArray[arr[0]].push({
          attributeId: this.selectLocationAddressAttribute?.attributeId,
          attributeValue: attributeValue?.address
        });
        if (attributeValue?.locationEntityId) {
          this.addressArray[arr[0]].push({
            attributeId: this.locationEntityIdAttribute?.attributeId,
            attributeValue: attributeValue?.locationEntityId
          });
        }

        if (attributeValue?.placeId) {
          this.addressArray[arr[0]].push({
            attributeId: this.locationPlaceIdAttribute?.attributeId,
            attributeValue: attributeValue?.placeId
          });
        }
      } else {
        this.setStayHomeAddress(keyId, attributeValue, arr[0]);
      }
    }
  }

  setStayHomeAddress(keyId, attributeValue, index) {
    this.addressArray[index].push({
      attributeCode: keyId,
      attributeValue: keyId === AppSettings.FIELDS.INSTRUCTIONS ? (attributeValue ? attributeValue : '   ') : (attributeValue ? attributeValue : '')
    })
  }

  setPassengerData(key, attributeValue) {
    if (!AppSettings.SPACERS_ATTRIBUTE_CODES_FOR_PASSENGER_DETAILS.includes(key)) {
      if (key === 'seeded_player') {
        if (attributeValue) {
          this.passengerData.data.push({
            attributeCode: key,
            attributeValue: attributeValue.toString()
          });
        }
      } else if (key === 'seed_number') {
        if (attributeValue) {
          this.passengerData.data.push({
            attributeCode: key,
            attributeValue: Number(attributeValue)
          });
        }
      } else {
        if (attributeValue) {
          this.passengerData.data.push({
            attributeCode: key,
            attributeValue
          });
        }
      }
    }
  }

  setPassengerEntityData(entityCode) {
    this.passengerEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: entityCode
    }
  }

  saveStayHomeAddress() {
    this.relationshipDataForAddress = [];
    const newAddresssList: any[] = [];
    const addressEntitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: this.passengerAddressEntityCode
    }
    this.createEntityObsArray = [];
    this.saveAttributeObsArray = [];
    this.addressArray.forEach((address, index) => {
      const addressEntity = {
        forTenantCode: this.configService.getForTenantCode(),
        entityCode: this.passengerAddressEntityCode,
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        data: address
      };

      const defaultAddressIndex = addressEntity.data.findIndex(ele => (ele.attributeId === this.defaultAddressAttributeId && (ele.attributeValue === '' || ele.attributeValue === false)));
      if (defaultAddressIndex !== -1) {
        addressEntity.data.splice(defaultAddressIndex, 1);
      }
      if (this.addressEntityIdList[index]) {
        this.saveAttributeObsArray.push(this.passengerService.saveAttributeData(addressEntitiesData.entityCode, this.addressEntityIdList[index], addressEntity));
        this.relationshipDataForAddress.push(
          {
            entityRelationshipConfigId: this.passengerAttributeData.tabs[0].groups.find(ele => ele.code === 'stay_home_address').relation.entityRelationshipConfigId,
            otherEntityId: this.addressEntityIdList[index]
          }
        );
      } else {
        newAddresssList.push(addressEntity);
        this.createEntityObsArray.push(this.passengerService.createEntities(addressEntitiesData.entityCode, addressEntitiesData));
      }
      if (index === this.addressArray.length - 1) {
        this.createAndSaveAddress(addressEntitiesData, newAddresssList);
      }
    });

  }

  createAndSaveAddress(addressEntitiesData, newAddresssList) {
    if (this.createEntityObsArray.length > 0) {
      forkJoin(this.createEntityObsArray).subscribe(result => {
        if (result && result.length > 0) {
          result.forEach((createEntityResponse: any, index) => {
            this.addressEntityIdList.push(createEntityResponse.entityId);
            this.saveAttributeObsArray.push(this.passengerService.saveAttributeData(addressEntitiesData.entityCode, createEntityResponse.entityId, newAddresssList[index]));
            this.relationshipDataForAddress.push(
              {
                entityRelationshipConfigId: this.passengerAttributeData.tabs[0].groups.find(ele => ele.code === 'stay_home_address').relation.entityRelationshipConfigId,
                otherEntityId: createEntityResponse.entityId
              }
            );
          });
          this.saveAddressAttributes();
        }
  
      })
    } else {
      this.saveAddressAttributes();
    }
  }

  saveAddressAttributes() {
    forkJoin(this.saveAttributeObsArray).subscribe(data => {
      this.passengerData.data.push({
        attributeId: this.attributeIdForStayHomeAddress,
        attributeValue: this.addressEntityIdList
      });
      this.savePassengerToDb(this.passengerEntityData.entityCode)
    });
  }

  getAttributeIdForGroupCode(groupCode: string): string | undefined {
    const group = _.flatMap(this.passengerAttributeData.tabs, 'groups').find(group => group.code === groupCode);
    return group ? group.relation.ownerAttributeId : undefined;
  }

  savePassengerToDb(entityCode) {
    this.passengerData.relationshipData = this.relationshipDataForAddress;
    this.passengerData.data = this.cs.mapAttributeIds(this.passengerData.data, this.passengerAttributeData.tabs);
    if (this.passengerEntityId) {
      this.savePassengerApiCall(entityCode);
    } else {
      this.createPassengerEntity();
    }
  }

  savePassengerApiCall(entityCode) {
    this.passengerService.saveAttributeData(entityCode, this.passengerEntityId, this.passengerData).subscribe(() => {
      if(this.isEdit){
        this.cs.onDisplayMessage(this.data, 'passenger', AppSettings.MSG_ACTION.UPDATE, 'success');

      } else{
        this.cs.onDisplayMessage(this.data, 'passenger', AppSettings.MSG_ACTION.ADD, 'success');
      }
      
      this.disableSaveBtn = false;
    },
      (error) => {
        this.activeIndex--;
        this.disableSaveBtn = false;
        this.updateNextBtnLabel();
        this.displayErrors(error);

      })
  }

  displayErrors(error) {
    for (const [key, value] of Object.entries(error.errors)) {
      let messageObject: any = value;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(this.attributeLabels[messageObject[0]?.messageCode]) });
    }
  }

  createPassengerEntity() {
    this.passengerService.createEntities(this.passengerEntityData.entityCode, this.passengerEntityData).subscribe({
      next: (res: entityResponse) => {
        this.passengerEntityId = res.entityId;
        this.savePassengerApiCall(this.passengerEntityData.entityCode);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  onCancel() {
    this.isFirstTabSaved = false;
    this.miFormComponent.resetForm();
    this.router.navigate(['app/passengers']);
  }

  onNextBtnClick() {
    const btn = document.getElementById(this.activeIndex === 0 ? 'details' : 'privilegeSettings');
    btn.click();
  }

  onPreviousClick() {
    this.router.navigate(['app/passengers']);
  }

  onNextClick() {
    if (this.activeIndex < this.getLastIndex()) {
      this.activeIndex++;
    }
    this.updateNextBtnLabel();
  }

  updateNextBtnLabel() {
    this.nextBtnLabel = this.isLastIndex() ? this.saveUpdateBtnLabel : this.cs.getLabel('lbl_next');
  }

  onTabViewChange(event: any) {
    if (event.index === 0) {
      this.isFirstTabSaved = false;
    }
    if (!this.isFirstTabSaved && event.index > 0) {
      this.activeIndex = 0;
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please save details in first tab before proceeding'
      });
      return;
    }
    this.setRoutePath();
    this.nextBtnLabel = this.isLastIndex() ? this.saveUpdateBtnLabel : this.cs.getLabel('lbl_next');
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  getLastIndex(): number {
    return this.passengerAttributeLength - 1;
  }
}
