import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { AppIcons } from '../shared/app.icons';
import { AppSettings } from '../shared/app.settings';
import { MITableComponent } from '../shared/components/mi-table/mi-table.component';
import { ModuleHeaderComponent } from '../shared/components/module-header/module-header.component';
import { Country } from '../shared/models/country';
import { Language } from '../shared/models/language';
import { CommonBindingDataService } from '../shared/services/common-binding-data.service';
import { ConfigService } from '../shared/services/config.service';
import { EntityService } from '../shared/services/entity.service';
import type { Column } from '../vehicles/models/attribute.models';
import type { EntityCount, EntityCountsResponse } from '../vehicles/models/entity.models';
import type { Values } from '../vehicles/models/listResponse.models';
import { EventService } from './services/event.service';
import { AccessProviderDirective } from '../shared/directives/access-provider.directive';
import { PermissionAccessService } from '../shared/services/permission-access.service';

@Component({
  selector: 'app-events',
  standalone: true,
  imports: [BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, MITableComponent, MITableComponent, AsyncPipe, AccessProviderDirective],
  templateUrl: './events.component.html',
  styleUrl: './events.component.scss'
})

export class EventsComponent implements OnInit {
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLElement>;
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  activeIndex: number = 0;
  isLoading = false;
  filterAvailableColumns = [];
  cols = [];
  @Input() moduleName: string;
  filterList;
  value: number = 50;
  btnLabel: string;
  items: MenuItem[] | undefined;
  isShowMenu: boolean = true;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  buttons: any[] = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    status: 'Both',
    startDate: 1704067200000,
    endDate: 0
  };
  routePath: MenuItem[] = [];
  home: MenuItem = {};
  isModal: boolean = false;
  selectedColumns!: Column[];
  deleteEventMsg: string;
  deleteVehicleHeaderMsg: string;
  showMenu: boolean = false;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  filterFieldsArray: any[];
  attributeData: any;
  data;
  totalRecords: number = 0;
  btnUpdate: any;
  deletePassengerMsg: string;
  bulkSelectionsItems: MenuItem[] | undefined;
  bulkDeleteLabel: string = this.cs.getLabel('events.delete');
  rowItems: MenuItem[] | undefined;
  currentRow: any;
  showImportExportBtn: boolean = false;
  fieldForState: any;
  entityName = AppSettings.ENTITY_TYPE.EVENTS;
  isEntity: boolean = false;
  uniqueId: string = 'event-grid';
  showTable: boolean = true;
  labelData = {
    attributeLabels: [
      { labelKey: 'event.fields.date.label', labelValue: 'Date' },
    ]
  };
  eventId: any;

  constructor(private entityService: EntityService,
    private eventService: EventService,
    private cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private router: Router, private messageService: MessageService, private actRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private configService: ConfigService, private permissionAccessService: PermissionAccessService) { }

  ngOnInit() {
    this.routePath = [
      { label: "Events", routerLink: 'events/list' },
    ];
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.setBulkSelectionOptions();
    this.setRowSelectionOptions();
    this.getData();
    this.setHeadersAndLabels();
    this.initializeColumn();
    this.getFilterView();
  }

  
  initializeColumn() {
    this.allColumns = [
      {
        attributeCode: "event_name",
        attributeValue: 'Event',
        attributeIndex: 0,
        field: "eventName",
        header: this.cs.getLabel('events.columns.name')
      },
      {
        attributeCode: "event_short_code",
        attributeValue: 'Code',
        attributeIndex: 1,
        field: "eventShortcode",
        header: this.cs.getLabel('events.columns.code')
      },
      {
        attributeCode: "event_start_date",
        attributeValue: 'Email Address',
        attributeIndex: 2,
        field: "eventStartDateTime",
        header: this.cs.getLabel('events.columns.start_date')
      },
      {
        attributeCode: "event_end_date",
        attributeValue: 'status',
        attributeIndex: 2,
        field: "eventEndDateTime",
        header: this.cs.getLabel('events.columns.end_date')
      },
      {
        attributeCode: "event_state",
        attributeValue: 'Event State',
        attributeIndex: 3,
        field: "eventState",
        header: this.cs.getLabel('events.columns.state')
      },
      {
        attributeCode: "event_status",
        attributeValue: 'Event Status',
        attributeIndex: 3,
        field: "eventStatusStr",
        header: this.cs.getLabel('events.columns.status')
      }
    ];
  }

  setHeadersAndLabels() {
    this.btnUpdate = this.cs.getLabel('events.sidebar_lbl_events');
    this.btnLabel = this.cs.getLabel('events.add_event');
    this.deleteEventMsg = this.cs.getLabel('events.message.confirm_delete_msg');
    this.deleteVehicleHeaderMsg = this.cs.getLabel('events.message.confirm_delete_header');
    this.btnLabel = this.cs.getLabel('add_event');
  }

  setBulkSelectionOptions() {
    this.bulkSelectionsItems = [
      {
        label: this.bulkDeleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: () => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.BULK);
        },
      },
    ];
  }

  setRowSelectionOptions() {
    const isEdit = this.permissionAccessService.hasAccess('EVENTS', '', 'ALL_EVENTS', 'EDIT');
    this.rowItems = [
      ...(isEdit ? [{
        label: this.cs.getLabel('events.lbl_edit'),
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.router.navigate(['app/events/edit/', this.currentRow.eventId, 0, 'details']);
        }
      }] : []),
    ];
  }

  setCurrentRowData(event) {
    this.currentRow = event;
    this.setEditOptionDisabledForDeletedRecord(event);
  }

  setEditOptionDisabledForDeletedRecord(data) {
    this.rowItems[0].disabled = data.deleted;
    this.cd.detectChanges();
  }

  getAttributeData() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.EVENTS, AppSettings.VIEW_CODE.BULK_UPDATE_VIEW).subscribe(res => {
      if (res) {
        this.attributeData = res;
        this.isLoading = false;
        this.filterFieldsArray = res.tabs[0].groups[0].fields;
        this.filterFieldsArray.forEach(element => {
          switch (element.attributeCode) {
            case 'event_status':
              element.attributeValue = 'Status';
              element.isDisable = false;
              break;
          }
        });
      }
    });
  }

  getFilterView() {
    this.filterAttributeLabels = this.cs.getAttributeLabels(this.labelData);
    this.filterList = [
      {
        attributeCode: "date",
        inputCode: 'dateRange',
        presetValues: [],
        validation: {}
      }
    ];
  }


  getData() {
    this.searchEntity();
  }


  searchEntity() {
    this.entityData.countryCode = this.country[0].countryCode;
    this.eventService.getEventList(this.entityData).subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.pagination = res?.pagination;
        this.totalRecords = res?.count;
        this.tableData = [];
        this.tableData = res.data;
        this.setStateForEvent(res);
        this.tableData.forEach(event => {
          if (event?.eventLogoImgUrl) {
            event.profile = event.eventLogoImgUrl;
          }
        });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }

    })
  }

  setStateForEvent(res) {
    const currentTime = new Date().getTime();
    res?.data.forEach((element: any) => {
      if (element?.values?.event_start_date < currentTime && element?.values?.event_end_date > currentTime) {
        element.values.State = AppSettings.EVENT_ATTRIBUTES.STATE.ONGOING;
      } else if (element?.values?.event_start_date > currentTime) {
        element.values.State = AppSettings.EVENT_ATTRIBUTES.STATE.UPCOMING;
      } else if (element?.values?.event_start_date < currentTime && element?.values?.event_end_date < currentTime) {
        element.values.State = AppSettings.EVENT_ATTRIBUTES.STATE.COMPLETED;
      }
    });
  }

  onAddEvent() {
    this.router.navigate(['app/events/add']);
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.entityData.offset = 0;
    this.searchEntity();
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.EVENTS,
      entityIds: event,
      countryCode: this.country[0].countryCode,
    };
    this.entityService.deleteEntityActionStatus(requestBody).subscribe(() => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('events.lbl_event_deleted_successfully'), });
      this.searchEntity();
    })
  }

  onEventStatusChangeEvent(event) {
    const statusChangeConfirmMsg = event.eventStatusStr === AppSettings.EVENT_STATUS.ACTIVE ? this.cs.getLabel('events.lbl_make_event_inactive_msg') : this.cs.getLabel('events.lbl_make_event_active_msg')
    this.confirmationService.confirm({
      header: event.eventStatusStr === AppSettings.EVENT_STATUS.ACTIVE ? this.cs.getLabel('events.lbl_make_event_inactive') : this.cs.getLabel('events.lbl_make_event_active'),
      message: statusChangeConfirmMsg,
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: this.cs.getLabel('events.lbl_yes'),
      rejectLabel: this.cs.getLabel('events.lbl_no'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: () => {
        this.updateEventStatus(event );
      },
      reject: () => {
        this.searchEntity();
      }
    });
  }

  updateEventStatus(event) {
    const data = {
      active: event.eventStatusStr === AppSettings.EVENT_STATUS.ACTIVE  ? false : true,
      forTenantCode: this.configService.getForTenantCode()
    }
    this.eventService.updateEventStatus(event.eventId, data).subscribe({
      next: () => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('events.lbl_event_status_updated_successfully') });
        window.location.reload();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
        this.searchEntity();
      }
    });
  }

  onUpdateEvent(eventId) {
    this.router.navigate(['app/events/add/' + eventId]);
  }

  onRowSelect(event) {
    if (event.type === 'row') {
      this.eventId = event.data.eventId;
      this.router.navigate(['app/events/details/' + event.data.eventId,]);
    }
  }

  onFilterValueChange(event) {
    this.entityData.startDate = (event.date && event.date[0]) ? event.date[0].getTime() : 1704067200000;
    this.entityData.endDate = (event.date && event.date[1]) ? event.date[1].getTime() : null;
    this.searchEntity();
  }


  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  getEntityCount() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityCountPayload = <EntityCount>{
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.EVENTS,
      countryCode: country[0].countryCode,
    };

    this.entityService.getEntityCount(AppSettings.ENTITY_CODE.EVENTS, entityCountPayload).subscribe((res: EntityCountsResponse) => {
      this.entityCount = res;
    })
  }

  tabViewChange(event) {
    switch (event.index) {
      case 0:
        this.entityData.actionStatus = '';
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.entityData.filters = [];
        this.searchEntity();
        break;
      case 1:
        this.entityData.actionStatus = AppSettings.VEHICLE_TAB_LIST.DELETE;
        this.searchEntity();
        break;
      default:
        break;
    }
  }

  onBulkDataUpdateEvent() {
    this.searchEntity();
  }
}
