import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { DashboardNavbarComponent } from "../dashboard-navbar/dashboard-navbar.component";
import { ToggleButtonModule } from "primeng/togglebutton";
import { AppSettings } from "../../../shared/app.settings";
import { FormsModule } from "@angular/forms";
import { AccessProviderDirective } from "app/modules/shared/directives/access-provider.directive";
import { DashboardService } from "../dashboard/dashboard.service";
import { ConfigService } from "app/modules/shared/services/config.service";
import { EventService } from "app/modules/events/services/event.service";
import { EntityService } from "app/modules/shared/services/entity.service";
import { TranslateModule } from "@ngx-translate/core";
import { NgClass } from "@angular/common";
import { CommonBindingDataService } from "app/modules/shared/services/common-binding-data.service";
import { MultiSelect, MultiSelectModule } from "primeng/multiselect";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { Country } from "app/modules/shared/models/country";
import { SidebarModule } from "primeng/sidebar";
import { AppIcons } from "app/modules/shared/app.icons";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { Language } from "app/modules/shared/models/language";
import { ConfirmationService, MessageService } from "primeng/api";
import { entityResponse } from "app/modules/vehicles/models/attribute.models";
import { MapsModal } from "./maps.modal";
import { GoogleMapsService } from "app/modules/shared/services/google-map.services";

@Component({
  selector: "app-maps",
  templateUrl: "./maps.component.html",
  styleUrls: ["./maps.component.scss"],
  standalone: true,
  imports: [DashboardNavbarComponent, ToggleButtonModule, ButtonModule, InputTextModule, FormsModule, 
    AccessProviderDirective, TranslateModule, NgClass, MultiSelectModule, SidebarModule, DialogModule, 
    DropdownModule,],
})
export class MapsComponent implements OnInit {
  @ViewChild('multiSelect') multiSelect!: MultiSelect;
  miIcons = AppIcons;
  map: google.maps.Map;
  checked: boolean = false;
  infoWindow: google.maps.InfoWindow;
  marker: any = null;
  markers: any = [];
  records: any = [];
  vehicleTypeList;
  queueLocationList;
  queueLocations;
  searchValue;
  searchDriver;
  filterList;  
  locationFilters;
  bookingCode;
  categoryAttributeIdInLocationFilter;
  filterIcon = 'pi pi-search';
  selectedQueueLocation: any[];
  selectedVehicleType: any[];
  uniqueId: string = 'dashboard_map';
  country: Country;
  language: Language;
  rightDriversSectionVisible: boolean = false;
  rightDialogPosition: string = "center";
  position: string;
  availableDriverList;
  filteredDriverList;
  sidebarDriverList;
  refreshTime;
  lastRefreshTime;
  addToQueueBtn = this.cs.getLabel('lbl_add_queue');
  assignTripBtn = this.cs.getLabel('lbl_assign_trip');
  queueDialogHeader = this.cs.getLabel('lbl_add_queue');
  tripDialogHeader = this.cs.getLabel('lbl_assign_trip');
  isVisibleQueue: boolean = false;
  isVisibleTrip: boolean = false;
  driverIdForQueue;
  driverIdForTrip;
  queueAttributeData;
  driverAttributeId;
  locationAttributeId;
  selectedQueue;
  queueId;
  queueData;
  addToQueueList;
  deleteQueueMsg = this.cs.getLabel('queue.message.confirm_remove_msg');
  deleteQueueHeaderMsg = this.cs.getLabel('queue.message.confirm_remove_header');
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  entityData = {
    forTenantCode: this.configService.getForTenantCode(),
    eventCode: this.eventService.getSelectedEventcode(),
    vehicleBodyTypes: [],
    queueIds: [],
    searchStr: ''
  }
  entityDataForVehicleType: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: this.configService.getForTenantCode(),
    attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.BODY_TYPE
  };
  entityDataForQueueLocation = {
    limit: 2000,
    offset: 0,
    searchStr: '',
    filters: [],
    forTenantCode: this.configService.getForTenantCode(),
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    countryCode: ''
  }

  constructor(private dashboardService: DashboardService, private configService: ConfigService,
    private eventService: EventService, private entityService: EntityService, 
    private cs: CommonBindingDataService, private cd: ChangeDetectorRef,
    private messageService: MessageService, private confirmationService: ConfirmationService,
    private googleMapsService: GoogleMapsService
  ) { }

  ngOnInit() {
    this.initMap();
  }

  private setLabelsAndTime() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const lastSync = new Date();
    this.refreshTime = lastSync.toLocaleTimeString();
  }

  async initMap(): Promise<void> {
    const { Map, InfoWindow } = (await google.maps.importLibrary(
      "maps"
    )) as google.maps.MapsLibrary;

    this.map = new Map(document.getElementById("map") as HTMLElement, {
      center: { lat: 18.50932924799519, lng: 74.32022387996037 },
      zoom: 10,
      zoomControl: true,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      mapId:this.googleMapsService.mapId,
    });
    this.infoWindow = new InfoWindow();
    await this.loadMapData();
  }

  loadMapData() {
    this.setLabelsAndTime();
    this.listAvailableDrivers();
    this.searchVehicleTypes();
    this.getFilterViewForLocation();
    this.getAttributes();
    this.setupControls();
    this.addMarkers();
  }

  setupControls() {
    const toggleControl = document.getElementById("style-selector-control");
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      toggleControl
    );

    const geolocationControl = document.getElementById("my-location-control");
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      geolocationControl
    );
  }

  async addMarkers() {
    this.dashboardService.mapAvailableDriverList(this.entityData).subscribe(async (res: MapsModal) => {
      const iconBase = "/assets/images/mi-theme/light/map/";
      const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;
      const icons: Record<string, { icon: string }> = {
        primaryColor: {
          icon: iconBase + "blue-pin.svg",
        },
      };
  
      let currentInfoWindow: google.maps.InfoWindow | null = null;
      res.forEach((driver) => {
        if (!driver?.driverCurrentLocation) {
          return false;
        }
        const location = driver?.driverCurrentLocation.split(",");
        const lat = parseFloat(location[0]);
        const lng = parseFloat(location[1]);
        const iconImage = document.createElement("img");
        iconImage.src = icons.primaryColor.icon;
  
        const marker = new AdvancedMarkerElement({
          map: this.map,
          position: new google.maps.LatLng(lat, lng),
          content: iconImage,
          title: driver.driverName,
        });
        const infoWindow = new google.maps.InfoWindow({
          content: this.createInfoWindowContent(driver),
          maxWidth: 400,
          minWidth: 400,
        });

        marker.addListener("click", () => {
          if (currentInfoWindow) {
            currentInfoWindow.close();
          }
          infoWindow.open(this.map, marker);
          currentInfoWindow = infoWindow;
          google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
            const infoWindowHeader = document.querySelector('.gm-style-iw-ch') as HTMLElement;
            
            if (infoWindowHeader) {
              infoWindowHeader.style.paddingTop = '2px';
              infoWindowHeader.innerHTML = this.getCustomHeaderContent(driver);
            }
            const closeButton = document.querySelector('.gm-ui-hover-effect') as HTMLElement;
            if (closeButton) {
              closeButton.style.zIndex = '9999';
            }
            const actionButton = document.getElementById(`driver-action-btn-${driver.driverId}`);
            if (actionButton) {
              actionButton.addEventListener("click", (event) => {
                event.stopPropagation();
                this.assignTrip(driver);
              });
            }
            const iwOuter = document.querySelector(".gm-style-iw");
            if (iwOuter && iwOuter instanceof HTMLElement) {
              iwOuter.style.width = "400px";
            }
          });
        });
      });
    });
  }

  private getCustomHeaderContent(driver: any): string {
    const driverEntity = driver.relatedData.find(item => item.entityCode === 'driver');
    driver.relatedData.forEach(relatedDataObject => {
      if (relatedDataObject.entityCode === AppSettings.ENTITY_CODE.DRIVER) {
        driver.profileImage = (relatedDataObject.values.driver_profile_image_url && relatedDataObject.values.driver_profile_image_url.length > 0) 
          ? relatedDataObject.values.driver_profile_image_url[0] 
          : null;
      }
    });

    return `
      <div style="display: flex; align-items: center; padding: 10px;">
        <div style="flex: 0 0 auto; margin-right: 10px;">
          <img src="${driver.profileImage ?? this.defaultLoaderImg}" alt="${driver.driverName}" style="width: 40px; height: 40px; border-radius: 50%;">
        </div>
        <div style="flex: 1; display: flex; flex-direction: column; justify-content: center;">
          <div style="font-size: 16px; font-weight: bold; line-height: 1.2;">
            ${driver.driverName} <small>(Driver)</small>
          </div>
          <div style="font-size: 14px; color: #555;">
            <small>${driverEntity?.values?.unique_id ?? 'N/A'}</small>
          </div>
        </div>
      </div>
    `;
  }

  private createInfoWindowContent(driver: any): string {
    const driverEntity = driver.relatedData.find(item => item.entityCode === 'driver');
    const vehicleEntity = driver.relatedData.find(item => item.entityCode === 'vehicle');
    const groupEntity = driver.relatedData.find(item => item.entityCode === 'driver_group');
    
    return `
      <div style="display: flex; flex-direction: column;">
        <div class="row" style="display: flex; justify-content: space-between; width: 100%;">
          <div class="col-6" style="padding-right: 10px;">
            <strong>Call Sign/Code</strong>
          </div>
          <div class="col-6" style="text-align: left;">
            <b>${driverEntity?.values?.call_sign_code ?? 'N/A'}</b>
          </div>
        </div>
        <hr>
        <div class="row" style="display: flex; justify-content: space-between; width: 100%; margin-top: 5px;">
          <div class="col-6" style="padding-right: 10px;">
            <strong>Group Name</strong>
          </div>
          <div class="col-6" style="text-align: left;">
            <b>${groupEntity?.values?.group_name ?? 'N/A'}</b>
          </div>
        </div>
        <hr>
        <div class="row" style="display: flex; justify-content: space-between; width: 100%; margin-top: 5px;">
          <div class="col-6" style="padding-right: 10px;">
            <strong>Vehicle</strong>
          </div>
          <div class="col-6" style="text-align: left;">
            <b>${vehicleEntity?.values?.name_code ?? 'N/A'}</b> 
            (${vehicleEntity?.values?.body_type ?? 'N/A'} ${vehicleEntity?.values?.vehicle_type ?? 'N/A'})
          </div>
        </div>
        <hr>
        <div class="row" style="margin-top: 10px;">
          <div class="col-12" style="text-align: right;">
            <button pButton id="driver-action-btn-${driver.driverId}" class="action-btn mt-2">
              Assign Trip
            </button>
          </div>
        </div>
      </div>
    `;
  }  

  listAvailableDrivers() {
    this.dashboardService.mapAvailableDriverList(this.entityData).subscribe(async (res: any) => {
      this.availableDriverList = res.filter(driver => driver?.driverCurrentLocation);
      this.filteredDriverList = [...this.availableDriverList];
      this.filteredDriverList.forEach(driver => {
        driver.relatedData.forEach(relatedDataObject => {
          if (relatedDataObject.entityCode === AppSettings.ENTITY_CODE.DRIVER) {
            driver.profileImage = (relatedDataObject.values.driver_profile_image_url && relatedDataObject.values.driver_profile_image_url.length > 0) ? relatedDataObject.values.driver_profile_image_url[0] : null
          }
        });
      });
    });
  }

  onRefresh() {
    this.initMap();
    const lastSync = new Date();
    this.refreshTime = lastSync.toLocaleTimeString();
  }
  
  searchVehicleTypes() {
    this.entityService.searchAttributeSettings(AppSettings.ENTITY_TYPE.VEHICLE, this.entityDataForVehicleType).subscribe((res: any) => {
      this.vehicleTypeList = res.data.map(item => item);
    })
  }

  getFilterViewForLocation() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.LOCATION, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
       this.locationFilters = filterResponse;
       const fields = this.locationFilters?.tabs[0]?.groups[0]?.fields;
       const categoryField = fields ? fields.find(ele => ele.attributeCode === AppSettings.LOCATION_FILTER_ATTRIBUTES.LOCATION_CATEGORY) : [];
       this.categoryAttributeIdInLocationFilter = categoryField ? categoryField.attributeId : null;
       if(this.categoryAttributeIdInLocationFilter) {
         this.searchQueueLocation();
       }
      }
    })
  }

  searchQueueLocation(){
    this.entityDataForQueueLocation.countryCode = this.country[0].countryCode;
    this.entityDataForQueueLocation.filters = [{
      attributeId: this.categoryAttributeIdInLocationFilter,
      attributeValue: [AppSettings.ATTRIBUTE_VALUES_FOR_LOCATION_CATEGORY.QUEUE]
    }];

    this.entityService.searchEntity(AppSettings.ENTITY_CODE.LOCATION, this.entityDataForQueueLocation).subscribe((result: any) => {
      this.queueLocations = result.data;
      this.filterQueueLocation();
    })
  }

  filterQueueLocation() {
    this.queueLocationList = [];
    this.addToQueueList = [];
    this.queueLocations.forEach(element => {
      this.queueLocationList.push({
        labelKey: element.values.location_display_name_for_booker,
        labelValue: element.id
      });
      this.addToQueueList.push({
        labelKey: element.values.location_display_name_for_booker,
        labelValue: element.id
      });
    });
  }
  
  onChangeVehicleType(event) {
    this.entityData.vehicleBodyTypes = event.value;
    this.initMap();
  }

  onChangeQueueLocation(event) {
    this.entityData.queueIds = event.value;
    this.initMap();
  }

  onFilter(event) {
    if (event.filter) {
      this.filterIcon = 'pi pi-times'
    } else {
      this.filterIcon = 'pi pi-search';
    }
  }

  onFilterIconClick() {
    if (this.filterIcon.includes('times')) {
      this.multiSelect.filterValue = null;
      this.filterIcon = 'pi pi-search';
    }
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.QUEUE;
    this.entityService.getAttributeDefinition(entityType, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.queueData = res;
        this.queueAttributeData = this.cs.getOrganizedAttribute(this.queueData);
        this.locationAttributeId = this.cs.getAttributeId('location_entity_id', this.queueAttributeData);
        this.driverAttributeId = this.cs.getAttributeId('driver_entity_id', this.queueAttributeData);
      }
    });
  }

  getProfileImageUrl(driver: any): string {
    const driverData = driver.relatedData.find((data: any) => data.entityCode === 'driver');
    return driverData?.values?.profile_image_url || '';
  }

  getCallSignCode(driver: any): string {
    const driverData = driver.relatedData.find((data: any) => data.entityCode === 'driver');
    const callSign = driverData?.values?.call_sign_code || '';
    return `${callSign}`;
  }

  getDriverActionStatus(driver: any): string {
    const driverData = driver.relatedData.find((data: any) => data.entityCode === 'driver');
    const actionStatus = driverData?.actionStatus || '';
    return actionStatus.toLowerCase() === 'active' ? 'active' : 'inactive';
  }
  
  getGroupName(driver: any): string {
    const groupData = driver.relatedData.find((data: any) => data.entityCode === 'driver_group');
    return groupData?.values?.group_name || '';
  }

  getQueueLocation(driver: any): string {
    const queueData = driver.relatedData.find((data: any) => 
      data.entityCode === 'location' && data.values?.location_category === 'Queue'
    );
    return queueData?.values?.location_display_name_for_booker || '';
  }
  
  getVehicleInfo(driver: any): string {
    const vehicleData = driver.relatedData.find((data: any) => data.entityCode === 'vehicle');
    const nameCode = vehicleData?.values?.name_code || '';
    const bodyType = vehicleData?.values?.body_type || '';
    const passengerCapacity = vehicleData?.values?.passenger_capacity || '';
    const manufacturer = vehicleData?.values?.manufacturer || '';
    return `${nameCode} - ${manufacturer} (${bodyType} - ${passengerCapacity})`;
  }
  
  addToQueue(driverData) {
    this.driverIdForQueue = driverData.driverId;
    this.isVisibleQueue = true;
  }

  assignTrip(driverData) {
    this.driverIdForTrip = driverData.driverId;
    this.isVisibleTrip = true;
  }

  onHideQueue() {
    this.isVisibleQueue = false;
    this.selectedQueue = '';
  }

  onHideTrip() {
    this.isVisibleTrip = false;
    this.bookingCode = '';
  }

  onRemoveQueue(event) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.QUEUE,
      entityIds: [
        event.queueEntityId
      ]
    };
    this.confirmationService.confirm({
      header: this.deleteQueueHeaderMsg,
      message: this.deleteQueueMsg,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: () => {
        this.entityService.deleteEntityActionStatus(requestBody).subscribe(response => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('queue.message.queue_remove_success') });
          this.initMap();
        })
      },
      reject: () => {
      }
    });
  }

  onAddToQueue() {
    this.queueData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.QUEUE,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };

    this.queueData?.data?.push({
      attributeId: this.driverAttributeId,
      attributeValue: this.driverIdForQueue,
    });

    this.queueData?.data?.push({
      attributeId: this.locationAttributeId,
      attributeValue: this.selectedQueue,
    });

    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.QUEUE
    }
    this.createEntityAndUpdateAttributeData(entitiesData);
  }

  createEntityAndUpdateAttributeData(entitiesData) {
    this.entityService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.queueId = res.entityId;
      this.saveQueueData(this.queueData);
    });
  }

  saveQueueData(queueData?) {
    this.entityService.saveAttributeData(queueData.entityCode, this.queueId, queueData).subscribe(res => {
      this.isVisibleQueue = false;
      this.selectedQueue = '';
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('dashboard.driver_added_to_queue_success') });
      this.initMap();
    });
  }

  onAddToTrip() {
    const payload = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      eventCode: this.eventService.getSelectedEventcode(),
      bookingCode: this.bookingCode,
      driverEntityId: this.driverIdForTrip,
    }
    this.dashboardService.mapAssignTrip(payload).subscribe((res : any) => {
      this.bookingCode = '';
      this.isVisibleTrip = false;
      this.messageService.add({key: 'tst', severity: 'success', summary: 'Success', detail: res.message});
    });
  }

  clearSearchField(multiSelect: any) {
    multiSelect.filterValue = null;
  }

  onSearch(event) {
    const value = event.target.value;
    this.entityData.searchStr = value;
    this.initMap();
  }

  clearSearch() {
    this.searchValue = "";
    this.entityData.searchStr = '';
    this.initMap();
  }

  onSearchDriver() {
    if (this.searchDriver.length >= 3) {
      const query = this.searchDriver.toLowerCase();
      this.filteredDriverList = this.availableDriverList.filter(driver => {
        return (
          driver.driverName.toLowerCase().includes(query) ||
          driver.driverUniqueId.toLowerCase().includes(query) ||
          driver.vehicleCode.toLowerCase().includes(query)
        );
      });
    } else {
      this.filteredDriverList = [...this.availableDriverList];
    }
  }

  clearSearchDriver() {
    this.searchDriver = '';
    this.filteredDriverList = [...this.availableDriverList];
  }

  clearFilter() {
    this.selectedVehicleType = [];
    this.selectedQueueLocation = [];
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.infoWindow.setPosition(pos);
          this.infoWindow.setContent("Location found.");
          this.infoWindow.open(this.map);
          this.map.setCenter(pos);
        },
        () => {
          this.handleLocationError(true);
        }
      );
    } else {
      this.handleLocationError(false);
    }
  }

  handleLocationError(browserHasGeolocation: boolean) {
    const pos = this.map.getCenter();
    this.infoWindow.setPosition(pos);
    this.infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    this.infoWindow.open(this.map);
  }

  toggleDarkAndLightMap(event) {
    this.checked = event.checked;
    const mapStyles = this.checked
      ? AppSettings.LOCATION_MAP_STYLE_DARK
      : AppSettings.LOCATION_MAP_STYLE_DEFAULT;
    if (!this.checked) {
      mapStyles.push({ featureType: "poi", stylers: [{ visibility: "off" }] });
    }
    this.map.setOptions({ styles: mapStyles });
  }

  showRightDriversDialog(position: string) {
    this.rightDialogPosition = position;
    this.position = position;
    this.rightDriversSectionVisible = false;
    this.cd.detectChanges();
    this.rightDriversSectionVisible = true;
  }

  ngOnDestroy() {
    if (this.infoWindow) {
      this.infoWindow.close();
    }
  }
}
