import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigService } from 'app/modules/shared/services/config.service';
import * as _ from 'lodash';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { AppSettings } from '../../../shared/app.settings';
import { BasicTableComponent } from '../../../shared/components/basic-table/basic-table.component';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import type { Country } from '../../../shared/models/country';
import type { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { AttributeData, entityResponse } from '../../models/attribute.models';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-vehicle-document-entity',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, BasicTableComponent, ButtonModule, TranslateModule, ToastModule],
  templateUrl: './vehicle-document-entity.component.html',
  styleUrl: './vehicle-document-entity.component.scss'
})
export class VehicleDocumentEntityComponent {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  @Output() previousClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextClick: EventEmitter<void> = new EventEmitter<void>();
  @Input() tab;
  @Input() activeIndex;
  @Input() attributeLabels = {};
  @Input() moduleName: string;
  @Input() submitBtnText: string;
  @Input() previousBtnText: string;
  @Input() attributeValues = [];
  @Input() attributeData: any;
  @Input() vehicleId;
  @Input() saveBtnId: string;
  routePath: MenuItem[] = [];
  data: AttributeData;
  vehicleAttributeData: any;
  country: Country;
  language: Language;
  cols: any = [];
  vehicleAttributeLength: number;
  vehicleDocumentId: string;
  vehicleDocument: any[] = [];
  vehicleDocumentFields: number = 5;
  start: number = 0;
  end: number = 5;
  prefixForOneToManyRelatedFields: any = '_';
  vehicleDocumentEntityIdList: any[] = [];
  attributeIdForVehicleDocument;
  edit: boolean = false;
  vehicleData: any;
  vehicleDetails: any;
  isReady: boolean = false;
  vehicleDocumentPayload: any;
  entityId: string;
  imgFileId;
  vehicleDocumentToShow;
  documentTypeValue;
  uploadDocumentValue;
  relationshipDataForVehicleDocument: any[] = [];
  removedVehicleDocumentId;
  removedIndex;
  attributeIdForVehicleEntityId: any;
  vehicleDocumentArray: any[] = [];
  createEntityObsArray: any[] = [];
  saveAttributeObsArray: any[] = [];
  isNew: boolean = true;

  constructor(private vehicleService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    public appSettings: AppSettings,
    private configService: ConfigService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.setLabels();
    this.setVehicleId();
    this.setCountryAndLanguage();
    this.loadVehicleData();
    this.setRoutePath();
  }

  setLabels() {
    this.submitBtnText = this.cs.getLabel('lbl_next');
    this.previousBtnText = this.cs.getLabel('lbl_previous');
  }

  setVehicleId() {
    this.vehicleId = this.route.snapshot.paramMap.get('id');
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  private loadVehicleData() {
    if (this.vehicleId) {
      this.vehicleService
        .getEntity(this.vehicleId, AppSettings.ENTITY_CODE.VEHICLE, AppSettings.VIEW_CODE.ADD_EDIT_VIEW)
        .subscribe((res) => {
          if (res) {
            const data = res;
            this.vehicleDetails = res;
            this.attributeValues = this.cs.getOrgAttributeValues(data);
            this.parseAttributeValues();
            this.getVehicleDocumentData();
          }
          this.getAttributes();
        });
    } else {
      this.getAttributes();
    }
  }

  getVehicleDocumentData() {
    if (this.vehicleDetails.relatedData.length > 0) {
      const documentList = this.vehicleDetails.relatedData.filter(ele => ele.entityCode === AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT);
      if (documentList && documentList.length > 0) {
        documentList.forEach((data, index) => {
          this.setAttributeValuesForVehicleDocumentEditMode(data, index);
        });
      }

    }
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.VEHICLE;
    this.vehicleService.getAttributeDefinition(entityType, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.vehicleAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.vehicleAttributeLength = this.vehicleAttributeData.tabs.length;
        this.attributeIdForVehicleDocument = this.getAttributeIdForGroupCode(AppSettings.GROUP_CODE.DOCUMENTS);
        this.isReady = true;
        this.attributeIdForVehicleEntityId = this.getAttributeId('vehicle_entity_id');
      }
    });
  }

  getAttributeId(attributeCode: string): number | undefined {
    const vehicleDocument = _.find(this.vehicleAttributeData.relatedAttributes, { entityCode: 'vehicle_document' });
    for (const tab of vehicleDocument.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  getAttributeIdForGroupCode(groupCode: string): string | undefined {
    const group = _.flatMap(this.vehicleAttributeData.tabs, 'groups').find(group => group.code === groupCode);
    return group ? group.relation.ownerAttributeId : undefined;
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('lbl_add_new_vehicles'),
        routerLink: '../list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: this.vehicleId ? this.cs.getLabel('vehicle.lbl_edit_vehicle') : this.cs.getLabel('vehicle.add'),
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      }
    ];
  }

  private parseAttributeValues() {
    const dateAttributes = [
      AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_ISSUED_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_EXPIRY_DATE
    ];
    dateAttributes.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
    });

    const vehicleDocumentIdArray = this.vehicleDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === AppSettings.GROUP_CODE.DOCUMENT_SECTION);
    this.vehicleDocumentEntityIdList = (vehicleDocumentIdArray && vehicleDocumentIdArray.attributeValue.length > 0) ? vehicleDocumentIdArray.attributeValue : [];
  }

  setAttributeValuesForVehicleDocumentEditMode(data, index) {
    for (const labelsObj of data.attributeCodeValueDtoList) {
      this.attributeValues[index + '_' + labelsObj.attributeCode] =
        (labelsObj.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_ISSUED_DATE ||
          labelsObj.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_EXPIRY_DATE) ? new Date(labelsObj.attributeValue) : labelsObj.attributeValue;
    }
  }

  getAttributeValueByCode(attributes: any[], code: string): any {
    const attribute = attributes.find(attr => attr.attributeCode === code);
    return attribute ? attribute : null;
  }

  onRemoveVehicleDocumentEntity(event) {
    this.removedIndex = event[1];
    this.removedVehicleDocumentId = typeof event[0] === 'string' ? event[0] : String(event[0]);
    this.vehicleDocumentEntityIdList = this.vehicleDocumentEntityIdList.filter(id => id !== this.removedVehicleDocumentId);
    this.saveVehicleDocumentData();
  }

  isDocumentUrlKey(key) {
    return key.includes(AppSettings.FIELDS.VEHICLE_DOCUMENT_URL) || key.includes(AppSettings.FIELDS.DRIVER_DOCUMENT_URL);
  }

  isValidObject(data) {
    return data && typeof data === 'object' && !Array.isArray(data);
  }

  saveData(event?) {
    const vehicleFormData = event;
    this.vehicleData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.VEHICLE,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };
    const removedVehicleFormData = this.cs.removeDocumentByIndex(vehicleFormData, this.removedIndex);
    if (this.isValidObject(removedVehicleFormData)) {
      for (const [key, value] of Object.entries(removedVehicleFormData)) {
        if (!this.isDocumentUrlKey(key)) {
          const attributeValue = value;
          const arr = key.split('_');
          if (parseInt(arr[0]) >= 0) {
            this.setAttributeValuesForVehicleDocument(key, attributeValue);
          } else {
            this.setAttributeValuesForOtherDetails(key, attributeValue);
          }
        }
      }
    }
    this.vehicleDocumentArray.forEach((vehicleDocument, index) => {
      this.vehicleDocumentArray[index] = this.cs.mapAttributeIdsForRelatedData(vehicleDocument, this.vehicleAttributeData.tabs);
    });
    this.saveVehicleDocumentData();
  }

  private setAttributeValuesForVehicleDocument(key, attributeValue) {
    if(!key) {
      return;
    }
    const arr = key.split('_');
    if (typeof this.vehicleDocumentArray[arr[0]] === 'undefined') {
      this.vehicleDocumentArray[arr[0]] = [];
    }
    const attributeCode = key.substring(key.indexOf('_') + 1);
    if (attributeValue) {
      const isExpiryDate = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_EXPIRY_DATE;
      const isIssuedDate = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_ISSUED_DATE;
      const value = isExpiryDate || isIssuedDate ? new Date(attributeValue).getTime() : attributeValue;
      this.vehicleDocumentArray[arr[0]].push({ attributeCode, attributeValue: value });
    }
  }

  private setAttributeValuesForOtherDetails(key, attributeValue) {
    if (attributeValue) {
      this.vehicleData?.data?.push({
        'attributeCode': key,
        attributeValue
      });
    }
  }

  saveVehicleDocumentData() {
    this.relationshipDataForVehicleDocument = [];
    const newVehicleRecordList: any[] = [];
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT,
    }

    this.createEntityObsArray = [];
    this.saveAttributeObsArray = [];
    this.vehicleDocumentArray.forEach((record, index) => {
      const vehicleRecordEntity = {
        forTenantCode: this.configService.getForTenantCode(),
        entityCode: AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT,
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        data: record
      };

      vehicleRecordEntity?.data?.push({
        'attributeId': this.attributeIdForVehicleEntityId,
        attributeValue: this.vehicleId
      })

      if (this.vehicleDocumentEntityIdList[index]) {
        this.saveAttributeObsArray.push(this.vehicleService.saveAttributeData(vehicleRecordEntity.entityCode, this.vehicleDocumentEntityIdList[index], vehicleRecordEntity));
        this.relationshipDataForVehicleDocument.push(
          {
            entityRelationshipConfigId: this.vehicleAttributeData.tabs[1].groups.find(ele => ele.code === AppSettings.GROUP_CODE.DOCUMENTS).relation.entityRelationshipConfigId,
            otherEntityId: this.vehicleDocumentEntityIdList[index]
          }
        );
      } else {
        newVehicleRecordList.push(vehicleRecordEntity);
        this.createEntityObsArray.push(this.vehicleService.createEntities(entitiesData.entityCode, entitiesData));
      }
      if (index === this.vehicleDocumentArray.length - 1) {
        this.createAndSaveVehicleDocument(entitiesData, newVehicleRecordList);
      }
    });
  }

  createAndSaveVehicleDocument(entitiesData, newVehicleRecordList) {
    if (this.createEntityObsArray.length > 0) {
      forkJoin(this.createEntityObsArray).subscribe(result => {
        if (result && result.length > 0) {
          result.forEach((createEntityResponse: any, index) => {
            this.vehicleDocumentEntityIdList.push(createEntityResponse.entityId);
            this.saveAttributeObsArray.push(this.vehicleService.saveAttributeData(entitiesData.entityCode, createEntityResponse.entityId, newVehicleRecordList[index]));
            this.relationshipDataForVehicleDocument.push(
              {
                entityRelationshipConfigId: this.vehicleAttributeData.tabs[1].groups.find(ele => ele.code === AppSettings.GROUP_CODE.DOCUMENTS).relation.entityRelationshipConfigId,
                otherEntityId: createEntityResponse.entityId
              }
            );
          });
          this.saveVehicleDocumentAttributes();
        }
 
      })
    } else {
      this.saveVehicleDocumentAttributes();
    }
  }

  saveVehicleDocumentAttributes() {
    forkJoin(this.saveAttributeObsArray).subscribe(data => {
      this.vehicleData.data.push({
        attributeId: this.attributeIdForVehicleDocument,
        attributeValue: this.vehicleDocumentEntityIdList
      });
      this.saveVehicleDocumentDataToDb();
    });
  }

  saveVehicleDocumentDataToDb() {
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.VEHICLE
    }
    this.vehicleData.relationshipData = this.relationshipDataForVehicleDocument;
    if (this.vehicleId) {
      this.saveAttributeData(entitiesData.entityCode,true)
    } else {
      this.createEntityAndUpdateAttributeData(entitiesData);
    }
  }

  saveAttributeData(entityCode,isNew: boolean) {
    this.isNew = isNew;
    this.vehicleService.saveAttributeData(entityCode, this.vehicleId, this.vehicleData).subscribe(() => {
      // this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('vehicle.vehicle_added') });
      if (this.isNew) {
        this.cs.onDisplayMessage(this.vehicleAttributeData, AppSettings.ENTITY_TYPE.VEHICLE, AppSettings.MSG_ACTION.ADD, 'success');
      } else {
        this.cs.onDisplayMessage(this.vehicleAttributeData, AppSettings.ENTITY_TYPE.VEHICLE, AppSettings.MSG_ACTION.UPDATE, 'success');
      }
    });
  }

  createEntityAndUpdateAttributeData(entitiesData) {
    this.vehicleService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.vehicleId = res.entityId;
      this.vehicleService.saveAttributeData(entitiesData.entityCode, this.vehicleId, this.vehicleData).subscribe(() => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('vehicle.vehicle_added') });
      });
    });
  }

  saveVehicleData(vehicleData) {
    const { entityRelationshipConfigId } = this.vehicleAttributeData?.tabs[1]?.groups.find(ele => ele.code === AppSettings.GROUP_CODE.DOCUMENTS)?.relation || {};
    this.vehicleData.relationshipData = [];
    for (let index = 0; index < this.vehicleDocumentEntityIdList.length; index++) {
      this.vehicleData.relationshipData.push({ entityRelationshipConfigId, otherEntityId: this.vehicleDocumentEntityIdList[index] });
    }
    this.vehicleService.saveAttributeData(AppSettings.ENTITY_CODE.VEHICLE, this.vehicleId, vehicleData).subscribe(() => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('vehicle.vehicle_added'), });
    });
  }

  onPreviousClick() {
    this.previousClick.emit();
  }

  onNextClick() {
    this.nextClick.emit();
  }

  getLastIndex(): number {
    return this.vehicleAttributeLength - 1;
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(['app/vehicles/list']);
  }
}
