import { Directive, HostListener, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimSpacesProfile]',
  standalone: true,
})
export class TrimSpacesProfileDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private ngControl: NgControl
  ) {}

  @HostListener('blur') onBlur() {
    this.trimValue();
  }

  @HostListener('input') onInput() {
    this.trimValue();
  }

  private trimValue() {
    const input = this.el.nativeElement as HTMLInputElement;
    const originalValue = input.value;
    const trimmedValue = originalValue.trim();
    if (originalValue !== trimmedValue) {
      this.renderer.setProperty(input, 'value', trimmedValue);
      if (this.ngControl && this.ngControl.control) {
        this.ngControl.control.setValue(trimmedValue, { emitEvent: false });
      }
      this.cdRef.detectChanges();
    }
  }
}
