<div class="main-settings">
    <p-tabView [scrollable]="true">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="{{miIcons.BOOKING_USER}} wh-16 inline-blick mr-2"></i>
                <span translate>settings.lbl_login_information</span>
            </ng-template>
            <div class="flex justify-content-end flex-wrap btn-with-links">
                <button pButton class="bg-white default-text-light-black-color border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
                <p-button class="flex align-items-center justify-content-center" [label]="updateLabel" (click)="onAddLoginInfo()"></p-button>
            </div>
            <div class="card background-not">
                <div class="font-semibold pb-2">
                    <label class="navigation-item-label font-20" translate>settings.lbl_login_settings</label>
                </div>
                <form [formGroup]="loginForm">
                    <div class="grid">
                        <div class="form-field col-6 md:col-10 lg:col-6 field">
                            <label class="flex" translate>manage_users.user_form.username
                                <span class="required">*</span>
                            </label>
                            <div class="form-control">
                                <input #nameInput formControlName="userName" class="p-component p-element w-full" type="text" pInputText appAutoFocus appTrimSpacesProfile/>
                                <div class="mt-1">
                                    <label class="font-12" for="userName" translate>settings.lbl_you_can_log_in_to_tms_using_your_username_or_your_email_address</label>
                                </div>
                            </div>
                            @if (loginForm.controls.userName.errors && (loginForm.controls.userName.dirty || loginForm.controls.userName.touched)) {
                                <div class="form-errors mt-1 required-field">
                                    <div [hidden]="!loginForm.controls.userName.errors.required" translate>
                                        settings.lbl_user_name_req
                                    </div>
                                </div>
                            }
                        </div>
                        <div class="form-field col-6 md:col-10 lg:col-6 field">
                            <label class="flex" translate>lbl_email
                                <span class="required">*</span>
                            </label>
                            <div class="form-control">
                                <input formControlName="email" class="p-component p-element w-full" type="email" pInputText appTrimSpacesProfile/>
                                <div class="mt-2">
                                    <p-checkbox class="mr-2" [binary]="true" formControlName="emailUpdates" inputId="emailUpdates"></p-checkbox>
                                    <label for="emailUpdates" translate>settings.lbl_send_me_email_updates_about_tms</label>
                                </div>
                            </div>
                            @if (loginForm.controls.email.errors && (loginForm.controls.email.dirty || loginForm.controls.email.touched)) {
                                <div class="form-errors mt-1 required-field">
                                    <div [hidden]="!loginForm.controls.email.errors.required" translate>
                                        settings.lbl__email_req
                                    </div>
                                </div>
                                <div class="form-errors mt-1 required-field" [hidden]="!loginForm.controls.email.errors.pattern" translate>
                                    settings.email_pattern
                                </div>
                            }
                        </div>
                        <div class="form-field col-6 md:col-10 lg:col-6 field">
                            <label class="flex" translate>settings.lbl_time_zone</label>
                            <div class="form-control">
                                <p-dropdown class="timezone-dropdown p-component p-element w-full" [options]="timezoneList" formControlName="timezones" 
                                    optionLabel="timezone" [placeholder]="'lbl_select_timezone' | translate" [filter]="true" filterBy="timezone">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="flex justify-content-end mr-17">
                <button pButton class="mr-2 mb-2 border-none bg-color-gray default-text-light-black-color border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
                <p-button class="mb-2 flex align-items-center justify-content-center" [label]="'lbl_update' | translate" (click)="onAddLoginInfo()"></p-button>
            </div> 
        </p-tabPanel>

        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="{{miIcons.PASSWORD}} wh-16 inline-blick mr-2"></i>
                <span translate>settings.lbl_change_password</span>
            </ng-template>
            <div class="flex justify-content-end flex-wrap btn-with-links">
                <button pButton class="border-none bg-white default-text-light-black-color border-button" [label]="'cancel' | translate" (click)="onCancelChangePwd()"></button>
                <p-button class="flex align-items-center justify-content-center" [label]="changePasswordLabel" (click)="onChangePassword()"></p-button>
            </div>
            <div class="card background-not">
                <div class="font-semibold pb-2">
                    <label class="navigation-item-label font-20" translate>settings.lbl_change_password_uppercase</label>
                </div>
                <form [formGroup]="changePasswordForm">
                    <div class="grid">
                        <div class="form-field col-6 md:col-10 lg:col-6 field">
                            <label class="flex" translate>lbl_current_password
                                <span class="required">*</span>
                            </label>
                            <div class="form-control">
                                <input formControlName="currentPassword" class="p-component p-element w-full" type="text" [placeholder]="'lbl_enter_here' | translate" pInputText/>
                                <div class="mt-1">
                                    <label class="font-12" for="currentPassword" translate>lbl_enter_your_current_password_to_confirm</label>
                                </div>
                            </div>
                            @if (changePasswordForm.controls.currentPassword.errors && (changePasswordForm.controls.currentPassword.dirty || changePasswordForm.controls.currentPassword.touched)) {
                                <div class="form-errors mt-1 required-field">
                                    <div [hidden]="!changePasswordForm.controls.currentPassword.errors.required" translate>
                                        lbl_current_password_req
                                    </div>
                                </div>
                            }
                        </div>
                        <div class="form-field col-6 md:col-10 lg:col-6 field"></div>
                        <div class="form-field col-6 md:col-10 lg:col-6 field">
                            <label class="flex" translate>lbl_new_password
                                <span class="required">*</span>
                            </label>
                            <div class="form-control">
                                <input formControlName="newPassword" [minlength]="minLength" class="p-component p-element w-full" type="text" [placeholder]="'lbl_enter_here' | translate" pInputText/>
                                <div class="mt-1">
                                    <label class="font-12" for="newPassword">
                                        {{ 'lbl_must_be_at_least_char_long' | translate:{minLength: minLength} }}
                                    </label>
                                </div>
                                <div>
                                    <label class="font-12" for="newPassword">{{passwordRequirementsMessage}}</label>
                                </div>
                            </div>
                            @if (changePasswordForm.controls.newPassword.errors && (changePasswordForm.controls.newPassword.dirty || changePasswordForm.controls.newPassword.touched)) {
                                <div class="form-errors mt-1 required-field">
                                    <div [hidden]="!changePasswordForm.controls.newPassword.errors.required" translate>
                                        lbl_new_password_req
                                    </div>
                                </div>
                                <div class="form-errors mt-1 required-field" [hidden]="!changePasswordForm.controls.newPassword.errors.pattern" translate>
                                    settings.password_pattern
                                </div>
                            }
                        </div>
                        <div class="form-field col-6 md:col-10 lg:col-6 field">
                            <label class="flex" translate>lbl_confirm_new_password
                                <span class="required">*</span>
                            </label>
                            <div class="form-control">
                                <input formControlName="confirmPassword" class="p-component p-element w-full" type="text" [placeholder]="'lbl_enter_here' | translate" pInputText/>
                            </div>
                            @if (changePasswordForm.controls.confirmPassword.errors && (changePasswordForm.controls.confirmPassword.dirty || changePasswordForm.controls.confirmPassword.touched)) {
                                <div class="form-errors mt-1 required-field">
                                    <div [hidden]="!changePasswordForm.controls.confirmPassword.errors.required" translate>
                                        lbl_confirm_new_password_req
                                    </div>
                                </div>
                            }
                            @if(changePasswordForm.errors?.passwordMismatch) {
                                <div class="form-errors mt-1 required-field" translate>
                                    lbl_please_match_the_password_with_new_password
                                </div>
                            }
                        </div>
                    </div>
                </form>
            </div>
            <div class="flex justify-content-end mr-10">
                <button pButton class="mr-2 mb-2 bg-color-gray default-text-light-black-color border-button " [label]="'cancel' | translate" (click)="onCancelChangePwd()"></button>
                <p-button class="mb-2 flex align-items-center justify-content-center" [label]="'lbl_change_password' | translate" (click)="onChangePassword()"></p-button>
            </div> 
        </p-tabPanel>
    </p-tabView>
</div>
