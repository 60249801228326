import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MiTranslateLoaderService implements TranslateLoader {

  getTranslation(lang: string): Observable<any> {
    return of({
      Username: 'Email',
      Username_or_Email: 'Email/Username',
      Password: 'Password',
      placeholder_email: 'Enter Email/Username',
      'Username is should be greater than x': 'Username is should be greater than {{minval}}',
      forgot_password: 'Forgot Password',
      login_success: 'Admin logged in successfully.',
      logout_success: 'Admin logged out successfully.',
      email_placeholder: 'Enter Email',
      email_request: 'Please, enter your email address so we can send you a link to reset password.',
      lbl_remember_me: 'Remember me',
      lbl_logo_rights: '© logo 2023. All rights reserved',
      lbl_powered_by: 'Powered by',
      lbl_forget_password: 'Forgot Password',
      forgot_password_link: 'Password Link Send Successfully',
      reset_request: 'Please enter the new password',
      lbl_reset_passowrd: 'Password',
      lbl_reset_confirm_passowrd: 'Confirm Password',
      set_password: 'Set Password',
      lbl_password_change_message: 'Your password has been changed successfully',
      lbl_password_character_error: 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
      lbl_password_required:'Password is required',
      lbl_password_match: 'Password must match',
      lbl_passenger: 'Passenger',
      lbl_invalid_email_format: 'Invalid email format.',
      view_document: 'View Document',
      
      grid_title: 'TITLE',
      grid_trial_start_date: 'START DATE',
      grid_trail_end_date: 'END DATE',
      grid_patients: 'PATIENTS',
      lbl_no_records_found: 'No records found',
      lbl_enter_labels: 'Enter Labels',

      sidebar_lbl_dashboard: 'Dashboard',
      sidebar_lbl_events: 'Events',
      sidebar_lbl_organization: 'Organization',
      sidebar_lbl_vehicles: 'Vehicles',
      sidebar_lbl_drivers: 'Drivers',
      sidebar_lbl_queue_management: 'Queue Management',
      sidebar_lbl_dispatch: 'Dispatch',
      sidebar_lbl_passenger: 'Passengers',
      sidebar_lbl_bookings: 'Bookings',
      sidebar_lbl_service: 'Service',
      sidebar_lbl_reminder: 'Reminder',
      sidebar_lbl_expense_history: 'Expense History',
      sidebar_lbl_equipment: 'Equipment',
      sidebar_lbl_vehicle_inspection: 'Vehicle Inspection',
      sidebar_lbl_issues: 'Issues',
      sidebar_lbl_document: 'Document',
      sidebarr_label_incidents_complaints: 'Incidents & Complaints',
      sidebarr_label_manage_location: 'Manage Locations',
      sidebarr_label_manage_zones: 'Manage Zones',
      sidebar_lbl_fuel_history: 'Fuel History',
      sidebar_lbl_vendors: 'Vendors',
      sidebar_lbl_shift_management: 'Shift Management',
      sidebar_lbl_reports: 'Reports',
      sidebar_lbl_training: 'Training',
      sidebar_lbl_communication: 'Communication',
      sidebar_lbl_knowledge: 'Knowledge',
      sidebar_lbl_user_management: 'User Management',
      sidebar_lbl_manage_users: 'Manage Users',
      sidebar_lbl_manage_roles: 'Manage Roles',
      sidebar_lbl_manage_groups: 'Manage Groups',
      sidebar_lbl_all_vehicles: 'All Vehicles',
      sidebar_lbl_all_driver: 'All Drivers',
      sidebar_leave_management: 'Leave Management',
      sidebar_driver_assignment: 'Driver Assignment',

      sidebar_lbl_current_bookings: 'Current Bookings',
      sidebar_lbl_bookings_history: 'Bookings History',
      sidebar_lbl_service_history: 'Service History',
      sidebar_lbl_service_task: 'Service Task',
      sidebar_lbl_service_reminder: 'Service Reminder',
      sidebar_lbl_shift_calendar: 'Shift Calendar',
      sidebar_lbl_create_new_shift: 'Create New Shift',
      sidebar_lbl_manage_segments: 'Manage Groups',
      sidebar_lbl_shift_template: 'Shift Template',
      sidebar_lbl_manage_shift: 'Manage Shift',
      sidebar_lbl_content_management: 'Content Management',
      sidebar_lbl_about_company: 'About Us',
      sidebar_lbl_faqs: 'FAQs',
      sidebar_lbl_terms_and_conditions: 'Terms And Conditions',
      sidebar_lbl_privacy_policy: 'Privacy Policy',
      sidebar_lbl_contact_support: 'Contact Support',

      header_logout_confirmation: 'Logout Confirmation',

      msg_logout: '<p><b>Are you sure you want to logout?</b></p>\n\n Logging out from the admin panel will end your current session. Ensure all tasks and configurations are saved. You will need to re-enter your credentials to access the admin functions again.',
      lbl_confirm_idle_session: `Session has been idle over its time limit,
      It will be disconnected in 2 minutes. Click on Yes to continue the session.`,

      manage_column_header: 'Manage Columns',
      manage_column_sub_header: 'Select which columns are visible for you. To change the column order, drag and drop the visible fields.',
      manage_column_available_columns: 'Available Columns',
      manage_column_visible_columns: 'Visible Columns',
      manage_column_restore_default: 'Restore defaults',
      manage_column_apply_changes: 'Apply Changes',
      cancel: 'Cancel',
      reset: "Reset",
      label_save: 'Save',
      label_update: 'Update',
      lbl_reset: 'Reset',
      lbl_confirm_action: 'Confirm Action',
      please_type_reset_to_confirm: 'Please Type "RESET" To Confirm',
      lbl_warning: 'WARNING: This will require all users to change their password the next time they log in.',
      lbl_text_for_download_data: 'Export a copy of data from your account to use as a backup or use it with another service for reporting. It may take a few minutes depending on the amount of data.',
      lbl_note_for_download_date: 'You will receive an email when your export file is ready to download, for security purposes, the download link in the expires after 7 days.',
      lbl_text_for_password_requirement: 'Customize the security level of the passwords your users create for their portal login',
      lbl_note_for_password_requirement: 'Changes here are enforced on new password creation, and on password reset. Use the Reset All Passwords option to require ALL users to change their password the next time they log in. Requirements do not affect password managed through external authentication (e.g. SAML, Okta, Google authentication).',
      lbl_text_for_session_timeout: 'Control how long users can be inactive  before automatically signed out.',

      clear_all: 'Clear all',
      add_event: 'Add Event',
      add_vehicle: 'Add Vehicle',
      lbl_selected: 'Selected',
      lbl_name: 'Name',
      lbl_email: 'Email',
      lbl_user_role: 'User Role',
      lbl_username: 'Username',
      lbl_new_password: 'New Password',
      lbl_confirm_password: 'Confirm Password',
      lbl_phone_number: 'Phone Number',
      lbl_user_status: 'User Status',
      lbl_new_user: 'New User',
      lbl_create_new: 'Create New',
      lbl_role_name: 'Role Name',
      lbl_role_code: 'Role Code',
      lbl_created_by: 'Created By',
      lbl_created_on: 'Created On',
      lbl_modified_by: 'Modified By',
      lbl_modified_on: 'Modified On',
      lbl_f_name: 'First Name',
      lbl_l_name: 'Last Name',
      lbl_country_code: 'Country Code',
      lbl_user_details: 'User Details',
      search_by_body_type: 'Search by Body Type',
      select_body_type: 'Select Body Type',
      filters_lbl: 'Filters',
      close_filter: 'Close',
      items_per_page: 'Items Per Page',
      lbl_details: 'Details',
      lbl_files: 'Files',
      lbl_all: 'All',
      lbl_active: 'Active',
      lbl_inactive: 'Inactive',
      lbl_assigned: 'Assigned',
      lbl_unassigned: 'Unassigned',
      lbl_deleted: 'Deleted',
      lbl_search: 'Search',
      lbl_action: 'Action',
      lbl_actions: 'Actions',
      lbl_restore_defaults: 'Restore defaults',
      lbl_apply: 'Apply',
      lbl_clear_all: 'Clear all',
      clear_selection: 'Clear Selection',
      lbl_update_vehicles: 'Update Vehicles',
      bulk_row_sub_header: 'You have selected',
      bulk_row_sub_header2: 'The new value for the selected fields will be applied to all selected',
      lbl_field_upper: 'FIELD',
      lbl_value_upper: 'VALUE',
      lbl_messaging: 'Messaging',
      lbl_broadcasting: 'Broadcasting',
      lbl_manage_segments: 'Manage Segments',
      customize_feeds: 'Customize Feeds',
      lbl_type_a_message: 'Type a message...',
      quick_call_text: 'Hi, can we have a quick call? its related to vehicles.',
      lbl_mentioned_you: 'mentioned you',
      lbl_seen: 'Seen',
      lbl_hide: 'Hide',
      lbl_sent: 'Sent',
      lbl_add_field: 'Add Field',
      lbl_continue_to_review: 'Continue to Review',
      lbl_field: 'Field',
      lbl_value: 'Value',
      review_bulk_row_sub_header: 'Please review the summary of changes you are about to make to',
      review_bulk_row_sub_header2: 'Do you wish to continue?',
      lbl_review_vehicles: 'Review Vehicles',
      btn_update_vehicles: 'Update',
      btn_update_vehicles2: 'Vehicle',
      btn_passengers: 'Passengers',
      btn_passenger: 'Passenger',
      lbl_max: 'Max',
      lbl_please_select: 'Please Select',
      lbl_select: 'Please Select',
      lbl_select_only: 'Select',
      placeholder_search: 'Search',
      placeholder_search_by: 'Search by',
      lbl_no_results_to_show: 'No results to show.',
      lbl_no_records_available1: 'It looks like there are currently no records ',
      lbl_no_records_available2: 'available for this view.',
      lbl_img_pdf_upload_instruction: 'Accepted file formats: JPG, PNG, PDF (Maximum file size: 10MB)',
      lbl_img_upload_instruction: 'Accepted file formats: JPG, PNG (Maximum file size: 10MB)',
      lbl_select_document: 'Select Document',
      lbl_select_image: 'Select Image',
      lbl_select_logo: 'Select Logo',
      err_file_size_exceed_msg: 'File size exceeds the maximum limit.',
      lbl_drop_img: 'Or drop image here',
      lbl_drop_logo: 'Or drop logo here',
      lbl_drop_document: 'Or drop document here',
      lbl_file_uploaded_successfully: 'File uploaded successfully',
      lbl_img_uploaded_successfully: 'Image uploaded successfully',
      lbl_file_download_successfully: 'File downloaded successfully',
      lbl_barcode_download_successfully: 'Barcode downloaded successfully',
      lbl_empty: '--',
      lbl_add_more_locations: 'Add More Locations',
      lbl_add_more: 'Add More',
      lbl_btn_previous: 'Previous',
      lbl_next_btn: 'Next',
      lbl_add: 'Add',
      lbl_save: 'Save',
      lbl_yes: 'Yes',
      lbl_no: 'No',
      lbl_delete: 'Delete',
      sidebar_shift_template: 'Shift Template',
      add_new: 'Add New',
      data_updated_successfully: 'Data Updated Successfully',
      lbl_breaks: ' Breaks',
      lbl_break: ' Break',
      lbl_select_date: 'Select Date',
      lbl_drivers: 'Drivers',
      lbl_total_drivers: 'Total Drivers',
      location_already_added: 'Location Name Already Added.',
      record_is_deleted: 'Record is deleted',
      lbl_add_assignment: 'Add Assignment History',

      invalid: {
        breakTime: 'Invalid Break Time'
      },

      dashboard: {
        live_dashboard: 'Live Dashboard',
        booking_status: "Booking Status",
        live_status: "Live Status",
        cancelled_journey: "Cancelled Journey",
        journey_status: "Journey Status",
        emergency: "Emergency",
        drivers: "Drivers",
        on_duty: "On Duty",
        upcoming_journey: "Upcoming Journey",
        live_journeys_by_type: "Live Journeys By Types",
        passenger_by_types: "Passenger By Types",
        vehicles: "Vehicles",
        available: "Available",
        occupied: "Occupied",
        parking_and_queues: "Parking & Queues",
        bookings: "Bookings",
        vehicle_status: "Vehicle Status",
        equipment_status: "Equipment Status",
        assigned_vehicles: "Assigned Vehicles",
        assigned: "Assigned",
        unassigned: "Unassigned",
        open_issues: "Open Issues",
        open: "Open",
        overdue: "Overdue",
        service_reminders: "Service Reminders",
        due_soon: "Due Soon",
        service_compliance: "On-Time Service Compliance",
        all_time: "All Time",
        last_30_days: "Last 30 Days",
        time_to_resolve: "Time To Resolve",
        repair_priority: "Repair Priority Class Trends",
        inspection_failure: "Inspection Item Failure Rate",
        this_week: "This Week",
        change_from_last_week: "Change From Last Week",
        overdue_inspections: "Overdue Inspections",
        of_total_due: "Of Total Due",
        inspections_summary: "Inspections Summary",
        inspection_submission: "Inspection Submissions",
        total_service_costs: "Total Service Costs",
        recent_comments: "Recent Comments",
        commented_on: "commented on",
        widgets: 'Widgets',
        restore: 'Restore',
        reorganize_automatically: 'Reorganize Automatically',
        latest_meter_readings: 'Latest Meter Readings',
        vehicle_assignments: 'Vehicle Assignments',
        vehicle_locations: 'Vehicle Locations',
        map: 'Map',
        heat_map: 'Heat Map',
        activity_feed: 'Activity Feed',
        vehicle_transfer: 'Vehicle Transfer',
        by: 'By',
        driver_added_to_queue_success: 'Driver added to queue successfully',
      },

      dashboard_card_ids: {
        booking_status: "bookingStatus",
        cancelled_journey: "cancelledJourney",
        drivers_status: "driversStatus",
        emergency_status: "emergencyStatus",
        journey_status: "journeyStatus",
        live_journey_by_types: "liveJourneyByTypes",
        live_status: "liveStatus",
        parking_queues: "parkingQueues",
        passenger_by_types: "passengerByTypes",
        upcoming_journey: "upcomingJourney",
        vehicle_table: "vehicleTable",
        equipment_status: "equipmentStatus",
        inspection_failure: "inspectionFailure",
        inspection_pie_chart: "inspectionPieChart",
        inspection_submission: "inspectionSubmission",
        open_issues: "openIssues",
        overdue_inspection: "overdueInspection",
        recent_comments: "recentComments",
        repair_priority_line_chart: "repairPriorityLineChart",
        service_compliance: "serviceCompliance",
        service_cost_bar_chart: "serviceCostBarChart",
        service_reminders: "serviceReminders",
        time_to_resolve_graph: "timeToResolveGraph",
        vehicle_assignments: "vehicleAssignments",
        vehicle_status_section: "vehicleStatusSection",
        drivers: "drivers",
        vehicles: "vehicles",
        vehicle_status: "vehicleStatus",
        assigned_vehicles: "assignedVehicles",
      },

      manage_zones: {
        manage_zone: "Manage Zone",
        add_zone_lbl: "Add New Zone",
        details: "Details",
        status: "Status",
        dispatch_zone_name: "Zone Name",
        alias_name: "Alias Name",
        description: "Description",
        geofence_category: "Geofence Category",
        geofence: "Geofence",
        latitude: "Latitude",
        longitude: "Longitude",
        radius: "Radius",
        zone: "Zone",
        tag: "Tag",
        dispatch_zone: "Dispatch Zone",
        add_zone: "Add Zone",
        edit_zone: "Edit Zone",
        setup_new_zone: "Setup New Zone",
        zone_name: "Zone Name",
        zone_name_required: "Zone Name is required",
        alias_name_required: "Alias Name is required",
        radius_required: 'Radius is required',
        latitude_required: 'Latitude is required',
        longitude_required: 'Longitude is required',
        radius_nonzero_err: 'Radius should be non zero',
        radius_negative_err: 'Radius should not be negative',
        zone_name_max: 'Zone Name should not exceed more than 50 character',
        alias_name_max: 'Alias Name should not exceed more than 50 character',
        latitude_max: 'Latitude should not exceed more than 20 digits',
        longitude_max: 'Longitude should not exceed more than 20 digits',
        use_radius: "Use Radius",
        draw_geofencing: "Draw Geofence",
        default_radius: "Radius (Default : 500 meters)",
        meters: "Meters",
        color_picker: "Color Picker",
        zone_update_msg: 'Zone record updated successfully',
        lbl_location_found: 'Location found.',
        zone_name_pattern_err: 'Zone Name should accepted only alpha numeric characters.',
        alias_name_pattern_err: 'Alias Name should accepted only alpha numeric characters.',
        confirm_header: 'Confirmation',
        confirm_discard_msg: 'Are you sure you want to discard the current changes?',
        lbl_no: 'No',
        lbl_discard: 'Discard'
      },

      dispatch: {
        driver_is_already_assigned_to_booking_do_you_want_to_assign_new_driver: 'Driver is already assigned for this booking, Do you want to assign new driver for this booking?',
        driver_is_already_assigned_to_booking: 'Driver is already assigned to booking',
        confirmation: 'Confirmation',
        assign_driver_confirmation_msg: 'The selected driver is currently off duty OR on leave OR not available for booking, Do you still want to assign booking to this driver?',
        passenger_capacity_exceeds: 'Number of passengers in selected booking exceeds vehicle passenger capacity.',
        onshift: 'On Shift',
        offShift: 'Off Shift',
        search_by_passenger_and_booking_code: 'Search by passenger or booking code',
        no_route_found: 'No route available for selected booking',
        lbl_please_select_booking_to_assign_driver: 'Please Select Booking To Assign Driver.',
        passenger_type: 'Passenger Type',
        watchlist: 'Watchlist',
        uniqueId: 'Unique ID',
        contact_number: 'Contact Number',
        email_address: 'Email Address',
        barcode: 'Barcode',
        opt_in_for_trip_notifications: 'Opt In For Trip Notifications',
        gender: 'Gender',
        name: 'Name',
        dispatch: "Dispatch",
        bookings: "BOOKINGS",
        booking: "BOOKING",
        passenger_tab: "PASSENGER",
        activity: "ACTIVITY",
        assign_driver: "ASSIGN DRIVER",
        passenger: "Pass",
        child: "C",
        by: "By",
        wheelChair: "W",
        bags: "Bags",
        journey_status: "Journey Status",
        acknowledged: "Acknowledged",
        assign: "Assign",
        auto: "Auto",
        manual: "Manual",
        car: "Car",
        trans_mode: "Transportation Mode",
        trans_type: "Transportation Type",
        airport: "Airport",
        trip: "Trip",
        ride: "Ride",
        eta: "ETA",
        pickup: "Pickup Time & Date",
        return: "Return Time & Date",
        distance: "Distance",
        stop_location: "Stop Location",
        pickup_location: "Pickup Location",
        drop_location: "Drop Location",
        duration: "Duration",
        waiting_time: "Waiting Time",
        total_pass: "Total Passenger(s)",
        child_seats: "Child seat(s)",
        child_seat: "Child seat : ",
        infant_carrier: "Infant Carrier : ",
        luggages: "Luggage(s)",
        wheelchairs: "Wheelchair(s)",
        add_vehicle_requirements: "Additional Vehicle Requirement(s)",
        add_requirements: "Additional Requirement(s)",
        booking_by_name: "Booking By Name",
        booking_time: "Booking Time",
        booking_note: "Booking Note",
        note: "Note for Driver",
        driver_assign_msg: 'Driver assign Successfully',
        booking_assign_first: 'Please select booking first',
        driver_tab: {
          tab: 'DRIVER',
          name: 'Driver Name',
          shift: 'Driver Shift',
          shift_time: 'Shift Time',
          checkin_status: 'Check-in Status',
          call_sign: 'Call Sign',
          group: 'Group',
          rating: 'Ratings',
          gender: 'Gender'
        },
        schedule_tab: {
          tab: 'SCHEDULE',
        },
        vehicle_tab: {
          tab: 'VEHICLE',
          name: 'Name',
          group: 'Group',
          capacity: 'Capacity',
          vehicle_type: 'Vehicle Type',
          body_type: 'Body Type',
          make_model: 'Make & Model'
        },
        driver_status: {
          driver_status: 'Driver Status',
          availability: 'Availability',
          busy: 'Busy',
          reconfirmed: 'Reconfirmed',
          current_status: 'Current Status',
          next_upcoming: 'Next Upcoming'
        }
      },

      bookings: {
        not_assigned: 'Not assigned',
        booking_history: 'Booking History',
        bookings: 'Bookings',
        auto_assign_header: 'Confirm Auto Assign',
        auto_assign_msg: 'Are you sure you want to auto assign the driver for this booking? <br> With auto assign system will choose driver best suited for this booking.',
        remove_driver_msg: `Are you sure you want to remove driver for this booking? <br> Once removed, it can't be undone. The driver needs to be assigned again.`,
        remove_driver_msg_header: 'Want to Remove Driver',
        pickup_date_time: 'Pickup Date & Time',
        passenger: 'Passenger',
        info: 'Info',
        pickup_drop_off: 'Pickup & Drop Off',
        vehicle_driver_info: 'Vehicle & Driver Info',
        status: 'Status',
        vehicle_and_driver_info: 'Vehicle & Driver Info',
        driverRemovedSuccessMsg: 'Driver removed successfully',
        removeDriver: 'Remove Driver',
        admin_note: 'ADMIN NOTE',
        post_journey_lbl: 'Post Journey Note Details By Admin',
        lbl_select: 'Select',
        lbl_time: 'Time',
        lb_journey: "Journey",
        lbl_book_request: "Book Request",
        search_passenger_placeholder: 'Search passenger by name / barcode / URN',
        search_requested_passenger_placeholder: 'Search who is making the booking?',
        search_and_select_passenger_header: 'Search & Select Passenger(s)',
        create_new_passenger: 'Create New Passenger',
        booking_requested_by: 'Requested by',
        booking_for: 'Booking for',
        booking_select_for: 'Booking for:',
        booking_show_requested_by: 'Requested by:',
        booking_for_note: 'Select the passengers and co-passengers you want to book this journey for. If any passenger is not listed, please create a new one by clicking the plus button in the search box.',
        search_and_select_passenger_help_tip: 'e.g. If a passenger is not already listed, please add the passenger.',
        view_passenger_info: 'Please search and select passenger to view passenger information',
        booking_status: "Booking Status",
        header: "Bookings",
        edit: "Edit",
        add_bookings: "Add New Bookings",
        critical: "Critical",
        watchlist: "Watchlist",
        add_booking_msg: "Vehicle added successfully",
        confirm_delete_msg: "<p>Are you sure you want to delete this records? Deleting a record does not remove it from system, but it will no longer be usable.</p><p>Deleting a vehicle will end any associations with linked assets (vehicles and equipment), end any current vehicle assignments, and remove any future-dated vehicle assignments.</p><p><b>Vehicles with active work orders cannot be delete. Please complete any active work orders before you proceed.</b></p>",
        delete_booking: "Delete Booking",
        cancel: "Cancel",
        cancel_journey: "Cancel Journey",
        confirm_delete_header: "Confirm Delete Booking",
        update_booking: "Update Booking",
        import_booking: "Import Booking",
        bags: "Bags",
        child_seat: "C",
        wheelchair: "W",
        pet_count: 'P',
        flight_details: "Flight Details",
        flight_number: "Flight Number",
        flight_name: "Flight Name",
        arrival_time: "Arrival Time",
        reassign: "Reassign",
        assign: "Assign",
        autoAssign: "Auto Assign",
        manualAssign: "Manual Assign",
        book_request: "Create Booking",
        review:'Review',
        update_book_request: "Update Booking",
        add_new: "Add New",
        details: "Details",
        booking_added: "Booking added Successfully",
        booking_status_added: "Booking status updated Successfully",
        booking_update: "Booking Updated Successfully.",
        booking_note_added: "Booking note added successfully",
        added_passenger: "Added Passenger ({{passengerCount}})",
        optInForTripNotification: 'Opt in for trip notifications',
        save: 'Save',
        add_stops: 'Add Stops',
        pickup: ' Pickup',
        add_stop: 'Add a Stop',
        drop_off: "Drop off",
        assign_vehicle_later: 'Assign Vehicle Later',
        assign_vehicle_now: 'Assign Vehicle Now',
        any_vehicle: 'Any Vehicle',
        select_vehicle: 'Select Vehicle',
        activity: 'Activity',
        map: 'Map',
        msg_please_select_passengers: 'Please search and select passenger',
        select_pick_up_and_drop_locations: 'Select Pickup & Drop Off Location',
        pass: 'Pass',
        replace: "Replace",
        confirm_manual_assign: "Confirm Manual Assign",
        manual_assign_msg: "<p>Are you sure you want to manually assign the driver for this booking?</p> <p> With manual assign, operator need to choose driver best suited for this booking.<p>",
        lbl_photo: 'Photo',
        passenger_information: 'Passenger Information',
        selected_passenger_message: 'You have selected {{passengerCount}} passengers for this booking. Select from below to view:',
        lbl_passenger_vehicle: 'Passenger Vehicle',
        lbl_additional_passenger_vehicle: 'Additional Passenger Vehicle',
        lbl_luggage_vehicle: 'Luggage Vehicle {{luggageCount}}',
        lbl_assign_now: 'Assign Now',
        lbl_manual_dispatch: 'Manual dispatch',
        lbl_assign_vehicles_manually: 'Assign vehicles manually',
        lbl_auto_dispatch: 'Auto dispatch',
        lbl_assign_vehicles_automatically: 'Vehicles will be assigned automatically',
        lbl_passenger: "Pass",
        child: "C",
        by: "By",
        wheelChair: "W",
        lbl_bags: "Bags",
        lbl_vehicle_transfer: 'Select Vehicle To Transfer',
        lbl_select_vehicle_transfer: 'Select Vehicle To Transfer',
        lbl_booking_details: 'BOOKING DETAILS',
        lbl_vehicle_details: 'VEHICLE DETAILS',
        lbl_driver_details: 'DRIVER DETAILS',
        boooking_id: 'Booking ID',
        lbl_activity_status: 'Status',
        lbl_activity_time_date: 'Time & Date',
        lbl_activity_updated_by: 'Updated By',
        lbl_activity_location: 'Location',
        lbl_special_request: 'SPECIAL REQUEST ',
        lbl_special_request_text:'Please review & confirm special booking request',
        lbl_passenger_vehicle_count: ' You can modify additional vehicle counts added by passenger & save the request. If you make any changes passenger will get notified.',
        lbl_note: 'Note:',
        lbl_luggage_vehicle_count: 'Luggage Vehicle Count',
        lbl_passenger_details: 'Passenger Details',
        lbl_journey_details: 'Journey Details',
        lbl_passenger_type: 'Passenger Type',
        lbl_total_passengers: 'Total Passenger(s)',
        lbl_additional_vehicles: 'Additional Vehicle(s)',
        lbl_luggage_vehicles: 'Luggage Vehicle(s)',
        lbl_child_seats: 'Child seat(s)',
        lbl_luggage: 'Luggage',
        lbl_wheelchairs: 'Wheelchair(s)',
        lbl_pet_accomodation: 'Pet Accommodation',
        lbl_modify_details: 'Modify Details',
        lbl_pickup: 'Pickup',
        lbl_stop: 'Stops',
        lbl_drop: 'Drop',
        lbl_booking_id: 'Booking ID',
        lbl_status: 'Status',
        lbl_keep_journeys: 'Keep Journeys',
        lbl_cancel_text_part_1: ' Once driver is',
        lbl_cancel_text_part_2: ' on the way',
        lbl_cancel_text_part_3: ' booking cannot be cancelled',
        lbl_main_booking: '(Main Booking)',
        lbl_cancel_booking_text: 'Are you sure you want to cancel The Main Booking?',
        lbl_cancel_booking_body_text: 'With main booking all associated booking will get cancelled. Once cancelled it cannot be undone.',
        lbl_go_back: 'Go back',
        lbl_cancel_journey_button: 'Yes, Cancel journey',
        lbl_cancellation_popup_text :'You must select the main booking to proceed with the cancellation.',
        lbl_booking_vehicle_transfer: 'Vehicle Transfer',
        lbl_minutes: 'Mins',
        lbl_pickup_location: 'Pickup Location',
        lbl_dropoff_location: 'Dropoff Location',
        lbl_part_of: 'Part of ',
        lbl_on: 'ON',
        lbl_off: 'OFF',
        lbl_confirmation: 'Confirmation',
        confirmation_msg: 'Are you sure you want to apply these location changes to all associated bookings?',
        booking_manual_dispatch: 'Manual Dispatch',
        booking_auto_dispatch: 'Auto Dispatch',
        disable_stops_msg: 'You can not change the pickup, stops locations once request sent',
        disable_body_type_msg: 'You can not change the vehicle body type preference',
        disable_pickup_date_time_msg: 'You can not change the pickup date and time once request sent',
        on_shift_pickup: 'On Shift For Pickup',
        off_shift_pickup: 'Off Shift For Pickup'
      },

      vehicle: {
        lbl_selected_driver: "Selected Driver",
        print_label: "Print Labels",
        message: {
          assign_driver_header: "Assign Driver",
          assign_driver_message: `The driver you've selected is already assigned to another vehicle. Do you want to proceed and assign this vehicle to the selected driver? This action will replace the existing vehicle for this driver.`,
          add_vehicle: "Vehicle added successfully",
          confirm_delete_msg: '<p>Are you sure you want to delete this records? Deleting a record does not remove it from system, but it will no longer be usable.</p><p>Deleting a vehicle will end any associations with linked assets (vehicles and equipment), end any current vehicle assignments, and remove any future-dated vehicle assignments.</p><p><b>Vehicles with active work orders cannot be delete. Please complete any active work orders before you proceed.</b></p>',
          delete_vehicle: 'Delete Vehicle',
          cancel: 'Cancel',
          confirm_delete_header: 'Confirm Delete Vehicle',
          document_remove_msg: 'Are you sure you want to remove this document? Doing so will delete it from the system and end any associations with linked vehicle.'
        },
        add_multiple_vehicle: 'Add Multiple Vehicles',
        import_vehicle: 'Import Vehicles',
        download_important_template: 'Download Import Template',
        download_important_guide: 'Download Import Guide',
        add_gps_telematics_integration: 'Add GPS / Telematics Integration',
        export_xlsx_csv: 'Export XLSX / CSV',
        update_vehicle: 'Update Vehicle',
        delete: 'Delete',
        lbl_edit_vehicle: 'Edit Vehicle',
        lbl_save_vehicle: 'Save Vehicle',
        add: 'Add',
        edit: 'Edit',
        cancel: 'Cancel',
        remove: 'Remove',
        vehicle_feature_add_err_msg: 'Input cannot empty, start with a space or special symbol.',
        document_remove_header: 'Remove Document',
        vehicle_added: ' Vehicle Added Successfully',
        vehicle_document_added: 'Document added Successfully',
        vehicle_update_message: 'Vehicle Details Updated Successfully',
        lbl_overview: 'Overview',
        lbl_documents: 'Documents',
        lbl_specs: 'Specs',
        lbl_financial: 'Financial',
        lbl_service_history: 'Service History',
        lbl_inspection_history: 'Inspection History',
        lbl_service_reminders: 'Service Reminders',
        lbl_assignment_history: 'Assignment History',
        lbl_journey_history: 'Journey History',
        lbl_qr_sticker: 'QR Sticker',
        lbl_qr_instruction: 'Make a Vehicle Verification / Assignment Process Smarter with QR code Label and Scan',
        lbl_details: 'Details',
        lbl_edit: 'Edit',
        lbl_link_equipment: 'Linked Equipment',
        all_time: 'All Time',
        lbl_more: 'More',
        lbl_issues: 'Issues',
        lbl_fuel_history: 'Fuel History',
        lbl_expense_history: 'Expense History',
        last_known_location: 'Last Known Location',
        lbl_weight: 'Weight',
        lbl_performance: 'Performance',
        lbl_fuel_economy: 'Fuel Economy',
        lbl_fuel: 'Fuel',
        lbl_oil: 'Oil',
        lbl_dimensions: 'Dimensions',
        lbl_engine: 'Engine',
        lbl_transmission: 'Transmission',
        lbl_wheels_and_tiers: 'Wheels & Tiers',
        lbl_width: 'Width',
        lbl_height: 'Height',
        lbl_length: 'Length',
        lbl_interior_volume: 'Interior Volume',
        lbl_passenger_volume: 'Passenger Volume',
        lbl_cargo_volume: 'Cargo Volume',
        lbl_cargo_compartment_dimensions: 'Cargo Compartment Dimensions (ft)',
        lbl_ground_clearance: 'Ground Clearance',
        lbl_bed_length: 'Bed Length',
        lbl_curb_weight: 'Curb Weight',
        lbl_gross_vehicle_weight_rating: 'Gross Vehicle Weight Rating',
        lbl_towing_capacity: 'Towing Capacity',
        lbl_max_payload: 'Max Payload',
        lbl_epa_city: 'EPA City',
        lbl_epa_highway: 'EPA Highway',
        lbl_epa_combined: 'EPA Combined',
        lbl_fuel_type: 'Fuel Type',
        lbl_expected_mileage: 'Expected Mileage',
        lbl_fuel_quality: 'Fuel Quality',
        lbl_fuel_tank_capacity: 'Fuel Tank Capacity',
        lbl_engine_summary: 'Engine Summary',
        lbl_engine_brand: 'Engine Brand',
        lbl_aspiration: 'Aspiration',
        lbl_block_type: 'Block Type',
        lbl_bore: 'Bore',
        lbl_cam_type: 'Cam Type',
        lbl_compression: 'Compression',
        lbl_cylinders: 'Cylinders',
        lbl_displacement: 'Displacement',
        lbl_fuel_induction: 'Fuel Induction',
        lbl_stroke: 'Stroke',
        lbl_valves: 'Valves',
        lbl_transmission_summary: 'Transmission Summary',
        lbl_transmission_brand: 'Transmission Brand',
        lbl_transmission_type: 'Transmission Type',
        lbl_transmission_gears: 'Transmission Gears',
        lbl_drive_type: 'Drive Type',
        lbl_brake_system: 'Brake System',
        lbl_front_track_width: 'Front Track Width',
        lbl_rear_track_width: 'Rear Track Width',
        lbl_wheelbase: 'Wheelbase',
        lbl_front_wheel_diameter: 'Front Wheel Diameter',
        lbl_rear_wheel_diameter: 'Rear Wheel Diameter',
        lbl_number_of_axles: 'Number of Axles',
        lbl_front_axle: 'Front Axle',
        lbl_rear_axle: 'Rear Axle',
        lbl_front_tyre_type: 'Front Tyre Type',
        lbl_rear_tyre_type: 'Rear Tyre Type',
        lbl_front_tyre_psi: 'Front Tyre PSI',
        lbl_rear_tyre_psi: 'Rear Tyre PSI',

        lbl_meter: 'Meter',
        lbl_status: 'Status',
        lbl_group: 'Group',
        lbl_operator_driver: 'Operator/Driver',
        lbl_type: 'Type',
        lbl_vin_sn: 'VIN / SN',
        lbl_license_plate: 'License Plate',
        lbl_year: 'Year',
        lbl_make: 'Make',
        lbl_model: 'Model',
        lbl_trim_variant: 'Trim / Variant',
        lbl_region_geofence: 'Region / Geofence',
        lbl_color: 'Color',
        lbl_ownership: 'Ownership',
        lbl_body_type: 'Body Type',
        lbl_msrp: 'MSRP',
        lbl_update: 'Update Vehicles',

      },

      events: {
        lbl_yes: 'Yes',
        lbl_no: 'No',
        event_change_confirmation_msg: 'Changing event will reload the admin portal, Are you sure you want to continue?',
        confirmation_msg: 'Creating or Modifying event will reload the admin portal, Are you sure you want to continue?',
        confirmation: 'Confirmation',
        configuration_settings: 'Configuration Settings',
        event_configuration: 'Event Configuration',
        select_short_date_format: 'Select Short Date Format',
        select_time_format: 'Select Time Format',
        short_date_is_required: 'Short Date is Required.',
        please_add_event_to_add_time_settings: 'Please Add event details to add time and regional settings',
        status: 'Date',
        sidebar_lbl_events: 'Events',
        lbl_state: 'State',
        add_event: 'Add Event',
        edit_event: 'Edit Event',
        lbl_update_events: 'Update Event',
        lbl_edit: 'Edit',
        update_events: 'Update Events',
        delete: "Delete",
        event_added_successfully: 'Event Added Successfully.',
        event_updated_successfully: 'Event Details Updated Successfully.',
        lbl_details: 'Details',
        event_related_settings: 'Event Related Settings',
        lbl_address: 'Address',
        lbl_save: 'Save',
        lbl_cancel: 'Cancel',
        lbl_event_deleted_successfully: 'Event Deleted Successfully.',
        lbl_review_events: 'Review Events',
        lbl_add_new_events: 'Add New Event',
        lbl_make_event_inactive: 'Make Event Inactive',
        lbl_make_event_active: 'Make Event Active',
        lbl_make_event_inactive_msg: 'Creating or Modifying event will reload the admin portal, Are you sure you want to make event inactive?',
        lbl_make_event_active_msg: 'Creating or Modifying event will reload the admin portal, Are you sure you want to make event active?',
        lbl_event_status_updated_successfully: 'Event status updated successfully.',
        lbl_regional_settings: 'Regional Settings',
        short_date_format: 'Short Date Format',
        time_zone: 'Time Zone',
        time_format: 'Time Format',
        time_settings: 'Time Settings',
        message: {
          confirm_delete_msg: '<p></p><p>Are you sure you want to delete this events?</p><p><b>Once deleted, it can\'t be undone.</b></p><p></p>',
          delete_vehicle: 'Delete Event',
          cancel: 'Cancel',
          confirm_delete_header: 'Confirm Delete Event',
        },
        columns: {
          name: 'Event',
          code: 'Short Code',
          start_date: 'Start Date',
          end_date: 'End Date',
          state: 'State',
          status: 'Status'
        },
        lbl_select_an_event: 'Select an Event',
        lbl_name: 'Name',
        lbl_enter_event_name: 'Enter Event Name',
        name_is_required: 'Name is required.',
        lbl_short_code: 'Short Code',
        lbl_enter_event_short_code: 'Enter event Short Code',
        short_code_is_required: 'Short Code is required.',
        lbl_transport_supervisor_number: 'Transport Supervisor Number',
        lbl_enter_transport_supervisor_number: 'Enter Transport Supervisor Number.',
        transport_supervisor_contact_number_is_required: 'Transport Supervisor Number is required.',
        lbl_emergency_number: 'Emergency Number',
        lbl_emergency_contact_number: 'Emergency Contact Number',
        emergency_contact_number_is_required: 'Emergency Number is required.',
        lbl_enter_emergency_number: 'Enter Emergency Number.',
        description: 'Description',
        map_address: 'Map Address',
        map_address_is_required: 'Map Address is required.',
        event_logo: 'Event Logo',
        image: 'Image',
        lbl_this_image_will_appear_in_event_list: 'This image will appear in event list',
        lbl_this_address_will_be_used_to_determine_the_timezone_for_this_event: 'This address will be used to determine the timezone for this event.',
        lbl_related_settings: 'Related Settings',
        transportation_type: 'Transportation Type',
        transportation_type_is_required: 'Transportation Type is required.',
        select_transportation_type: 'Select Transportation Type',
        start_date_is_required: 'Start Date is required',
        transportation_start_date: 'Start Date (Transportation Start Date)',
        transportation_end_date: 'End Date (Transportation End Date)',
        end_date_is_required: 'End Date is required.',
        start_booking_date_passenger_app: 'Start Booking Date (Passenger App)',
        stop_booking_date_passenger_app: 'Stop Booking Date (Passenger App)',
        start_booking_date_is_required: 'Start Booking Date is required.',
        end_booking_date_is_required: 'End Booking Date is required.',
        operating_radius: 'Operating Radius',
        broadcast_radius: 'Broadcast Radius',
        select_operating_radius: 'Select Operating Radius',
        enter_broadcast_radius: 'Enter Broadcast Radius',
        operating_radius_is_required: 'Operating Radius is required',
        select_transportation_start_date: 'Select Transportation Start Date',
        select_transportation_end_date: 'Select Transportation End Date',
        select_start_booking_date: 'Select Start Booking Date',
        select_stop_booking_date: 'Select Stop Booking Date',
        lbl_the_maximum_distance_the_services_can_be_provided_or_operate_effectively: 'The maximum distance the services can be provided or operate effectively.',
        lbl_the_radius_will_be_used_for_serving_the_drivers_for_booking_at_the_time_of_auto_broadcast: 'The radius will be used for serving the drivers for the bookings at the time of Auto Broadcast.',
        please_enter_valid_event_name: 'Please enter valid event name.',
        please_enter_valid_short_code: 'Please enter valid short code.',
        description_is_required: 'Description is required.',
        time_and_regional_settings: 'Time & Regional Settings',
        lbl_regional_settings_saved_successfully: 'Regional Settings saved successfully.'
      },

      shiftTemplate: {
        shift_template: 'Shift Template',
        add_new: 'Add New',
        add_new_shift_template: 'Add New Shift Template',
        edit_shift_template: 'Edit Shift template',
        lbl_update_shift_template: 'Update Shift Template',
        lbl_edit: 'Edit',
        update_shift_templates: 'Update Shift Templates',
        delete: "Delete",
        shift_templates: 'Shift Templates',
        lbl_details: 'Details',
        lbl_shift_template_deleted_successfully: 'Shift Template Deleted Successfully.',
        shift_name_pattern: 'Please enter valid shift name.',
        shift_name_exceeds_max_character: 'Shift name exceeds max character limit. (Max char limit - 50)',
        calculate_hours: 'Calculate Hours',
        lbl_save_template: 'Save Template',
        lbl_update_template: 'Update Template',
        lbl_save_and_add_another: 'Save & Add Another',
        start_time_is_required: 'Start Time is required',
        end_time_is_required: 'End Time is required',
        shift_name_is_required: 'Shift Name is required',
        invalid_time: 'End time should be greater than Start time.',
        import_shift_templates: 'Import Shift Templates',
        download_import_template: 'Download Import Template',
        download_import_guide: 'Download Import Guide',
        shift_template_added_successfully: 'Shift Template Added successfully.',
        shift_template_updated_successfully: 'Shift Template Updated successfully.',
        lbl_enter_shift_template_name: 'Enter Shift Template Name',
        lbl_enter_start_time: 'Select Start Time',
        lbl_enter_end_time: 'Select End Time',
        enter_break_start_time: 'Select Break Start Time',
        enter_break_end_time: 'Select Break End Time',
        columns: {
          name: 'Name',
          startTime: 'Start Time',
          endTime: 'End Time',
          hours: 'Hours',
          breaks: 'Breaks',
          createdOn: 'Created On',
        },
        message: {
          confirm_delete_msg: '<p></p><p>Are you sure you want to delete this Shift Template?</p><p><b>Once deleted, it can\'t be undone.</b></p><p></p>',
          delete_shift_template: 'Delete Shift Template',
          cancel: 'Cancel',
          confirm_delete_header: 'Confirm Delete Shift Template',
        },
      },

      organization: {
        orgname_max_length_100_character: 'Organization name field should accepted 100 alphanumeric only.',
        please_enter_valid_org_name: 'Only alphanumeric characters allowed in organization name.',
        firstname_max_length_100_character: 'First name field should accepted 100 alphabets only.',
        lastname_max_length_100_character: 'Last name field should accepted 100 alphabets only.',
        password_max_length_64_character: 'Password max length is 64.',
        lbl_organization_deactivated_successfully: 'Organization Deactivated Successfully.',
        lbl_organization_activated_successfully: 'Organization Activated Successfully.',
        are_you_sure_you_want_to_activate_organization:' Are you sure, you want to activate organization?',
        are_you_sure_you_want_to_deactivate_organization:' Are you sure, you want to deactivate organization?',
        activate_organization : 'Activate Organization',
        deactivate_organization: 'Deactivate Organization',
        lbl_settings: 'Settings',
        shuttle_booking: 'Shuttle Booking',
        vehicle_auto_dispatch: 'Vehicle Auto Dispatch',
        unique_identification: 'Unique Identifications',
        unique_identification_text: 'Select which methods will be used as unique identifiers for organization. It will be applicable for all events.',
        admin_user_not_assigned: 'Admin user not assigned to Organization',
        lbl_enter_company_name: 'Enter Organization Name',
        lbl_enter_company_code: 'Enter Organization Code',
        lbl_enter_email_address: 'Enter Email Address',
        lbl_enter_contact_number: 'Enter Contact Number',
        lbl_enter_username: 'Enter User Name',
        lbl_enter_password: 'Enter Password',
        lbl_confirm_password: 'Enter Password Again',
        lbl_enter_first_name: 'Enter First Name',
        lbl_enter_last_name: 'Enter Last Name',
        lbl_there_is_no_data_to_save: 'There is no data to save',
        save_organization: 'Save Organization',
        please_enter_valid_first_name: 'First Name should include only alphanumeric characters.',
        please_enter_valid_last_name: 'Last Name should include only alphanumeric characters.',
        username_is_required: 'User Name is required.',
        password_is_required: 'Password is required.',
        password_must_contain: 'Password Must Contain, ',
        at_least_one_uppercase_letter: 'At least one uppercase letter, ',
        at_least_one_lowercase_letter: 'At least one lowercase letter, ',
        at_least_one_number: 'At least one number, ',
        at_least_one_special_character: 'At least one special character, ',
        minimum_eight_character: 'Minimum eight characters',
        company_details: 'Organization Details',
        time_settings: 'Time Settings',
        lbl_organizations: 'Organizations',
        lbl_organization: 'Organization',
        add_organization: 'Add Organization',
        edit_organization: 'Edit Organization',
        details: 'Details',
        user_details: 'Admin Details',
        reset_password: 'Reset Password',
        enter_password: 'Enter Password',
        enter_new_password_for_organization: 'Enter new password for organization',
        remove_access_to_module: 'Remove Access To Module',
        are_you_sure_to_remove_access_of_this_module: 'Are you sure you want to remove access to this module?',
        lbl_company_details: 'Company Details',
        username: 'User Name',
        password: 'Password',
        overview: 'Overview',
        accessToModules: 'Access to Modules',
        lbl_organization_name: 'Organization Name',
        lbl_company_name: 'Company Name',
        lbl_name: 'Name',
        lbl_code: 'Code',
        lbl_logo: 'Logo',
        lbl_email_address: 'Email Address',
        lbl_contact_number: 'Contact Number',
        lbl_time_settings: 'Time Settings',
        lbl_enable_or_disable_specific_notifications_about_vehicles: 'Enable or disable specific notifications about Vehicles',
        time_setting_for: 'Time Setting For',
        lbl_hours: 'Hours',
        lbl_minutes: 'Minutes',
        lbl_seconds: 'Seconds',
        name_is_required: 'Organization Name is required.',
        code_is_required: 'Code is required.',
        email_is_required: 'Email is required.',
        contact_number_is_required: 'Contact Number is required.',
        lbl_org_name_text: 'Company or organization name, or whatever you want to name your fleet.',
        lbl_organization_saved_successfully: 'Organization saved successfully.',
        lbl_dashboard: 'Dashboard',
        lbl_first_name: 'First Name',
        lbl_last_name: 'Last Name',
        first_name_is_required: 'First Name is required.',
        last_name_is_required: 'Last Name is required.',
        logo: 'Logo',
        lbl_save_organization: 'Save',
        lbl_update_organization: 'Update',
        lbl_organization_status_updated_successfully: 'Organization Status Updated Successfully.',
        lbl_access_to_modules_saved_successfully: 'Access to modules saved successfully.',
        lbl_industry: 'Industry',
        lbl_select_industry: 'Select Industry',
        lbl_edit: 'Edit',
        lbl_vehicle_management: 'Vehicle Management',
        lbl_bookings: 'Bookings',
        lbl_dispatch: 'Dispatch',
        lbl_passenger_management: 'Passenger Management',
        lbl_driver_management: 'Driver Management',
        lbl_event_management: 'Event Management',
        lbl_manage_locations: 'Manage Locations',
        lbl_manage_zones: 'Manage Zones',
        lbl_queue_management: 'Queue Management',
        lbl_incidents_and_complaints: 'Incidents and Complaint',
        lbl_shift_management: 'Shift Management',
        lbl_communication: 'Communication',
        lbl_user_management: 'User Management',
        lbl_details: 'Details',
        columns: {
          name: 'Name',
          code: 'Code',
          emailAddress: 'Email Address',
          status: 'Status',
          phoneNumber: 'Phone Number',
          contactNumber: 'Contact Number'
        },
        lbl_details_settings: 'SETTINGS',
        lbl_unique_identifications: 'UNIQUE IDENTIFICATIONS',
        lbl_shuttle_bookings: 'Shuttle Bookings',
        lbl_vehicle_auto_dispatch: 'Vehicle Auto Dispatch',
        lbl_unique_identifiers: 'Unique Identifiers',
      },


      shift: {
        dutySatus: 'Duty Status',
        discard: 'Discard',
        confirmation: 'Confirmation',
        discard_changes: 'Are you sure, you want to discard the changes?',
        can_not_publish_unpublish_past_shifts: 'Can not publish/unpublish past shifts',
        are_you_sure_you_want_to_delete_this_break: 'Are you sure, You want to delet this break.',
        delete_break: 'Delete Break',
        lbl_vehicle: 'Vehicle',
        lbl_shift_published_successfully: 'Shift Published successfully',
        all_shifts: 'All shifts',
        lbl_search_by_name_or_shift: 'Search by name or shift',
        driverType: 'Driver Type',
        shift_name_pattern: 'Please enter valid shift name',
        name_is_required: 'Name is required.',
        shift_name_exceeds_max_character: 'Shift name exceeds max character limit. (Max char limit - 50)',
        shift_template_is_required: 'Shift Template is required.',
        start_date_is_required: 'Start Date is required.',
        end_date_is_required: 'End Date is required.',
        export_calendar: 'Export Calendar',
        individuals: 'Individuals',
        assign_drivers_to_shift: 'Assign Drivers To Shift',
        lbl_save: 'Save',
        name: 'Name',
        start_time: 'Start Time',
        end_time: 'End Time',
        days: 'Days',
        reason: 'Reason',
        created_on: 'Created On',
        created_by: 'Created By',
        total_drivers: 'Total Drivers',
        drivers_in_group: 'Drivers in group',
        color: 'Color',
        total_break_duration: 'Total Break Duration',
        driver_type: 'Driver Type',
        shift_details: 'Shift Details',
        export_xlsx_csv: 'Export XLSX / CSV',
        lbl_download_shift_template: 'Download Shift Template',
        lbl_download_shift_guide: 'Download Shift Guide',
        lbl_bulk_shift_import: 'Bulk Shift Import',
        lbl_shift_list: 'Shift List',
        lbl_add_shift: 'Add Shift',
        lbl_delete_shift: 'Delete Shift',
        lbl_clear_shift: 'Clear Shift',
        lbl_unpublish_new: 'Unpublish',
        lbl_save_shift: 'Save',
        add_new_shift: 'Add New Shift',
        lbl_update_shift: 'Update Shift',
        lbl_edit: 'Edit',
        delete: "Delete",
        shift_added_successfully: 'Shift Added Successfully',
        shift_updated_successfully: 'Shift Updated Successfully',
        lbl_shift: 'Shift',
        lbl_shifts: 'Shifts',
        lbl_shift_deleted_successfully: 'Shift Deleted Successfully',
        search_driver: 'Search Driver',
        search_group: 'Search Group',
        message: {
          confirm_delete_msg: '<p></p><p>Are you sure you want to delete this shift?</p><p><b>Once deleted, it can\'t be undone.</b></p><p></p>',
          delete_shift: 'Delete shift',
          cancel: 'Cancel',
          confirm_delete_header: 'Confirm Delete Shift',
        },
        columns: {
          name: 'Name',
          start_date: 'Start Date',
          end_date: 'End Date',
          start_time: 'Start Time',
          break_duration: 'Break Duration',
          driver_type: 'Driver Type',
          assign_to: 'Assign To'
        },
        lbl_shift_details: 'Shift Details',
        lbl_shift_template: 'Shift Template',
        lbl_add_manually: 'Add Manually',
        lbl_shift_name: 'Shift Name',
        lbl_shift_start_date: 'Start Date',
        lbl_end_date: 'End Date',
        lbl_start_time: 'Start Time (From)',
        lbl_end_time: 'End Time (To)',
        lbl_break: 'Break',
        lbl_add_break: 'Add Break',
        lbl_reason_for_break: 'Reason for Break',
        lbl_not_added: 'Not added',
        lbl_break_start_time_from: 'Start Time (From)',
        lbl_break_end_time_from: 'End Time (To)',
        lbl_assignment: 'Assignment',
        lblP_individual_driver: 'Individual Driver',
        lbl_drivers_group: 'Drivers Group',
        lbl_drivers: 'Drivers',
        lbl_search_driver: 'Search Driver',
        lbl_add_reason: 'Add Reason',
        lbl_edit_reason: 'Edit Reason',
        lbl_save_reason: 'Save Reason',
        lbl_edit_shift: 'Edit Shift',
        manage_shift: 'Manage Shift',
        shifts_calendar: 'Shifts Calendar',
        download_shift_template: 'Download Shift Template',
        bulk_shift_import: 'Bulk Shift Import',
        download_shift_guide: 'Download Shift Guide',
        add_shift: 'Add Shift',
        publish: 'Publish',
        publish_shift: 'Publish Shift',
        notify_employees_with_changes: 'Notify employees with changes',
        notify_all_employees: 'Notify all employees',
        dont_notify_just_published: 'Don\'t notify, just mark as published',
        dont_notify_just_unpublished: 'Don\'t notify, just mark as Unpublished',
        clear_shift: 'Clear Shift',
        clear_shift_msg: 'Are you sure you want to clear 7 Shifts? Doing so will remove it from the calendar and end any associations with further Shifts.',
        clear_single_shift_msg: 'Are you sure you want to clear this Shift? Doing so will remove it from the calendar and end any associations with further Shift.',
        dayOff: 'Day Off',
        available: 'Available',
        print: 'Print Labels',
        publish_shifts: 'Publish Shifts',
        unpublish_shifts: 'Unpublish Shifts',
        unpublish_shift: 'Unpublish Shift',
        clear_shifts: 'Clear Shifts',
        mobileNumber: 'Mobile Number',
        contactNumber: 'Contact Number',
        gender: 'Gender',
        status: 'Status',
        email: 'Email',
        az: 'A - Z',
        za: 'Z - A',
        noEndTime: 'No End Time',
        breaks: 'Breaks',
        groupMembers: 'Group Members',
        drivers: 'Drivers',
        groups: 'Groups',
        driverAssigned: 'Driver Assigned',
        lbl_year_make_model: 'Year Make Model',
        group: 'Group',
        driver: 'Driver',
        vin_sin: 'VIN/SIN',
        lbl_type: 'Type',
        lbl_publish: 'Publish',
        lbl_unpublish: 'Un-Publish',
        lbl_name: 'Name',
        lbl_select_shift: 'Select a Shift Template',
        lbl_enter_shift_name: 'Enter Shift Name',
        lbl_select_start_date: 'Select Start Date',
        lbl_select_end_date: 'Select End Date',
        lbl_select_start_time: 'Select Start Time',
        lbl_select_end_time: 'Select End Time',
        lbl_select_reason: 'Select Reason'
      },

      passengers: {
        address_deleted_successfully: 'Address deleted successfully',
        enter_seed_number: 'Enter seed number',
        lbl_delete_location: 'Delete Location',
        lbl_are_you_sure_you_want_to_delete_this_location: 'Are you sure! you want to delete this location?',
        location_name_is_required: 'Location name is required',
        title_for_transportation_end_date_in_passenger_list: 'The date on which the passenger\'s transportation service will end',
        add_passengers_lbl: "Add Passenger",
        edit_passengers_lbl: "Edit Passenger",
        lbl_details: 'Details',
        update_passengers: 'Update Passenger',
        lbl_update_passengers: 'Update Passengers',
        lbl_review_passengers: 'Review Passenger',
        delete: 'Delete',
        print_label: 'Print Labels',
        save_passenger_label: 'Save Passenger',
        lbl_passengers: 'Passengers',
        lbl_passenger: 'Passenger',
        lbl_passengers_add: 'Add',
        lbl_passengers_edit: 'Edit',
        lbl_stay_home_address: 'Stay / Home Address',
        privilege_data_saved_successfully: 'Passenger Privilege data saved successfully.',
        privilege_data_updated_successfully: 'Passenger Privilege data updated successfully.',
        lbl_passenger_saved_successfully: 'Passenger Saved Successfully.',
        please_fill_mandatory_data_in_details_tab: 'Please fill mandatory data in details tab.',
        message: {
          confirm_delete_msg: '<p></p><p>Are you sure, you want to delete this Passenger?</p><p><b>Once delete, it can\'t be restored. The passenger needs to be added again.</b></p><p></p>',
          delete_vehicle: 'Delete Vehicle',
          cancel: 'Cancel',
          confirm_delete_header: 'Delete Passenger',
          empty_result_msg: 'Passenger not found.',
          confirm_passenger_active: 'Are you sure, you want to make active this passenger?',
          confirm_passenger_inactive: 'Are you sure, you want to make Inactive this passenger?',
          change_passenger_status: 'Change Passenger Status'
        },
        lbl_privilege_settings: 'Privilege Settings',
        lbl_app_access: 'App Access',
        lbl_classification: 'Classification',
        lbl_transportation_restrictions: 'Transportation Restrictions',
        lbl_booking_time_restriction: 'Booking Time Restrictions',
        lbl_assign_location: 'Assign Locations',
        lbl_home_stay_location_restriction: 'Home / Stay Location Restrictions',
        lbl_special_request: 'Special Request',
        lbl_other_information: 'Other Information',
        update_passenger: 'Update Passenger',
        lbl_edit: 'Edit',
        lbl_make_passenger_inactive: 'Make Passenger Inactive',
        lbl_make_passenger_active: 'Make Passenger Active',
        lbl_delete_passenger: 'Delete Passenger',
        import_passenger: 'Import Passenger',
        download_important_template: 'Download Import Template',
        download_important_guide: 'Download Import Guide',
        export_xlsx_csv: 'Export CSV / XLS',
        only_three_location: 'Only Four location allowed.',
        lbl_passenger_deleted_successfully: 'Passenger Deleted Successfully',
      },

      driver: {
        add_driver_lbl: "Add Drivers",
        lbl_add_driver: "Add Driver",
        update_driver: 'Update Drivers',
        delete: 'Delete',
        add: 'Add',
        edit: 'Edit',
        cancel: 'Cancel',
        remove: 'Remove',
        document_remove_header: 'Remove Document',
        driver_added: 'Driver Added',
        import_driver: 'Import Drivers',
        offDutyMessage: 'Are you sure you want to make driver off duty?',
        onDutyMessage: 'Are you sure you want to make driver on duty?',
        add_multiple_driver: 'Add Multiple Driver',
        download_import_template: 'Download Import Template',
        download_import_guide: 'Download Import Guide',
        export_xlsx_csv: 'Export XLSX / CSV',
        active_driver_message: 'Are you sure you want to make driver active?',
        inactive_driver_message: 'Are you sure you want to make driver inactive?',
        active_driver_header_message: 'Make Driver Active',
        inactive_driver_header_message: 'Make Driver Inactive',
        driver_status: 'Driver Active successfully',
        driver_status_inactive: 'Driver Inactive successfully',
        lbl_assignment_history: 'Assignment History',
        lbl_documents: 'Documents',
        lbl_journey_history: 'Journey History',
        lbl_ratings_performance_analytics: 'Ratings & Performance Analytics',
        lbl_training: 'Training',
        lbl_attendance: 'Attendance',
        lbl_overview: 'Overview',
        lbl_driver_deleted_successfully: 'Driver Deleted Successfully',
        lbl_modify: 'Modify Default Vehicle',
        lbl_release_default_vehicle: 'Release Default Vehicle',
        lbl_release_active_vehicle: 'Release Active Vehicle',
        lbl_make_driver_inactive: 'Make Driver Inactive',
        lbl_make_driver_active: 'Make Driver Active',
        lbl_on_duty_reason: 'On Duty Reason',
        lbl_off_duty_reason: 'Off Duty Reason',
        review_header: 'Review Driver',
        updateBtnLbl: 'Driver',
        message: {
          release_default_vehicle_header: 'Release Default Vehicle',
          release_default_vehicle: 'Are you sure you want to remove default vehicle for this driver?',
          release_active_vehicle_header: 'Release Active Vehicle',
          release_active_vehicle: 'Are you sure you want to remove active vehicle for this driver?',
          release_driver_group_header: 'Release Driver Group',
          release_driver_group: 'Are you sure you want to remove driver group for this driver?',
          add_driver: "Driver added successfully",
          confirm_delete_msg: '<p>Are you sure you want to delete this Driver?</p><p><b>Once delete, it can\'t be undone. The driver needs to be added again.</b></p><p></p>',
          delete_driver: 'Delete Driver',
          cancel: 'Cancel',
          confirm_delete_header: 'Confirm Delete Driver',
          document_remove_msg: 'Are you sure you want to remove this document? Doing so will delete it from the system and end any associations with linked driver.'
        },
      },

      incidents_complaints: {
        lbl_route_path: 'Concerns',
        lbl_route_path_add: 'Add Concerns',
        lbl_route_path_edit: 'Edit Concerns',
        add_new_lbl: 'Add New',
        delete: 'Delete',
        lbl_update: 'Update Concerns',
        import_incidents_complaint: 'Import Concerns',
        export_xlsx_csv: 'Export XLSX / CSV',
        add: 'Add',
        edit: 'Edit',
        lbl_bulk_update_header: 'Update Concerns',
        lbl_entity_name_display: 'Concern',
        lbl_review_incident: 'Review Concerns',
        btn_bulk_update: 'Concerns',
        lbl_save: 'Save',
        lbl_cancel: 'Cancel',
        lbl_overview: 'Overview',
        lbl_overview_details: 'Details',
        lbl_address: 'Address',
        lbl_timeline: 'Timeline',
        lbl_journey: 'Journey',
        lbl_id: 'ID',
        lbl_title: 'Title',
        lbl_summary: 'Summary',
        message: {
          add_incidents: "Concerns Added Successfully",
          update_incidents: "Concerns Updated Successfully",
          delete_incidents: "Concerns Delete Successfully",
          confirm_delete_msg: '<p></p><p>Are you sure you want to delete this Concerns?</p><p><b>Once delete, it can\'t be undone. The Concerns needs to be added again.</b></p><p></p>',
          delete_incidents_complaints: 'Delete Concerns',
          cancel: 'Cancel',
          confirm_delete_header: 'Confirm Delete Concerns',
          bulk_row_sub_header2: 'Concerns. The new value for the selected fields will be applied to all selected Concerns.',
          confirm_change_status_header: 'Confirm',
          confirm_change_status_msg: '<p></p><p>Are you sure you want to change the status?</p>',
          confirm_resolved_status_header: 'Resolved status',
          confirm_resolved_status_msg: '<p></p><p>No action can be performed. Concern is resolved.</p>',
        },

      },
      lbl_add_new_features: 'Add new features',
      lbl_add_document: 'Add Document',
      lbl_next: 'Next',
      lbl_previous: 'Previous',
      lbl_add_new_vehicles: 'Add New Vehicle',
      lbl_edit_vehicles: 'Edit Vehicle',
      lbl_save_vehicle: 'Save Vehicle',
      lbl_update_vehicle: 'Update Vehicle',
      lbl_select_all: 'Select All',
      lbl_select_all_count: 'Select All',
      lbl_select_field: '{{field}}',
      lbl_print_labels: 'Print Labels',
      print_label_sub_header: 'This will generate a printable PDF template of labels for the ',
      print_label_sub_header2: 'selected vehicles.',
      lbl_select_label_quantity: '1. Select label quantity',
      lbl_select_label_size: '2. Select label size',
      lbl_small_size: 'Small (1" x 2-5/8")',
      lbl_large_size: 'Large (2" x 4")',
      lbl_avery_30_per_sheet: 'Avery 5160/8160, 30 per sheet',
      lbl_avery_10_per_sheet: 'Avery 5163/8163, 10 per sheet',
      lbl_small_labels_we_recommend: 'For small labels we recommend:',
      lbl_large_labels_we_recommend: 'For large labels we recommend:',
      lbl_avery_inkjet: 'Avery 8160 (Inkjet) -',
      lbl_avery_laser: 'Avery 5160 (Laser) -',
      lbl_inches_1: '1 x 2.62 inches',
      lbl_inches_2: '2 x 4 inches',
      lbl_template_preview: 'Template Preview',
      lbl_label_preview: 'Label Preview',
      lbl_generate_template: 'Generate Template',
      lbl_print_label_tooltip_msg: 'Use this amount to control the number of label duplicates you want to create for each vehicle',
      lbl_qr_code: 'QR Code',
      lbl_vehicle_name: 'Vehicle Name',
      lbl_add_new_feature: 'Add New Features',
      lbl_enter_here: 'Enter here',
      lbl_note: 'Note: ',
      lbl_edit_or_delete_new_feature: 'For edit or delete new feature please go to ',
      lbl_settings_vehicle_features: 'Settings > Vehicle > features',
      lbl_modify_default_vehicle: 'Modify Default Vehicle',
      lbl_slot: 'Slot',
      lbl_remove: 'Remove',
      lbl_record: 'Record',
      lbl_save_driver: 'Save Driver',
      lbl_update_driver: 'Update Driver',
      lbl_add_new_driver: 'Add New Drivers',
      lbl_download_label: 'Download Label',
      lbl_print_label: 'Print Label',
      lbl_edit: 'Edit',
      lbl_address_information: 'ADDRESS INFORMATION',
      lbl_other_details: 'OTHER DETAILS',
      lbl_geo_location: 'GEO LOCATION',
      lbl_busy_on_the_way: 'Busy - On the way',
      lbl_background_check: 'Background Check',
      lbl_availability_preference: 'Availability Preference',
      lbl_driving_records: 'Driving Records',
      manage_locations: {
        lbl_new_locations: 'New Locations',
        lbl_save_location: 'Save',
        lbl_cancel: 'Cancel',
        lbl_update_location: 'Update',
        lbl_address: 'Address',
        lbl_details: 'Details',
        update_location: 'Update Locations',
        import_location: 'Import Locations',
        add: 'Add',
        edit: 'Edit',
        add_location: 'Add Location',
        edit_location: 'Edit Location',
        active_location_message: 'Are you sure you want to make location active?',
        inactive_location_message: 'Are you sure you want to make location inactive?',
        active_location_header_message: 'Make Location Active',
        inactive_location_header_message: 'Make Location Inactive',
        location_added: 'Location Added Successfully',
        location_update: 'Location Updated Successfully',
        delete: 'Delete',
        lbl_make_location_active: 'Make Location Active',
        lbl_make_location_inactive: 'Make Location Inactive',
        lbl_review_location: 'Review Location',
        btn_bulk_update: 'Locations',
        lbl_entity_name_display: 'location',
        location_error: 'Please select a location from the dropdown',
        message: {
          confirm_delete_msg: '<p></p><p>Are you sure you want to delete this Location?</p><p><b>Once delete, it can\'t be undone. The location needs to be added again.</b></p><p></p>',
          delete_vehicle: 'Delete Location',
          cancel: 'Cancel',
          confirm_delete_header: 'Confirm Delete Locations',
          active_location_header_message: 'Make Location Active',
          inactive_location_header_message: 'Make Location Inactive',
          active_status_message: 'Are you sure you want to make location active?',
          inactive_status_message: 'Are you sure you want to make location inactive?',
          msg_location_active: 'Location active Successfully',
          msg_location_inactive: 'Location inactive Successfully',
        },
      },
      manage_group: {
        lbl_new_groups: 'Add Group',
        lbl_save_group: 'Save Group',
        lbl_groups: 'Groups',
        lbl_cancel: 'Cancel',
        lbl_update_group: 'Update',
        lbl_address: 'Address',
        update_group: 'Update Shift Groups',
        import_group: 'Import Groups',
        add: 'Add',
        edit: 'Edit',
        lbl_add_group: 'Add Group',
        lbl_edit_group: 'Edit Group',
        lbl_save_and_add_another: 'Save & Add Another',
        lbl_update_and_add_another: 'Update & Add Another',
        group_added_successfully: 'Group Added Successfully',
        active_group_message: 'Are you sure you want to make group active?',
        inactive_group_message: 'Are you sure you want to make group inactive?',
        active_group_header_message: 'Make Group Active',
        inactive_group_header_message: 'Make Group Inactive',
        group_added: 'Group Added',
        delete: 'Delete Group',
        lbl_make_group_active: 'Make Group Active',
        lbl_make_group_inactive: 'Make Group Inactive',
        lbl_group_details: 'Groups Details',
        lbl_search_driver_group: 'Search Driver Group',
        lbl_confirmation: 'Confirmation',
        confirmation_msg: 'Are you sure you want to apply these changes?',
        message: {
          confirm_delete_msg: '<p></p><p>Are you sure you want to delete this Group?</p><p><b>Once delete, it can\'t be undone. The Group needs to be added again.</b></p><p></p>',
          delete_vehicle: 'Delete Group',
          cancel: 'Cancel',
          confirm_delete_header: 'Confirm Delete Groups',
          group_delete_success: 'Group Deleted Successfully',
        },
        lbl_save: 'Save',
        lbl_update: 'Update',
        added_drivers: 'Added Drivers',
        bulk_update_header: 'Update Shift Groups',
        lbl_group: 'Group',
        review_header: 'Review Shift Groups',
        updateBtnLbl: 'Shift Group'
      },
      lbl_edit_driver: 'Edit Driver',
      lbl_cancel: 'Cancel',
      lbl_address: 'Address',
      lbl_drivers_deleted_successfully: 'Drivers deleted successfully',
      lbl_assign_vehicle: 'Assign Vehicle',
      lbl_assign_driver_group_success: 'Driver group assigned successfully',
      lbl_assign_checkin_location_success: 'Check-in location assigned successfully',
      lbl_release_checkin_location_success: 'Check-in location released successfully',
      lbl_assign_vehicle_success: 'Vehicle assigned successfully',
      lbl_modify_vehicle_assign_success: 'Default vehicle assigned successfully',
      lbl_release_driver_group_success: 'Driver group released successfully',
      lbl_release_modify_vehicle_success: 'Vehicle released successfully',
      lbl_off_duty_reason_success: 'Off duty reason assigned successfully',
      lbl_invalid_data: 'Invalid data',
      lbl_home: 'Home',
      lbl_new_leave: 'New Leave',
      lbl_leaves_overview: 'LEAVES OVERVIEW',
      lbl_today: 'TODAY',
      lbl_tomorrow: 'TOMORROW',
      lbl_view_all: 'View All',
      lbl_apply_leave: 'Apply Leave',
      lbl_edit_leave: 'Edit Leave',
      lbl_driver_name_id: 'Driver Name/ID',
      lbl_search_name_id: 'Search Driver By Name/ID',
      lbl_leave_type: 'Leave Type',
      lbl_from_date: 'From Date',
      lbl_to_date: 'To Date',
      lbl_reason_for_leave: 'Reason For Leave',
      lbl_reason: 'Reason',
      lbl_driver_name_id_req: 'Driver Name/ID required',
      lbl_leave_type_req: 'Leave Type required',
      lbl_from_date_req: 'From Date required',
      lbl_to_date_req: 'To Date required',
      lbl_reason_for_leave_req: 'Reason For Leave required',
      lbl_groups: 'Groups',
      label_today: 'Today',
      label_tomorrow: 'Tomorrow',
      leave_request: {
        columns: {
          lbl_reason: 'Reason',
          lbl_action: 'Action',
          lbl_leave_type: 'Leave Type',
          lbl_from_date: 'From Date',
          lbl_to_date: 'To Date',
          lbl_no_of_days: 'No. Of Days',
          lbl_status: 'Status',
        },
      },

      leave_management: {
        lbl_reject: 'Reject',
        lbl_edit: 'Edit',
        lbl_update_leaves: "Update Leaves",
        lbl_bulk_update_header: "Update Leave",
        lbl_review_leaves: "Review Leaves",
        columns: {
          lbl_name: 'Name',
          lbl_id: 'ID',
          lbl_unique_id: 'Unique ID',
          lbl_call_sign: 'Call Sign',
          lbl_leave_type: 'Leave Type',
          lbl_from_date: 'From Date',
          lbl_to_date: 'To Date',
          lbl_no_of_days: 'No. Of Days',
          lbl_status: 'Status',
        },
        message: {
          confirm_delete_msg:
            "<p></p><p>Are you sure you want to delete this Leave?</p><p><b>Once delete, it can't be undone. The leave needs to be added again.</b></p><p></p>",
          delete_leave: "Delete Leaves",
          cancel: "Cancel",
          confirm_delete_header: "Confirm Delete Leave",
        },
      },

      manage_roles: {
        columns: {
          lbl_role_name: 'Role Name',
          lbl_role_code: 'Role Code',
          lbl_created_by: 'Created By',
          lbl_created_on: 'Created On',
          lbl_modified_by: 'Modified By',
          lbl_modified_on: 'Modified On',
          lbl_role_status: 'Status',
        },
        lbl_route_path: "Roles",
        lbl_create_new: "Add New",
        lbl_add_new_role: "Add New Role",
        lbl_edit_role: "Edit Role",
        lbl_save_location: "Save",
        lbl_cancel: "Cancel",
        lbl_update_role: "Update",
        lbl_bulk_update_header: "Update Roles",
        lbl_entity_name_display: 'role',
        btn_bulk_update: 'Roles',
        update_roles: "Update Roles",
        lbl_review_roles: "Review Roles",
        add: "Add",
        edit: "Edit",
        active: 'Active',
        deactivate: 'Inactive',
        active_status_message:
          "This action will continue your account and all associated privileges.",
        inactive_status_message:
          "Are you sure you want to make status inactive?",
        active_roles_header_message: "Make Roles Active",
        inactive_roles_header_message: "Make Roles Inactive",
        roles_added: "Location Added",
        delete: "Delete",
        lbl_make_roles_active: "Make Roles Active",
        lbl_make_roles_inactive: "Make Roles Inactive",
        lbl_role_name: 'Role Name',
        lbl_description: 'Description',
        lbl_access_control: 'Add New role',
        deactive_role_header_message: "Are you sure you want to Inactive?",
        active_role_header_message: "Are you sure you want to Active?",
        deactive_status_message: "This action will temporarily suspend your account and all associated privileges.",
        lbl_deactivate: 'Inactive',
        lbl_activate: 'Active',
        role_name_err: 'Role Name is required',
        enter_role: 'Enter role name',
        enter_description: 'Enter description',
        message: {
          confirm_delete_msg:
            "<p></p><p>Are you sure you want to delete this Role?</p><p><b>Once delete, it can't be undone. The role needs to be added again.</b></p><p></p>",
          delete_Roles: "Delete Roles",
          cancel: "Cancel",
          confirm_delete_header: "Confirm Delete Roles",
          msg_role_added_successfully: 'Role record added successfully',
          msg_role_update_suucessfully: 'Role record updated successfully',
          msg_role_active: 'Role Active Successfully',
          msg_role_deactive: 'Role Inactive Successfully',
        },
      },
      lbl_selected_driver_header: 'SELECT OR REMOVE DRIVERS TO FORM DRIVER GROUPS',
      lbl_all_drivers: 'All Drivers',
      lbl_selected_drivers: 'Selected Drivers',

      manage_users: {
        columns: {
          lbl_name: 'Name',
          lbl_user_email: 'Email',
          lbl_user_role: 'User Role',
          lbl_user_name: 'Username',
          lbl_phone_number: 'Phone Number',
          lbl_user_status: 'User Status',
        },
        user_form: {
          user_details: 'User Details',
          user_role: 'User Role',
          select_user_role: 'Please select',
          first_name: 'First Name',
          last_name: 'Last Name',
          email_address: 'Email Address',
          mobile_number: 'Contact Number',
          password: 'Password',
          username: 'Username',
          new_password: 'New Password',
          confirm_password: 'Confirm Password',
          placeholder: 'Enter here',
          lbl_credentials: 'Credentials',
          lbl_events: 'Events'
        },
        form_error: {
          user_role_err: 'Select User Role',
          user_event_err: 'Select event',
          first_name_err: 'First Name is required',
          last_name_err: 'Last Name is required',
          email_required_err: 'Email address is required',
          contact_required_err: 'Contact number is required',
          username_err: 'Username is required',
          password_err: 'Please Enter new password',
          confirm_password_err: 'Please Enter confirm password',
          password_mismatch_err: 'Passwords do not match',
          firstname_pattern_err: 'First Name should include only alphabetical characters',
          lastname_pattern_err: 'Last Name should include only alphabetical characters',
          firstname_max: 'First Name should not exceed more than 50 character',
          lastname_max: 'Last Name should not exceed more than 50 character',
          email_pattern_err: 'Please enter valid email address',
          contact_pattern_err: 'Please enter valid contact number',
          username_max: 'Username should not exceed more than 50 character'
        },
        lbl_route_path: "Manage Users",
        lbl_create_new: "New User",
        lbl_save_location: "Save",
        lbl_cancel: "Cancel",
        lbl_update_user: "Update Users",
        lbl_bulk_update_header: "Update Users",
        lbl_entity_name_display: 'user',
        btn_bulk_update: 'Users',
        lbl_review_users: "Review Users",
        import_user_template: 'Import User Templates',
        add: "Add",
        edit: "Edit",
        cancel: 'Cancel',
        update: 'Update',
        save: 'Save',
        reInvite: 'Re-Invite',
        lbl_add_user: 'Add New User',
        lbl_edit_user: 'Edit User',
        active_status_message:
          "This action will continue your account and all associated privileges.",
        inactive_status_message:
          "This action will temporarily suspend your account and all associated privileges.",
        active_user_header_message: "Are you sure you want to Active?",
        inactive_user_header_message: "Are you sure you want to Inactive?",
        user_added: "User Added",
        delete: "Delete",
        lbl_make_user_active: "Active",
        lbl_make_user_inactive: "Inactive",
        lbl_role_name: 'Role Name',
        lbl_description: 'Description',
        lbl_deactivate: 'Inactive',
        lbl_activate: 'Active',
        lbl_route_users: 'Users',
        lbl_route_user_details: 'Users Details',
        lbl_details: 'Details',
        lbl_status: 'Status',
        lbl_name: 'Name',
        lbl_email: 'Email',
        lbl_role: 'Role',
        lbl_events: 'Events',
        lbl_contact: 'Contact Number',
        lbl_credential: 'Credentials',
        lbl_username: 'Username',
        reset_info: 'User will be required to re-login with admin generated passwords & can reset it later',
        lbl_reset_passwords: 'Reset Passwords',
        lbl_reset_password: 'Reset Password',
        lbl_default_password: 'New Default Password',
        reset_password_err_msg: 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character with no spaces',
        message: {
          confirm_delete_msg:
            "<p></p><p>Are you sure you want to delete this User?</p><p><b>Once delete, it can't be undone. The user needs to be added again.</b></p><p></p>",
          delete_user: "Delete User",
          cancel: "Cancel",
          confirm_delete_header: "Confirm Delete Users",
          msg_user_added_successfully: 'User record added successfully',
          msg_user_update_successfully: 'User record updated successfully',
          msg_user_active: 'User Active Successfully',
          msg_user_inactive: 'User Inactive Successfully',
          msg_reset_password_success: 'Reset password set successfully'
        },
      },

      manage_segments: {
        lbl_new_segments: 'New Segment',
        lbl_save_segment: 'Save Segment',
        lbl_segments: 'Segments',
        lbl_cancel: 'Cancel',
        lbl_update_segment: 'Update',
        lbl_address: 'Address',
        update_segment: 'Update Segments',
        import_segment: 'Import Segments',
        add: 'Add',
        edit: 'Edit',
        lbl_add_segment: 'Add Segment',
        lbl_edit_segment: 'Edit Segment',
        lbl_save_and_add_another: 'Save & Add Another',
        segment_added_successfully: 'Segment Added Successfully',
        segment_added: 'Segment Added',
        delete: 'Delete Segment',
        message: {
          confirm_delete_msg: '<p></p><p>Are you sure you want to delete this Segment?</p><p><b>Once delete, it can\'t be undone. The Segment needs to be added again.</b></p><p></p>',
          delete_vehicle: 'Delete Segment',
          cancel: 'Cancel',
          confirm_delete_header: 'Confirm Delete Segments',
          segment_delete_success: 'Segment Deleted Successfully',
        },
      },
      lbl_selected_people_header: 'SELECT OR REMOVE PEOPLE TO FORM A SEGMENT',
      lbl_all_people: 'All People',
      lbl_selected_people: 'Selected People',
      lbl_driver: 'Driver',

      broadcasting: {
        lbl_new_broadcast: 'New Broadcast',
        lbl_send: 'Send',
        lbl_broadcast: 'Broadcast',
        lbl_cancel: 'Cancel',
        lbl_update_broadcast: 'Update',
        lbl_address: 'Address',
        update_broadcast: 'Update Broadcast',
        import_broadcast: 'Import Broadcast',
        add: 'Add',
        edit: 'Edit',
        broadcast_added_successfully: 'Broadcast Added Successfully',
        broadcast_added: 'Broadcast Added',
        delete: 'Delete Broadcast',
        lbl_send_notification: 'Send Notification',
        message: {
          confirm_delete_msg: '<p></p><p>Are you sure you want to delete this Broadcast?</p><p><b>Once delete, it can\'t be undone. The Broadcast needs to be added again.</b></p><p></p>',
          delete_vehicle: 'Delete Broadcast',
          cancel: 'Cancel',
          confirm_delete_header: 'Confirm Delete Broadcast',
          broadcast_delete_success: 'Broadcast Deleted Successfully',
        },
      },
      lbl_pick_date_and_time: 'Pick Date & Time',
      lbl_pending: 'Pending',
      lbl_rejected: 'Rejected',
      lbl_reject: 'Reject',
      lbl_approve: 'Approve',
      lbl_approve_leave_header: 'Confirm Approve Leave',
      lbl_reject_leave_header: 'Confirm Reject Leave',
      lbl_approve_message: 'Are you sure you want to approve the leave?',
      lbl_leave_added_successfully: 'Leave Added Successfully',
      lbl_leave_rejected_successfully: 'Leave Rejected Successfully',
      lbl_reject_header: 'Reject Leave',
      lbl_reject_message: 'Are you sure you want to reject the leave?',
      lbl_request: 'Request',
      lbl_history: 'History',
      lbl_classification: 'Classification',
      lbl_unique_id: 'Unique ID',
      lbl_call_sign: 'Call Sign',
      lbl_casual_leaves: 'Casual Leaves',
      lbl_earned_leaves: 'Earned Leaves',
      lbl_sick_leaves: 'Sick Leaves',
      bulk_leave_sub_header: 'You have selected',
      bulk_leave_sub_header2: 'Leaves. The new value for the selected fields will be applied to all selected Leaves.',
      review_bulk_leave_sub_header: 'Please review the summary of changes you are about to make to',
      review_bulk_leave_sub_header2: 'Leaves. Do you wish to continue?',
      queue: {
        lbl_add_queue: 'Add Driver in Queue',
        remove_driver: 'Remove Driver',
        remove_drivers: 'Remove Drivers',
        add: 'Add',
        edit: 'Edit',
        lbl_outbound_queue: 'Outbound Queue',
        queue_added_successfully: 'Queue Added Successfully',
        queue_added: 'Queue Added',
        delete: 'Delete Queue',
        assign_queue_location: 'Assign Queue Location',
        columns: {
          driver_name: 'Driver Name',
          duty_status: 'Duty Status',
          vehicle_assigned: 'Vehicle Assigned',
          driver_group: 'Driver Group',
          unique_id: 'Unique ID',
          parking: 'Parking',
          queue_location: 'Queue Location',
          action: 'Action'
        },
        message: {
          confirm_delete_msg: '<p></p><p>Are you sure you want to remove this Queue?</p><p><b>Once remove, it can\'t be undone. The Queue needs to be added again.</b></p><p></p>',
          delete_vehicle: 'Delete Queue',
          cancel: 'Cancel',
          confirm_delete_header: 'Confirm Remove Queue',
          queue_delete_success: 'Queue Deleted Successfully',
          queue_remove_success: 'Driver Removed From Queue Successfully',
          confirm_remove_header: 'Confirm Remove Driver From Queue',
          confirm_remove_msg: '<p></p><p>Are you sure you want to remove driver from this Queue?</p><p><b>Once removed, it can\'t be undone. The Driver needs to be added again into Queue.</b></p><p></p>',
        },
      },
      lbl_add_to_queue: 'ADD TO QUEUE',
      lbl_select_placeholder: 'Select',
      lbl_on: 'On',
      lbl_off: 'Off',
      lbl_logout: 'Log Out',

      content: {
        lbl_about_us: 'About US',
        lbl_caps_about_us: 'ABOUT US',
        lbl_update: 'Update',
        lbl_company_name: 'Company Name',
        lbl_company_website: 'Company Website',
        lbl_address_line_1: 'Address Line1',
        lbl_address_line_2: 'Address Line 2',
        lbl_support_number: 'Support Number',
        lbl_phone_number: 'Phone Number',
        lbl_email_address: 'Email Address',
        placeholder_company_name: 'Enter company name',
        placeholder_company_website: 'Enter company website',
        placeholder_address_line1: 'Enter address line 1',
        placeholder_address_line2: 'Enter address line 2',
        placeholder_support_number: 'Enter support number',
        placeholder_email: 'Enter email address',
        placeholder_about_company: 'Describe your company in 5000 characters',
        lbl_play_store_url: 'Play Store URL',
        lbl_app_store_url: 'App Store URL',
        lbl_twitter_url: 'Twitter URL',
        lbl_facebook_url: 'Facebook URL',
        lbl_about_company: 'ABOUT COMPANY',
        lbl_company_location: 'Company Location',
        lbl_save: 'Save',
        lbl_cancel: 'Cancel',
        lbl_terms_and_conditions: 'Terms and Conditions',
        lbl_terms_conditions: 'TERMS & CONDITIONS',
        lbl_privacy_policy: 'Privacy Policy',
        lbl_contact_support: 'Contact Support',
        lbl_support_name: 'Support Name',
        lbl_driver: 'Driver',
        lbl_passenger: 'Passenger',
        lbl_admin: 'Admin',
        lbl_faqs: `FAQ's`,
        lbl_add_new_faq: 'add new faq',
        lbl_add_question: 'Add Question',
        lbl_add_answer: 'Add Answer',
        lbl_frequently_asked: `Frequently asked question (FAQ's)`,
        faq_question_req_err: 'Question is required',
        faq_answer_req_err: 'Answer is required',
        lbl_enter_here: 'Enter here',
        placeholder_faq_question: 'Enter your question here',
        placeholder_faq_answer: 'Enter your answer here',
        company_name_req_err: 'Company Name is required',
        company_website_req_err: 'Company Website is required',
        email_req_err: 'Email Address is required',
        delete_contact_header: 'Confirm Delete Contact Support',
        delete_contact_msg: 'Are you sure you want to delete this Contact Support?',
        delete_faq_header: 'Confirm Delete FAQ',
        delete_faq_msg: 'Are you sure you want to delete this FAQ?',
        lbl_delete: 'Delete',
        contact_delete_message: 'Contact Support deleted successfully',
        lbl_confirmation: 'Confirmation',
        confirm_discard_msg: 'Are you sure you want to discard the changes?',
        lbl_no: 'No',
        lbl_discard: 'Discard',
        terms_condition_req_err: 'Terms & conditions is required',
        privacy_policy_req_err: 'Privacy policy is required',
        company_name_max_err: 'Company name field accept only 256 characters only',
        company_website_max_err: 'Company website field accept only 256 characters only',
        address1_max_err: 'Address line1 field accept only 256 characters only',
        address2_max_err: 'Address line2 field accept only 256 characters only',
        email_max_err: 'Email address field accept only 256 characters only',
      },

      settings: {
        save_configuration_settings: 'Save Configuration Settings',
        lbl_save_configuration_settings: 'Configuration Settings saved successfully',
        lbl_configuration_settings: 'Configuration Settings',
        lbl_config_settings_saved_successfully: 'Config Settings saved successfully',
        lbl_general_settings_saved_successfully: 'General Settings saved successfully.',
        export_account_data: 'Export Account Data',
        general_settings: 'General Settings',
        lbl_save_export_settings: 'Save Export Settings',
        lbl_export_settings_saved_successfully: 'Export settings saved successfully',
        lbl_save_security_settings: 'Save Security Settings',
        lbl_security_settings_saved_successfully: 'Security settings saved successfully',
        security: 'Security',
        lbl_settings: 'Settings',
        lbl_account_settings: 'Account Settings',
        lbl_user_profile: 'User Profile',
        lbl_user_login_password: 'User Login Password',
        lbl_select_driver_app_login_option: 'Select Driver App Login Option',
        lbl_select_passenger_app_login_option: 'Select Passenger App Login Option',
        lbl_password_option: 'Password Options',
        lbl_user_login_setting: 'User Login Setting',
        lbl_driver_setting: 'Driver Settings',
        lbl_passenger_setting: 'Passenger Settings',
        lbl_default_password: 'Default password',
        lbl_login_information: 'Login Information',
        lbl_change_password: 'Change Password',
        lbl_login_settings: 'Login Settings',
        lbl_change_password_uppercase: 'CHANGE PASSWORD',
        lbl_notification_settings: 'Notification Settings',
        lbl_login_and_password: 'Login & Password',
        lbl_administration: 'Administration',
        lbl_general_settings: 'General Settings',
        lbl_time_settings: 'Time Settings',
        lbl_export_account_data: 'Export Account Data',
        lbl_integrations: 'Integrations',
        lbl_security: 'Security',
        lbl_system_settings: 'System Settings',
        lbl_custom_fields: 'Custom Fields',
        lbl_labels: 'Labels',
        lbl_bookings: 'Bookings',
        lbl_transportation_type: 'Transportation Type',
        lbl_child_seats: 'Child Seats',
        lbl_luggage: 'Luggage',
        lbl_journey_preference: 'Journey Preference',
        lbl_vehicle_body_type: 'Vehicle Body Type',
        lbl_vehicle_dispatch: 'Vehicle Dispatch',
        lbl_events: 'Events',
        lbl_event_status: 'Event Status',
        lbl_event_state: 'Event State',
        lbl_transportation_mode: 'Transportation Mode',
        lbl_passengers: 'Passengers',
        lbl_passenger_type: 'Passenger Type',
        lbl_passenger_status: 'Passenger Status',
        lbl_privilege_settings: 'Privilege Settings',
        lbl_default_values: 'Default Values',
        lbl_vehicles: 'Vehicles',
        lbl_vehicle_status: 'Vehicle Status',
        lbl_vehicle_types: 'Vehicle Types',
        lbl_body_types: 'Body Types',
        lbl_features: 'Features',
        lbl_drivers: 'Drivers',
        lbl_account_status: 'Account Status',
        lbl_duty_status: 'Duty Status',
        lbl_reason_for_duty_status: 'Reason For Duty Status',
        lbl_app_login: 'App Login',
        lbl_driving_record: 'Driving Record',
        lbl_leave_type: 'Leave Type',
        lbl_location: 'Locations',
        lbl_location_status: 'Location Status',
        lbl_location_category: 'Location Category',
        lbl_dispatch_zone: 'Dispatch Zone',
        lbl_concerns: 'Concerns',
        lbl_incidents_and_complaints_status: 'Incidents & Complaints Status',
        lbl_complaints_status: 'Complaints Status',
        lbl_shift_management: 'Shift Management',
        lbl_driver_group_status: 'Driver Group Status',
        lbl_group_colors: 'Group Colors',
        lbl_reason_for_break:'Reason For Break',
        lbl_communication: 'Communication',
        lbl_message_type: 'Message Type',
        lbl_select_time: 'Select Time',
        lbl_reports: 'Reports',
        lbl_saved_reports: 'Saved Reports',
        lbl_add_event_status: 'Add Event Status',
        lbl_edit_event_status: 'Edit Event Status',
        lbl_add_label: 'Add Label',
        lbl_add_transportation_mode: 'Add Transportation Mode',
        lbl_edit_transportation_mode: 'Edit Transportation Mode',
        lbl_please_fill_the_form: 'Please fill the form',
        lbl_name_req: 'Name is required',
        lbl_vehicle_type_req: 'Vehicle type is required',
        lbl_label_req: 'Label is required',
        lbl_reason_req: 'Reason is required',
        lbl_first_name_req: 'First Name is required',
        lbl_user_name_req: 'User Name is required',
        lbl__email_req: 'Email is required',
        lbl_website_req: 'Website is required',
        email_pattern: 'Email ID is invalid.',
        website_pattern: 'Company website is invalid.',
        password_pattern: 'Please enter valid password',
        lbl_time_zone: 'Time Zone',
        lbl_send_me_email_updates_about_tms: 'Send me email updates about TMS',
        lbl_you_can_log_in_to_tms_using_your_username_or_your_email_address: 'You can log in to TMS using your username or your email address.',
        edit: 'Edit',
        delete: 'Delete',
        lbl_time_settings_saved_successfully: 'Time Settings Saved Successfully',
        lbl_save_time_settings: 'Save Time Settings',
        lbl_mark_as_default: 'Mark as Default',
        lbl_select_color: 'Select Color',
        lbl_default: 'Default',
        lbl_profile_data_updated_successfully: 'Profile Data Updated Successfully',
        lbl_login_info_updated_successfully: 'Login Information Updated Successfully',
        faq_add_message: 'FAQ added successfully',
        faq_update_message: 'FAQ updated successfully',
        faq_delete_message: 'FAQ deleted successfully',
        error_faq_delete_message: 'Error while deleting FAQ',
        error_faq_update_message: 'Error while updating FAQ',
        error_faq_add_message: 'Error while adding FAQ',
        terms_and_cond_update_msg: 'Terms and Conditions Updated Successfully',
        privacy_policy_update_msg: 'Privacy Policy Updated Successfully',
        contact_support_update_msg: 'Contact support Updated Successfully',
        contact_support_added_msg: 'Contact support added Successfully',
        about_us_information_update_msg: 'About Us Information Updated Successfully',
        booking: {
          lbl_bookings: 'Bookings',
          lbl_booking: 'Booking',
          lbl_add_transportation_mode: 'Add Transportation Mode',
          lbl_edit_transportation_mode: 'Edit Transportation Mode',
          lbl_transportation_mode_added_successfully: 'Transportation Mode Added Successfully',
          lbl_transportation_mode_updated_successfully: 'Transportation Mode Updated Successfully',
          lbl_transportation_mode_delete_successfully: 'Transportation Mode Deleted Successfully',
          lbl_transportation_modes_delete_successfully: 'Transportation Modes Deleted Successfully',
          lbl_add_luggage: 'Add Luggage',
          lbl_edit_luggage: 'Edit Luggage',
          lbl_luggage_added_successfully: 'Luggage Added Successfully',
          lbl_luggage_updated_successfully: 'Luggage Updated Successfully',
          lbl_luggage_delete_successfully: 'Luggage Deleted Successfully',
          lbl_add_journey_preference: 'Add Journey Preference',
          lbl_edit_journey_preference: 'Edit Journey Preference',
          lbl_journey_preference_added_successfully: 'Journey Preference Added Successfully',
          lbl_journey_preference_updated_successfully: 'Journey Preference Updated Successfully',
          lbl_journey_preference_delete_successfully: 'Journey Preference Deleted Successfully',
          lbl_journey_preferences_delete_successfully: 'Journey Preferences Deleted Successfully',
          lbl_add_vehicle_body_type: 'Add Vehicle Body Type',
          lbl_edit_vehicle_body_type: 'Edit Vehicle Body Type',
          lbl_vehicle_body_type_added_successfully: 'Vehicle Body Type Added Successfully',
          lbl_vehicle_body_type_updated_successfully: 'Vehicle Body Type Updated Successfully',
          lbl_vehicle_body_type_delete_successfully: 'Vehicle Body Type Deleted Successfully',
          lbl_vehicle_body_types_delete_successfully: 'Vehicle Body Types Deleted Successfully',
          lbl_add_vehicle_dispatch: 'Add Vehicle Dispatch',
          lbl_edit_vehicle_dispatch: 'Edit Vehicle Dispatch',
          lbl_vehicle_dispatch_added_successfully: 'Vehicle Dispatch Added Successfully',
          lbl_vehicle_dispatch_updated_successfully: 'Vehicle Dispatch Updated Successfully',
          lbl_vehicle_dispatch_delete_successfully: 'Vehicle Dispatch Deleted Successfully',
          lbl_vehicles_dispatch_delete_successfully: 'Vehicles Dispatch Deleted Successfully',
          transportation_mode_delete_header: 'Confirm Delete Transportation Mode',
          transportation_type_delete_header: 'Confirm Delete Transportation Type',
          luggage_delete_header: 'Confirm Delete Luggage',
          journey_preference_delete_header: 'Confirm Delete Journey Preference',
          vehicle_body_type_delete_header: 'Confirm Delete Vehicle Body Type',
          vehicle_dispatch_delete_header: 'Confirm Delete Vehicle Dispatch',
          delete_msg: 'Are you sure you want to delete this records? Deleting a record does not remove it from system, but it will no longer be usable.',
        },
        events: {
          lbl_event: 'Event',
          lbl_events: 'Events',
          lbl_add_transportation_type: 'Add Transportation Type',
          lbl_edit_transportation_type: 'Edit Transportation Type',
          lbl_transportation_type_added_successfully: 'Transportation Type Added Successfully',
          lbl_transportation_type_updated_successfully: 'Transportation Type Updated Successfully',
          lbl_transportation_type_delete_successfully: 'Transportation Type Deleted Successfully',
          lbl_transportation_types_delete_successfully: 'Transportation Types Deleted Successfully',
          transportation_type_delete_header: 'Confirm Delete Transportation Type',
          delete_msg: 'Are you sure you want to delete this records? Deleting a record does not remove it from system, but it will no longer be usable.',
        },
        passenger: {
          lbl_passengers: 'Passengers',
          lbl_passenger: 'Passenger',
          lbl_add_passenger_type: 'Add Passenger Type',
          lbl_edit_passenger_type: 'Edit Passenger Type',
          lbl_passenger_type_added_successfully: 'Passenger Type Added Successfully',
          lbl_passenger_type_updated_successfully: 'Passenger Type Updated Successfully',
          lbl_passenger_type_delete_successfully: 'Passenger Type Deleted Successfully',
          lbl_passenger_types_delete_successfully: 'Passenger Types Deleted Successfully',
          lbl_add_passenger_status: 'Add Passenger Status',
          lbl_edit_passenger_status: 'Edit Passenger Status',
          lbl_add_privilege_settings: 'Add Privilege Settings',
          lbl_view_edit_privilege_settings: 'View / Edit Privilege Settings',
          lbl_passenger_status_added_successfully: 'Passenger Status Added Successfully',
          lbl_passenger_status_updated_successfully: 'Passenger Status Updated Successfully',
          lbl_passenger_status_delete_successfully: 'Passenger Status Deleted Successfully',
          passenger_type_delete_header: 'Confirm Delete Passenger Type',
          passenger_status_delete_header: 'Confirm Delete Passenger Status',
          passenger_privilege_header: 'Confirm Delete Passenger Type',
          lbl_added_privilege_settings_success: 'Privilege Settings Added Successfully',
          lbl_updated_privilege_settings_success: 'Privilege Settings Updated Successfully',
          delete_msg: 'Are you sure you want to delete this records? Deleting a record does not remove it from system, but it will no longer be usable.',
          title: 'Title',
          data_type: 'Data Type',
          required: 'Required',
          settings_section: 'Settings Section',
          settings_section_p: 'Settings section will content set of data which will enable Privileges for passenger',
          privileges: 'Privileges',
          privilege_section_p: 'List of privileges under selected section',
          privilege_edit_p: 'Customize privileges data for selected Privilege',
          content: 'Content',
          add_other_option: `Add option of “Other”`,
          requiredField: 'Required Field',
          you_can_add: 'You can add multiple items',
          clear: 'Clear',
          edit: 'Edit',
          info_text: 'Information Text',
          note: 'Note',
          required_field: 'Required Field',
          yes: 'Yes',
          no: 'No',
          default_values_placeholder:'Set default values for selected player type',
        },
        vehicle: {
          lbl_vehicle: 'Vehicle',
          lbl_add_vehicle_type: 'Add Vehicle Type',
          lbl_edit_vehicle_type: 'Edit Vehicle Type',
          lbl_vehicle_type_added_successfully: 'Vehicle Type Added Successfully',
          lbl_vehicle_type_updated_successfully: 'Vehicle Type Updated Successfully',
          lbl_vehicle_type_delete_successfully: 'Vehicle Type Deleted Successfully',
          lbl_vehicle_types_delete_successfully: 'Vehicle Types Deleted Successfully',
          lbl_add_vehicle_status: 'Add Vehicle Status',
          lbl_edit_vehicle_status: 'Edit Vehicle Status',
          lbl_vehicle_status_added_successfully: 'Vehicle Status Added Successfully',
          lbl_vehicle_status_updated_successfully: 'Vehicle Status Updated Successfully',
          lbl_vehicle_status_delete_successfully: 'Vehicle Status Deleted Successfully',
          lbl_vehicles_status_delete_successfully: 'Vehicles Status Deleted Successfully',
          vehicle_type_delete_header: 'Confirm Delete Vehicle Type',
          vehicle_status_delete_header: 'Confirm Delete Vehicle Status',
          lbl_add_body_types: 'Add Body Types',
          lbl_edit_body_types: 'Edit Body Types',
          lbl_body_type_added_successfully: 'Body Type Added Successfully',
          lbl_body_type_updated_successfully: 'Body Type Updated Successfully',
          lbl_body_type_delete_successfully: 'Body Type Deleted Successfully',
          lbl_body_types_delete_successfully: 'Body Types Deleted Successfully',
          lbl_add_features: 'Add Features',
          lbl_edit_features: 'Edit Features',
          lbl_features_added_successfully: 'Feature Added Successfully',
          lbl_feature_updated_successfully: 'Feature Updated Successfully',
          lbl_feature_delete_successfully: 'Feature Deleted Successfully',
          lbl_features_delete_successfully: 'Features Deleted Successfully',
          body_type_delete_header: 'Confirm Delete Body Type',
          features_delete_header: 'Confirm Delete Features',
          delete_msg: 'Are you sure you want to delete this records? Deleting a record does not remove it from system, but it will no longer be usable.',
        },
        driver: {
          lbl_driver: 'Driver',
          lbl_drivers: 'Drivers',
          lbl_add_driving_record: 'Add Driving Record',
          lbl_edit_driving_record: 'Edit Driving Record',
          lbl_driving_record_added_successfully: 'Driving Record Added Successfully',
          lbl_driving_record_updated_successfully: 'Driving Record Updated Successfully',
          lbl_driving_record_delete_successfully: 'Driving Record Deleted Successfully',
          lbl_driving_records_delete_successfully: 'Driving Records Deleted Successfully',
          driving_record_delete_header: 'Confirm Delete Driving Record',
          lbl_add_duty_status: 'Add Duty Status',
          lbl_edit_duty_status: 'Edit Duty Status',
          lbl_duty_status_added_successfully: 'Duty Status Added Successfully',
          lbl_duty_status_updated_successfully: 'Duty Status Updated Successfully',
          lbl_duty_status_delete_successfully: 'Duty Status Deleted Successfully',
          duty_status_delete_header: 'Confirm Delete Duty Status',
          lbl_add_reason_for_duty_status: 'Add Reason For Duty Status',
          lbl_edit_reason_for_duty_status: 'Edit Reason For Duty Status',
          lbl_reason_for_duty_status_added_successfully: 'Reason For Duty Status Added Successfully',
          lbl_reason_for_duty_status_updated_successfully: 'Reason For Duty Status Updated Successfully',
          lbl_reason_for_duty_status_delete_successfully: 'Reason For Duty Status Deleted Successfully',
          lbl_reasons_for_duty_status_delete_successfully: 'Reasons For Duty Status Deleted Successfully',
          reason_for_duty_status_delete_header: 'Confirm Delete Reason For Duty Status',
          lbl_leave: 'Leave',
          lbl_leaves: 'Leaves',
          lbl_add_leave_type: 'Add Leave Type',
          lbl_edit_leave_type: 'Edit Leave Type',
          lbl_leave_type_added_successfully: 'Leave Type Added Successfully',
          lbl_leave_type_updated_successfully: 'Leave Type Updated Successfully',
          lbl_leave_type_delete_successfully: 'Leave Type Deleted Successfully',
          lbl_leave_types_delete_successfully: 'Leave Types Deleted Successfully',
          leave_type_delete_header: 'Confirm Delete Leave Type',
          delete_msg: 'Are you sure you want to delete this records? Deleting a record does not remove it from system, but it will no longer be usable.',
        },
        location: {
          lbl_location: 'Location',
          lbl_locations: 'Locations',
          lbl_zones: 'Zones',
          lbl_zone: 'Zone',
          lbl_add_location_status: 'Add Location Status',
          lbl_edit_location_status: 'Edit Location Status',
          lbl_location_status_added_successfully: 'Location Status Added Successfully',
          lbl_location_status_updated_successfully: 'Location Status Updated Successfully',
          lbl_location_status_delete_successfully: 'Location Status Deleted Successfully',
          location_status_delete_header: 'Confirm Delete Location Status',
          lbl_add_location_category: 'Add Location Category',
          lbl_edit_location_category: 'Edit Location Category',
          lbl_location_category_added_successfully: 'Location Category Added Successfully',
          lbl_location_category_updated_successfully: 'Location Category Updated Successfully',
          lbl_location_category_delete_successfully: 'Location Category Deleted Successfully',
          location_category_delete_header: 'Confirm Delete Location Category',
          lbl_add_dispatch_zone: 'Add Dispatch Zone',
          lbl_edit_dispatch_zone: 'Edit Dispatch Zone',
          lbl_dispatch_zone_added_successfully: 'Dispatch Zone Added Successfully',
          lbl_dispatch_zone_updated_successfully: 'Dispatch Zone Updated Successfully',
          lbl_dispatch_zone_delete_successfully: 'Dispatch Zone Deleted Successfully',
          dispatch_zone_delete_header: 'Confirm Delete Dispatch Zone',
          delete_msg: 'Are you sure you want to delete this records? Deleting a record does not remove it from system, but it will no longer be usable.',
        },
        incidents_complaints: {
          lbl_incidents_complaints: 'Incidents & Complaints',
          lbl_incident_complaint: 'Incident & Complaint',
          lbl_incidents_complaints_status: 'Incidents & Complaints Status',
          lbl_add_status: 'Add Status',
          lbl_add_incidents_complaint_status: 'Add Incidents & Complaints Status',
          lbl_edit_incidents_complaint_status: 'Edit Incidents & Complaints Status',
          lbl_incidents_complaint_status_added_successfully: 'Status Added Successfully',
          lbl_incidents_complaint_status_updated_successfully: 'Status Updated Successfully',
          lbl_incidents_complaint_status_delete_successfully: 'Status Deleted Successfully',
          incidents_complaint_status_delete_header: 'Confirm Delete Status',
          lbl_add_category: 'Add Category',
          lbl_add_incidents_complaint_category: 'Add Incidents & Complaints Category',
          lbl_edit_incidents_complaint_category: 'Edit Incidents & Complaints Category',
          lbl_incidents_complaint_category_added_successfully: 'Category Added Successfully',
          lbl_incidents_complaint_category_updated_successfully: 'Category Updated Successfully',
          lbl_incidents_complaint_category_delete_successfully: 'Category Deleted Successfully',
          incidents_complaint_category_delete_header: 'Confirm Delete Category',
          lbl_add_type: 'Add Type',
          lbl_add_raised_by_type: 'Add Raised by Type',
          lbl_edit_raised_by_type: 'Edit Raised by Type',
          lbl_raised_by_type_added_successfully: 'Raised by Type Added Successfully',
          lbl_raised_by_type_updated_successfully: 'Raised by Type Updated Successfully',
          lbl_raised_by_type_delete_successfully: 'Raised by Type Deleted Successfully',
          raised_by_type_delete_header: 'Confirm Delete Raised by Type  ',
          delete_msg: 'Are you sure you want to delete this records? Deleting a record does not remove it from system, but it will no longer be usable.',
        },
        shift_management: {
          lbl_groups: 'Groups',
          lbl_group: 'Group',
          lbl_add_driver_group_status: 'Add Driver Group Status',
          lbl_edit_driver_group_status: 'Edit Driver Group Status',
          lbl_driver_group_status_added_successfully: 'Driver Group Status Added Successfully',
          lbl_driver_group_status_updated_successfully: 'Driver Group Status Updated Successfully',
          lbl_driver_group_status_delete_successfully: 'Driver Group Status Deleted Successfully',
          driver_group_status_delete_header: 'Confirm Delete Driver Group Status',
          lbl_group_color: 'Group Color',
          lbl_add_driver_color: 'Add Group Color',
          lbl_edit_driver_color: 'Edit Group Color',
          lbl_driver_color_added_successfully: 'Group Color Added Successfully',
          lbl_driver_color_updated_successfully: 'Group Color Updated Successfully',
          lbl_driver_color_delete_successfully: 'Group Color Deleted Successfully',
          lbl_drivers_color_delete_successfully: 'Group Colors Deleted Successfully',
          driver_color_delete_header: 'Confirm Delete Group Color',
          lbl_shift: 'Shift',
          lbl_shifts: 'Shifts',
          lbl_reason_for_break: 'Reason For Break',
          lbl_add_reason_for_break: 'Add Reason For Break',
          lbl_edit_reason_for_break: 'Edit Reason For Break',
          lbl_reason_for_break_added_successfully: 'Reason For Break Added Successfully',
          lbl_reason_for_break_updated_successfully: 'Reason For Break Updated Successfully',
          lbl_reason_for_break_delete_successfully: 'Reason For Break Deleted Successfully',
          lbl_reason_for_breaks_delete_successfully: 'Reason For Breaks Deleted Successfully',
          reason_for_break_delete_header: 'Confirm Delete Reason For Break',
          delete_msg: 'Are you sure you want to delete this records? Deleting a record does not remove it from system, but it will no longer be usable.',
        },
        communication: {
          lbl_messages: 'Messages',
          lbl_message: 'Message',
          lbl_add_message_type: 'Add Message Type',
          lbl_edit_message_type: 'Edit Message Type',
          lbl_message_type_added_successfully: 'Message Type Added Successfully',
          lbl_message_type_updated_successfully: 'Message Type Updated Successfully',
          lbl_message_type_delete_successfully: 'Message Type Deleted Successfully',
          message_type_delete_header: 'Confirm Delete Message Type',
          lbl_add_select_time: 'Add Select Time',
          lbl_edit_select_time: 'Edit Select Time',
          lbl_select_time_added_successfully: 'Select Time Added Successfully',
          lbl_select_time_updated_successfully: 'Select Time Updated Successfully',
          lbl_select_time_delete_successfully: 'Select Time Deleted Successfully',
          select_time_delete_header: 'Confirm Delete Select Time',
          delete_msg: 'Are you sure you want to delete this records? Deleting a record does not remove it from system, but it will no longer be usable.',
        },
        labels: {
          lbl_label: 'Label',
          lbl_add_label: 'Add Label',
          lbl_edit_label: 'Edit Label',
          lbl_label_added_successfully: 'Label Added Successfully',
          lbl_label_updated_successfully: 'Label Updated Successfully',
          lbl_label_delete_successfully: 'Label Deleted Successfully',
          label_delete_header: 'Confirm Delete Label',
          delete_msg: 'Are you sure you want to delete this records? Deleting a record does not remove it from system, but it will no longer be usable.',
        },
        custom_fields: {
          lbl_custom_fields_upper: 'CUSTOM FIELDS',
          lbl_these_custom_fields_text: 'These custom fields will reflect in chosen services is shown',
          lbl_details_upper: 'DETAILS',
          lbl_customize_how_you_want_to_show_text: 'Customize how you want to show the data in this field',
          lbl_no_custom_fields_added: 'No Custom Field added',
          lbl_add_first_custom_field: 'Add first Custom Field',
          lbl_add_custom_fields_btn: 'Add Custom Fields',
          lbl_add_new: 'Add New',
          lbl_delete_highlight_illness: 'Delete Highlight illness',
          lbl_delete_confirm_message: 'Are you sure you want to delete this custom field data?',
          lbl_clear: 'Clear',
          lbl_select_the_module_text: 'Select the module where you want to show this field',
          lbl_all_modules: 'Select Module',
          lbl_select_sub_module: 'Select Sub-Module',
          lbl_module: 'Module',
          lbl_sub_module: 'Sub Module',
          lbl_tab: 'Tab',
          lbl_sections: 'Section',
          lbl_data_type: 'Data Type',
          lbl_select_the_type_text: 'Select the type of field you want as input',
          lbl_content: 'Content',
          lbl_you_can_add_multiple_items: 'You can add multiple items',
          lbl_add_option_of_other: 'Add option of "Other"',
          lbl_allow_action: 'Allow Action',
          lbl_search: 'Search',
          lbl_advance_filters: 'Advance Filters',
          lbl_manage_columns: 'Manage Columns',
          lbl_information_text: 'Information Text',
          lbl_additional_details_about_this_field_text: 'Additional details about this field. This will appear when a user hovers on ',
          lbl_note: 'Note',
          lbl_this_note_is_to_get_more_information_text: 'This note is to get more information and this will appear at the bottom of data type',
          lbl_validation: 'Validation',
          lbl_required_field: 'Required Field',
          lbl_please_select: 'Please Select',
          lbl_module_required: 'Module is required',
          lbl_data_type_required: 'Data type is required',
          lbl_content_required: 'Content is required',
          lbl_note_required: 'Note is required',
          custom_field_added_successfully: 'Custom field added successfully',
          confirm_delete_header: 'Confirm Delete Custom Fields',
          lbl_alphabets_allowed: 'Alphabets Allowed',
          lbl_numbers_allowed: 'Numbers Allowed',
          lbl_special_characters_allowed: 'Special Characters Allowed',
          lbl_information_text_required: 'Information text is required',
          lbl_tab_required: 'Tab is required',
          lbl_section_required: 'Section is required',
          lbl_only_available_for_tab : '(Only available for tab 1)',
        },
        authentication: {
          header: 'Authentication',
          user: 'User',
          driver: 'Driver',
          passenger: 'Passenger',
          password_is_required: 'Password is required.',
          lbl_password_updated_successfully: 'Password Updated Successfully',
          lbl_password_pattern_error: 'The password must be at least 8 characters long, contain a mix of uppercase and lowercase letters, numbers, and special characters, and avoid sequences like "1234" or "abcd."',
          lbl_description: '(OTP will be send on Registered Contact Number or Email Address)',
          lbl_session_timeout: 'SESSION TIMEOUT',
          lbl_session_description: 'Control how long users can be inactive before automatically signed out.',
          lbl_mobile_app: 'Mobile APP',
          lbl_select_an_option: 'Select an option',
          password_help_text: 'Password will be send on Registered Email Address',
          lbl_meta_url: 'Meta URL',
          lbl_enter_meta_url: 'Enter meta URL for SSO',
          lbl_error_meta_url_required: 'Meta URL is required'
        },
        notification: {
          lbl_notification_event: 'Notification Event',
          lbl_email_address: 'Email Address',
          lbl_push: 'Push',
          lbl_sms: 'SMS',
          confirm_delete_header: 'Confirm Change Notification Config',
          confirm_delete_msg: '<p></p><p>Are you sure you want to change this notification config?</p><p></p>',
          notification_config_successfully: 'Notification config updated successfully',
          notification_matrix_successfully: 'Notification matrix updated successfully',
          confirm_discard_header: 'Are you sure you want to discard the changes?',
          lbl_confirmation: 'Confirmation',
        }
      },
      lbl_hex_code: 'Hex Code',
      lbl_contact_number: 'Contact Number',
      lbl_update: 'Update',
      lbl_change_password: 'Change Password',
      lbl_select_timezone: 'Select a Timezone',
      lbl_current_password: 'Current Password',
      lbl_confirm_new_password: 'Confirm New Password',
      lbl_current_password_req: 'Current Password is required',
      lbl_new_password_req: 'New Password is required',
      lbl_confirm_new_password_req: 'Confirm New Password is required',
      lbl_enter_your_current_password_to_confirm: 'Enter your current password to confirm.',
      lbl_must_be_at_least_char_long: 'Must be at least {{minLength}} characters long',
      lbl_must_contain_an_uppercase_and_a_lowercase_letter: 'Must contain an uppercase and a lowercase letter (A, z)',
      lbl_please_match_the_password_with_new_password: 'Please match the password with new password',
      lbl_uploaded_file: 'File Uploaded: ',
      lbl_usage: 'Usage',
      lbl_casual_leave: 'Casual Leave',
      lbl_earned_leave: 'Earned Leave',
      lbl_sick_leave: 'Sick Leave',
      lbl_replace_with: 'Replace With',
      lbl_remove_all_values: 'Remove all values',
      lbl_working: 'Working',
      lbl_on_leave: 'On Leave',
      lbl_remove_slot_message: 'Are you sure you want to remove this slot? Removing a slot does not remove it from system, but it will no longer be usable.',
      lbl_remove_slot_header: 'Remove Slot',
      lbl_mobile_number_max_length: 'Please enter valid contact number',
      lbl_transportation_end_date: 'Transportation End Date',
      lbl_watchlist: 'Watchlist',
      lbl_type: 'Type',
      lbl_mi: 'mi',
      lbl_start_and_end_time: 'Start & End Time',
      lbl_ddr_record: 'Record',
      lbl_ddr_date: 'Date',
      lbl_ddr_note: 'Note',
      lbl_vehicle_type: 'Vehicle Type',
      lbl_languages_spoken: 'Languages Spoken',
      lbl_types_of_vehicles_driven: 'Types Of Vehicle Driven',
      lbl_search_by_driver_name_id: 'Search by Driver name / ID',
      lbl_no_usage: 'No Usage',
      lbl_driving_record: 'DRIVING RECORD',
      lbl_add_address: 'Add Address',
      lbl_save_new_address: 'Save New Address',
      lbl_locations: 'Locations',
      lbl_saved_addresses : 'Saved Addresses',
      lbl_save_address: 'Save Address',
      lbl_numbers: 'Numbers',
      lbl_alphabets: 'Alphabets',
      lbl_special_characters: 'Special Characters',
      lbl_numbers_alphabets: 'Numbers | Alphabets',
      lbl_alphabets_special_characters: 'Alphabets | Special Characters',
      lbl_number_special_characters: 'Numbers | Special Characters',
      lbl_number_alphabet_special_characters: 'Numbers | Alphabets | Special Characters',
      lbl_pdf_files_cannot_be_uploaded : 'PDF files cannot be uploaded',
      lbl_log_off: 'Log Off',
      lbl_next_shift_location_of_driver: 'Next Shift Location Of Driver',
      lbl_next_shift_location: 'Next Shift Location:',
      lbl_change_driver_on_duty: 'Change Driver On Duty',
      lbl_remove_all_columns_err_msg: 'Minimum two columns should be selected; the selection cannot be empty',
      lbl_select_journey: 'Select Journey',
      err_please_select_reporter_type_reported_by_and_incident_complaint_date: 'Please select reporter type, reported by and incident/complaint date',
      lbl_select_location_for_pickup: 'Select Location For Pickup',
      lbl_select_location_for_drop: 'Select Location For Drop',
      lbl_show_own_stay_locations_only: 'Show own stay locations only',
      lbl_select_predefined_location_category_for_pickup: 'Select Predefined Location Category For Pickup',
      lbl_select_predefined_location_category_for_drop: 'Select Predefined Location Category For Drop',
      lbl_queue_location: 'Queue Location',
      lbl_all_drivers_upper: 'ALL DRIVERS',
      lbl_add_queue: 'Add To Queue',
      lbl_assign_trip: 'Assign Trip',
      lbl_select_queue: 'Select Queue',
      lbl_add_booking_id: 'Add Booking ID',
      lbl_select_group: 'Select Group',
      lbl_incidents: 'Incidents',
      lbl_complaints: 'Complaints',
      lbl_reset_privilege_settings_header: 'Reset To Default Privilege Settings',
      lbl_reset_privilege_settings_message: '<p></p><p>Are you sure you want to reset all passengers privilege settings to their default values?</p><p></p>',
      lbl_reset_privilege_successfully: 'Passengers Privilege Settings Reset Successfully',
      lbl_must_contain_uppercase_lowercase: 'Must contain an uppercase and a lowercase letters (A, z)',
      lbl_must_contain_digits: 'Must contain digits (0-9)',
      lbl_must_contain_special_character: 'Special characters (!, @, #, etc.)',
    });
  }
}
