<div class="booking-assign-vehicle-sidebar">
  <p-sidebar [(visible)]="visibleAssignVehicle" [position]="'right'" (onHide)="onHide()" [modal]="true">
    <ng-template pTemplate="header" class="p-3">
      <div class="text-lg font-bold" translate>bookings.lbl_select_vehicle_transfer</div>
    </ng-template>
    <ng-template pTemplate="content" class="p-3 pt-0">
      <div class="flex flex-row mb-2 header">
        <span class="p-input-icon-left mr-2">
          <i class="pi search-icon relative left-20 cursor-pointer"
            [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
            (click)="clearSearch()"></i>
          <input class="input-width search-filter" type="text" pInputText (input)="onSearch($event)"
            [placeholder]="'lbl_search' | translate" [(ngModel)]="searchValue" />
        </span>
        <p-button  [styleClass]="selectedFilterCount ? 'with-filter' : 'no-filter'"  icon="mi-lg mi-filter_2 inline-block wh-24 vertical-align-text-bottom" styleClass=" bg-f5f5f5 border-none" style="width:10%"
          (click)="showFilterDialog()" />
        @if (selectedFilterCount) {
          <p-badge [value]="selectedFilterCount" severity="success" />
        }
        @if(filterList) {
          <app-filter-box [filterList]="filterList" [show]="show" [position]="position" [moduleName]="moduleName"
              [selectedFilterCount]="selectedFilterCount" [filterAttributeLabels]="filterAttributeLabels" class="filter-box-div"
             (onValueChange)="onFilterValueChange(true,$event)" (onToggleOverLay)="show = false" isFilterBox="true"></app-filter-box>
        }
      </div>
      
      <div class="vehicle-list-container">
        @if(loading){
          <div class="loader">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </div>
        }
        <div class="flex align-items-center w-full list-row">
          <p-checkbox 
            name="selectAll"
            [inputId]="'selectAll'" 
            [value]="true"
            [label]="'Select All'" 
            [checked]="isAllSelected()" 
            (onChange)="toggleSelectAll($event)"
            class="flex ml-2">
          </p-checkbox>
        </div>
        @for(data of vehicleList; track data; let i = $index) {
          <div class="flex align-items-center w-full list-row">
            <div class="flex align-items-center">
              <p-checkbox name="data" [inputId]="'vehicle' + i" [value]="data?.driverId" class="flex ml-2" [checked]="selectedModify.has(data?.driverId)" (onChange)="onCheckboxChange($event, data?.driverId)"></p-checkbox>
              <div class="driver-image ml-2">
                @if (data?.driverProfileImage && data?.driverProfileImage.length > 0) {
                  <p-image [src]="data?.driverProfileImage[0]" class="vertical-align-middle border-round-sm hw-30 driver-img mr-1" width="30" alt="Image"/>
                } @else {
                  <p-image [src]="defaultLoaderImg" class="vertical-align-middle border-round-sm hw-30 driver-img mr-1" width="30" alt="Image"/>
                }
              </div>
              <div class="flex flex-column ml-2">
                <div class="driver-name mb-2">
                  {{data?.driverName}} ({{data?.driverUniqueId}})
                  @if (data?.driverGroupName) {
                    <span class="font-medium"> | {{data?.driverGroupName}}</span>
                  }
                </div>
                <div class="driver-vehicle-detail mb-2">
                  <span class="p-1 mr-2 duty-status font-semibold" [ngClass]="{'on-duty': data?.driverStatus === 'ON', 'off-duty': data?.driverStatus === 'OFF'}">
                    {{ data?.driverStatus === 'ON' ? 'On Duty' : (data?.driverStatus === 'OFF' ? 'Off Duty' : data?.driverStatus) }}
                  </span>
                  <span class="p-1 mr-2 shift-status font-semibold" [ngClass]="{'on-shift': data?.onShift, 'off-shift': !data?.onShift}">
                    {{ data?.onShift ? ('bookings.on_shift_pickup' | translate) : ('bookings.off_shift_pickup' | translate) }}
                  </span>
                  <span class="font-semibold">{{data?.vehicleName}}</span> - {{data?.vehicleRegNumber}} ({{data?.vehicleBodyType}} - {{data?.vehiclePassengerCapacity}})
                </div>
                <div class="location-detail">
                  <span class="font-semibold">{{data?.driverCheckInTime}}</span>
                  @if (data?.locationAddress) {
                    <span> | {{data?.locationAddress}}</span>
                  }
                </div>
              </div>
            </div>
          </div>
        }

        @if (vehicleList?.length === 0) {
          <div class="w-full pt-0 pr-2 pb-0 pl-3">
            <app-no-result-found></app-no-result-found>    
          </div>         
        }
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="flex justify-content-end button-container">
        <button pButton class="bg-white default-text-black-color border-1 border-black-alpha-20 px-20px py-5px" (click)="cancelVehicleData()" [label]="'bookings.cancel' | translate"></button>
        <button pButton class="px-20px py-5px ml-2" (click)="assignVehicleData()" [label]="'bookings.lbl_select' | translate"></button>
      </div>
    </ng-template>
  </p-sidebar>
  @if (visibleAssignVehicle) {
    <div class="backdrop" (click)="onHide()"></div>
  }
</div>