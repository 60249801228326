import { Component } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ConfigService } from '../../../shared/services/config.service';
import { Country } from '../../../shared/models/country';
import { AppSettings } from '../../../shared/app.settings';
import { ToastModule } from 'primeng/toast';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityList } from '../../../vehicles/models/entity.models';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { ListResponse } from '../../../vehicles/models/listResponse.models';
import { TabViewModule } from 'primeng/tabview';
import { AppIcons } from 'app/modules/shared/app.icons';
import { LeaveManagementService } from '../../services/leave-management.service';
import { CommonModule } from '@angular/common';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';

interface DropdownList {
  name: string;
  value: string;
}
@Component({
  selector: 'app-apply-new-leave',
  standalone: true,
  imports: [BreadcrumbModule, FormsModule, ReactiveFormsModule, ButtonModule, TranslateModule, CalendarModule, DropdownModule, ToastModule, AutoCompleteModule, TabViewModule, CommonModule, AccessProviderDirective],
  templateUrl: './apply-new-leave.component.html',
  styleUrl: './apply-new-leave.component.scss'
})
export class ApplyNewLeaveComponent {
  routePath: MenuItem[] = [];
  applyNewLeave: FormGroup;
  dropdownList: any[] = [];
  labels: { labelKey: string; labelValue: string }[] = [];
  country: Country;
  approvalStatus = this.cs.getLabel('lbl_pending');
  leaveId: string;
  selectedDriverName: string = '';
  editLeaveData: any = {};
  tableData = [];
  suggestions: any[] | undefined;
  miIcons = AppIcons;
  activeIndex: number = 0;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "",
    actionStatus: ''
  };
  minDate: Date;
  maxDate: Date;
  minDateForToDate: Date | null = null;
  driverEntityId: string;
  btnLabel: string;
  formattedDate;

  constructor(private entityService: EntityService,
    private leaveManagementService: LeaveManagementService,
    private cs: CommonBindingDataService,
    private configService: ConfigService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private metaDataService: MetaDataService,
    private formBuilder: FormBuilder) {
    this.minDate = this.metaDataService.getCurrentTimeInTimezone();
    this.minDate = this.cs.adjustAndFormatMinDate(this.minDate.toISOString(), false);
    this.maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  }

  ngOnInit() {
    this.setDropdownValues();
    this.formValidation();
    this.getLeaveDetailsById();
    this.setRoutePath();
    this.btnLabel = this.leaveId ? this.cs.getLabel('label_update') : this.cs.getLabel('label_save');
  }

  getLeaveDetailsById() {
    this.leaveId = this.route.snapshot.paramMap.get('id');
    if (this.leaveId) {
      this.leaveManagementService.getLeaveByLeaveId(this.leaveId).subscribe(res => {
        this.editLeaveData = res;
        this.driverEntityId = this.editLeaveData.driverEntityId;
        this.editLeave(this.editLeaveData);
      })
    }
  }

  editLeave(editLeaveData) {
    const driverName = editLeaveData.firstName + ' ' + editLeaveData.lastName;
    this.applyNewLeave.patchValue({
      driverName: driverName,
      leaveType: editLeaveData.leaveType,
      fromDate: new Date(editLeaveData.fromDateStr),
      toDate: new Date(editLeaveData.toDateStr),
      reasonForLeave: editLeaveData.reasonForLeave,
    });
  }

  setDropdownValues() {
    const reqBody = {
      forTenantCode: this.configService.getForTenantCode(),
      attributeCode: "leave_type",
      searchStr: "",
      usageRequired: false
    }
    this.leaveManagementService.getLeaveTypes(reqBody).subscribe((res: any) => {
      this.labels = res.data.map(item => ({
        labelKey: item.labelKey,
        labelValue: item.labelValue
      }));
      this.dropdownList = this.labels.map(label => ({
        label: label.labelKey,
        value: label.labelKey
      }));
    })
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.leaveId ? this.cs.getLabel('lbl_edit_leave') : this.cs.getLabel('lbl_apply_leave'),
        routerLink: this.leaveId ? '../../../driver-leave-management' : '../../driver-leave-management',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      }
    ];
  }

  formValidation() {
    this.applyNewLeave = this.formBuilder.group({
      driverName: ['', [Validators.required]],
      leaveType: ['', [Validators.required]],
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required]],
      reasonForLeave: ['', [Validators.required]],
    });

    const currentDateInTimezone = this.metaDataService.getCurrentTimeInTimezone(); 
    this.applyNewLeave.controls['fromDate'].setValue(currentDateInTimezone);
    this.applyNewLeave.get('fromDate')?.valueChanges.subscribe(fromDate => {
      this.applyNewLeave.controls['toDate'].setValue('');
      this.updateMinDateForToDate(fromDate);
    });
  }

  onDateChange(event: any) { 
    this.formattedDate = this.metaDataService.formatDateInTimezone(event);
    console.log(this.formattedDate);
  }

  updateMinDateForToDate(fromDate: Date | null) {
    if (fromDate) {
      this.minDateForToDate = new Date(fromDate);
      this.minDateForToDate.setHours(0, 0, 0, 0);
    } else {
      this.minDateForToDate = null;
    }
  }

  search(event: AutoCompleteCompleteEvent) {
    this.entityData.searchStr = event.query;
    this.searchEntity();
  }

  searchEntity() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.tableData = [];
    this.entityData.countryCode = country[0].countryCode;
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    const isLoader = false;
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.DRIVER, this.entityData, isLoader).subscribe((res: ListResponse) => {
      const driver = this.cs.getTableData(res);
      driver.map(item => {
        item.name = item.first_name + ' ' + item.last_name
      });
      this.suggestions = driver;
    });
  }

  onSelectDriver(event) {
    this.applyNewLeave.value.driverName = event.value.id;
    this.selectedDriverName = event.value.first_name;
  }

  displayDriverName(): string {
    const driverId = this.applyNewLeave.get('driverName').value;
    const selectedDriver = this.suggestions?.find(driver => driver.id === driverId);
    return selectedDriver ? selectedDriver.name : this.selectedDriverName;
  }


  addEditLeave() {
    if (this.applyNewLeave.valid) {
      const fromDateStr = this.cs.adjustAndFormatDate(this.applyNewLeave.value.fromDate, false);
      const toDateStr = this.cs.adjustAndFormatDate(this.applyNewLeave.value.toDate, true);
      this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
      const reqBody = {
        forTenantCode: this.configService.getForTenantCode(),
        countryCode: this.country[0].countryCode,
        driverEntityId: this.leaveId ? this.driverEntityId : this.applyNewLeave.value.driverName.id,
        leaveType: this.applyNewLeave.value.leaveType,
        fromDateStr: fromDateStr,
        toDateStr: toDateStr,
        reasonForLeave: this.applyNewLeave.value.reasonForLeave,
        approvalStatus: this.approvalStatus,
      }
      const addEditAPI = this.leaveId ? this.leaveManagementService.editLeave(this.leaveId, reqBody) : this.leaveManagementService.saveNewLeave(reqBody)
      addEditAPI.subscribe((res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
        this.applyNewLeave.reset();
        this.router.navigate(["app/driver-leave-management"]);
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_invalid_data') });
      });
    } else {
      Object.keys(this.applyNewLeave.controls).forEach(key => {
        this.applyNewLeave.controls[key].markAsTouched();
      });
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('settings.lbl_please_fill_the_form') });
    }
  }

  onClose() {
    this.router.navigate(["app/driver-leave-management"]);
  }
}
