  import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
  import { ActivatedRoute, Router } from '@angular/router';
  import { TranslateModule } from '@ngx-translate/core';
  import { MenuItem, MessageService } from 'primeng/api';
  import { BreadcrumbModule } from 'primeng/breadcrumb';
  import { ButtonModule } from 'primeng/button';
  import { DialogModule } from 'primeng/dialog';
  import { TabViewModule } from 'primeng/tabview';
  import { AppSettings } from '../../../shared/app.settings';
  import { BasicTableComponent } from '../../../shared/components/basic-table/basic-table.component';
  import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
  import { Country } from '../../../shared/models/country';
  import { Language } from '../../../shared/models/language';
  import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
  import { EntityService } from '../../../shared/services/entity.service';
  import type { AttributeData, entityResponse } from '../../../vehicles/models/attribute.models';

  import { ConfigService } from 'app/modules/shared/services/config.service';
  import * as _ from 'lodash';
  import { ToastModule } from 'primeng/toast';
  import { forkJoin } from 'rxjs';

  @Component({
    selector: 'app-driver-driving-record-entity',
    standalone: true,
    imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, BasicTableComponent, ButtonModule, TranslateModule, ToastModule],
    templateUrl: './driver-driving-record-entity.component.html',
    styleUrl: './driver-driving-record-entity.component.scss'
  })
  export class DriverDrivingRecordEntityComponent {
    @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
    @Output() previousClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() nextClick: EventEmitter<void> = new EventEmitter<void>();
    @Input() dataToChild;
    @Input() tab;
    @Input() activeIndex;
    @Input() attributeLabels = {};
    @Input() moduleName: string;
    @Input() submitBtnText: string;
    @Input() previousBtnText: string;
    @Input() attributeValues = [];
    @Input() attributeData: any;
    @Input() driverId: string;
    @Input() saveBtnId: string;
    routePath: MenuItem[] = [];
    data: AttributeData;
    driverAttributeData: any;
    country: Country;
    language: Language;
    prefixForOneToManyRelatedFields: any = '_';
    driverDrivingRecord: any[] = [];
    driverDrivingRecordFields: any = 3;
    start: number = 0;
    end: number;
    entityId: string;
    driverDrivingRecordEntityIdList: any[] = [];
    driverDrivingRecordData = { data: [] };
    attributeIdForDriverDrivingRecord: any;
    driverDetails: any;
    isReady: boolean = false;
    driverAttributeLength: number;
    edit: boolean = false;
    driverData: any;
    removedDriverDrivingRecordId;
    removedIndex;
    drivingRecordArray: any[] = [];
    relationshipDataForDriverDrivingRecord: any[] = [];
    createEntityRecordArray: any[] = [];
    saveAttributeRecordArray: any[] = [];
    currentTab;
    tabGroup;

    constructor(private driverService: EntityService,
      private cs: CommonBindingDataService,
      private messageService: MessageService,
      private router: Router,
      private route: ActivatedRoute,
      private configService: ConfigService,
      public appSettings: AppSettings) { }

    ngOnInit() {
      this.setLabels();
      this.setDriverId();
      this.setCountryAndLanguage();
      this.loadDriverData();
      this.setRoutePath();
      this.currentTab= this.tab.tabCode;
      console.log(this.currentTab);
      this.tabGroup = this.tab;
      console.log(this.tabGroup);
    }

    setLabels() {
      this.submitBtnText = this.cs.getLabel('lbl_next');
      this.previousBtnText = this.cs.getLabel('lbl_previous');
    }

    setDriverId() {
      this.driverId = this.route.snapshot.paramMap.get('id');
    }

    setCountryAndLanguage() {
      this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
      this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    }

    private loadDriverData() {
      if (this.driverId) {
        this.driverService
          .getEntity(this.driverId, AppSettings.ENTITY_CODE.DRIVER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW)
          .subscribe((res) => {
            if (res) {
              const data = res;
              this.driverDetails = res;
              this.attributeValues = this.cs.getOrgAttributeValues(data);
              console.log(this.attributeValues);
              this.parseAttributeValues();
              this.getDriverDrivingRecordData();
            }
            this.getAttributes();
          });
      } else {
        this.getAttributes();
      }
    }

    private parseAttributeValues() {
      const dateAttributes = [
        AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DRIVING_RECORD_DATE,
        AppSettings.DATE_ATTRIBUTE_IDS.START_TIME,
        AppSettings.DATE_ATTRIBUTE_IDS.END_TIME
      ];
      dateAttributes.forEach(attr => {
        this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
      });

      const driverDrivingRecordIdArray = this.driverDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === AppSettings.FIELDS.DRIVER_DRIVING_RECORD_SECTION);
      this.driverDrivingRecordEntityIdList = (driverDrivingRecordIdArray && driverDrivingRecordIdArray.attributeValue.length > 0) ? driverDrivingRecordIdArray.attributeValue : [];
    }

    getDriverDrivingRecordData() {
      if (this.driverDetails.relatedData.length > 0) {
        this.driverDetails.relatedData.forEach((data, index) => {
          if (data.entityCode === AppSettings.ENTITY_CODE.DRIVER_DRIVING_RECORD) {
            this.setAttributeValuesForDriverDrivingRecordEditMode(data, index);
          }
        });
      }
    }

    setAttributeValuesForDriverDrivingRecordEditMode(data, index) {
      for (const labelsObj of data.attributeCodeValueDtoList) {
        this.attributeValues[index + '_' + labelsObj.attributeCode] =
          labelsObj.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DRIVING_RECORD_DATE ? new Date(labelsObj.attributeValue) : labelsObj.attributeValue;
      }
    }

    getAttributes() {
      this.attributeLabels = this.cs.getAttributeLabels(this.dataToChild);
      this.driverAttributeData = this.cs.getOrganizedAttribute(this.dataToChild);
      this.driverAttributeLength = this.driverAttributeData.tabs.length;
      this.attributeIdForDriverDrivingRecord = this.getAttributeIdForGroupCode('driver_driving_record');
      this.isReady = true;
      this.driverService
        .getAttributeDefinition(
          AppSettings.ENTITY_CODE.DRIVER,
          AppSettings.VIEW_CODE.ADD_EDIT_VIEW
        )
        .subscribe((res) => {
          if (res) {
            this.attributeLabels = this.cs.getAttributeLabels(res);
            this.driverAttributeData = this.cs.getOrganizedAttribute(res);
            this.driverAttributeLength = this.driverAttributeData.tabs.length;
            this.attributeIdForDriverDrivingRecord = this.getAttributeIdForGroupCode('driver_driving_record');
            this.isReady = true;
          }
        });
    }

    getAttributeIdForGroupCode(groupCode: string): string | undefined {
      const group = _.flatMap(this.driverAttributeData.tabs, 'groups').find(group => group.code === groupCode);
      return group ? group.relation.ownerAttributeId : undefined;
    }

    onRemoveDriverDrivingRecordEntity(event) {
      this.removedIndex = event[1];
      this.removedDriverDrivingRecordId = typeof event[0] === 'string' ? event[0] : String(event[0]);
      this.driverDrivingRecordEntityIdList = this.driverDrivingRecordEntityIdList.filter(id => id !== this.removedDriverDrivingRecordId);
      this.saveDriverDrivingRecord();
    }

    setRoutePath() {
      this.routePath = [
        {
          label: this.cs.getLabel('lbl_add_new_driver'),
          routerLink: '../list',
          icon: 'pi pi-arrow-left',
          iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
        },
        {
          label: this.driverId ? this.cs.getLabel('lbl_edit_driver') : this.cs.getLabel('driver.add'),
          styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
          style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
        }
      ];
    }

    onSaveOtherDetails(event) {
      const driverFormData = event;
      this.driverDrivingRecord = [];
      this.driverData = {
        forTenantCode: this.configService.getForTenantCode(),
        entityCode: AppSettings.ENTITY_CODE.DRIVER,
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        data: [],
      };
      const removedDriverFormData = this.cs.removeDocumentByIndex(driverFormData, this.removedIndex);
      const dateIds = [AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DRIVING_RECORD_DATE, AppSettings.DATE_ATTRIBUTE_IDS.START_TIME, AppSettings.DATE_ATTRIBUTE_IDS.END_TIME];
      for (const [key, value] of Object.entries(removedDriverFormData)) {
        let attributeValue = value;
        const arr = key.split('_');
        if (dateIds.includes(key)) {
          attributeValue = this.cs.convertToTimestamp(value as any);
        }
        if (parseInt(arr[0]) >= 0) {
          this.setAttributeValuesForDriverDrivingRecord(key, attributeValue);
        } else {
          this.setAttributeValuesForOtherDetails(key, attributeValue);
        }
      }
      this.drivingRecordArray.forEach((drivingRecord, index) => {
        this.drivingRecordArray[index] = this.cs.mapAttributeIdsForRelatedData(drivingRecord, this.driverAttributeData.tabs);
      });
      this.driverData.data = this.cs.mapAttributeIds(this.driverData.data, this.driverAttributeData.tabs);
      this.saveDriverDrivingRecord();
    }

    setAttributeValuesForOtherDetails(key, attributeValue) {
      if (attributeValue) {
        this.driverData.data.push({
          attributeCode: key,
          attributeValue
        });
      }
    }


    setAttributeValuesForDriverDrivingRecord(key, attributeValue) {
      if(!key) {
        return;
      }
      const arr = key.split('_');
      if (typeof this.drivingRecordArray[arr[0]] === 'undefined') {
        this.drivingRecordArray[arr[0]] = [];
      }
      const attributeCode = key.substring(key.indexOf('_') + 1);
      if (attributeValue) {
        const driverRecordDate = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DRIVING_RECORD_DATE;
        const startTime = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.START_TIME;
        const endTime = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.END_TIME;
        const value = driverRecordDate || startTime || endTime ? new Date(attributeValue).getTime() : attributeValue;
        this.drivingRecordArray[arr[0]].push({ attributeCode, attributeValue: value });
      }
    }

    saveDriverDrivingRecord() {
      this.relationshipDataForDriverDrivingRecord = [];
      const newDriverRecordList: any[] = [];
      const entitiesData = {
        countryCode: this.country[0].countryCode,
        tenantCode: this.configService.getForTenantCode(),
        entityCode: AppSettings.ENTITY_CODE.DRIVER_DRIVING_RECORD,
      }

      this.createEntityRecordArray = [];
      this.saveAttributeRecordArray = [];
      this.drivingRecordArray.forEach((record, index) => {
        const driverRecordEntity = {
          forTenantCode: this.configService.getForTenantCode(),
          entityCode: AppSettings.ENTITY_CODE.DRIVER_DRIVING_RECORD,
          countryCode: this.country[0].countryCode,
          languageCode: this.language[0].langCode,
          data: record
        };
  

      if (this.driverDrivingRecordEntityIdList[index]) {
        this.saveAttributeRecordArray.push(this.driverService.saveAttributeData(driverRecordEntity.entityCode, this.driverDrivingRecordEntityIdList[index], driverRecordEntity));
        this.relationshipDataForDriverDrivingRecord.push(
          {
            entityRelationshipConfigId: this.driverAttributeData?.tabs[1]?.groups.find(ele => ele.code === 'driver_driving_record')?.relation.entityRelationshipConfigId,
            otherEntityId: this.driverDrivingRecordEntityIdList[index]
          }
        );
      } else {
        newDriverRecordList.push(driverRecordEntity);
        this.createEntityRecordArray.push(this.driverService.createEntities(entitiesData.entityCode, entitiesData));
      }
      if (index === this.drivingRecordArray.length - 1) {
        this.createAndSaveDriverRecord(entitiesData, newDriverRecordList);
      }
    });
  }

    createAndSaveDriverRecord(entitiesData, newDriverRecordList) {
      if (this.createEntityRecordArray.length > 0) {
        forkJoin(this.createEntityRecordArray).subscribe(result => {
          if (result && result.length > 0) {
            result.forEach((createEntityResponse: any, index) => {
              this.driverDrivingRecordEntityIdList.push(createEntityResponse.entityId);
              this.saveAttributeRecordArray.push(this.driverService.saveAttributeData(entitiesData.entityCode, createEntityResponse.entityId, newDriverRecordList[index]));
              this.relationshipDataForDriverDrivingRecord.push(
                {
                  entityRelationshipConfigId: this.driverAttributeData?.tabs[1]?.groups.find(ele => ele.code === 'driver_driving_record')?.relation.entityRelationshipConfigId,
                  otherEntityId: createEntityResponse.entityId
                }
              );
            });
            this.saveDriverRecordAttributes();
          }
  
        })
      } else {
        this.saveDriverRecordAttributes();
      }
    }

    saveDriverRecordAttributes() {
      forkJoin(this.saveAttributeRecordArray).subscribe(data => {
        this.driverData.data.push({
          attributeId: this.attributeIdForDriverDrivingRecord,
          attributeValue: this.driverDrivingRecordEntityIdList
        });
        this.saveDriverDrivingDataToDb();
      });
    }

    saveDriverDrivingDataToDb() {
      const entitiesData = {
        countryCode: this.country[0].countryCode,
        tenantCode: this.configService.getForTenantCode(),
        entityCode: AppSettings.ENTITY_CODE.DRIVER
      }
      this.driverData.relationshipData = this.relationshipDataForDriverDrivingRecord;
      if (this.driverId) {
        this.saveAttributeData(entitiesData.entityCode)
      } else {
        this.createEntityAndUpdateAttributeData(entitiesData);
      }
    }

  saveAttributeData(entityCode) {
    this.driverService.saveAttributeData(entityCode, this.driverId, this.driverData).subscribe((res: any) => {
      this.cs.onDisplayMessage(this.dataToChild, 'driver', AppSettings.MSG_ACTION.ADD, 'success');
    });
  }



  createEntityAndUpdateAttributeData(entitiesData) {
    this.driverService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.driverId = res.entityId;
      this.driverService.saveAttributeData(entitiesData.entityCode, this.driverId, this.driverData).subscribe((res: any) => {
        this.cs.onDisplayMessage(this.dataToChild, 'driver', AppSettings.MSG_ACTION.ADD, 'success');
      });
    });
  }

    saveDriverData(driverData) {
      this.driverData.relationshipData = this.relationshipDataForDriverDrivingRecord;
      this.driverData.data = this.cs.mapAttributeIds(this.driverData.data, this.driverAttributeData.tabs);
      if (this.driverId) {
        this.saveDriverApiCall(this.driverData.entityCode);
      } else {
        this.createDriverEntity();
      }
    }

    saveDriverApiCall(entityCode) {
      this.driverService.saveAttributeData(entityCode, this.driverId, this.driverData).subscribe({
        next: (res: any) => {
          // this.cs.onDisplayMessage(this.driverData, 'driver', AppSettings.MSG_ACTION.ADD, 'success');
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
          setTimeout(() => {
            this.onNextClick();
          }, 500);
        },
        error: (error) => {
          // this.cs.onDisplayMessage(this.driverData, 'driver', AppSettings.MSG_ACTION.ADD, 'error');
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
        }
      });
    }

    createDriverEntity() {
      this.driverService.createEntities(this.driverData.entityCode, this.driverData).subscribe({
        next: (res: entityResponse) => {
          this.driverId = res.entityId;
          this.saveDriverApiCall(this.driverData.entityCode);
        },
        error: (error) => {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
        }
      });
    }

    onPreviousClick(event) {
      this.previousClick.emit();
    }

    onNextClick() {
      this.nextClick.emit();
    }

    getLastIndex(): number {
      return this.driverAttributeLength - 1;
    }

    isLastIndex(): boolean {
      return this.activeIndex === this.getLastIndex();
    }

    onCancel() {
      this.miFormComponent.resetForm();
      this.router.navigate(['app/drivers/list']);
    }
  }
