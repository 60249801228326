@if (allColumns) {
    <app-mi-table
        [collectionOfColumns]="allColumns"
        [attributeLabels]="filterAttributeLabels"
        [data]="tableData"
        [entityName]="entityName"
        [isLoading]="isLoading"
        [totalRecords]="totalRecords"
        [filterList]="filterList"
        (onValueChange)="onFilterValueChange($event)"
        [filterFields]="filterFieldsArray"
        (onSearchValueChange)="onSearchValueChanges($event)"
        (setCurrentRowData)="setCurrentRowData($event)"
        (onRowSelection)="onRowSelect($event)"
        [label]="addBtnLabel"
        (onBtnClick)="onAddDocument()">
    </app-mi-table>
}
