<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'ORGANIZATION', pageCode: 'ALL', subPageCode: 'ADD' }">
  <div class="flex justify-content-end flex-wrap btn-with-links">
    <p-button class="flex align-items-center justify-content-center add-org-btn" icon="pi pi-plus" [label]="btnLabel" (click)="onAddOrganization()"></p-button>
  </div>
</div>
<p-tabView [(activeIndex)]="activeIndex" appAccessProvider [accessCode]="{ menuCode: 'ORGANIZATION', pageCode: 'ALL', subPageCode: 'LIST' }">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="mi-lg mi-activity_feed mr-1"></i>
      <span translate>lbl_all</span>&nbsp;<span class="iconNumber text-color-secondary">({{count}})</span>
    </ng-template>
    <ng-template pTemplate="body">

          <app-mi-table
              [collectionOfColumns]="allColumns"
              [attributeLabels]="filterAttributeLabels"
              [data]="tableData"
              [isLoading]="isLoading"
              [entityNameToDisplay]="entityNameToDisplay"
              [entityNameForBulkUpdate]="entityNameForBulkUpdate"
              (onPageChange)="onPageChange($event)"
              (itemPerPageChange)="itemPerPageChange($event)"
              [message]="deleteShiftTemplateMsg"
              [header]="deleteShiftTemplateHeaderMsg"
              (onRowSelection)="onRowSelect($event)"
              [filterFields]="filterFieldsArray"
              [label]="btnLabel"
              (onBtnClick)="onAddOrganization()"
              [attributeData]="attributeData"
              [rowItems]="rowItems"
              [bulkSelectionsItems] = "bulkSelectionsItems"
              [bulkUpdateLabel]="bulkUpdateLabel"
              [bulkUpdateHeader]="bulkUpdateHeader"
              [bulkDeleteLabel]="bulkDeleteLabel"
              (setCurrentRowData)="setCurrentRowData($event)"
              [showBulkUpdateDialog]="showBulkUpdateDialog"
              (onBulkDataUpdateEvent)="onBulkDataUpdateEvent()"
              [reviewHeader]="reviewHeader"
              (onSearchValueChange)="onSearchValueChanges($event)"
              [totalRecords]="totalRecords"
              [visible]="visible"
              [isEntity]="isEntity"
              [uniqueId]="uniqueId"
              (onOrganizationStatusChangeEvent)="onOrganizationStatusChangeEvent($event)"
              [staticEntity]="entityName"
              >
            </app-mi-table>

    </ng-template>
  </p-tabPanel>
</p-tabView>

<input hidden type="file" id="upload" (change)="onChange()" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" #uploader>
