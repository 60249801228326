import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsService } from 'app/modules/settings/settings.service';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { EditorModule } from 'primeng/editor';
import { TabViewModule } from 'primeng/tabview';
import { AppSettings } from '../../../shared/app.settings';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [EditorModule, TranslateModule, BreadcrumbModule, ButtonModule, TabViewModule, FormsModule, ReactiveFormsModule, AccessProviderDirective],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {
  btnLabel: string;
  routePath: MenuItem[] = [];
  privacyForm: FormGroup;
  addBtnLabel: string;
  miIcons = AppIcons;
  originalPrivacyContent: string = '';

  constructor(private cs: CommonBindingDataService,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private messageService: MessageService,
    private configService: ConfigService,
    private confirmationService: ConfirmationService) {

  }


  ngOnInit(): void {
    this.setRoutePath();
    this.initForm();
    this.getPrivacyPolicyData();
  }

  private setLabels() {
    this.addBtnLabel = this.cs.getLabel('label_save');
  }

  private initForm() {
    this.privacyForm = this.fb.group({
      privacy: ['', [Validators.required, this.noEmptyHtmlValidator()]],
    });
  }

  private noEmptyHtmlValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value || value.trim() === '' || value.replace(/<[^>]*>/g, '').trim() === '') {
        return { required: true };
      }
      return null;
    };
  }

  private setRoutePath() {
    this.routePath = [
      { label: this.cs.getLabel('content.lbl_privacy_policy'), routerLink: 'content/terms-and-conditions' },
    ];
  }

  onCancel() {
    if (this.isFormChanged()) {
      this.confirmationService.confirm({
        header: this.cs.getLabel('content.lbl_confirmation'),
        message: this.cs.getLabel('content.confirm_discard_msg'),
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptLabel: this.cs.getLabel('content.lbl_discard'),
        rejectLabel: this.cs.getLabel('content.lbl_no'),
        accept: () => {
          this.restoreOriginalData();
        },
        reject: () => {
          
        }
      });
    } else {
      this.restoreOriginalData();
    }
  }

  private isFormChanged(): boolean {
    return this.privacyForm.get('privacy')?.value !== this.originalPrivacyContent;
  }

  private restoreOriginalData() {
    this.privacyForm.patchValue({
      privacy: this.originalPrivacyContent
    });
  }

  onAdd() {
    if (this.privacyForm.valid) {
      const privacyContent = this.privacyForm.get('privacy')?.value;
      const privacyData = {
        forTenantCode: this.configService.getForTenantCode(),
        contentType: AppSettings.STATIC_CONTENT.PRIVACY_POLICY,
        staticContentReqDto: [
          {
            contentType: AppSettings.STATIC_CONTENT.PRIVACY_POLICY,
            roleCode: "DRIVER",
            content: privacyContent
          }
        ]
      };
      this.settingsService.updateStaticContentInfo(privacyData).subscribe(res => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('settings.privacy_policy_update_msg') });
      }, (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: error.errors.general[0].message });
      });
    } else {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('settings.lbl_please_fill_the_form') });
    }
  }

  getPrivacyPolicyData() {
    const contentType = AppSettings.STATIC_CONTENT.PRIVACY_POLICY;
    this.settingsService.getStaticContentData(contentType).subscribe((res: any) => {
      if (res && res.length > 0) {
        const staticContentReqDto = res[0]?.staticContentReqDto;
        if (staticContentReqDto && staticContentReqDto.length > 0) {
          const content = staticContentReqDto.find(dto => dto.contentType === AppSettings.STATIC_CONTENT.PRIVACY_POLICY)?.content;

          if (content) {
            this.privacyForm.patchValue({
              privacy: content
            });
            this.originalPrivacyContent = content;
          }
        }
        this.addBtnLabel = this.cs.getLabel('content.lbl_update');
      } else {
        this.setLabels();
      }
    });
  }

}
