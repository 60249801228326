import { Injectable, signal } from '@angular/core';
import type { EntityCount, EntityList } from 'app/modules/vehicles/models/entity.models';
import type { RelatedDataSchema } from 'app/modules/vehicles/models/entityResponse.models';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettings } from '../app.settings';
import type { Country } from '../models/country';
import type { CreateEntityPayload } from '../models/entity.models';
import { ConfigService } from './config.service';
import { RestApiService } from './rest-api.service';
import { EventService } from 'app/modules/events/services/event.service';

@Injectable({
  providedIn: 'root'
})
export class EntityService {
  country: Country;

  private entityView: { [key: string]: { [key: string]: any } } = {};
  private filterState: any = {};
  private relatedData = signal<RelatedDataSchema>({});
  private bookingStatuses: any = [];
  private transportationTypes: any = [];
  private passengerTypes: any = [];
  public defaultAddressSelection = new BehaviorSubject({});

  constructor(private restApiService: RestApiService, private confService: ConfigService,
    private eventService: EventService
  ) { }

  vehicleCurrentRow = new BehaviorSubject(null);


  get getRelateData() {
    return this.relatedData();
  }


  setRelatedData(newString: RelatedDataSchema): void {
    this.relatedData.set(newString);
  }

  setMiState(key: string, state: any): void {
    this.filterState[key] = state;
  }

  getMiState(key: string): any {
    return this.filterState[key];
  }

  clearMiState(key: string): void {
    delete this.filterState[key];
  }

  setBookingStatuses(bookingStatuses) {
    this.bookingStatuses = bookingStatuses;
  }

  getBookingStatuses() {
    return this.bookingStatuses;
  }

  setTransportationType(transportationTypes) {
    this.transportationTypes = transportationTypes;
  }

  getTransportationType(): [] {
    return this.transportationTypes;
  }

  setPassengerTypes(passengertypes) {
    this.passengerTypes = passengertypes;
  }

  getPassengerTypes(): [] {
    return this.passengerTypes;
  }


  setEntityView(entityName: string, view: string, state: any) {
    if (!this.entityView[entityName]) {
      this.entityView[entityName] = {};
    }
    this.entityView[entityName][view] = state;
  }

  getCachedEntityView(entityName: string, view: string) {
    if (this.entityView[entityName] && this.entityView[entityName][view] !== undefined) {
      return this.entityView[entityName][view];
    }
    return null;
  }

  cleanEntityView(entityName: string, view: string) {
    if (this.entityView[entityName]) {
      delete this.entityView[entityName][view];
      if (Object.keys(this.entityView[entityName]).length === 0) {
        delete this.entityView[entityName];
      }
    }
  }

  getAttributeDefinition(entityCode: string, viewCode: string): Observable<any> {
    // Check cache first
    const cachedData = this.getCachedEntityView(entityCode, `attributes_${viewCode}`);
    if (cachedData) {
      return new Observable(observer => {
        observer.next(cachedData);
        observer.complete();
      });
    }

    // If not in cache, make API call and store response
    return new Observable(observer => {
      this.restApiService.get(
        'ATTRIBUTE DEFINITION', 
        `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes?forTenantCode=${this.confService.getForTenantCode()}&viewCode=${viewCode}`, 
        'page-center'
      ).subscribe({
        next: (response) => {
          this.setEntityView(entityCode, `attributes_${viewCode}`, response);
          observer.next(response);
          observer.complete();
        },
        error: (error) => observer.error(error)
      });
    });
  }

  saveAttributeData(entityCode: string, entityId: string, data: {}, isMainSettingsData?: boolean) {
    if (!isMainSettingsData) {
      this.setEventCode(data);
    }
    return this.restApiService.post('SAVE ATTRIBUTE DEFINITION', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/${entityId}/attribute-data`, data, 'page-center');
  }

  saveDriverDutyStatusData(entityCode: string, data) {
    this.setEventCode(data);
    return this.restApiService.post('SAVE DRIVER DUTY STATUS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/custom/update-duty-status`, data, 'page-center');
  }

  createEntities(entityCode: string, data: CreateEntityPayload, isMainSettingsData?: boolean) {
    if (!isMainSettingsData) {
      this.setEventCode(data);
    }
    return this.restApiService.post('CREATE NEW ENTITY', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities`, data, 'page-center');
  }

  getEntityCount(entityCode: string, data: EntityCount) {
    this.setEventCode(data);
    return this.restApiService.post('CREATE ENTITIES', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/counts`, data, 'page-center');
  }

  searchEntity(entityCode: string, data: EntityList, loader?: boolean) {
    this.setEventCode(data);
    return this.restApiService.post('SEARCH ENTITY', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/search`, data, loader ? 'page-center' : '');
  }

  searchEntityForVehicleTransfer(data) {
    this.setEventCode(data);
    return this.restApiService.post('SEARCH VEHICLE TRANSFER', `${this.confService.getLoggedInTenantCode()}/api/v1/custom/vehicle-transfer-list`, data, 'page-center');
  }

  searchEntityForTags(entityCode: string, data, loader?: boolean) {
    return this.restApiService.post('SEARCH ENTITY FOR TAGS', `${this.confService.getLoggedInTenantCode()}/api/v1/${entityCode}/search?forTenantCode=${this.confService.getForTenantCode()}`, data, loader ? 'page-center' : '');
  }

  searchEntityForUsage(data, loader?: boolean) {
    return this.restApiService.post('SEARCH ENTITY FOR USAGE', `${this.confService.getLoggedInTenantCode()}/api/v1/usages/count`, data, loader ? 'page-center' : '');
  }

  getEntity(entityId: string, entityCode: string, viewCode: string) {
    return this.restApiService.get('GET ENTITY DETAILS BY ENTITYID', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/${entityId}?forTenantCode=${this.confService.getForTenantCode()}&viewCode=${viewCode}&eventCode=${this.eventService.getSelectedEventcode()}`, 'page-center');
  }

  getEntityDetailsByEntityIds(data: any, entityCode: string) {
    this.setEventCode(data);
    return this.restApiService.post('GET ENTITY DETAILS BY ENTITYIDS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/selected-list`, data, 'page-center');
  }

  downloadImportTemplate(entityCode: string): Observable<any> {
    return this.restApiService.downloadDocument(`${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entity-files/template?forTenantCode=${this.confService.getForTenantCode()}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  }

  downloadBarcode(entityId, entityCode: string): Observable<any> {
    return this.restApiService.downloadDocumentBarcode(`${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/${entityId}/barcode?forTenantCode=${this.confService.getForTenantCode()}&countryCode=IN&eventCode=${this.eventService.getSelectedEventcode()}`);
  }

  printLabels(entityCode, data): Observable<any> {
    this.setEventCode(data);
    return this.restApiService.downloadDocumentPost(`${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/print-labels`, data);
  }

  downloadImportGuide(entityCode: string): Observable<any> {
    return this.restApiService.downloadDocument(`${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entity-files/guide?forTenantCode=${this.confService.getForTenantCode()}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  }
  deleteEntityActionStatus(data) {
    this.setEventCode(data);
    return this.restApiService.put('DELETE ENTITIES', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${data.entityCode}/entities/bulk-delete`, data, 'page-center');
  }

  updateBulkData(data) {
    this.setEventCode(data);
    return this.restApiService.put('BULK UPDATE', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${data.entityCode}/entities/bulk-update`, data, 'page-center');
  }

  getFile(fileId, fileType, thumb = 'yes') {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    return this.restApiService.downloadDocument(`${this.confService.getLoggedInTenantCode()}/api/v1/files/${fileType}/download/${fileId}?forTenantCode=${this.confService.getForTenantCode()}&thumb=${thumb}&X-COUNTRY-CODE=${this.country[0].countryCode}`, 'application/json', 'blob');
  }

  updateStatus(entityId, data) {
    this.setEventCode(data);
    return this.restApiService.put('ACTION-STATUS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${data.entityCode}/entities/${entityId}/action-status`, data, 'page-center');
  }

  assignDriverGroup(entityCode, data) {
    this.setEventCode(data);
    return this.restApiService.post('DRIVER GROUP', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/custom/assign-driver-group`, data, 'page-center');
  }

  releaseDriverGroup(entityCode, data) {
    this.setEventCode(data);
    return this.restApiService.post('RELEASE DRIVER GROUP', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/custom/release-driver-group`, data, 'page-center');
  }

  assignLocation(entityCode, data) {
    this.setEventCode(data);
    return this.restApiService.post('ASSIGN LOCATION', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/custom/assign-location`, data, 'page-center');
  }

  releaseLocation(entityCode, data) {
    this.setEventCode(data);
    return this.restApiService.post('RELEASE LOCATION', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/custom/release-location`, data, 'page-center');
  }

  assignVehicle(entityCode, data) {
    this.setEventCode(data);
    return this.restApiService.post('ASSIGN VEHICLE', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/custom/assign-vehicle`, data, 'page-center');
  }

  assignDriver(data, entityCode) {
    this.setEventCode(data);
    return this.restApiService.post('ASSIGN DRIVER', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/custom/booking-assign-driver`, data, 'page-center');
  }

  releaseVehicle(entityCode, data) {
    this.setEventCode(data);
    return this.restApiService.post('RELEASE VEHICLE', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/custom/release-vehicle`, data, 'page-center');
  }

  autoComplete(entityCode, data): Observable<any> {
    this.setEventCode(data);
    return this.restApiService.post('AUTO COMPLETE', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/auto-complete`, data, 'page-center');
  }

  distinctValues(entityCode, data): Observable<any> {
    return this.restApiService.post('AUTO COMPLETE', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes/distinct-attribute-values`, data, 'page-center');
  }

  updateIncidentStatus(entityCode: string, data: {}) {
    return this.restApiService.post('UPDATE INCIDENT STATUS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/custom/update-incident-status`, data, 'page-center');
  }

  updateEventStatus(entityCode: string, data: {}) {
    this.setEventCode(data);
    return this.restApiService.post('UPDATE EVENT STATUS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/custom/update-event-status`, data, 'page-center');
  }

  searchEntityHeader(entityCode: string, entityData: any): Observable<any> {
    if (this.confService.getLoggedInTenantCode()) {
      const url = `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/search`;
      return this.restApiService.post('SEARCH ENTITY', url, entityData, 'page-center');
    }
  }

  addOrganization(requestBody) {
    return this.restApiService.post('ADD ORGANIZATION', `${this.confService.getLoggedInTenantCode()}/api/v1/tenants`, requestBody, 'page-center');
  }

  updateOrganization(requestBody, tenantId) {
    return this.restApiService.put('UPDATE ORGANIZATION', `${this.confService.getLoggedInTenantCode()}/api/v1/tenants/${tenantId}`, requestBody, 'page-center');
  }

  getEntityConfiguration(requestBody, tenantCode) {
    return this.restApiService.post('ADD ORGANIZATION', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-configurations/search?forTenantCode=${tenantCode}`, requestBody, 'page-center');
  }

  getOrganizationList(requestBody) {
    return this.restApiService.post('ORGANIZATION LIST', `${this.confService.getLoggedInTenantCode()}/api/v1/tenants/search`, requestBody, 'page-center');
  }

  getOrgDetails(tenantId: string) {
    return this.restApiService.get('GET TENANT DETAILS BY TENANT ID', `${this.confService.getLoggedInTenantCode()}/api/v1/tenants/${tenantId}`, 'page-center');
  }

  getUserDetails(userId: string, forTenantCode) {
    return this.restApiService.get('GET USER BY ID', `${this.confService.getLoggedInTenantCode()}/api/v1/users/${userId}?forTenantCode=${forTenantCode}`, 'page-center');
  }

  updateOrganizationStatus(tenantId: string, data: {}) {
    return this.restApiService.put('Update Organization Status', `${this.confService.getLoggedInTenantCode()}/api/v1/tenants/${tenantId}/action`, data, 'page-center');
  }

  addAttributeSettings(entityCode: string, data: {}) {
    return this.restApiService.post('ADD ATTRIBUTE SETTINGS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes/preset-values`, data, 'page-center');
  }

  getEntityDetailsByView(entityCode: string, data: {}) {    
    return this.restApiService.post('get entity details by view', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/find-by-view?forTenantCode=${this.confService.getForTenantCode()}&eventCode=`, data, '');
  }

  getEntityDetailsById(entityCode: string, data: {}) {
    this.setEventCode(data);
    return this.restApiService.post('get entity details by Id', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/find-by-view?forTenantCode=${this.confService.getForTenantCode()}`, data, 'page-center');
  }

  editAttributeSettings(entityCode: string, presetLabelId, data: {}) {
    return this.restApiService.put('EDIT ATTRIBUTE SETTINGS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes/preset-values/${presetLabelId}`, data, 'page-center');
  }

  searchAttributeSettings(entityCode: string, data: {}, loader?: boolean) {
    return this.restApiService.post('SEARCH ATTRIBUTE SETTINGS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes/preset-values/search`, data, loader ? 'page-center' : '');
  }

  deleteAttributeSettings(entityCode: string, data) {
    return this.restApiService.put('DELETE ATTRIBUTE SETTINGS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/attributes/preset-values/action/bulk-delete`, data, 'page-center');
  }

  saveAccessToModules(data) {
    return this.restApiService.put('save access to modules', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-configurations/bulk-update`, data, 'page-center');
  }

  getTimeZones(data) {
    return this.restApiService.post('get time zones', `${this.confService.getLoggedInTenantCode()}/api/v1/timezones`, data,'page-center');
  }

  updateDriverStatus(data) {
    this.setEventCode(data);
    return this.restApiService.post('ACTION-STATUS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${data.entityCode}/custom/update-driver-status`, data, 'page-center');
  }

  getPresetLabels(data, entityCode) {
    return this.restApiService.post('get preset labels', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/custom/preset-labels/search`, data, 'page-center');
  }

  getTagsList(data) {
    return this.restApiService.post('get tags', `${this.confService.getLoggedInTenantCode()}/api/v1/tags/search?forTenantCode=${this.confService.getForTenantCode()}`, data, 'page-center');
  }

  getCommonPresetLabels(data) {
    return this.restApiService.post('get common preset labels', `${this.confService.getLoggedInTenantCode()}/api/v1/common-preset-labels/search`, data, 'page-center');
  }
  
  getUserDetailsById(data) {
    return this.restApiService.post('GET USER DETAILS BY ID', `${this.confService.getLoggedInTenantCode()}/api/v1/users/user-details?forTenantCode=${this.confService.getForTenantCode()}`, data, 'page-center');
  }

  getReverseGeoCodeUsingLatLng(latLng) {
    return this.restApiService.get('GET REVERSE LAT LNG', `${this.confService.getLoggedInTenantCode()}/api/v1/location-info/reverse-geocode?forTenantCode=${this.confService.getForTenantCode()}&latLng=${latLng}`, 'page-center');
  }

  getUniqueIdentificationByTenant() {
    return this.restApiService.get('UNIQUE IDENTIFICATION BY TENANT', `${this.confService.getLoggedInTenantCode()}/api/v1/tenants/unique-ids?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  getEntityDetailsByUniqueId(data, entityCode) {
    return this.restApiService.post('GET ENTITY DETAILS BY UNIQUE ID', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/details-other-events`, data, 'page-center');
  }

  googlemapAutoCompleteApi(input, location) {
    return this.restApiService.get('google map autocomplete', `${this.confService.getLoggedInTenantCode()}/api/v1/location-info/autocomplete?forTenantCode=${this.confService.getForTenantCode()}&input=${input}&location=${location}`, 'page-center');
  }

  getDriverCurrentLocationById(entityCode, entityId) {
    return this.restApiService.get('GET DRIVER CURRENT LOCATION BY ID', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/driver-location/${entityId}?forTenantCode=${this.confService.getForTenantCode()}&eventCode=${this.eventService.getSelectedEventcode()}`, 'page-center');
  }

  resetPrivilegeSettings(entityCode: string, data: {}, loader?: boolean) {
    return this.restApiService.post('RESET PRIVILEGE SETTINGS', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/custom/reset-privileges`, data, loader ? 'page-center' : '');
  }

  driverCurrentShift(data: {}, loader?: boolean) {
    return this.restApiService.post('DRIVER CURRENT SHIFT', `${this.confService.getLoggedInTenantCode()}/api/v1/shift-calendars/driver/current-shifts`, data, loader ? 'page-center' : '');
  }

  private setEventCode(data) {
   data.eventCode = this.eventService.getSelectedEventcode();
   return data;
  }

  reviewSpecialRequest(data: {
    forTenantCode: string;
    countryCode: string;
    languageCode: string;
    bookingEntityId: string;
    passengerVehicleCount: number;
    luggageVehicleCount: number;
  }) {
    this.setEventCode(data);
    return this.restApiService.post(
      'REVIEW SPECIAL REQUEST', 
      `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/booking/custom/review-special-request`, 
      data, 
      'page-center'
    );
  }
}
