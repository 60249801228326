import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import type { EntityList } from '../../../vehicles/models/entity.models';
import type { ListResponse, Values } from '../../../vehicles/models/listResponse.models';
import { AppIcons } from '../../app.icons';
import { AppSettings } from '../../app.settings';
import type { Country } from '../../models/country';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { ConfigService } from '../../services/config.service';
import { EntityService } from '../../services/entity.service';
import { NoResultFoundComponent } from '../no-result-found/no-result-found.component';
import { StatusComponent } from '../status/status.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-modify-default-entity',
  standalone: true,
  imports: [DialogModule, TranslateModule, InputTextModule, RadioButtonModule, StatusComponent, ButtonModule, ReactiveFormsModule, FormsModule, NoResultFoundComponent, NgClass],
  templateUrl: './modify-default-entity.component.html',
  styleUrl: './modify-default-entity.component.scss',
})
export class ModifyDefaultEntityComponent implements OnChanges, OnInit {
  miIcons = AppIcons;
  @Input() visibleModify;
  @Input() rowData;
  @Input() filterList;
  @Input() attributeLabels: {};
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Output() onToggleOverLaySuccess: EventEmitter<boolean> = new EventEmitter();
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  @Output() onValueChange = new EventEmitter<any>();
  smartSearchTimer: any;
  moduleName;
  selectedFilterCount: number;
  driverData;
  advanceFilterValue: any[] = [];
  quickFilterValue: any[] = [];
  userId: any = '';
  imgFileId: any;
  modifyVehicle;
  selectedModify;
  tableData: any = [];
  tableDataTemp: any = [];
  country: Country;
  listColumns: Values;
  vehicleAttributeDate: any;
  vehicleStatusAttributeId;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "",
    actionStatus: ''
  };

  constructor(private driverService: EntityService, private cs: CommonBindingDataService, private messageService: MessageService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.moduleName = AppSettings.ENTITY_CODE.DRIVER;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.getFilterView();
  }

  ngOnChanges(): void {
    this.driverData = this.rowData;
    this.tableData = JSON.parse(localStorage.getItem('modifyVehicleData'));
    const indexOfDefaultVehicle = this.tableData?.findIndex(ele => ele.id === this.driverData?.default_vehicle_id);
    if (indexOfDefaultVehicle >= 0) {
      const deletedData = this.tableData.splice(indexOfDefaultVehicle, 1);
      this.tableData.unshift(deletedData[0]);
      this.selectedModify = this.tableData[0]?.id;
    }
    this.tableDataTemp = this.tableData;
  }

  onHide() {
    this.visibleModify = false;
    this.onToggleOverLay.emit(this.visibleModify);
  }

  getFilterView() {
    this.driverService.getAttributeDefinition(AppSettings.ENTITY_CODE.VEHICLE, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
        this.setVehicleFilterView(filterResponse);
      }
    })
  }

  setVehicleFilterView(filterResponse) {
    this.vehicleAttributeDate = this.cs.getOrganizedAttribute(filterResponse);
    this.vehicleStatusAttributeId = this.cs.getAttributeId('vehicle_status', this.vehicleAttributeDate );
    this.searchEntity();
  }

  searchEntity() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.tableData = [];
    this.entityData.filters.push({
      attributeId: this.vehicleStatusAttributeId,
      attributeValue: AppSettings.VEHICLE_STATUS.active
    });
    this.entityData.countryCode = country[0].countryCode;
    this.driverService.searchEntity(AppSettings.ENTITY_CODE.VEHICLE, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      if (res?.data[0]?.values) {
        for (const data of res.data) {
          this.tableData.push({
            id: data.id,
            ...data.values
          });
        }
      } else {
        this.tableData = [];
      }
      localStorage.setItem('modifyVehicleData', JSON.stringify(this.tableData));
    })
  }

  onSearch(event, driverGroupList) {
    const value = event.target.value.toLowerCase();
    if (driverGroupList) {
      this.tableData = this.tableDataTemp.filter(ele => ele?.name_code.toLowerCase().includes(value));
    }
  }

  selectDefaultVehicle(data) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const reqBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      languageCode: language[0].langCode,
      driverEntityId: this.driverData?.id,
      defaultVehicleEntityId: data?.id,
    }
    const entityCode = AppSettings.ENTITY_CODE.VEHICLE;
    this.driverService.assignVehicle(entityCode, reqBody).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('lbl_modify_vehicle_assign_success'), });
      this.visibleModify = false;
      this.searchEntity();
      this.onToggleOverLaySuccess.emit(this.visibleModify);
    },
      errors => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_invalid_data') });
      });
  }

  removeDefaultVehicle() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const reqBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      languageCode: language[0].langCode,
      driverEntityId: this.driverData?.id,
      type: AppSettings.DRIVER.RELEASE.DEFAULT_VEHICLE,
    }
    const entityCode = AppSettings.ENTITY_CODE.VEHICLE;
    this.driverService.releaseVehicle(entityCode, reqBody).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('lbl_release_modify_vehicle_success'), });
      this.visibleModify = false;
      this.searchEntity();
      this.onToggleOverLaySuccess.emit(this.visibleModify);
    },
      errors => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_invalid_data') });
      });
  }
}
