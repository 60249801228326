import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { CheckboxWithAddNewComponent } from './checkbox-with-add-new/checkbox-with-add-new.component';
import { CounterComponent } from './counter/counter.component';
import { DateInputComponent } from './date-input/date-input.component';
import { DateTimeComponent } from './date-time/date-time.component';
import { DateYearComponent } from './date-year/date-year.component';
import { DaysOfWeekComponent } from './days-of-week/days-of-week.component';
import { GeoPointComponent } from './geo-point/geo-point.component';
import { HourMinuteComponent } from './hour-minute/hour-minute.component';
import { InputIntComponent } from './input-int/input-int.component';
import { InputMileageComponent } from './input-mileage/input-mileage.component';
import { MiCheckboxComponent } from './mi-checkbox/mi-checkbox.component';
import { MiChipsComponent } from './mi-chips/mi-chips.component';
import { MiIncrementIntComponent } from './mi-increment-int/mi-increment-int.component';
import { MiInputWidthHeightLengthComponent } from './mi-input-width-height-length/mi-input-width-height-length.component';
import { MiInputComponent } from './mi-input/mi-input.component';
import { MiQuickFilterMultiSelectComponent } from './mi-quick-filter-multi-select/mi-quick-filter-multi-select.component';
import { MiRadioComponent } from './mi-radio/mi-radio.component';
import { MiSelectWithOtherComponent } from './mi-select-with-other/mi-select-with-other.component';
import { MiSelectComponent } from './mi-select/mi-select.component';
import { MiTextareaComponent } from './mi-textarea/mi-textarea.component';
import { MiUploadFileComponent } from './mi-upload-file/mi-upload-file.component';
import { MobileInputComponent } from './mobile-input/mobile-input.component';
import { SelectButtonWithAddNewComponent } from './select-button-with-add-new/select-button-with-add-new.component';
import { SelectButtonComponent } from './select-button/select-button.component';
import { SelectDependOnWithOtherComponent } from './select-depend-on-with-other/select-depend-on-with-other.component';
import { SpacerComponent } from './spacer/spacer.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { GeoTransportComponent } from './geo-transport/geo-transport.component';
import { SelectWithAddNewComponent } from './select-with-add-new/select-with-add-new.component';
import { DateTimeSelectorCustomComponent } from './date-time-selector-custom/date-time-selector-custom.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { MiSelectOptionsWithIconComponent } from './mi-select-options-with-icon/mi-select-options-with-icon.component';
import { MiReadOnlyTextComponent } from './mi-read-only-text/mi-read-only-text.component';
import { ButtonComponent } from './button/button.component';
import { InputIdComponent } from './input-id/input-id.component';
import { MiInputUniqueIdComponent } from './mi-input-unique-id/mi-input-unique-id.component';
import { GeoSearchComponent } from './geo-search/geo-search.component';
import { MiEntityAutoCompleteComponent } from './mi-filter-fields/mi-entity-auto-complete/mi-entity-auto-complete.component';
import { EntitySelectComponent } from './entity-select/entity-select.component';
import { RestrictedDateOfBirthComponent } from './restricted-date-of-birth/restricted-date-of-birth.component';
import { MiInputSyncSelectComponent } from './mi-input-sync-select/mi-input-sync-select.component';
import { InputSelectButtonComponent } from './input-select-button/input-select-button.component';
import { SelectJourneyIdComponent } from './select-journey-id/select-journey-id.component';
import { SingleItemSelectComponent } from './single-item-select/single-item-select.component';
import { MiSeedNumberComponent } from './mi-seed-number/mi-seed-number.component';
import { MetaDataService } from '../../services/meta-data.service';

@Component({
  selector: 'app-mi-fields',
  templateUrl: './mi-fields.component.html',
  styleUrls: ['./mi-fields.component.scss'],
  standalone: true
})
export class MiFieldsComponent implements AfterViewInit {

  supportedMiComponents = [
    {
      name: 'text',
      component: MiInputComponent
    },
    {
      name: 'inputString',
      component: MiInputComponent
    },
    {
      name: 'inputInt',
      component: InputIntComponent
    },
    {
      name: 'inputKilometer',
      component: InputIntComponent
    },
    {
      name: 'number',
      component: InputIntComponent
    },
    {
      name: 'counter',
      component: CounterComponent
    },
    {
      name: 'incrementInt',
      component: MiIncrementIntComponent
    }, {
      name: 'inputAutocomplete',
      Component: MiEntityAutoCompleteComponent
    },
    {
      name: 'inputWeight',
      component: MiInputWidthHeightLengthComponent
    },
    {
      name: 'readOnlyText',
      component: MiReadOnlyTextComponent
    },
    {
      name: 'select',
      component: MiSelectComponent
    },
    {
      name: 'timeZoneSelector',
      component: MiSelectComponent
    },
    {
      name: 'SelectOptionsWithIcon',
      component: MiSelectOptionsWithIconComponent
    },
    {
      name: 'selectWithOther',
      component: MiSelectWithOtherComponent
    },
    {
      name: 'selectDependOnWithOther',
      component: SelectDependOnWithOtherComponent
    },
    {
      name: 'entitySelect',
      component: EntitySelectComponent
    },
    {
      name: 'radioButton',
      component: MiRadioComponent
    },
    {
      name: 'date',
      component: DateInputComponent
    },
    {
      name: 'dateOfBirth',
      component: DateInputComponent
    },
    {
      name: 'dateYear',
      component: DateYearComponent
    },
    {
      name: 'checkbox',
      component: MiCheckboxComponent
    },
    {
      name: 'checkBox',
      component: MiCheckboxComponent
    },
    {
      name: 'checkboxesWithOther',
      component: MiCheckboxComponent
    },
    {
      name: 'tags',
      component: MiChipsComponent
    },
    {
      name: 'inputColor',
      component: MiSelectComponent
    },
    {
      name: 'fileImg',
      component: MiUploadFileComponent
    },
    {
      name: 'fileImgPdf',
      component: MiUploadFileComponent
    },
    {
      name: 'geofence',
      component: MiSelectComponent
    },
    {
      name: 'documentTypeNoStartEndDateFiles',
      component: MiInputComponent
    },
    {
      name: 'inputPressure',
      component: InputMileageComponent
    },
    {
      name: 'inputDiameter',
      component: InputMileageComponent
    },
    {
      name: 'inputMilege',
      component: InputMileageComponent
    },
    {
      name: 'input3Dimentions',
      component: MiInputWidthHeightLengthComponent
    },
    {
      name: 'inputVolume',
      component: MiInputWidthHeightLengthComponent
    },
    {
      name: 'inputWidthHeightLength',
      component: MiInputWidthHeightLengthComponent
    },
    {
      name: 'inputVolumeLiquid',
      component: InputMileageComponent
    },
    {
      name: 'checkBoxWithAddNew',
      component: CheckboxWithAddNewComponent
    },
    {
      name: 'geoPoint',
      component: GeoPointComponent
    },
    {
      name: 'geoTransport',
      component: GeoTransportComponent
    },
    {
      name: 'geoSearch',
      component: GeoSearchComponent
    },
    {
      name: 'selectButtonWithAddNew',
      component: SelectButtonWithAddNewComponent
    },
    {
      name: 'toggleButton',
      component: ToggleButtonComponent
    },
    {
      name: 'spacer',
      component: SpacerComponent
    },
    {
      name: 'daysOfWeek',
      component: DaysOfWeekComponent
    },
    {
      name: 'textArea',
      component: MiTextareaComponent
    },
    {
      name: 'inputMobile',
      component: MobileInputComponent
    },
    {
      name: 'hourMinute',
      component: HourMinuteComponent
    },
    {
      name: 'hourMinuteSec',
      component: HourMinuteComponent
    },
    {
      name: 'dateTime',
      component: DateTimeComponent
    },
    {
      name: 'selectButton',
      component: SelectButtonComponent
    },
    {
      name: 'selectWithAddNew',
      component: SelectWithAddNewComponent
    },
    {
      name: 'dateTimeSelectorCustom',
      component: DateTimeSelectorCustomComponent
    },
    {
      name: 'button',
      component: ButtonComponent
    },
    {
      name: 'inputId',
      component: InputIdComponent
    },
    {
      name: 'inputUniqueId',
      component: MiInputUniqueIdComponent
    },
    {
      name: 'restrictedDateOfBirth',
      component: RestrictedDateOfBirthComponent
    },
    {
      name: 'inputSyncSelect',
      component: MiInputSyncSelectComponent
    },
    {
      name: 'inputSelectButton',
      component: InputSelectButtonComponent
    },
    {
      name: 'inputJourneyId',
      component: SelectJourneyIdComponent
    },
    {
      name: 'multiItemSelect',
      component: SingleItemSelectComponent
    },
    {
      name: 'inputFrontendRequired',
      component: MiSeedNumberComponent
    },
  ];

  supportedQuickFilterMiComponents = [
    {
      name: 'text',
      component: MiQuickFilterMultiSelectComponent
    },
    {
      name: 'date',
      component: DateRangeComponent
    },
    {
      name: 'dateTime',
      component: DateTimeComponent
    },
    {
      name: 'dateRange',
      component: DateRangeComponent
    },
  ];

  @ViewChild('miInputContainer', { read: ViewContainerRef }) miInputContainer!: ViewContainerRef;
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() filter: string;
  @Input() label: string;
  @Input() showImg: boolean = false;
  @Input() isMultipleFilesUpload: boolean = true;
  @Input() multiSelectDisplay: any;
  @Input() passengerId: any;
  @Input() isBookingForm: boolean = false;
  @Input() pKeyFilter: string = null;
  @Input() dateFormat: any = this.metaDataService.lowerDateFormat;
  formName: FormGroup;
  docType: any;

  constructor(private cd: ChangeDetectorRef, private metaDataService: MetaDataService,
    public cs: CommonBindingDataService) {
  }

  ngAfterViewInit(): void {
    this.registerMiField();
  }

  private registerMiField() {
    this.miInputContainer.clear();
    const componentInstance = this.getComponentByType(this.field.inputCode);
    const miComponent = this.miInputContainer.createComponent(componentInstance)
    if (this.filter === 'quickFilter' && this.field.inputCode === AppSettings.DATE_INPUT_CODE) {
      miComponent.setInput('isFilter', true);
    }
    miComponent.setInput('field', this.field);
    miComponent.setInput('attributeLabels', this.attributeLabels);
    miComponent.setInput('moduleName', this.moduleName);
    // if (this.moduleName === 'booking' && this.isBookingForm === true) {
    //   miComponent.setInput('passengerId', this.passengerId);
    // }
    if (this.field.inputCode === 'fileImg') {
      miComponent.setInput('showImg', this.showImg);
      miComponent.setInput('isMultipleFilesUpload', this.isMultipleFilesUpload);
    }

    if (this.field.inputCode === 'select') {
      miComponent.setInput('multiSelectDisplay', this.multiSelectDisplay);
    }

    if (this.field.inputCode === 'date') {
      miComponent.setInput('dateFormat', this.dateFormat);
    }

    if (this.field.inputCode === 'inputString') {
      miComponent.setInput('pKeyFilter', this.pKeyFilter);
    }

    if (this.moduleName === AppSettings.ENTITY_CODE.VEHICLE &&  this.field.attributeCode === 'variant') {
      miComponent.setInput('pKeyFilter', AppSettings.ONLY_ALPHA_P_KEY_FILTER);
    }

    if (this.field.inputCode === 'inputString' && this.field.attributeCode === 'pincode') {
      miComponent.setInput('pKeyFilter', 'num');
    }
    if (this.label) {
      miComponent.setInput('label', this.label);
    }
    if (this.field.inputCode === 'fileImgPdf' || this.field.inputCode === 'fileImg') {
      this.docType = this.field.inputCode === 'fileImgPdf' ? AppSettings.DOCUMENTS_TYPE.DOCUMENT : AppSettings.DOCUMENTS_TYPE.PROFILE;
      miComponent.setInput('docType', this.docType);
    }
    // this.cd.detectChanges();
  }

  getComponentByType(type: string): any {
    if (this.filter === 'quickFilter') {
      const componentMi = this.supportedQuickFilterMiComponents.find(c => c.name === type);
      return componentMi?.component || MiQuickFilterMultiSelectComponent;
    } else {
      const componentMi = this.supportedMiComponents.find(c => c.name === type);
      return componentMi?.component || MiInputComponent;
    }
  }

}
