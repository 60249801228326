import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MITableComponent } from 'app/modules/shared/components/mi-table/mi-table.component';
import { ModuleHeaderComponent } from 'app/modules/shared/components/module-header/module-header.component';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';
import { PermissionAccessService } from 'app/modules/shared/services/permission-access.service';
import { addTimeToCurrentDate } from 'app/modules/shared/utility-functions/date';
import { ShiftService } from 'app/modules/shift-management/services/shift.service';
import { Column } from 'app/modules/vehicles/models/attribute.models';
import { Values } from 'app/modules/vehicles/models/listResponse.models';
import dayjs from 'dayjs';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';

@Component({
  selector: 'app-manage-shift-list',
  standalone: true,
  imports: [BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, MITableComponent, MITableComponent, AsyncPipe, ButtonModule,
    DialogModule, CheckboxModule, ButtonModule, RadioButtonModule, FormsModule, AccessProviderDirective,
  ],
  templateUrl: './manage-shift-list.component.html',
  styleUrl: './manage-shift-list.component.scss'
})
export class ManageShiftListComponent {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLElement>;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];

  miIcons = AppIcons;
  value: number = 50;
  btnLabel: string;
  items: MenuItem[] | undefined;
  isShowMenu: boolean = true;
  attributeLabels = {};
  attributeLabelsData: any = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  totalRecords: number = 0;
  tableData: any = [];
  buttons: any[] = [];
  country: Country;
  language: Language;
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    startDateStr: null,
    endDateStr: null
  };
  routePath: MenuItem[] = [];
  home: MenuItem = {};
  isModal: boolean = false;
  selectedColumns!: Column[];
  attributeData: any;
  data;
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  columnsData;
  bulkDeleteLabel: string = this.cs.getLabel('shiftTemplate.delete');
  bulkUpdateLabel: string = this.cs.getLabel('shiftTemplate.lbl_update_shift_template');
  bulkUpdateHeader: any;
  reviewHeader: any;
  showBulkUpdateDialog: boolean = false;
  visible: boolean = false;
  filterFieldsArray: any[];
  uniqueId: string = 'shift_list';
  isEntity: boolean = false;
  entityName = AppSettings.ENTITY_TYPE.MANAGE_SHIFT;
  showImportExportBtn: boolean = false;
  btnUpdate: any;
  deleteShiftTemplateMsg: any;
  deleteShiftTemplateHeaderMsg: any;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  currentRow: any;
  entityNameForBulkUpdate = AppSettings.ENTITY_TYPE.SHIFT_TEMPLATE;
  count: any;
  filterList: any[] = [];
  lastSync: Date = new Date();
  shiftList: any[] = [];
  showShiftDialog: boolean = false;
  shiftActionHeader: any;
  selectedShift: any;
  actionBtnLabel: any;
  isDelete: boolean = false;
  selectedShifts: any[] = [];
  entityNameToDisplay: any;
  moduleName: any = 'shift';
  dateFormat: any = this.metaDataService.upperDateFormat;
  hourFormat = this.metaDataService.timeFormat;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cs: CommonBindingDataService,
    private configService: ConfigService,
    private cd: ChangeDetectorRef,
    private shiftService: ShiftService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private entityService: EntityService,
    private metaDataService: MetaDataService,
    private permissionAccessService: PermissionAccessService,
  ) { }

  ngOnInit() {
    this.setHeadersAndLabels();
    this.setModuleHeaderSecondaryBtnMenuItems();
    this.setRowSelectionOptions();
    this.setBulkSelectionOptions();
    this.initializeColumn();
    this.getFilterView();
    this.setFilterAttributeLabels();
  }

  setFilterAttributeLabels() {
    this.filterAttributeLabels = {
      labelKey: "shift.fields.date.placeholder",
      labelValue: "Select Date"
    }
  }

  setUpRoutePath() {
    this.routePath = [
      { label: this.cs.getLabel('shift.lbl_shift_list'), routerLink: 'shift-management/manage-shift' },
    ];
  }

  getFilterView() {
    this.filterList = [
      {
        attributeCode: "date",
        inputCode: "dateRange",
        attributeIndex: 2,
        presetValues: [],
        validation: {
          min: null,
          max: null
        }
      },
      {
        attributeCode: 'DriverType',
        inputCode: "select",
        attributeIndex: 5,
        label: 'Driver Type',
        presetValues: [
          {
            labelKey: 'Group',
            labelValue: 'groups',
          },
          {
            labelKey: 'Individual',
            labelValue: 'drivers',
          }
        ],
      }
    ];
  }

  initializeColumn() {
    this.allColumns = [
      {
        attributeCode: "shiftName",
        attributeValue: 'Name',
        attributeIndex: 0,
        field: "shiftName",
        header: this.cs.getLabel('shift.columns.name')
      },
      {
        attributeCode: "startDateStr",
        attributeValue: 'Start Date',
        attributeIndex: 1,
        field: "startDateStr",
        header: this.cs.getLabel('shift.columns.start_date')
      },
      {
        attributeCode: "endDateStr",
        attributeValue: 'End Date',
        attributeIndex: 2,
        field: "endDateStr",
        header: this.cs.getLabel('shift.columns.end_date')
      },
      {
        attributeCode: "startTimeStr",
        attributeValue: 'Start Time',
        attributeIndex: 3,
        field: "startTimeStr",
        header: this.cs.getLabel('shift.columns.start_time')
      },
      {
        attributeCode: "breakDuration",
        attributeValue: 'Break Duration',
        attributeIndex: 4,
        field: "breakDuration",
        header: this.cs.getLabel('shift.columns.break_duration')
      },
      {
        attributeCode: "assignCount",
        attributeValue: 'Assign To',
        attributeIndex: 5,
        field: "assignCount",
        header: this.cs.getLabel('shift.columns.assign_to')
      },
      {
        attributeCode: "assignedEntity",
        attributeValue: 'Driver Type',
        attributeIndex: 6,
        field: "assignedEntity",
        header: this.cs.getLabel('shift.columns.driver_type')
      }
    ];
  }

  setHeadersAndLabels() {
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.bulkUpdateHeader = this.cs.getLabel('shiftTemplate.update_shift_templates');
    this.btnUpdate = this.cs.getLabel('shiftTemplate.lbl_update_shift_template');
    this.deleteShiftTemplateMsg = this.cs.getLabel('shiftTemplate.message.confirm_delete_msg');
    this.deleteShiftTemplateHeaderMsg = this.cs.getLabel('shiftTemplate.message.confirm_delete_header');
    this.btnLabel = this.cs.getLabel('shift.lbl_add_shift');
    this.entityNameToDisplay = this.cs.getLabel('shift.lbl_shift');
    this.getBulkUpdateAttributeData();
    this.getData();
  }

  getBulkUpdateAttributeData() {
    this.filterFieldsArray = [
      {
        attributeCode: "startTime",
        attributeValue: 'Start Time',
        attributeIndex: 1,
        field: "startTime",
        header: this.cs.getLabel('shiftTemplate.columns.startTime')
      },
      {
        attributeCode: "endTime",
        attributeValue: 'End Time',
        attributeIndex: 2,
        field: "endTime",
        header: this.cs.getLabel('shiftTemplate.columns.endTime')
      },
    ];
  }

  setModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.cs.getLabel('shift.lbl_edit_shift'),
        icon: AppIcons.EDIT_2 + " wh-16",
        command: () => {

          this.showShiftDialog = true;
          this.isDelete = false;
          this.shiftActionHeader = this.cs.getLabel('shift.lbl_edit_shift');
          this.actionBtnLabel = this.cs.getLabel('shift.lbl_edit');
        }
      },
      {
        label: this.cs.getLabel('shift.lbl_delete_shift'),
        icon: AppIcons.BASIC_DELETE + " wh-16",
        command: () => {
          this.showShiftDialog = true;
          this.isDelete = true;
          this.shiftActionHeader = this.cs.getLabel('shift.lbl_delete_shift');
          this.actionBtnLabel = this.cs.getLabel('shift.delete');
        }
      },
      {
        label: this.cs.getLabel('shift.lbl_bulk_shift_import'),
        icon: AppIcons.UPLOAD + " wh-16",
        command: () => {
          const el: HTMLElement = this.uploader.nativeElement;
          el.click();
        }
      },
      {
        label: this.cs.getLabel('shift.lbl_download_shift_template'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.shiftService.downloadImportTemplate().subscribe(
            (result: any) => {
              const a = document.createElement('a');
              a.href = URL.createObjectURL(result);
              a.download = 'Import Template.xlsx';
              a.click();
            });
        }
      },
      {
        label: this.cs.getLabel('shift.lbl_download_shift_guide'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.shiftService.downloadImportGuide().subscribe(result => {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(result);
            a.download = 'Import Guide.xlsx';
            a.click();
          });
        }
      },
      {
        separator: true
      },
      {
        label: this.cs.getLabel('shift.export_xlsx_csv'),
        icon: AppIcons.EXPORT + " wh-16",
      },
    ];
  }

  setBulkSelectionOptions() {
    this.bulkSelectionsItems = [
      {
        label: this.bulkDeleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: () => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.BULK);
        },
      }
    ];
  }

  setRowSelectionOptions() {
    const isEdit = this.permissionAccessService.hasAccess('SHIFT_MANAGEMENT', 'MANAGE_SHIFT', 'ALL', 'EDIT');
    const isDelete = this.permissionAccessService.hasAccess('SHIFT_MANAGEMENT', 'MANAGE_SHIFT', 'ALL', 'DELETE');
    this.rowItems = [
      ...(isEdit ? [{
        label: this.cs.getLabel('shift.lbl_edit'),
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.router.navigate(['app/manage-shift/edit-shift/' + this.currentRow.id]);
        }
      }] : []),
      ...(isDelete ? [{
        label: this.cs.getLabel('shift.delete'),
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.ROW);
        }
      }] : []),
    ];
  }

  onRowSelect(event) {
    if (event.type === 'row') {
      this.router.navigate(['app/manage-shift/details/' + event.data.id]);
    }
  }

  setCurrentRowData(event) {
    this.currentRow = event;
    this.setEditOptionDisabledForDeletedRecord(event);
  }

  setEditOptionDisabledForDeletedRecord(data) {
    this.rowItems[0].disabled = data.deleted;
    this.cd.detectChanges();
  }

  getData() {
    this.searchEntity();
  }

  editShift() {
    this.router.navigate([`app/manage-shift/edit-shift/${this.selectedShift.shiftId}`]);
  }

  onIndividualCheckboxChange(event, shift) {
    if (event.checked) {
      this.selectedShifts.push(shift.id);
    } else {
      const index = this.selectedShifts.findIndex(ele => ele === shift.id);
      if (index >= 0) {
        this.selectedShifts.splice(index, 1);
      }
    }
  }

  confirmDelete() {
    this.confirmationService.confirm({
      header: this.cs.getLabel('shift.message.delete_shift'),
      message: this.cs.getLabel('shift.message.confirm_delete_msg'),
      acceptIcon: null,
      rejectIcon: null,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      acceptLabel: this.cs.getLabel('lbl_yes'),
      rejectLabel: this.cs.getLabel('lbl_no'),
      accept: () => {
        this.deleteShift();
      },
      reject: () => {
      }
    });
  }

  deleteShift() {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      shiftIds: this.selectedShifts
    };
    this.shiftService.deleteShift(requestBody).subscribe({
      next: (res: any) => {
        this.showShiftDialog = false;
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: res.message });
        setTimeout(() => {
          this.searchEntity();
        }, 300);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  onCancel() {
    this.showShiftDialog = false;
  }

  searchEntity() {
    this.shiftService.getShiftList(this.entityData).subscribe((res: any) => {
      this.count = res.count;
      this.setUpRoutePath();
      this.listColumns = res?.data[0];
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = [];
      for (const data of res.data) {
        data.breakDurationStr = this.getFormattedTimeDuration(data.breakDuration);
        data.startTimeStr = addTimeToCurrentDate(data.startTimeStr);
        if (this.hourFormat === AppSettings.HOUR_FORMAT) {
          data.startTimeStr = dayjs(data.startTimeStr).format(`hh:mm A`);
        } else {
          data.startTimeStr = dayjs(data.startTimeStr).format(`HH:mm`);
        }
        this.tableData.push({
          id: data.shiftId,
          ...data
        });
      }
    })
    this.isLoading = false;
  }

  getFormattedTimeDuration(breakDuration) {
    if (breakDuration >= 0) {
      const hours = breakDuration >= 60 ? Math.floor(breakDuration / 60) : 0;
      const minutes = breakDuration % 60;
      if (minutes > 0) {
        return hours ? `${hours} hr ${minutes} min` : `${minutes} min`;
      } else {
        return hours ? `${hours} hr` : `${minutes} min`;
      }

    } else {
      return null;
    }
  }

  onAddShift() {
    this.router.navigate(['app/manage-shift/add-shift']);
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  onShiftDelete(event) {
    this.selectedShifts = [event];
    this.deleteShift();
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  onFilterValueChange(event) {
    this.entityData.driverType = null;
    this.entityData.startDateStr = null;
    this.entityData.endDateStr = null;
    for (const [key, value] of Object.entries(event)) {
      const attributeValue: any = value;
      if (attributeValue && attributeValue.length > 0) {
        if (key === AppSettings.DATE_ATTRIBUTE_IDS.DATE) {
          this.entityData.startDateStr = dayjs(value[0]).format(this.dateFormat);
          this.entityData.endDateStr = dayjs(value[1]).format(this.dateFormat);
        } else {
          if (value) {
            this.entityData.driverType = value
          } else {
            this.entityData.driverType = null
          }
        }
      }
    }
    this.entityData.offset = event.first;
    this.searchEntity();
  }

  onBulkDataUpdateEvent() {
    this.searchEntity();
  }

  assignDriver(data) {
    this.router.navigate([`app/manage-shift/assign-driver/${data.id}`]);
  }

  onFileSelect(event) {
    for (const file of event.target.files) {
      if (file) {
        if (file.size > AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE) {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('err_file_size_exceed_msg') });
        } else {
          const formData = new FormData();
          formData.append("file", file);
          formData.append('forTenantCode', this.configService.getForTenantCode());
          formData.append('viewCode', AppSettings.VIEW_CODE.EXCEL_IMPORT_VIEW);
          this.shiftService.importShift(formData).subscribe(() => {
            this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_file_uploaded_successfully') });
          })
        }
      }
    }
  }
}
