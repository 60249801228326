import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ButtonModule } from 'primeng/button';
import { AppSettings } from '../../../../shared/app.settings';
import { MiErrorComponent } from '../../../../shared/components/mi-error/mi-error.component';
import { MiFieldsComponent } from '../../../../shared/components/mi-fields/mi-fields.component';
import { MiTooltipComponent } from '../../../../shared/components/mi-fields/mi-tooltip/mi-tooltip.component';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { JsonPipe, NgClass } from '@angular/common';
import { AppIcons } from '../../../../shared/app.icons';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { MiValidationsService } from 'app/modules/shared/services/mi-validations.service';
import { UIElementService } from 'app/modules/shared/services/ui-element.service';
import { BookingService } from 'app/modules/bookings/services/booking.service';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { GoogleMapsService } from '../../../../shared/services/google-map.services';
@Component({
  selector: 'app-stop-locations',
  standalone: true,
  imports: [DragDropModule, FormsModule, JsonPipe, ReactiveFormsModule, MiFieldsComponent, MiErrorComponent, ButtonModule, MiTooltipComponent, TranslateModule, NgClass, DialogModule, InputTextModule],
  templateUrl: './stop-locations.component.html',
  styleUrl: './stop-locations.component.scss'
})
export class StopLocationsComponent implements OnInit {
  @Output() stopRemoved = new EventEmitter<number>();
  @Input() field: any;
  @Input() isTwoColStructure: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() miFormGroup: FormGroup;
  @Input() attributeValues;
  @Input() editStopLocations;
  @Input() transportationTypeView: any;
  @Input() isRequestSentFlag;
  @Input() isVehicleBodyTypeAndLocationDisableOnwardOnTheWayStatuses;
  stopLocations: any = [];
  @Input() edit: boolean = false;
  passengerId: any;
  stops: any = [];
  groupCopy: any = [];
  bookingTypeRestrictionCount = 1000;
  miIcons = AppIcons;
  currentSelectedCode: string;
  showHamburger: boolean = false;
  showMap: boolean = false;
  showBookingGoogleSearchBox: boolean = false;
  map: google.maps.Map;
  infoWindow: google.maps.InfoWindow;
  selectedLocation;
  markerLocation;
  zoom = 4;
  position: any;
  cancelBtnText = this.cs.getLabel("cancel");
  saveBtnText = this.cs.getLabel("label_save");
  blockedDocument: boolean = false;
  newGeoLocation: any;
  newGeoAddress: any;
  searchText: string = null;
  selectedStop;
  selectedStopIndex;

  constructor(
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private validationsService: MiValidationsService,
    private uiService: UIElementService,
    private entityService: EntityService,
    private bookingService: BookingService,
    private googleMapsService: GoogleMapsService
  ) {
  }

  ngOnInit(): void {
    this.moduleName = AppSettings.ENTITY_CODE.BOOKING;
    this.groupCopy = _.cloneDeep(this.field);

    if (this.edit) {
      this.setFieldsForEdit();
    } else {
      this.setFields();
      this.addMoreStops();  
      this.addMoreStops();
    }
  
  }

  getTranslatedMessage() {
    return this.cs.getLabel('bookings.disable_stops_msg');
  }

  getLetter(index: number): string {
    return String.fromCharCode(65 + index);
  }

  addStopPoint(event: Event) {
    if (this.stops.length < 4) {
      this.addMoreStops();
      this.showHamburger = true;
    }
  }

  setFieldsForEdit() {
    for (let j = 0; j < this.editStopLocations?.length; j++) {
      this.addMoreStops(this.editStopLocations[j]);
      const stopData = this.editStopLocations[j];
      let address = {
        labelKey: '',
        labelValue: {
          select_location_address: '',
          select_location_geolocation: '',
          select_location_type: '',
          location_id: ''
        }
      };
      const addressValue = Array.isArray(stopData.address) && stopData.address.length > 0 ? stopData.address[0] : stopData.address;

      if (stopData.geoLocation) {
        address = {
          "labelKey": addressValue,
          "labelValue": {
            "select_location_address": addressValue,
            "select_location_geolocation": stopData?.geoLocation,
            "select_location_type": stopData?.locationCategory,
            "location_id": stopData?.locationId
          }
        };
      }
  
      this.stops[j].displayedAddresses = [];
      this.stops[j].displayedAddresses.push(address.labelValue);
  
      const stop = this.stops[j];
      if (stop && stop.attributeCodeOneToMultiple) {
        const attributeCode = stop.attributeCodeOneToMultiple;
        this.miFormGroup.controls[attributeCode]?.setValue(address.labelValue);
        stop.attributeValue = address.labelValue;
    
        // console.log(`stop: ${attributeCode}`, 'value:', stop.attributeValue);
      }
    }
  }  

  drop(event: CdkDragDrop<string[]>) {
    console.log( this.stops, event.previousIndex, event.currentIndex);
    moveItemInArray(this.stops, event.previousIndex, event.currentIndex);
  }

  setFields() {
    this.currentSelectedCode = this.field.inputCode;
    const validators = this.validationsService.addValidator(this.field.validation, this.currentSelectedCode);
    this.field.attributeCodeOneToMultiple = `${this.bookingTypeRestrictionCount}_${this.field.attributeCode}`;
    this.field.isOneToMultiple = true;
    this.field.fieldName = this.field.attributeCode;
    const value = this.attributeValues ? this.attributeValues[this.field?.attributeCodeOneToMultiple] : '';
    this.miFormGroup.registerControl(this.field.attributeCodeOneToMultiple, new FormControl('', validators));
  }

  swipeAddress(event: Event, index: number) {
    console.log(event);
    const newIndex = index === this.stops.length - 1 ? index - 1 : index + 1;
    if (newIndex >= 0 && newIndex < this.stops.length) {
        const temp = this.stops[index];
        this.stops[index] = this.stops[newIndex];
        this.stops[newIndex] = temp;

        this.updateFormControls(index);
        this.updateFormControls(newIndex);
        
        this.cd.detectChanges();
    }
  }

  updateFormControls(index: number) {
    const stop = this.stops[index];
    this.miFormGroup.removeControl(stop.attributeCodeOneToMultiple);
    const validators = this.validationsService.addValidator(stop.validation, this.currentSelectedCode);
    const value = this.attributeValues ? this.attributeValues[stop?.attributeCodeOneToMultiple] : '';
    this.miFormGroup.registerControl(stop.attributeCodeOneToMultiple, new FormControl(value, validators));
  }

  addMoreStops(stopLocations?) {
    // console.log(stopLocations);
    if (this.stops.length > 4) {
      event.stopPropagation();
      return;
    }
    this.bookingTypeRestrictionCount += 1;
    const newStop = _.cloneDeep(this.groupCopy);
    // if (stopLocations) {
    //   newStop.address = stopLocations.address;
    //   newStop.geoLocation = stopLocations.geoLocation;
    // }
    const validators = this.validationsService.addValidator(newStop.validation, this.currentSelectedCode);
    newStop.attributeCodeOneToMultiple = `${this.bookingTypeRestrictionCount}_${newStop.attributeCode}`;
    newStop.isOneToMultiple = true;
    newStop.fieldName = newStop.attributeCode;
    const value = this.attributeValues ? this.attributeValues[newStop?.attributeCodeOneToMultiple] : '';
    this.miFormGroup.registerControl(newStop?.attributeCodeOneToMultiple, new FormControl(value, validators));
    this.stops.push(newStop);
    this.cd.detectChanges();
  }

  removeStop(index, stop) {
    this.deleteStopFromDb(index, stop);
    this.stopRemoved.emit(index);
    if (this.stops.length > 2) {
      this.showHamburger = true;
    } else {
        this.showHamburger = false;
    }
  }

  deleteStopFromDb(index, stop) {
    this.stops.splice(index, 1);
    this.miFormGroup.removeControl(stop.attributeCodeOneToMultiple);
    this.cd.detectChanges();
  }

  showDialog(stop: any, i: number) {
    this.selectedStop = stop;
    this.selectedStopIndex = i;
    this.showMap = true;
    this.showBookingGoogleSearchBox = true;
    this.blockedDocument = true;
    this.initMap();
    // this.uiService.showGeoLocationDialog();
  }

  close() {
    // this.uiService.hideGeoLocationDialog();
    this.showMap = false;
    this.blockedDocument = false;
    this.showBookingGoogleSearchBox = false;
  }

  saveGeoLocationBooking() {
    const address = {
      "labelKey": this.newGeoAddress,
      "labelValue": {
          "select_location_address": this.newGeoAddress,
          "select_location_geolocation": this.newGeoLocation,
          "select_location_type":''
      }
    }

    this.stops[this.selectedStopIndex].displayedAddresses = [];
    this.stops[this.selectedStopIndex].displayedAddresses.push(address.labelValue);

    if (this.selectedStop) {
      const stop = this.stops[this.selectedStopIndex];
      if (stop && stop.attributeCodeOneToMultiple) {
        const attributeCode = stop.attributeCodeOneToMultiple;
        this.miFormGroup.controls[attributeCode]?.setValue(address.labelValue);
        stop.attributeValue = address.labelValue;
  
        // console.log(`stop: ${attributeCode}`, 'value:', stop.attributeValue);
      }
    }

    this.cd.markForCheck();
    this.showMap = false;
    this.blockedDocument = false;
    this.showBookingGoogleSearchBox = false;
    this.uiService.hideGeoLocationDialog();
  }

  async initMap(): Promise<void> {
    this.searchText = null;
    const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 18.509863497519373, lng: 74.31908731567906 },
      zoom: 8,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapId: this.googleMapsService.mapId
    });

    this.infoWindow = new google.maps.InfoWindow();
    const element = document.getElementById('bookingGoogleSearch');
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(element);
    element.focus();

    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: this.position,
      gmpDraggable: true
    });

    const options = {
      fields: ['formatted_address', 'geometry', 'name'],
      strictBounds: false,
    };

    const inputHtmlElement = document.getElementById('autocompleteSearch') as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(inputHtmlElement, options);
    autocomplete.bindTo('bounds', this.map);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
      } else {
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(17);
      }
      marker.position = place.geometry.location;
      const latLong = place.geometry.location.lat() + ', ' + place.geometry.location.lng();
      this.getReverseGeoCodeFromLatLng(latLong);
    });


    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.position = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          marker.position = this.position;
          const latLong = this.position.lat + ', ' + this.position.lng;
          this.getReverseGeoCodeFromLatLng(latLong);
          marker.addListener('dragend', (event) => {
            const currentPosition = marker.position as google.maps.LatLng;
            this.position = { lat: currentPosition.lat, lng: currentPosition.lng };
            const latLong = this.position.lat + ', ' + this.position.lng;
            this.getReverseGeoCodeFromLatLng(latLong);
          });
          this.map.panTo(this.position);
        },
        () => {
          this.handleLocationError(true, this.infoWindow, this.map.getCenter());
        });
    } else {
      this.handleLocationError(false, this.infoWindow, this.map.getCenter());
    }
  }

  getReverseGeoCodeFromLatLng(latLong) {
    this.entityService.getReverseGeoCodeUsingLatLng(latLong).subscribe((result: any) => {
      if (result && result.results.length > 0) {
        this.newGeoAddress = null;
        this.newGeoAddress = result.results[0].formattedAddress;
        const { lat, lng } = result.results[0].geometry.location
        this.newGeoLocation = `${lat},${lng}`
      }
    })
  }

  handleLocationError(browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation.",
    );
    infoWindow.open(this.map);
  }
}
