import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { RadioButtonModule } from 'primeng/radiobutton';
import { JsonPipe, NgClass } from '@angular/common';

@Component({
  selector: 'app-mi-radio',
  templateUrl: './mi-radio.component.html',
  styleUrls: ['./mi-radio.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RadioButtonModule, NgClass, JsonPipe]
})
export class MiRadioComponent implements OnInit {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;

  excludeCode = ["vehicle_dispatch_preference"]

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }
  ngOnInit() {
    this.formName.get(this.field.attributeCode)?.setValue(this.field.defaultValue);
  }


  isSelected(labelKey: string): boolean {
    return this.formName.get(this.field.attributeCode)?.value?.key === labelKey;
  }
}
