import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { Column } from '../../../shared/components/mi-table/models/table';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';

import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { forkJoin } from 'rxjs';
import { ModuleHeaderComponent } from '../../../shared/components/module-header/module-header.component';
import { EntityCount, EntityCountsResponse, EntityList } from '../../../vehicles/models/entity.models';
import { ListResponse, Values } from '../../../vehicles/models/listResponse.models';
import { EntityService } from '../../../shared/services/entity.service';
import { ConfigService } from '../../../shared/services/config.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { PermissionAccessService } from 'app/modules/shared/services/permission-access.service';
import { RestApiService } from 'app/modules/shared/services/rest-api.service';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';

@Component({
  selector: 'app-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: ['./passengers.component.scss'],
  standalone: true,
  imports: [BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, MITableComponent, MITableComponent, AsyncPipe, AccessProviderDirective]
})

export class PassengersComponent implements OnInit {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLElement>;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];
  filterList;
  btnLabel: string;
  items: MenuItem[] | undefined;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  buttons: any[] = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  itemsPerPage: number;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "",
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  home: MenuItem = {};
  isModal: boolean = false;
  selectedColumns!: Column[];
  deletePassengerMsg: string;
  deleteVehicleHeaderMsg: string;
  showMenu: boolean = false;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  isBulkPrint = true;
  bulkUpdateLabel: string = this.cs.getLabel('passengers.update_passenger');
  bulkDeleteLabel: string = this.cs.getLabel('passengers.delete');
  filterFieldsArray: any[];
  isReady: boolean = false;
  attributeData: any;
  data;
  bulkSelectionsItems: MenuItem[] | undefined;
  showBulkUpdateDialog: boolean = false;
  rowItems: MenuItem[] | undefined;
  visible: boolean = false;
  bulkUpdateHeader: any;
  reviewHeader: any;
  btnUpdate: any;
  attributeIdOfPassengerStatus: any;
  totalRecords: number = 0;
  currentRow: any;
  lastSync: Date = new Date();
  emptyResultMsg: any;
  entityNameToDisplay: any;
  showTable: boolean = true;
  showLastSync: boolean = true;
  private phoneMaskPattern = AppSettings.PHONE_MASK_PATTERN;


  constructor(private passengerService: EntityService,
    private cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private router: Router, private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private restApiService: RestApiService,
    private metaDataService: MetaDataService,
    private configService: ConfigService, private permissionAccessService: PermissionAccessService,) { }

  ngOnInit() {
    this.setHeadersAndLabels();
    this.setBulkSelectionOptions();
    this.setRowSelectionOptions();
    this.setModuleHeaderSecondaryBtnMenuItems();
    this.setRoutePath();
    this.setCountryAndLanguage();
    this.getMetaData();
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  setRoutePath() {
    this.routePath = [
      { label: "Passengers", routerLink: 'passengers/list' },
    ];
  }

  setHeadersAndLabels() {
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.bulkUpdateHeader = this.cs.getLabel('passengers.update_passengers');
    this.entityNameToDisplay = this.cs.getLabel('passengers.lbl_passenger');
    this.reviewHeader = this.cs.getLabel('passengers.lbl_review_passengers');
    this.btnUpdate = this.cs.getLabel('btn_passenger');
    this.btnLabel = this.cs.getLabel('passengers.add_passengers_lbl');
    this.deletePassengerMsg = this.cs.getLabel('passengers.message.confirm_delete_msg');
    this.deleteVehicleHeaderMsg = this.cs.getLabel('passengers.message.confirm_delete_header');
    this.emptyResultMsg = this.cs.getLabel('passengers.message.empty_result_msg');
  }

  setModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.cs.getLabel('passengers.import_passenger'),
        icon: AppIcons.UPLOAD + " wh-16",
        command: () => {
          const el: HTMLElement = this.uploader.nativeElement;
          el.click();
        }
      },
      {
        label: this.cs.getLabel('passengers.download_important_template'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.passengerService.downloadImportTemplate(AppSettings.ENTITY_CODE.PASSENGER).subscribe(
            (result: any) => {
              const a = document.createElement('a');
              a.href = URL.createObjectURL(result);
              a.download = 'Import Template.xlsx';
              a.click();
            });
        }
      },
      {
        label: this.cs.getLabel('passengers.download_important_guide'),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.passengerService.downloadImportGuide(AppSettings.ENTITY_CODE.PASSENGER).subscribe(result => {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(result);
            a.download = 'Import Guide.xlsx';
            a.click();
          });
        }
      },
      {
        separator: true
      },
      {
        label: this.cs.getLabel('vehicle.export_xlsx_csv'),
        icon: AppIcons.EXPORT + " wh-16",
      },
    ];
  }

  setBulkSelectionOptions() {
    this.bulkSelectionsItems = [
      {
        label: this.bulkUpdateLabel,
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: () => {
          this.tableRef.showBulkRowDialog();
        },
      },
      {
        label: this.bulkDeleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: (e) => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.BULK);
        },
      },
    ];
  }

  setRowSelectionOptions() {
    const isEdit = this.permissionAccessService.hasAccess('PASSENGERS', '', 'ALL', 'EDIT');
    const isDelete = this.permissionAccessService.hasAccess('PASSENGERS', '', 'ALL', 'DELETE');
    this.rowItems = [
      ...(isEdit ? [{
        label: this.cs.getLabel('passengers.lbl_edit'),
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: 'bulk-update-icon',
        command: (event) => {
          this.router.navigate(['app/passengers/edit/', this.currentRow.id, 0]);
        }
      }] : []),
      {
        label: this.cs.getLabel('passengers.lbl_make_passenger_inactive'),
        icon: AppIcons.OVERDUE + " wh-16 bg-red-500",
        iconClass: 'bulk-update-icon',
        command: (e) => {
          this.makePassengerActiveInactive();
        }
      },
      {
        separator: true
      },
      ...(isDelete ? [{
        label: this.cs.getLabel('passengers.lbl_delete_passenger'),
        icon: AppIcons.BASIC_TRAY + " wh-16",
        iconClass: 'bulk-update-icon',
        command: (e) => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.ROW);
        }
      }] : []),
    ];
  }

  makePassengerActiveInactive() {
    this.tableRef.changeStatusOfEntity();
  }

  setCurrentRowData(event) {
    this.currentRow = event;
    if (event.passenger_status === 'INACTIVE' || event.passenger_status === 'Inactive') {
      this.rowItems[1].label = this.cs.getLabel('passengers.lbl_make_passenger_active'),
        this.rowItems[1].icon = AppIcons.OVERDUE + " wh-16 bg-green-500",
        this.cd.detectChanges();
    } else {
      this.rowItems[1].label = this.cs.getLabel('passengers.lbl_make_passenger_inactive'),
        this.rowItems[1].icon = AppIcons.OVERDUE + " wh-16 bg-red-500",
        this.cd.detectChanges();
    }
    this.setEditOptionDisabledForDeletedRecord(event);
  }

  setEditOptionDisabledForDeletedRecord(data) {
    this.rowItems[0].disabled = data.deleted;
    this.cd.detectChanges();
  }

  onChangeStatus(selectedRow) {
    this.confirmationService.confirm({
      header: this.cs.getLabel('passengers.message.change_passenger_status'),
      message: selectedRow.passenger_status === AppSettings.PASSENGER_STATUS.ACTIVE ? this.cs.getLabel('passengers.message.confirm_passenger_inactive') : this.cs.getLabel('passengers.message.confirm_passenger_active'),
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: this.cs.getLabel('events.lbl_save'),
      rejectLabel: this.cs.getLabel('events.lbl_cancel'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        this.changePassengerStatus(selectedRow);
      },
      reject: () => {
      }
    });


  }

  changePassengerStatus(selectedRow) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.PASSENGER,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [{
        attributeId: this.attributeIdOfPassengerStatus,
        attributeValue: selectedRow.passenger_status === AppSettings.PASSENGER_STATUS.ACTIVE ? AppSettings.PASSENGER_STATUS.INACTIVE : AppSettings.PASSENGER_STATUS.ACTIVE
      }]
    };
    this.passengerService.saveAttributeData(requestBody.entityCode, selectedRow.id, requestBody).subscribe(result => {
      this.cs.onDisplayMessage(this.data, 'passenger', AppSettings.MSG_ACTION.UPDATE, 'success');
      setTimeout(() => {
        this.searchEntity();
      }, 500);
    });
  }

  getAttributeData() {
    this.passengerService.getAttributeDefinition(AppSettings.ENTITY_CODE.PASSENGER, AppSettings.VIEW_CODE.BULK_UPDATE_VIEW).subscribe(res => {
      if (res) {
        this.setPassengerBulkView(res);
      }
    });
  }

  setPassengerBulkView(res) {
    this.attributeData = res;
    this.isLoading = false;
    this.filterFieldsArray = res.tabs[0].groups[0].fields;
    this.filterFieldsArray.forEach(element => {
      switch (element.attributeCode) {
        case 'passenger_type':
          element.attributeValue = 'Type';
          element.isDisable = false;
          break;
        case 'passenger_status':
          element.attributeValue = 'Status';
          element.isDisable = false;
          break;
      }
    });

  }

  setPassengerTableView(res) {
    this.isLoading = false;
    this.data = res;
    this.attributeIdOfPassengerStatus = res.tabs[0].groups[0].fields.find(element => element.attributeCode === 'passenger_status').attributeId;
    this.attributeLabels = this.cs.getAttributeLabels(this.data);

    this.allColumns = this.cs.getColumns(this.data);
    this.searchEntity();
    this.allColumns?.forEach((key, index) => {
      this.allColumns[index] = key;
      this.allColumns[index].field = key.attributeCode;
      this.allColumns[index].header = this.cs.getLabelValue(
        "passenger" + ".fields." + key.attributeCode + ".label",
        this.attributeLabels,
        key.attributeCode
      );
    });
    this.filterAvailableColumns = _.clone(this.allColumns);
  }

  getMetaData() {
    this.getEntityCount()
    const payload = this.getMultiApiPayload();
    this.metaDataService.metaDataMultipleApi(payload).subscribe((res) => {
      const advacneFilter = JSON.parse(res.advacneFilter.responseBody);
      if (advacneFilter) {
        this.setPassengerFilterView(advacneFilter);
      }

      const bulkUpdate = JSON.parse(res.bulkUpdate.responseBody);
      if (bulkUpdate) {
        this.setPassengerBulkView(bulkUpdate);
      }

      const defaultTableView = JSON.parse(res.defaultTableView.responseBody);
      if (defaultTableView) {
        this.setPassengerTableView(defaultTableView);
      }

    });
  }

  private getMultiApiPayload() {
    return [
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.PASSENGER}/attributes?forTenantCode=${this.configService.getForTenantCode()}&viewCode=${AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW}`,
        inputBody: '',
        method: 'GET',
        outputKey: AppSettings.META_OUTPUT_KEY.ADVANCED_FILTER,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.PASSENGER}/attributes?forTenantCode=${this.configService.getForTenantCode()}&viewCode=${AppSettings.VIEW_CODE.BULK_UPDATE_VIEW}`,
        inputBody: '',
        method: 'GET',
        outputKey: AppSettings.META_OUTPUT_KEY.BULK_UPDATE,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.PASSENGER}/attributes?forTenantCode=${this.configService.getForTenantCode()}&viewCode=${AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW}`,
        inputBody: '',
        method: 'GET',
        outputKey: AppSettings.META_OUTPUT_KEY.DEFAULT_TABLE_VIEW,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
    ]
  }

  setPassengerFilterView(filterResponse) {
    this.filterAttributeLabels = this.cs.getAttributeLabels(filterResponse);
    this.filterList = this.cs.getColumns(filterResponse);
    this.filterList = _.sortBy(this.filterList, 'attributeIndex');
  }

  searchEntity() {
    this.entityData.countryCode = this.country[0].countryCode;
    this.passengerService.searchEntity(AppSettings.ENTITY_CODE.PASSENGER, this.entityData).subscribe((res: ListResponse) => {
      this.lastSync = new Date();
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = [];
      this.tableData = this.cs.getTableData(res);
      this.tableData.forEach(rowData => {
        rowData.mobile_number = this.maskPhoneNumber(rowData.mobile_number);

        if (rowData?.relatedData?.length > 0) {
          rowData.selectLocation = rowData.relatedData.map(ele => {
            return `${ele?.values?.select_location_type}`
          }).join(', ');

          rowData.stayHomeAddress = rowData?.relatedData?.map(ele => {
            return ele?.values?.select_location_address
          });
        }

        if (rowData?.passenger_profile_image_url && (rowData?.passenger_profile_image_url[0])) {
          rowData.profileImage = rowData?.passenger_profile_image_url[0];
        }
      });

    })
  }

  maskPhoneNumber(phoneNumber) {
    if (phoneNumber) {
      const phoneCountryCodeArray = phoneNumber.split('-');
      const maskNumber = phoneCountryCodeArray[1]?.match(this.phoneMaskPattern);
      if (maskNumber) {
        phoneNumber = `${phoneCountryCodeArray[0]} - ${maskNumber[1]} ${maskNumber[2]} ${maskNumber[3]}`;
      }
      return phoneNumber;
    }
  }

  onAddPassenger() {
    this.router.navigate(['app/passengers/add',]);
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.itemsPerPage = event;
    this.entityData.limit = this.itemsPerPage;
    this.entityData.offset = 0;
    this.searchEntity();
  }

  onPassengerDelete(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      entityIds: [event],
      entityCode: AppSettings.ENTITY_CODE.PASSENGER
    };
    this.passengerService.deleteEntityActionStatus(requestBody).subscribe({
      next: (res: any) => {
        this.cs.onDisplayMessage(this.data, 'passenger', AppSettings.MSG_ACTION.DELETE, 'success');
        setTimeout(() => {
          this.searchEntity();
          this.getEntityCount();
        }, 300);
      },
      error: (error) => {
        this.cs.onDisplayMessage(this.data, 'passenger', AppSettings.MSG_ACTION.DELETE, 'error');
      }
    });
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      entityIds: event,
      entityCode: AppSettings.ENTITY_CODE.PASSENGER
    };
    this.passengerService.deleteEntityActionStatus(requestBody).subscribe({
      next: (res: any) => {
        this.cs.onDisplayMessage(this.data, 'passenger', AppSettings.MSG_ACTION.BULK_DELETE, 'success');
        this.searchEntity();
        this.getEntityCount();
      },
      error: (error) => {
        this.cs.onDisplayMessage(this.data, 'passenger', AppSettings.MSG_ACTION.BULK_DELETE, 'error');

      }
    });
  }

  onRowSelect(event) {
    if (event.type === 'row') {
      this.router.navigate(['app/passengers/' + event.data.id,]);
    }
  }

  onTransportationDateChange(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.PASSENGER,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [{
        attributeId: this.cs.getAttributeIdForFilterFields(this.filterList, AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_END_DATE),
        attributeValue: event.newDate.getTime()
      }]
    };
    this.passengerService.saveAttributeData(requestBody.entityCode, event.row.id, requestBody).subscribe(result => {
      setTimeout(() => {
        this.searchEntity();
      }, 300);
    });
  }


  onFilterValueChange(event) {
    this.tableRef.clearSelected();
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(event)) {
      const attributeValue = value;
      if (attributeValue && key === AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_END_DATE) {
        const date = new Date(value.toString());
        const timestamp = date.getTime();
        if (attributeValue) {
          this.entityData.filters.push({
            attributeId: this.cs.getAttributeIdForFilterFields(this.filterList, key),
            attributeValue: timestamp
          });
        }
      } else {
        if (attributeValue) {
          this.entityData.filters.push({
            attributeId: this.cs.getAttributeIdForFilterFields(this.filterList, key),
            attributeValue
          });
        }
      }
    }
    this.entityData.offset = event.first;
    this.searchEntity();
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
    this.cd.detectChanges();
  }

  getEntityCount() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityCountPayload = <EntityCount>{
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.PASSENGER,
      countryCode: country[0].countryCode,
    };
    this.passengerService.getEntityCount(AppSettings.ENTITY_CODE.PASSENGER, entityCountPayload).subscribe((res: EntityCountsResponse) => {
      if (res?.counts) {
        res.counts.ALL = res.counts.ALL - res.counts.DELETED;
      }
      this.entityCount = res;
    })
  }

  tabViewChange(event) {

    switch (event.index) {
      case 0:
        this.entityData.actionStatus = '';
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.searchEntity();
        break;
      case 1:
        this.entityData.actionStatus = AppSettings.VEHICLE_TAB_LIST.DELETE;
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_DELETED;
        this.searchEntity();
        break;
      default:
        break;
    }

    this.showTable = false;
    this.cd.detectChanges();
    this.showTable = true;
  }

  onBulkDataUpdateEvent(event) {
    this.searchEntity();
  }

  public onChange(event) {
    for (const file of event.target.files) {
      if (file) {
        if (file.size > AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('err_file_size_exceed_msg') });
        } else {
          const formData = new FormData();
          formData.append("file", file);
          formData.append('forTenantCode', this.configService.getForTenantCode());
          formData.append('entityCode', AppSettings.ENTITY_CODE.PASSENGER);
          formData.append('viewCode', AppSettings.VIEW_CODE.EXCEL_IMPORT_VIEW);
          this.cs.importEntity(formData, AppSettings.ENTITY_CODE.PASSENGER).subscribe(res => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_file_uploaded_successfully') });
          })
        }
      }
    }
  }

}
