@if (allColumns) {
    <app-mi-table
        [collectionOfColumns]="allColumns"
        [attributeLabels]="attributeLabels"
        [data]="tableData"
        [isLoading]="isLoading"
        entityName="booking"
        [totalRecords]="totalRecords"
        (onSearchValueChange)="onSearchValueChanges($event)"
        (onPageChange)="onPageChange($event)"
        (itemPerPageChange)="itemPerPageChange($event)"
        [filterList]="filterList"
        (onValueChange)="onFilterValueChange($event)"
        [filterFields]="filterFieldsArray">
    </app-mi-table>
}
